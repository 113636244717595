import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Preview = ({ className, item }) => {
	const [visible, setVisible] = useState(false);

	let setInnerHTML = (content) => {
		return (
			<div
				className={styles.content}
				dangerouslySetInnerHTML={{ __html: content }}
			></div>
		);
	};

	return (
		<div className={cn(className, styles.item, { [styles.active]: visible })}>
			<div
				className={cn(
					styles.head,
					{ [styles.active]: !visible },
					{ [styles.inactive]: visible }
				)}
				onClick={() => setVisible(!visible)}
			>
				{item?.question}
			</div>
			<div className={styles.body}>
				{setInnerHTML(item?.answer)}
				{/* <button className={cn("button-stroke button-small", styles.button)}>
          Learn more
        </button> */}
			</div>
		</div>
	);
};

export default Preview;
