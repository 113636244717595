import React, { useState, useEffect } from "react";
import styles from "./orderdetail.module.sass";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utils/API";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import cn from "classnames";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { current_datetime } from "../../controller/utils";
import { formatPrice } from "./../../controller/utils";
import useWindowSize from "../../common/hooks/useWindowSize";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

const OrderDetails = ({ sourcePage = null }) => {
  const [assets, setAssets] = React.useState();
  const [params, setParams] = useState({ limit: 10, page: 1 });
  const [count, setCount] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const cryptoCurrency = process.env.REACT_APP_CRYPTO_CURRENCY;
  const polygon =
    process.env.NODE_ENV === "development"
      ? "https://mumbai.polygonscan.com/tx/"
      : "https://polygonscan.com/tx/";
  const { id } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const [screenWidth] = useWindowSize();
  const status = useSelector((state) => state.counter.value);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;

  // page back handle using url parameter
  useEffect(() => {
    const urlParam = queryString.parse(search);
    if (urlParam?.pn) {
      setParams({ ...params, page: urlParam?.pn });
    }
  }, []);

  const handlePageClick = ({ selected: selectedPage }) => {
    setParams({ ...params, page: selectedPage + 1 });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    const urlParams = new URLSearchParams(window.location.search);
let tabNo='2'

if (urlParams.has('tab') && urlParams.get('tab') === '3') {
tabNo='3'
}


    history.push(`/dashboard?tab=${tabNo}&pn=${selectedPage + 1}`);
  };

  const getAllAssets = async () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      params,
    };
    try {
      let userData = await axiosInstance.get("user/orders/", config);
      setAssets(userData?.data?.result?.orders?.rows);
      setCount(userData?.data?.result?.totalPages);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getWindowSize = () => {
    setWidth(window.innerWidth);
  };

  const handleView = (asset) => {
    history.push({
      pathname: "/checkoutReceipt",
      state: {
        details: asset,
        name: asset?.asset?.name,
        paymentType: asset.paymentType,
        orderTx: asset?.asset?.orderTx,
      },
    });
  };

  React.useEffect(() => {
    getAllAssets(id);
    window.addEventListener("resize", getWindowSize);

    return () => window.removeEventListener("resize", getWindowSize);
  }, [status, params]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        {status ? (
          <div>
            {sourcePage != "myProfile" && (
              <div
                className={styles.title}
                style={{
                  width: "100%",
                  padding: "1.25rem 0px",
                  marginBottom: 45,
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: 600,
                  fontSize: "1.875rem",
                }}
              >
                {/* <div
                className={styles.backButtonWrapper}
                onClick={() => history.goBack()}
              >
                <img
                  src="/images/content/backArrow.svg"
                  height={40}
                  width={40}
                  style={{ marginRight: "0.625rem", opacity: 0 }}
                  alt="back"
                />
              </div> */}
                Transaction history
              </div>
            )}
           {isLoading && (
              <div className={styles.loaderContent}>
                <CircularProgress color="black" />
              </div>
            )}
            {assets?.map((asset, index) => {
              const item = asset.asset;
              return (
                <div className={styles.orderitem_main_container}>
                  <div
                    className={styles.orderitem}
                    style={{ padding: "0.313rem" }}
                    key={index}
                  >
                    <div>
                      {item.mediaType === "audio" && (
                        <div
                          className={styles.playerwrapper}
                          style={{ position: "relative", paddingTop: 0 }}
                        >
                          {/* <div> */}
                          <img
                            src={item?.audioThumbnail}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="thumbnail.png"
                          />
                          <audio
                            controls
                            controlsList="nodownload"
                            style={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              height: 25,
                              width: "100%",
                            }}
                          >
                            <source
                              src={item?.mediaPreviewUrl}
                              type="audio/mpeg"
                            />
                          </audio>
                          {/* </div> */}
                        </div>
                      )}
                      {item.mediaType === "video" && (
                        <div
                          className={styles.playerwrapper}
                          // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                          style={{ position: "relative", paddingTop: 0 }}
                        >
                          <video
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover", borderRadius: "0.313rem" }}
                            disablePictureInPicture
                            controlsList="nodownload"
                            loop="true"
                            autoplay="autoplay"
                            controls={true}
                            muted
                            playsInline
                          >
                            <source src={item?.mediaPreviewUrl} />
                            Your browser does not support the video.
                          </video>
                          {/* <ReactPlayer
                            url={item?.mediaPreviewUrl}
                            width="100%"
                            height="100%"
                            controls={true}
                            light={false}
                            style={{ objectFit: "cover" }}
                            config={{
                              file: {
                                attributes: {
                                  disablePictureInPicture: true,
                                  controlsList: "nodownload",
                                },
                              },
                            }}
                          /> */}
                        </div>
                      )}
                      {(item?.mediaType === "image" ||
                        item?.mediaType === "3D Model") && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={styles.playerwrapper}
                        >
                          <img
                            src={item.thumbnailUrl || item?.mediaPreviewUrl}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                            }}
                            alt="thumbnail.png"
                          />
                        </div>
                      )}
                    </div>

                    <div className={styles.itemdetails}>
                      <div className={styles.itemdetailsrow}>
                        <h4 style={{ flexBasis: "70%" }} data-id="itemname">
                          {/* {item?.name} */}
                          {asset?.status=="success" ? `${item?.name} #${item.currentEdition?item.currentEdition: "1"}/${item.totalEdition}`: item?.name}
                        </h4>
                        {screenWidth > 766 && (
                          <div className={styles.buttonWrapper}>
                            <button
                              className={cn("button-stroke", styles.button)}
                              onClick={() => handleView(asset)}
                              style={{
                                height: "3rem",
                                padding: "0px 2rem",
                                width: "7.25rem",
                              }}

                            >
                              View
                            </button>
                            {asset?.orderTx && (
                              <a
                                href={`
                              ${process.env.REACT_APP_POLYGON_LINK}tx/${asset?.orderTx}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  width: "230px",
                                  marginLeft: "0.4em",
                                }}
                                className={cn("button-stroke", styles.button)}
                                data-id="viewScan"
                              >
                                <img
                                  src="/images/polygon.svg"
                                  alt="polygon"
                                  style={{ marginRight: "0.313rem" }}
                                />
                                View on polygon scan
                              </a>
                            )}
                          </div>
                        )}
                        {/* <h4>
                            {"Order Id: " + asset.id}
                            </h4> */}
                      </div>
                      <div className={styles.itemdetailsrow}>
                        {screenWidth > 766 && (
                          <div>
                            <p className={styles.bold}>Date :</p>

                            <b>
                              {moment.utc(asset?.createdAt).local().format('DD/MM/YYYY    h:mm:ss A') || "-"}
                            </b>
                          </div>
                        )}
                        <div>
                          <p className={styles.bold}>Price :</p>
                          <b className={styles.flexCenter}>
                            {" "}
                            {/* &#36; {(asset?.totalPrice || "0") + " "}({" "}
                            {(Number(asset?.totalPrice) * euroValue).toFixed(2)}{" "} */}
                             &#36; {Number(asset?.totalPrice || "0").toFixed(2) + " "}({" "}
                            {(Number(asset?.totalPrice)).toFixed(2)}{" "}
                            <img
                              src={`/images/usdc-logo.png`}
                              alt="USDC"
                              className={styles.maticSymbol}
                            />
                            ){" "}
                          </b>
                        </div>
                        {screenWidth > 766 && (
                          <div data-id="o_status">
                            <p className={cn(styles.bold)}>Status :</p>
                            <div
                              className={
                                asset?.status == "pending" ||
                                asset?.status == "queue"
                                  ? styles.pending
                                  : asset?.status == "failed" || asset?.status == "time_out"
                                  ? styles.failed
                                  : styles.success
                              }
                            >
                              <p> {asset?.status === 'time_out' ? "failed" :asset?.status }</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {screenWidth < 767 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        justifyContent: "space-between",
                      }}
                    >
                      <p className={styles.createdAt}>
                        {moment.utc(asset?.createdAt).local().format('DD/MM/YYYY    h:mm:ss A') || "-"}
                      </p>

                      <div
                        className={
                          asset?.status == "pending" || asset?.status == "queue"
                            ? styles.pending
                            : asset?.status == "failed" || asset?.status == "time_out"
                            ? styles.failed
                            : styles.success
                        }
                      >
                              <p> {asset?.status === 'time_out' ? "failed" :asset?.status }</p>
                      </div>
                    </div>
                  )}
                  {screenWidth < 767 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.625rem",
                      }}
                    >
                      {asset?.orderTx ? (
                        <div className={styles.viewOnPolygon}>
                          <a
                            href={`
                              ${process.env.REACT_APP_POLYGON_LINK}tx/${asset?.orderTx}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "black" }}
                            data-id="viewScan"
                          >
                            <img src="/images/polygon.svg" alt="polygon" />
                            View on polygon scan
                          </a>
                        </div>
                      ) : (
                        <div></div>
                      )}
                     
                      <button
                              className={cn("button-stroke", styles.button)}
                              onClick={() => handleView(asset)}
                              style={{
                                height: "1.5rem",
                                padding: "0px 2rem",
                                width: "fit-content",
                              }}
                            >
                              View
                            </button>
                    </div>
                  )}
                </div>
              );
            })}
            {assets?.length === 0 && (
              <div className={`noRecordsFound ${styles.noRecordsFound}`}>
                <span>No records found.</span>
              </div>
            )}
            {!isLoading && assets?.length > 0 && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={count}
                forcePage={params?.page - 1}
                onPageChange={handlePageClick}
                containerClassName={styles.pagination}
                previousLinkClassName={cn(
                  styles.pagination__link,
                  "page_switch"
                )}
                nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                pageClassName={"page_no"}
                disabledClassName={styles.pagination__link__disabled}
                activeClassName={styles.pagination__link__active}
                pageRangeDisplayed={width < 510 ? 1 : 3}
                marginPagesDisplayed={width < 510 ? 1 : 3}
              />
            )}
          </div>
        ) : (
          <div
            style={{
              width: "90%",
              padding: "1.875rem 5%",
              margin: "auto",
              fontSize: "0.938rem",
            }}
          >
            Login to view details
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
