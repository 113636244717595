import { useHistory } from "react-router";
import styles from "../../ViewProfile/viewprofile.module.sass";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatPrice } from "../../../controller/utils";
const CollectionCard = ({ details, currentTab, profileDetails }) => {
  console.log(
    "collection.details, current tab, profileDetails",
    details,
    currentTab,
    profileDetails
  );
  const history = useHistory();
  const walletStatus = useSelector((state) => state?.counter?.value);
  const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
  const item = details.Assets;
  const cardImageValue = (type) => {
    return (
      <img
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push(`/collectionDetails/${details?.id}`);
        }}
        src={details?.imagePath || details?.Asset?.mediaPreviewUrl}
        alt="logo"
      ></img>
    );
  };

  const getPrice = (asset) => {
    if (asset?.saleType == "fixed") {
      return asset?.regularPrice;
    } else {
      return asset?.auction?.currentBid;
    }
  };
  return (
    <>
      <div className={styles.card}>
        <div
          className={styles.previewImage}
          onClick={() => {
            console.log(currentTab);
            if (currentTab == 1) {
              console.log("Clicked");
              history.push(`/collectionDetails/${details?.id}`);
            }
          }}
        >
          {cardImageValue(details?.Asset?.mediaType)}
          <p>{details?.name}</p>
        </div>
        <div>
          <div className={styles.logoOwnerWishlist}>
            <div className={styles.logoOwner}

            >
              <img

                src={
                  details?.assets[0]?.creator?.profilePicUrl || "/images/content/Avatar.png"
                }
                alt="logo"
              ></img>
              <div>
                <h5>{"Created by"}</h5>
                <p>
                  @
                  {details?.assets[0]?.creator?.userName
                    ? details?.assets[0]?.creator?.userName
                    : details?.assets[0]?.creator?.userName}
                </p>
              </div>
            </div>
            <div></div>
          </div>

          {/* <div className={styles.priceRow}>
            <p>Price</p>
            <p>${getPrice(details?.price)}</p>
          </div> */}
          <div className={styles.buyNowOrBidNow}>
            <button
              className={styles.buy}
              onClick={() => {
                history.push(`/collectionDetails/${details?.id}`);
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionCard;
