import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import counterReducer from "../redux/counterSlice";
import { persistReducer } from "redux-persist";
import keyReducer from "../redux/keySlice";
import circleReducer from "../redux/circlePaymentSlice";
import accountReducer from "../redux/accountSlice";
import utilityReducer from "../redux/utilitySlice";
import profileReducer from '../redux/profileSlice';
import usdcReducer from '../redux/usdcSlice';
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["accountReducer"],
};

const reducers = combineReducers({
  counter: counterReducer,
  keyVal: keyReducer,
  circlePayment: circleReducer,
  accountReducer: accountReducer,
  utilityReducer: utilityReducer,
  profileReducer : profileReducer,
  usdc : usdcReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
