import React, { useState, useEffect } from "react";
import styles from "../Earnings.module.sass";
import { axiosInstance } from "../../../utils/API";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import cn from "classnames";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { current_datetime } from "../../../controller/utils";
import useWindowSize from "../../../common/hooks/useWindowSize";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment";

const EarningsSale = ({
  count,
  isCreator,
  params,
  setParams,
  data,
  menuIndex,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { id } = useParams();
  const history = useHistory();
  const [screenWidth] = useWindowSize();
  const status = useSelector((state) => state.counter.value);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;

  const handlePageClick = ({ selected: selectedPage }) => {
    setParams({ ...params, page: selectedPage + 1 });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    const urlParams = new URLSearchParams(window.location.search);
    let tabNo='0'
    if (urlParams.has('tab') && urlParams.get('tab') === '1') {
      tabNo='1'
    }
    history.push(`/earnings?tab=${tabNo}&pn=${selectedPage + 1}`);
  };

  const getWindowSize = () => {
    setWidth(window.innerWidth);
  };

  const handleView = (asset) => {
    history.push({
      pathname: "/checkoutReceipt",
      state: {
        details: asset,
        name: asset?.asset?.name,
        paymentType: asset.paymentType,
        orderTx: asset?.asset?.orderTx,
      },
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", getWindowSize);
    return () => window.removeEventListener("resize", getWindowSize);
  }, [status, params]);

  return (
    <div className={cn("section", styles.section)}>

      {status ? (


        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Asset Name</TableCell>
                  <TableCell>Buyer Name</TableCell>
                  <TableCell>Amount</TableCell>
                  {isCreator && <TableCell align="">Commission</TableCell>}
                  <TableCell>Date</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item?.asset?.name }
                      {/* {item?.asset?.name + " #" + item?.asset?.currentEdition+ "/" +item?.asset?.totalEdition} */}
                    </TableCell>
                    <TableCell align="">
                      {
                        item?.order?.user.userName
                      }</TableCell>
                    <TableCell>
                      ${Number(item?.payableAmount)?.toFixed(
                        4
                      )}
                    </TableCell>
                    {isCreator &&
                      <TableCell align=""> 
                        {item?.order?.royaltyCommission
                          ? Number(
                            menuIndex == 1
                              ? item?.userAssetCommission.percentage
                              : item?.order?.royaltyCommission
                          )?.toFixed(1)
                          : "0"}%</TableCell>
                    }
                    <TableCell>
                      {moment.utc(item?.createdAt).local().format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align = "">
                      <a
                      href={`
                      ${process.env.REACT_APP_POLYGON_LINK}tx/${item?.order?.orderTx}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      > 
                        View
                      </a>
                  </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {data?.length === 0 && (
            <div className={`noRecordsFound ${styles.noRecordsFound}`}>
              <span>No records found.</span>
            </div>
          )}
          {data?.length > 0 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={count}
              forcePage={params?.page - 1}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={cn(styles.pagination__link, "page_switch")}
              nextLinkClassName={cn(styles.pagination__link, "page_switch")}
              pageClassName={"page_no"}
              disabledClassName={styles.pagination__link__disabled}
              activeClassName={styles.pagination__link__active}
              pageRangeDisplayed={width < 510 ? 1 : 3}
              marginPagesDisplayed={width < 510 ? 1 : 3}
            />
          )}

        </div>
      ) : (
        <div
          style={{
            width: "90%",
            padding: "1.875rem 5%",
            margin: "auto",
            fontSize: "0.938rem"
          }}
        >
          Login to view details
        </div>
      )}
    </div>
  );
};

export default EarningsSale;
