import { useEffect, useRef } from "react";
import snsWebSdk from "@sumsub/websdk";
import { axiosInstance } from "../utils/API";

const useSumsubWebSDK = (fetchNewAccessTokenFromBackend, accessToken, getProfileDetails) => {
  // Use useRef to store the SDK instance across renders
  const sdkInstanceRef = useRef(null);
  console.log("sumsub.hook.accessToken ", accessToken);
  let applicantEmail = "vishnuv@gmail.com",
    applicantPhone = "999999999",
    customI18nMessages = "Hi";

  useEffect(() => {
    console.log("sumsub.hitCustomHook");
    // Function to initialize and launch the Sumsub Web SDK
    const launchWebSdk = async () => {
      // Check if the SDK instance exists, if not, initialize it
      if (!sdkInstanceRef.current) {
        // Initialize the SDK with provided options
        const snsWebSdkInstance = snsWebSdk
          .init(
            accessToken,
            () => getNewAccessToken() // Token update callback
          )
          .withConf({
            lang: "en", // Language of WebSDK texts and comments (ISO 639-1 format)
            // email: applicantEmail, // Applicant email (optional)
            // phone: applicantPhone, // Applicant phone number (optional)
            // i18n: customI18nMessages, // Customized locale messages for the current session (optional)
            uiConf: {
              customCss: "https://url.com/styles.css", // URL to custom CSS file (optional)
            },
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true }) // Set additional options
          .on("idCheck.stepCompleted", (payload) => {

            console.log("each step complete --->")
            console.log("stepCompleted", payload); // Event handler for step completion

            // calling profile api on each step 
            console.log("calling profile api");
            getProfileDetails();
          })
          .on("idCheck.onError", (error) => {
            console.log("onError", error); // Event handler for errors
          })
          .build(); // Build the instance of the SDK

        // Store the SDK instance in the ref for future reference
        sdkInstanceRef.current = snsWebSdkInstance;
      }

      // If the SDK instance exists, launch the WebSDK by providing the container element
      if (sdkInstanceRef.current) {
        sdkInstanceRef.current.launch("#sumsub-websdk-container");
      }
    };

    // Function to fetch a new access token from the backend
    const getNewAccessToken = async () => {
      try {
        // Replace this function with actual API call to fetch a new access token
        const newAccessToken = await fetchNewAccessTokenFromBackend();
        return Promise.resolve(newAccessToken);
      } catch (error) {
        console.error("Error fetching new access token:", error);
        return Promise.resolve(null);
      }
    };

    // Call the function to launch the WebSDK when component mounts or when dependencies change
    launchWebSdk();

    // Return a cleanup function (if needed)
    return () => {
      // Cleanup logic (if needed)
    };
  }, [accessToken, applicantEmail, applicantPhone, customI18nMessages]);
};

export default useSumsubWebSDK;
