import { useEffect, useRef } from "react";

export const useThrottle = (callback, dependencyArray, delay) => {
  const savedCallback = useRef(callback);
  const throttleRef = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!throttleRef.current) {
      throttleRef.current = setTimeout(() => {
        throttleRef.current = null;
        savedCallback.current();
      }, delay);
    }

    return () => {
      if (throttleRef.current) {
        clearTimeout(throttleRef.current);
        throttleRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...(dependencyArray || [])]);
};
