import styles from "./dash.module.sass";
import cn from "classnames";
import { axiosInstance } from "../../utils/API";
import { useEffect, useState } from "react";
import { PopUpAlert, magic, maticWeb3 } from "../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DashTabs from "./DashTab";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import useWindowSize from "../../common/hooks/useWindowSize";
import copy from "copy-to-clipboard";
import TrackChangesRoundedIcon from "@material-ui/icons/TrackChangesRounded";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SecurityIcon from "@mui/icons-material/Security";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import VerifyKYCButton from "../KYCSumSub/VerifyKYCButton";
import Web3 from "web3";
import { setMagicUser } from "../../redux/counterSlice";
import TopUpWalletWert from "../WertPayment/topUpWalletWert";
import Avatar from "@mui/material/Avatar";
import TransparentModal from "../../components/TransparentModal";
import { getUsdcBalanceFromAccount } from "../../utils/balance";
const Dashboard = () => {
  const loginStatus = useSelector((state) => state.counter.value);
  let circleBalance = useSelector((state) => state.circlePayment.balance);
  const userAccount = useSelector((state) => state?.counter?.user);
  const usdcWalletBalance = useSelector((state) => state?.usdc?.amount)
  console.log(usdcWalletBalance, 'usdcWalletBalance')
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const accountDetails = useSelector((state) => state?.accountReducer);
  let loginWith = localStorage.getItem("network");
  console.log("KYC.Account.KYCStatus : ", magicLinkUser);
  console.log({ userAccount });
  const getConfig = () => {
    if (!localStorage.getItem("userToken")) {
      PopUpAlert("Alert", "No wallet connection found", "error");
      return;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    return config;
  };

  const [userDetails, setUserDetails] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [screenWidth] = useWindowSize();
  const [copied, setCopied] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [KYCModalOpen, setKYCModalOpen] = useState(false);
  const [openTopUpModal, setOpenTopUpModal] = useState(false);
  const [mountTransparent, setMountTransparent] = useState(false);

  const profile = useSelector((state) => state?.profileReducer);
  const dispatch = useDispatch();
  const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
  // const [copied2, setCopied2] = useState(false);

  useEffect(() => {
    //enable scrolling
    var body = document.querySelector("body");
    body.style.overflow = "auto";
  }, []);

  //Fetch User Data
  const getUserData = () => {
    // const config = getConfig();
    // axiosInstance
    //   .get("user/get-profile", config)
    //   .then((res) => {
    //     console.log(res);
    //     setUserDetails(res?.data?.result);
    //     setProfilePic(res?.data?.result?.profilePicUrl);
    //   })
    //   .catch((err) => {
    //     PopUpAlert("Alert", "Unable to fetch user details", "error");
    //     window.location.reload()
    //   });
    console.log(profile, "profile");
    setUserDetails(profile);
    setProfilePic(profile?.profilePicUrl);
  };
  const copiedToastHandler = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3500);
  };
  // Call Api everytime rerender
  useEffect(() => {
    getUserData();
  }, []);

  // Copy to clipboard handler
  const copyToClipboard = (content, type) => {
    copy(content);
    setCopied(true);
    copiedToastHandler();
    // setTimeout(()setCopied(false), 2000);
  };

  // Magiclink enable MFA functionality
  const handleToggleMagicMFA = async () => {
    setMountTransparent(true);
    await magic.user.showSettings();
    setMountTransparent(false);
    const userMetadata = await magic.user.getMetadata();
    let magicBalance = await fetchBalance(userMetadata?.publicAddress);
    userMetadata.magicBalance = magicBalance;
    dispatch(setMagicUser(userMetadata));
  };

  //Check Magiclink Balance

  const fetchBalance = (address) => {
    let web3 = new Web3(maticWeb3);
    return web3.eth.getBalance(address).then((bal) => web3.utils.fromWei(bal));
  };
// const [usdcWalletBalance, setUsdcWalletBalance]= useState(null)
//   useEffect(() => {
//     fetchUsdBalance()
//   }, [userAccount?.account?.[0]]);

//   const fetchUsdBalance = async () => {
//     if (userAccount?.account?.[0]) {
//       let address = userAccount?.account?.[0];
//       let balance = await getUsdcBalanceFromAccount(address);

//       console.log("usdc balance is",balance)
//       setUsdcWalletBalance(balance)
//     }
//   };

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          {loginStatus ? (
            <>
              <div className={styles.profileContainer}>
                <div className={`${styles.wrapper} ${styles.info}`}>
                  <div className={styles.profilePic}>
                    {/* {profilePic ? (
                      <img src={profilePic} alt="profile"></img>
                    ) : (
                      <img src="./images/content/Avatar.png" alt="avatar" />
                    )} */}
                    <Avatar
                      alt="avatar"
                      sx={{ height: "4.5rem", width: "4.5rem" }}
                      src={
                        profilePic ? profilePic : "./images/content/Avatar.png"
                      }
                    />
                  </div>

                  <div className={styles.userDetails}>
                    <h3>
                      {userDetails?.firstName} {userDetails?.lastName}
                    </h3>
                    <p>@{userDetails?.userName}</p>
                      <div vlassName = {styles.optionalButtonsDiv}>
                    <Link to="/my-account">
                      <div
                        className={`${styles.dashButton} ${styles.btn_black}`}
                      >
                        <img
                          src={
                            screenWidth > 1630
                              ? "/images/content/edit.svg"
                              : "/images/white_edit_icon.svg"
                          }
                          alt="edit"
                        ></img>
                        Edit profile
                      </div>
                    </Link>
                    {Number(accountDetails?.kycStatus) === 0 ? (
                      <div
                        className={`${styles.dashButton} ${styles.btn_black}`}
                        onClick={() =>
                          setKYCModalOpen({ open: true, mode: "kyc" })
                        } // Open the modal on button click
                      >
                        <img
                          src={
                            screenWidth > 1630
                              ? "/Icons/user-check.svg"
                              : "/Icons/user-check-white.svg"
                          }
                          alt="edit"
                          width={20}
                        ></img>
                        Complete KYC
                      </div>
                    ) : Number(accountDetails?.kycStatus) === 1 ? (
                      <div
                        className={`${styles.dashButton} ${styles.btn_black}`}
                      >
                        <VerifiedUserIcon />
                        KYC Verified
                      </div>
                    ) : (
                      <div
                        className={`${styles.dashButton} ${styles.btn_black}`}
                        onClick={() =>
                          setKYCModalOpen({ open: true, mode: "kyc" })
                        } // Open the modal on button click
                      >
                        <img
                          src={
                            screenWidth > 1630
                              ? "/Icons/user-check.svg"
                              : "/Icons/user-check-white.svg"
                          }
                          alt="edit"
                          width={20}
                        ></img>
                        Complete KYC
                      </div>
                    )}
                    </div>
                    {/* {Number(accountDetails?.emailVerified) === 0 ? (
                      <div
                        className={`${styles.dashButton} ${styles.btn_black}`}
                        onClick={() =>
                          setKYCModalOpen({ open: true, mode: "kycEmail" })
                        } // Open the modal on button click
                      >
                        <MarkEmailUnreadIcon />
                        Complete email verification
                      </div>
                    ) : Number(accountDetails?.emailVerified) === 1 ? (
                      <div
                        className={`${styles.dashButton} ${styles.btn_black}`}
                      >
                        <MarkEmailReadIcon />
                        Email verified
                      </div>
                    ) : (
                      <div
                        className={`${styles.dashButton} ${styles.btn_black}`}
                        onClick={() =>
                          setKYCModalOpen({ open: true, mode: "kycEmail" })
                        } // Open the modal on button click
                      >
                        <MarkEmailUnreadIcon />
                        Complete email verification
                      </div>
                    )} */}
                    {KYCModalOpen.open ? (
                      <VerifyKYCButton
                        KYCModalOpen={KYCModalOpen}
                        setKYCModalOpen={setKYCModalOpen}
                        mode={KYCModalOpen.mode}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className={styles.wrapper}>
                  <div className={styles.wrap}>
                    <div className={styles.walletDivider}>
                    <div
                        // className={styles.userDetails}
                        styles={{
                          // flex: screenWidth < 1061 ? "0 0 50%" : null,
                        }}
                      >
                        <div className={styles.rightWallet}>
                          {/* {screenWidth > 1061 && ( */}
                            {loginWith == "metamask" && <div className={styles.profilePic} style = {{minWidth:'4rem'}}>
                              <img
                                src={
                                   "/images/metamask.png"
                                }
                                alt="wallet"
                              />
                            </div>}
                            {loginWith !== "metamask" && <div style = {{minWidth:'4rem'}}>
                              <img
                                src={
                                    "/images/liiLogoArabic.png"
                                }
                                style = {{ width:'3rem', height:'4rem'}}
                                alt="wallet"
                              />
                            </div>}
                          {/* )} */}
                          <div className = {styles.cryptoRow} > 
                            {/* {screenWidth < 1061 && (
                              <img
                                src={
                                  loginWith == "metamask"
                                    ? "/images/metamask.png"
                                    : "/images/magiclink.png"
                                }
                                alt="wallet"
                                style={{
                                  paddingBottom: "0.625rem",
                                  height: "2.063rem",
                                }}
                              />
                            )} */}
                            <div style = {{flexGrow:2}}>
                            
                            <h4>Lii wallet balance</h4>
                            
                            <p>
                                {`${userAccount?.account?.[0]?.substring(
                                  0,
                                  6
                                )}......${userAccount?.account?.[0]?.substr(
                                  -6
                                )}`}
                              <img
                                src="/Icons/copy.svg"
                                alt="copy"
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  width: 17,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  copyToClipboard(userAccount?.account?.[0])
                                }
                              ></img>
                              {showToast && "Copied"}

                              </p>


                            
                            </div>
                            {loginWith == "metamask" ? (
                        <></>
                        ) : (
                          // <div className={styles.mailLoginWrapper}>
                          <>
                            <div
                              className={styles.dashButton}
                              style={{marginRight:'1.5rem', height:'fit-content'}}
                              // style={{
                              //   marginLeft: "5em",
                              // }}
                              onClick={() => {
                                window.open(
                                  "https://reveal.magic.link/lii",
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {/* <img src="/Icons/copy.svg" alt="copy"></img> */}
                              <TrackChangesRoundedIcon
                                style={{ fontSize: "1.563rem" }}
                              />{" "}
                              Reveal Private Keys
                            </div>
                            <div
                              className={styles.dashButton}
                              style={{
                                height:'fit-content'
                              }}
                              onClick={handleToggleMagicMFA}
                            >
                              {mountTransparent && <TransparentModal />}
                              {magicLinkUser?.isMfaEnabled ? (
                                <RemoveModeratorIcon />
                              ) : (
                                <SecurityIcon
                                  style={{ fontSize: "1.563rem" }}
                                />
                              )}{" "}
                              {magicLinkUser?.isMfaEnabled
                                ? "Disable MFA"
                                : "Enable MFA"}
                            </div>
                          </>
                        )}
                            {/* <div style = {{width:'30%'}}></div>
                            <div className={styles.flexStraightAlign}> */}
                             
                              {/* {loginWith !== "metamask" && (
                              )} */}
                              
                                {/* <div
                            className={styles.dashButton}
                            style={{
                              // marginLeft: screenWidth > 1061 ? "5em" : null,
                            }}
                            onClick={() =>
                              copyToClipboard(userAccount?.account?.[0])
                            }
                          >
                            <img src="/Icons/copy.svg" alt="copy"></img>
                            {copied ? "Copied" : "Copy"}
                          </div> */}
                            {/* </div> */}
                          </div>
                        </div>

                       
                        {/* <div
                          className={styles.dashButton}
                          style={{
                            marginLeft: screenWidth > 1061 ? "5em" : null,
                          }}
                          onClick={() => setOpenTopUpModal(true)}
                        >
                          <img src="/Icons/zap.svg" alt="topup"></img>
                          Topup Wallet
                        </div>
                        {openTopUpModal ? (
                          <TopUpWalletWert
                            setOpenTopUpModal={setOpenTopUpModal}
                            walletId={walletId}
                          />
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div
                        className={styles.rightWallet}
                        // styles={{
                        //   flex: screenWidth < 1061 ? "0 0 50%" : null,
                        // }}
                      >
                        {" "}
                        
                          {/* <div className={styles.profilePic}>
                           
                          </div> */}
                        
                        <div
                          className={styles.cryptoRowTwo}
                          // style={{marginLeft:'4rem'}}
                        >
{/*                           
                            {screenWidth < 1061 && (
                              <img
                                src="/images/usdc-logo.png"
                                alt="wallet"
                                style={{
                                  paddingBottom: "0.625rem",
                                  height: "2.1rem",
                                }}
                              />
                            )} */}
                            <div style = {{display: 'flex'}}>
                             <h3>{usdcWalletBalance!==null ? usdcWalletBalance==0?'0.00': usdcWalletBalance.toFixed(2) : "-"}</h3>
                            <img src="/images/usdc-logo.png" alt="wallet" className={styles.usdcLogo} style = {{}}/>
                            </div>
                            <div>
                            {/* <p>USDC Token balance</p> */}
                            <h3 className={styles.flexCenter}>
                              {loginWith == "metamask"
                                ? userAccount?.balance
                                  ? Number(userAccount?.balance)?.toFixed(2)
                                  : "0.00"
                                   : magicLinkUser?.magicBalance
                                      ? Number(
                                          magicLinkUser?.magicBalance
                                        ).toFixed(2)
                                      : "0"
                                  }{" "}
                              <img
                                src={`/images/matic.svg`}
                                alt="matic"
                                className={styles.maticSymbol}
                              />
                            </h3>
                            <div style = {{display:'flex', width:'100%', justifyContent:'center'}}>
                            <p
                              style={{
                                // fontSize: screenWidth < 1061 ? "1em" : "",
                                fontWeight:"600",
                              }}
                            >
                              {loginWith == "metamask"
                                ? userAccount?.balance
                                  ? (
                                      Number(userAccount?.balance) / euroValue
                                    ).toFixed(2)
                                  : "0"
                                    :magicLinkUser?.magicBalance
                                      ? (
                                          Number(magicLinkUser?.magicBalance) /
                                          euroValue
                                        ).toFixed(2)
                                      : "0"
                                  }{" "}
                              USD
                            </p>
                            </div>
                            </div>
                          
                          <Link to="/wallet">
                            <div className={styles.dashButton}>
                              <img
                                src="/images/content/edit.svg"
                                alt="edit"
                              ></img>
                              Manage balance
                            </div>
                          </Link>
                        </div>
                      </div>
                      
                      {/* <hr className={styles.divider} /> */}
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dashtab}>
                <DashTabs />
              </div>
            </>
          ) : (
            <div>
              <div style={{ textAlign: "center", fontSize: "0.938rem" }}>
                Connect wallet or login to view profile.
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
