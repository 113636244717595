import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./CirclePayment.module.sass";
import WalletDisplayBar from "./WalletDisplayBar";
import TransactionHistory from "./TransactionHistory";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw/Withdraw";
import { axiosInstance } from "../../utils/API";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCirlceDetails, setCirlceId } from "../../redux/circlePaymentSlice";
import { setRefresh } from "../../redux/utilitySlice";
import { PopUpAlert } from "../../controller/utils";
import useWindowSize from "../../common/hooks/useWindowSize";
import { getUsdcBalanceFromAccount } from "../../utils/balance";

function CirclePaymentIndex() {
	const [openDepoist, setOpenDepoist] = useState(false);
	const [openWithdraw, setOpenWithdraw] = useState(false);
	const [refreshPage, setRefreshPage] = useState(true);
	const dispatch = useDispatch();
	const loginStatus = useSelector((state) => state.counter.value);
	let circleBalance = useSelector((state) => state.circlePayment.balance);
	let walletId = useSelector((state) => state.circlePayment.walletId);
	let refresh = useSelector((state) => state.utilityReducer.refresh);
	const history = useHistory();
	const [screenwidth] = useWindowSize();

	const userAccount = useSelector((state) => state?.counter?.user);
	const [usdcWalletBalance, setUsdcWalletBalance]= useState(null)
	  useEffect(() => {
		fetchUsdBalance()
	  }, [userAccount?.account?.[0]]);
	
	  const fetchUsdBalance = async () => {
		if (userAccount?.account?.[0]) {
		  let address = userAccount?.account?.[0];
		  let balance = await getUsdcBalanceFromAccount(address);
		  setUsdcWalletBalance(balance)
		}
	  };

	useEffect(() => {
		const getAccountDetails = async () => {
			await getAccountStatus();
			if (!walletId) {
				await createCircleWallet();
			}
			// await getCircleAccount();
		};

		const checkLoggedIn = () => {
			const token = localStorage.getItem("userToken");
			console.log({ token });
			if (!token) {
				// history.push("/Marketplace");
				PopUpAlert("info", "Please login and proceed to wallet.", "error").then(
					(res) => {
						document.getElementById("connectWallet").click();

						return false;
					}
				);
			}
			return true;
		};
		const isLoggedIn = !checkLoggedIn();
		getAccountDetails();

		// return () => {

		// }
	}, [loginStatus]);

	const getAccountStatus = async () => {
		let config = {
			headers: {
				Authorization: localStorage.getItem("userToken"),
			},
		};
		const result = await axiosInstance
			.get("/user/wallets/balance", config)
			.then((result) => {
				// getETHPrice();
				setRefreshPage(!refreshPage);
				dispatch(setCirlceDetails(result.data?.result));
				dispatch(setRefresh(!refresh));
			})
			.catch((err) => {
				if (err?.response?.status == 401)
					PopUpAlert("Alert", err.response?.data?.message);
			});
	};

	const getCircleAccount = async () => {
		let config = {
			headers: {
				Authorization: localStorage.getItem("userToken"),
			},
		};

		let payload = {
			currency: "USD",
			chain: "ETH",
		};
		if (walletId) {
			const result = await axiosInstance
				.post(`/user/blockchain/${walletId}/address`, payload, config)
				.then((result) => {
					console.log("Payload", result);
					dispatch(setCirlceId(result.data?.result));
				})
				.catch((err) => {
					if (err?.response?.status == 401)
						PopUpAlert("Alert", err.response?.data?.message);
				});
		}
	};

	const createCircleWallet = async () => {
		let config = {
			headers: {
				Authorization: localStorage.getItem("userToken"),
			},
		};

		const result = await axiosInstance
			.post("/user/wallets", {}, config)
			.then((result) => {
				console.log("Result createCirclewallet", result);
				dispatch(setCirlceDetails(result.data?.result));
				setRefreshPage(!refreshPage);
			})
			.catch((err) => {});
	};

	const getBackButton = () => {
		return (
			<div
				className={styles.backArrowContainer}
				onClick={() => history.goBack()}
			>
				<img
					src={"/Icons/backArrow.svg"}
					alt="back arrow"
				
					style={{ marginTop: "0.25rem" ,
					height:"1.25rem",
					width:"1.25rem"}}
				/>
			</div>
		);
	};

	return (
		<>
			{loginStatus ? (
				<div className={cn("container", styles.container)}>
					{screenwidth < 767 && (
						<div style={{ paddingTop: "1.25rem" }}>{getBackButton()}</div>
					)}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						{screenwidth > 766 && getBackButton()}
						<WalletDisplayBar
							openDepositModal={() => setOpenDepoist(!openDepoist)}
							openWithdrawModal={() => setOpenWithdraw(!openWithdraw)}
							usdcWalletBalance = {usdcWalletBalance ? usdcWalletBalance.toFixed(2) : "-"}
							// refreshPage={refreshPage}
						/>
					</div>
					<TransactionHistory
						setRefreshPage={setRefreshPage}
						refreshPage={refreshPage}
						screenwidth={screenwidth}
					/>
					{/* <div style={{ display: "flex", justifyContent: "center", marginTop: '1.25rem' }}>
          <Deposit />
        </div> */}
					{openDepoist ? (
						<Deposit
							closePopup={() => setOpenDepoist(false)}
							setRefreshPage={setRefreshPage}
							refreshPage={refreshPage}
						/>
					) : null}
					{openWithdraw ? (
						<Withdraw
							closePopup={() => setOpenWithdraw(false)}
							setRefreshPage={setRefreshPage}
							refreshPage={refreshPage}
							usdcWalletBalance = {usdcWalletBalance ? usdcWalletBalance.toFixed(2) : "-"}
						/>
					) : null}
				</div>
			) : (
				<div>
					<div style={{ textAlign: "center", fontSize: "0.938rem" }}>
						Login to view wallet.
					</div>
				</div>
			)}
		</>
	);
}

export default CirclePaymentIndex;
