import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function VariantsMobile() {
  return (
    <>
      {/* <Stack spacing={2}> */}
      {/* For other variants, adjust the size with `width` and `height` */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20%",
          justifyContent:"center",
          alignItems : 'center',
          width: "100%",
          padding: 40
        }}
      >
        
        <div style = {{width:'100%', marginBottom:'50px'}}>
            
        <div style={{ display: "flex", flexDirection: "row", justifyContent:"center", alignItems:'center'}}>
            <Skeleton variant="rectangular" width={200} height={400} />
            <Skeleton variant="rectangular" width={200} height={400} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
         
            width: "80%",
          }}
        >
             <div style={{ display: "flex", flexDirection: "row"}}>
            <Skeleton variant="rectangular" width={400} height={50} />
            <Skeleton variant="rectangular" width={400} height={50} />
          </div>
          
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Skeleton variant="rectangular" width={200} height={50} />
            <Skeleton variant="rectangular" width={200} height={50} />
          </div>
          <Skeleton variant="rectangular" width={200} height={50} />
          <div style={{ display: "flex", flexDirection: "row"}}>
            <Skeleton variant="rectangular" width={400} height={100} />
            <Skeleton variant="rectangular" width={400} height={100} />
          </div>
          <div style={{ display: "flex", flexDirection: "row"}}>
            <Skeleton variant="rectangular" width={400} height={100} />
            <Skeleton variant="rectangular" width={400} height={100} />
          </div> 
           
           <div style={{ display: "flex", flexDirection: "row"}}>
            <Skeleton variant="rectangular" width={400} height={300} />
            <Skeleton variant="rectangular" width={400} height={300} />
          </div> 
          
        </div>
      </div>
      {/* </Stack> */}
    </>
  );
}
