import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setEuroValue } from "../../redux/counterSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Page = ({ children }) => {
  const { pathname } = useLocation();
  const cookieRead = localStorage.getItem("cookieRead");
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
    if (pathname.includes("search01")) {
      document.title = "lii - categories";
    } else if (pathname === "/") {
      document.title = "lii";
    } else if (pathname === "/item") {
      document.title = "lii - PurchasedAssets";
    } else {
      document.title = "lii - " + pathname.substring(1);
    }
  }, [pathname]);

  const dispatch = useDispatch();

 

  const getpricefeed = async () => {
    await axios.get(process.env.REACT_APP_RATE_API).then((res) => {
      console.log({ res });
      dispatch(setEuroValue(res.data?.result?.exchangeRate));
    });
  };


 
  useEffect(() => {
    if(pathname !== '/search01/all') getpricefeed()    
    // dataEncrypt();
    // const myInterval = setInterval(() => {
    //   getpricefeed()
    // }, 60000*2)

    // return () => {
    //   clearInterval(myInterval);
    // };
  });


  // const dataEncrypt = () => {
  //   axiosInstance.get("/user/getClientVariables").then(async (res) => {
  //     console.log("I am wait");
  //     await apiCall(res);
  //     console.log("Inside", res);
  //     dispatch(keyDetails(res?.data?.result));
  //   });


  // };
  

  const applyClass = pathname.includes('collectionDetails')?true:false

  return (
    <div className={applyClass?"":styles.page}>
      <Header/>
      
      <div className={styles.inner}>{children}</div>

      <Footer />
    </div>
  );
};

export default withRouter(Page);
