const AboutSantaCasa = () => {
    return (
      <>
      <div style={{marginTop:'3.125rem'}} className="container">
      <h2>About Santa Casa da Misericórdia de Lisboa</h2>
       <div className="about section" >
       With more than five centuries of existence, the Santa Casa da Misericórdia de Lisboa is a private institution of public administrative utility that has guided its performance, in the community, by the defense of priceless social values, in a perspective of proximity, respect and advocacy for good causes.

       <a style={{color:"#ff7acb"}} href="https://en.scml.pt/"> https://en.scml.pt/</a>
       </div>
      </div>
      <br/>
      <br/>
      </>
    );
  };
  
  export default AboutSantaCasa;