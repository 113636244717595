import React from "react";
import ReactPlayer from "react-player";
import cn from "classnames";
import styles from "../Popular/Popular.module.sass";
import { PopUpAlert } from "../../../controller/utils";
import { useCountdown } from ".././../../customHooks/CountDownTimer";
import { axiosInstance } from "../../../utils/API";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { useSelector } from "react-redux";

function CardHomePage(props) {
  let { index, fixed, history, x, walletStatus, document, setRefreshClick } =
    props;
  const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
  const userToken = localStorage.getItem("userToken");
  const screenheight = window.screen.availHeight;
  const [screenWidth] = useWindowSize();

  //Add item to wishlist
  const addToWishlist = (id) => {
    if (walletStatus) {
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };
      const payload = {
        assetId: id,
      };
      axiosInstance
        .post(`/user/wishlist`, payload, config)
        .then((res) => {
          if (res) {
            // setEnableLoader(false);
            // setSuccessShow(true);
            setRefreshClick();
            PopUpAlert("Success", "NFT added to wishlist.", "success");
          }
        })
        .catch((err) => {
          // setEnableLoader(false);
          PopUpAlert(
            "Oops",
            err?.response?.data?.message
              ? err.response.data.message
              : err?.message,
            "error"
          );
        });
    } else {
      PopUpAlert("Alert", "Login before adding to wishlist.", "error").then(
        () => document.getElementById("connectWallet").click()
      );
    }
  };

  //Remove item to wishlist
  const removeFromWishlist = (id) => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      data: {
        assetId: id,
      },
    };

    axiosInstance
      .delete(`/user/wishlist/remove`, config)
      .then((res) => {
        if (res) {
          // setEnableLoader(false);
          setRefreshClick();
          PopUpAlert("Success", "NFT removed from wishlist.", "success");
        }
      })
      .catch((err) => {
        // setEnableLoader(false);
        PopUpAlert(
          "Oops",
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message,
          "error"
        );
      });
  };

  return (
    <>
      <div className={styles.slide} key={index}>
        <div className={styles.item} style={{ cursor: fixed ? "pointer" : "" }}>
          <div className={styles.body}>
            <div
              className={
                x.asset?.mediaType === "video"
                  ? styles.avatarVideoCard
                  : styles.avatar
              }
              style={{
                height:
                  Number(screenheight) > 700
                    ? // ? screenWidth < 1700
                      //   ? "18em"
                      "22.2em"
                    : "16.5em",
              }}
              onClick={() =>
                fixed ? history.push(`item/${x?.asset?.id}`) : null
              }
              data-id="fp-media"
            >
              {(x.asset?.mediaType === "image" ||
                x.asset?.mediaType === "3D Model") && (
                <img
                  src={x?.asset?.thumbnailUrl || x?.asset?.mediaPreviewUrl}
                  className={styles.cardAssetImg}
                  alt="Avatar"
                />
              )}
              {x.asset?.mediaType === "video" && (
                <div
                  style={{
                    height:
                      Number(screenheight) > 700
                        ? // ? screenWidth < 1700
                          //   ? "18em"
                          "22.2em"
                        : "16.5em",
                  }}
                >
                  <video
                    id="vid"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                    disablePictureInPicture
                    controlsList="nodownload"
                    loop={true}
                    autoPlay="autoplay"
                    muted
                  >
                    <source src={x?.asset?.mediaPreviewUrl} />
                    Your browser does not support the video.
                  </video>
                </div>
              )}
              {x.asset?.mediaType === "audio" && (
                <>
                  <img
                    src={x?.asset?.audioThumbnail}
                    className={styles.cardAssetImg}
                    alt="Avatar"
                  />
                  <audio
                    controls
                    controlsList="nodownload"
                    style={{
                      position: "absolute",
                      bottom: "1.563rem",
                      left: 0,
                      height: 35,
                      width: "100%",
                    }}
                  >
                    <source src={x?.asset?.mediaPreviewUrl} type="audio/mpeg" />
                  </audio>
                </>
              )}
              <p>
              {x?.asset.purchasedBy
                      ? `${x?.asset?.name} #${x?.asset.currentEdition}/${x?.asset.totalEdition}`
                      : x?.asset?.name}
              </p>
            </div>
            <div className={styles.name} data-id="assetname"
              style={{ cursor: "pointer" }}
              onClick={(e) => {

                history.push("/viewProfile/" + x?.asset?.currentOwner?.id)
                e.preventDefault()

              }}>
              <img
                src={
                  x?.asset?.currentOwner?.profilePicUrl
                    ? x?.asset?.currentOwner?.profilePicUrl
                    : "./images/content/Avatar.png"
                }
                alt="Avatar"
                height="2.188rem"
                width="2.188rem"
              ></img>
              <div className={styles.ownerName}>
                <h5>Creator</h5>
                <p>@{x?.asset?.currentOwner?.userName}</p>
              </div>
              <div className={styles.love}>
                <div
                  className={styles.loveIcon}
                  onClick={(e) => {
                    if (x?.asset?.isWishlist) {
                      removeFromWishlist(x?.asset?.id)
                    }
                    else {
                      addToWishlist(x?.asset?.id)
                    }
                    e.stopPropagation()
                  }
                  }
                >
                  <img
                    src={
                      x?.asset?.isWishlist
                        ? "/Icons/blackLove.png"
                        : "/Icons/love.svg"
                    }
                    alt=""
                  ></img>
                </div>
              </div>
            </div>

            <div
              style={{
                paddingLeft: "0.313rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0em 1.2em",
                marginBottom: "0.8em",
              }}
            >
              <p className={styles.priceLabel}>
                {x?.asset?.saleType === "fixed" ? "Price" : "Current bid"}
              </p>

              <h5 className={styles.priceAmount}>
                
                {x?.asset?.saleType === "fixed"
                  ? x?.asset?.regularPrice ? `$${x?.asset?.regularPrice}`  : 'FREE'
                  : `$${x?.asset?.auction?.currentBid}`}
              </h5>
            </div>

            <div className={styles.priceSection}>
              {/* <h5>
                            {(x?.asset?.regularPrice * euroValue).toFixed(2)}{" "}
                            MATIC
                          </h5> */}
              {/* <h5>
                $
                {x?.asset?.saleType === "fixed"
                  ? x?.asset?.regularPrice
                  : x?.asset?.auction?.currentBid}
              </h5> */}
            </div>

            {x?.asset?.saleType === "fixed" && (
              <div
                style={{
                  padding: "0.313rem 0.625rem 0.313rem 0.625rem",
                  borderRadius: "0.625rem 0.625rem 0px 0px",
                }}
                onClick={() =>
                  fixed ? history.push(`item/${x?.asset?.id}`) : null
                }
              >
                <button
                  className={cn("button-black", styles.button)}
                  style={{ width: "100%" }}
                >
                  {
                        walletId == x?.asset?.currentOwner?.publicAddress
                          ? "View"
                          : x?.asset?.isAirdropNft ? "Request Asset" : "Buy Now"}
                </button>
              </div>
            )}
            {x?.asset?.saleType == "auction" && (
              <>
                <div
                  className={styles.auctionContainer}
                  onClick={() =>
                    fixed ? history.push(`item/${x?.asset?.id}`) : null
                  }
                >
                  <div className={styles.timeleft}>
                    <img src="/images/content/timer.png" alt="auction-timer" />
                    <CountDown x={x} />
                    {/* <span style={{ textAlign: "center" }}>
                      {days ? `${days}d` : ``}{" "}
                      {hours || hours == 0 ? `${hours}h` : ``}{" "}
                      {minutes || minutes == 0 ? `${minutes}m` : ``}{" "}
                      {seconds || seconds == 0 ? `${seconds}s` : ``}
                    </span> */}
                    {/* <Countdown
                      daysInHours={true}
                      date={
                        moment().format(x?.asset?.auction?.endingDate) <
                        moment.utc()
                          ? moment.utc()
                          : x?.asset?.auction?.endingDate
                      }
                    /> */}
                  </div>
                  <div
                    className={styles.bidNowButton}
                    onClick={() => {
                      if (!walletStatus) {
                        // PopUpAlert(
                        // 	"Alert",
                        // 	"No wallet connection found.",
                        // 	"error"
                        // ).then((res) => {
                        document.getElementById("connectWallet").click();
                        // });
                        return false;
                      } else {
                        let pathName = "/" + x?.asset?.id;
                        history.push(pathName);
                      }
                    }}
                  >
                    <button styles={{ cursor: "pointer" }}>Place Bid</button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CardHomePage;

const CountDown = ({ x }) => {
  const [days, hours, minutes, seconds] = useCountdown(
    x?.asset?.auction?.endingDate
  );

  return (
    <span style={{ textAlign: "center" }}>
      {days ? `${days}d` : ``} {hours || hours == 0 ? `${hours}h` : ``}{" "}
      {minutes || minutes == 0 ? `${minutes}m` : ``}{" "}
      {seconds || seconds == 0 ? `${seconds}s` : ``}
    </span>
  );
};
