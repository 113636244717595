import React, { useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Icon from "../Icon";
import styles from "./Header.module.sass";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import User from "./User";
import CloseIcon from "@material-ui/icons/Close";
import { setBasicAccountDetails } from "../../redux/accountSlice";
import { axiosInstance } from "../../utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PopUpAlert } from "../../controller/utils";
import { PersonOutlineOutlined } from "@material-ui/icons";

const MobileHeader = ({
	onSearchChange,
	searchValue,
	searchName,
	signedIn,
	nav,
	handleLogin,
	handleClientLogin,
	handleDisconnect,
	sourcePage,
}) => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	


	useEffect(() => {
		const getDetails = async (config) => {
			await axiosInstance
				.get(`user/get-profile`, config)
				.then((res) => {
					const { id, profilePicUrl, email, firstName, lastName, status, kycStatus, isKYCLimitExceeded, emailVerified, isCreatedByAdmin } =
						res.data?.result;
					let payload = {
						email: email,
						firstName: firstName,
						lastName: lastName,
						profilePicture: profilePicUrl,
						status: status,
						id: id,
						kycStatus: kycStatus,
						isKYCLimitExceeded: isKYCLimitExceeded,
						emailVerified: emailVerified,
						isCreatedByAdmin: isCreatedByAdmin
					};

					dispatch(setBasicAccountDetails(payload));
				})
				.catch(() => { });
		};
		if (localStorage.getItem("userToken")) {
			let config = {
				headers: {
					Authorization: localStorage.getItem("userToken"),
				},
			};
			getDetails(config);
		}
	}, []);

	useEffect(() => {
		if (searchValue != "" && searchValue != undefined && searchValue != null) {
			setOpen(true);
		} else {
			setOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	return (
		<div style={{ display: "flex" }}>
			<div
				className={open ? styles.search_box_hover : styles.search_box}
				style={
					sourcePage
						? {
							background: "transparent",
							border: "0.063rem solid white",
						}
						: { background: "#FCFCFD", border: "0.063rem solid rgb(35, 38, 47)" }
				}
			>
				<input
					type="text"
					id="searchBox"
					className={styles.search_txt}
					value={searchValue}
					name={searchName}
					placeholder="Search"
					onChange={onSearchChange}
				/>
				<Icon name="search" size="25" fill={sourcePage ? "#FFFFFF" : "rgb(35, 38, 47)"} />
			</div>
			<div
				className={styles.menu_icon}
				style={
					sourcePage
						? {
							background: "transparent",
							border: "0.063rem solid white",
						}
						: { background: "#FCFCFD", border: "0.063rem solid rgb(35, 38, 47)" }
				}
			>
				<MenuIcon
					style={{ color: sourcePage ? "#FFFFFF" : "rgb(35, 38, 47)" }}
					onClick={() => setOpenDrawer(true)}
				/>
			</div>
			{openDrawer && (
				<InfoDrawer
					open={openDrawer}
					setOpenDrawer={setOpenDrawer}
					signedIn={signedIn}
					nav={nav}
					handleLogin={handleLogin}
					handleClientLogin={handleClientLogin}
					handleDisconnect={handleDisconnect}
				/>
			)}
		</div>
	);
};

const InfoDrawer = ({
	open,
	setOpenDrawer,
	signedIn,
	nav,
	handleLogin,
	handleClientLogin,
	handleDisconnect,
}) => {
	const history = useHistory();
	const accountDetails = useSelector((state) => state.accountReducer);

	const isCreator = accountDetails?.isCreatedByAdmin;
	return (
		<Drawer anchor="right" open={open} onClose={() => setOpenDrawer(false)}>
			<List className={styles.list}>
				{!signedIn && (
					<>
						<ListItem
							style={{
								minWidth: "150px",
								maxWidth: "270px",
								justifyContent: "flex-end",
								padding: "0.75rem 1.25rem 1.25rem 1.25rem",
							}}
						>
							<div
								className={styles.menu_icon}
								style={{ border: "0.063rem solid #d4d4d4" }}
							>
								<CloseIcon
									style={{ color: "#E5E5E5" }}
									onClick={() => setOpenDrawer(false)}
								/>
							</div>
						</ListItem>
						<Divider style={{ backgroundColor: "#F2F2F2" }} />
						<ListItem
							onClick={() => {
								handleClientLogin();
								setOpenDrawer(false);
							}}
						>
							<ListItemText style={{ textAlign: "center" }} primary="Sign in" />
						</ListItem>
						<Divider style={{ backgroundColor: "#F2F2F2" }} />
					</>
				)}
				{signedIn && (
					<>
						<User
							signedIn={signedIn}
							isMobile={true}
							onClose={() => setOpenDrawer(false)}
						/>
						<Divider style={{ backgroundColor: "#F2F2F2" }} />
						<button
							className="blackBtn"
							style={{margin:'auto', marginTop: 15,  marginBottom: 15, }}
							onClick={() => {
							history.push("/dashboard");
							setOpenDrawer(false);
							}}
						>
							<PersonOutlineOutlined
							style={{ fontSize: "0.938rem" }} /> Go to
							Profile
						</button>
						<Divider style={{ backgroundColor: "#F2F2F2" }} />
					</>
				)}
				{nav
					.filter((item) => (!signedIn ? item?.auth == signedIn : item))
					.map((x, index) => (
						<div key={index}>
							<>
								<ListItem onClick={() => handleLogin(x)}>
									<ListItemText
										style={{ textAlign: "center" }}
										primary={x.title}
									/>
								</ListItem>
								<Divider style={{ backgroundColor: "#F2F2F2" }} />
							</>
						</div>
					))}

				<ListItem
					onClick={() => {
						if (signedIn) {
							history.push("/wishlist");
						} else {
							setOpenDrawer(false);
							PopUpAlert("Alert", "Login to view your wishlist.", "error").then(
								() => document.getElementById("connectWallet").click()
							);
						}
					}}
				>
					<ListItemText style={{ textAlign: "center" }} primary={"Wishlist"} />
				</ListItem>
				<Divider style={{ backgroundColor: "#F2F2F2" }} />

				{isCreator && (
                <ListItem
                  onClick={() => {
                    history.push("/analytics");
                  }}
                >
					<ListItemText style={{ textAlign: "center" }} primary={"Analytics"} />

                </ListItem>
              )}
              <ListItem
                onClick={() => {
                  history.push("/earnings");
                }}
              >
					<ListItemText style={{ textAlign: "center" }} primary={"Earnings"} />

              </ListItem>

				{/* <ListItem
					onClick={() => {
						history.push("/TermsAndConditions");
					}}
				>
					<ListItemText style={{ textAlign: "center" }} primary={"Terms and Conditions"} />
				</ListItem>
				<Divider style={{ backgroundColor: "#F2F2F2" }} /> */}

				{signedIn && (
					<>
						<ListItem
							onClick={() => {
								handleDisconnect();
								setOpenDrawer(false);
							}}
						>
							<ListItemText
								style={{ textAlign: "center" }}
								primary="Sign out"
							/>
						</ListItem>
						{/* <Divider style={{ backgroundColor: "#F2F2F2" }} /> */}
					</>
				)}
			</List>
		</Drawer>
	);
};

export default MobileHeader;
