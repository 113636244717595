import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import CirclePayment from "./screens/CirclePayment";
import UploadVariants from "./screens/UploadVariants";
import UploadDetails from "./screens/UploadDetails";
import ConnectWallet from "./screens/ConnectWallet";
import Faq from "./screens/Faq";
import About from "./screens/About/about";
import AboutSantaCasa from "./screens/About/AboutSantaCasa";
import TermsAndConditions from "./screens/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy/privacyPolicy";
import Activity from "./screens/Activity";
import ViewAssets from "./screens/ViewAssets";
import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item";
import PageList from "./screens/PageList";
import { Provider } from "react-redux";
import store from "./redux/store";
import UserItem from "./screens/userItem/userItem";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import CheckoutReceipt from "./screens/userItem/CheckoutReceipt";
import { useSelector } from "react-redux";
import ResellForm from "./components/Resellform";
import Useraccount from "./components/UserAccount/userAccount";
import OrderDetails from "./components/OrderDetails/OrderDetails";
import Landing from "./components/landingpage/landingpage";
import Web3signature from "./components/Signature/Web3signnature";
import ResellItem from "./screens/userItem/ResellItem";
import Counter from "./screens/counter/Counter";
import Auction from "./screens/Auction/Auction";
import ThreeDee from "./screens/Threedee/ThreeDee";
import { useEffect } from "react";
import ThreeDeeViewer from "./components/ThreeDeeViewer/ThreeDeeViewer";
import Faqs from "./screens/Faqs/Faqs";
import News from "./screens/News/News";
import EmailForm from "./screens/Email";
import Checkout from "./screens/Checkout";
import Dashboard from "./screens/dashboard/Dashboard";
import PlaceBid from "./screens/Auction/PlaceBid";
import Wishlist from "./screens/Wishlist/WishListDetails.jsx";
import SocialLogin from "./screens/SocialLogin";
import Analytics from "./screens/Analytics";
import Earnings from "./screens/Earnings";

import "react-phone-input-2/lib/style.css";
import ViewProfile from "./screens/ViewProfile/ViewProfile";
import CollectionDetails from "./screens/Home/Collections/CollectionDetails";
import NewsDetailPage from "./screens/News/NewsDetailPage";

let persistor = persistStore(store);

function App() {
  return (
    <Router>
      <Switch>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* <Route
              exact
              path="/"
              render={() => (
                <Page>
                  <Landing />
                </Page>
              )}
            /> */}
            <Route
              exact
              path="/upload-variants"
              render={() => (
                <Page>
                  <UploadVariants />
                </Page>
              )}
            />
            <Route exact path="/magicLogin" render={() => <EmailForm />} />
            <Route exact path="/socialLogin" render={() => <SocialLogin />} />
            <Route
              exact
              path="/upload-details"
              render={() => (
                <Page>
                  <UploadDetails />
                </Page>
              )}
            />
            <Route
              exact
              path="/connect-wallet"
              render={(props) => (
                <Page>
                  <ConnectWallet {...props} />
                </Page>
              )}
            />
            <Route
              exact
              path="/resell/:id"
              render={(props) => (
                <Page>
                  <ResellForm {...props} />
                </Page>
              )}
            />
            <Route
              exact
              path="/my-account"
              render={(props) => (
                <Page>
                  <Useraccount {...props} />
                </Page>
              )}
            />
            <Route
              exact
              path="/faq"
              render={() => (
                <Page>
                  <Faq />
                </Page>
              )}
            />
            <Route
              exact
              path="/activity"
              render={() => (
                <Page>
                  <Activity />
                </Page>
              )}
            />
            <Route
              exact
              path="/search01/:catagory"
              render={() => (
                <Page>
                  <ViewAssets />
                </Page>
              )}
            />
            <Route
              exact
              path="/sign"
              render={() => (
                <Page>
                  <Web3signature />
                </Page>
              )}
            />

            <Route
              exact
              path="/dashboard"
              render={() => (
                <Page>
                  <Dashboard />
                </Page>
              )}
            />
            <Route
              exact
              path="/search01"
              render={() => (
                <Page>
                  <ViewAssets />
                </Page>
              )}
            />
            <Route
              exact
              path="/collectionDetails/:id"
              render={() => (
                <Page>
                  <CollectionDetails />
                </Page>
              )}
            />
            <Route
              exact
              path="/checkoutReceipt"
              render={(props) => (
                <Page>
                  <CheckoutReceipt {...props} />
                </Page>
              )}
            />
            <Route
              exact
              path="/profile"
              render={() => (
                <Page>
                  <Profile />
                </Page>
              )}
            />
            <Route
              exact
              path="/orderDetails"
              render={() => (
                <Page>
                  <OrderDetails />
                </Page>
              )}
            />
            <Route
              exact
              path="/analytics"
              render={() => (
                <Page>
                  <Analytics />
                </Page>
              )}
            />
            <Route
              exact
              path="/earnings"
              render={() => (
                <Page>
                  <Earnings />
                </Page>
              )}
            />
            <Route
              exact
              path="/ResoldAssets"
              render={() => (
                <Page>
                  <ResellItem />
                </Page>
              )}
            />
            {/* edit ptofile route is /my-account */}
            {/* <Route
              exact
              path="/profile-edit"
              render={() => (
                <Page>
                  <ProfileEdit />
                </Page>
              )}
            /> */}
            <Route
              exact
              path="/item/:id"
              render={() => (
                <Page>
                  <Item />
                </Page>
              )}
            />
            <Route
              exact
              path="/item/:id/checkout"
              render={() => (
                <Page>
                  <Checkout />
                </Page>
              )}
            />
            {/* <Route
              exact
              path="/item/:id"
              render={() => (
                <Page>
                  <Item />
                </Page>
              )}
            /> */}
            <Route
              exact
              path="/About"
              render={() => (
                <Page>
                  <About />
                </Page>
              )}
            />
            <Route
              exact
              path="/placebid"
              render={() => (
                <Page>
                  <PlaceBid />
                </Page>
              )}
            />
            <Route
              exact
              path="/AboutSantaCasa"
              render={() => (
                <Page>
                  <AboutSantaCasa />
                </Page>
              )}
            />
            <Route
              exact
              path="/TermsAndConditions"
              render={() => (
                <Page>
                  <TermsAndConditions />
                </Page>
              )}
            />
            <Route
              exact
              path="/wishlist"
              render={() => (
                <Page>
                  <Wishlist />
                </Page>
              )}
            />
            <Route
              exact
              path="/PrivacyPolicy"
              render={() => (
                <Page>
                  <PrivacyPolicy />
                </Page>
              )}
            />
            <Route
              exact
              path="/item/view/:id"
              render={() => (
                <Page>
                  <Item mode={"view"} />
                </Page>
              )}
            />
            <Route
              exact
              path="/item"
              render={() => (
                <Page>
                  <UserItem />
                </Page>
              )}
            />
            <Route
              exact
              path="/pagelist"
              render={() => (
                <Page>
                  <PageList />
                </Page>
              )}
            />
            <Route
              exact
              path="/auction"
              render={() => (
                <Page>
                  <Auction />
                </Page>
              )}
            />
            <Route
              exact
              path="/threeDee"
              render={() => (
                <Page>
                  <ThreeDee />
                </Page>
              )}
            />
            <Route
              exact
              path="/Faqs"
              render={() => (
                <Page>
                  <Faqs />
                </Page>
              )}
            />
            <Route
              exact
              path="/news"
              render={() => (
                <Page>
                  <News />
                </Page>
              )}
            />
            <Route
              exact
              path="/news/:id"
              render={() => (
                <Page>
                  <NewsDetailPage />
                </Page>
              )}
            />

            <Route
              exact
              path="/threeDeeViewer/:id"
              render={() => <ThreeDeeViewer />}
            />

            <Route
              exact
              path="/Marketplace"
              render={() => (
                <Page>
                  <Home />
                </Page>
              )}
            />
            <Route
              exact
              path="/wallet"
              render={() => (
                <Page>
                  <CirclePayment />
                </Page>
              )}
            />
            <Route
              exact
              path="/viewProfile/:name"
              render={() => (
                <Page>
                  <ViewProfile />
                </Page>
              )}
            />

            {/* <Route
              exact
              path="/AboutUs"
              render={() => (
                <Page>
                  <Homepage />
                </Page>
              )}
            /> */}
            {/* <Route
              exact
              path="/goodcauses"
              render={() => (
                <Page>
                  <Homepage />
                </Page>
              )}
            /> */}
            <Route
              exact
              path="/"
              render={() => (
                // <Page>
                <Home />
                // </Page>
              )}
            />
            {/* <Route component={NoMatch} /> */}
          </PersistGate>
        </Provider>
      </Switch>
    </Router>
  );
}

export default App;
