import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TrackChangesRoundedIcon from "@material-ui/icons/TrackChangesRounded";
import styless from "./BalanceWidget.module.sass"; // Import your CSS module
import { magic, maticWeb3 } from "../../controller/utils";
import { useDispatch, useSelector } from "react-redux";
import TransparentModal from "../../components/TransparentModal";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import SecurityIcon from "@mui/icons-material/Security";
import styles from "../../../src/screens/dashboard/dash.module.sass";
import { setMagicUser } from "../../redux/counterSlice";
import Web3 from "web3";
import copy from "copy-to-clipboard";

const BalanceWidget = () => {
  const screenWidth = window.innerWidth; // You might need to handle window resize events to update this value

  let circleBalance = useSelector((state) => state.circlePayment.balance);
  const userAccount = useSelector((state) => state?.counter?.user);
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const usdcWalletBalance = useSelector((state) => state.usdc?.amount);
  let loginWith = localStorage.getItem("network");
  const [mountTransparent, setMountTransparent] = useState(false);
  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleToggleMagicMFA = async () => {
    setMountTransparent(true);
    await magic.user.showSettings();
    setMountTransparent(false);
    const userMetadata = await magic.user.getMetadata();
    let magicBalance = await fetchBalance(userMetadata?.publicAddress);
    userMetadata.magicBalance = magicBalance;
    dispatch(setMagicUser(userMetadata));
  };

  const fetchBalance = (address) => {
    let web3 = new Web3(maticWeb3);
    return web3.eth.getBalance(address).then((bal) => web3.utils.fromWei(bal));
  };

  const copiedToastHandler = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3500);
  };

  // Copy to clipboard handler
  const copyToClipboard = (content, type) => {
    copy(content);
    setCopied(true);
    copiedToastHandler();
    // setTimeout(()setCopied(false), 2000);
  };

  //Fetch User Data

  return (
    <div className={styless.wrapper}>
      <div>
        <div className={styless.wrapper}>
          <div
            className={styless.wrap}
            style={{ padding: "33px 15px 0px 15px" }}
          >
            <div className={styles.walletDivider}>
              <div
              // className={styles.userDetails}
              >
                <div
                  className={styless.rightWallet}
                  style={{ marginTop: "25px" }}
                >
                  {/* {screenWidth > 1061 && ( */}
                  {loginWith == "metamask" && (
                    <div
                      className={styles.profilePic}
                      style={{ minWidth: "4rem" }}
                    >
                      <img src={"/images/metamask.png"} alt="wallet" />
                    </div>
                  )}
                  {loginWith !== "metamask" && (
                    <div style={{ minWidth: "4rem" }}>
                      <img
                        src={"/images/liiLogoArabic.png"}
                        style={{ width: "3rem", height: "4rem" }}
                        alt="wallet"
                      />
                    </div>
                  )}
                  {/* )} */}
                  <div className={styles.cryptoRow} style={{ flex: "unset" }}>
                    <div style={{ flexGrow: 2 }}>
                      <h4>Lii wallet balance</h4>

                      <p>
                        {`${userAccount?.account?.[0]?.substring(
                          0,
                          6
                        )}......${userAccount?.account?.[0]?.substr(-6)}`}
                        <img
                          src="/Icons/copy.svg"
                          alt="copy"
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            width: 17,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            copyToClipboard(userAccount?.account?.[0])
                          }
                        ></img>
                        {showToast && "Copied"}
                      </p>
                    </div>
                    {loginWith == "metamask" ? (
                      <></>
                    ) : (
                      // <div className={styles.mailLoginWrapper}>
                      <>
                        <div
                          className={styles.dashButton}
                          style={{
                            marginRight: "1.5rem",
                            height: "fit-content",
                          }}
                          // style={{
                          //   marginLeft: "5em",
                          // }}
                          onClick={() => {
                            window.open(
                              "https://reveal.magic.link/lii",
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {/* <img src="/Icons/copy.svg" alt="copy"></img> */}
                          <TrackChangesRoundedIcon
                            style={{ fontSize: "1.563rem" }}
                          />{" "}
                          Reveal Private Keys
                        </div>
                        <div
                          className={styles.dashButton}
                          style={{
                            height: "fit-content",
                          }}
                          onClick={handleToggleMagicMFA}
                        >
                          {mountTransparent && <TransparentModal />}
                          {magicLinkUser?.isMfaEnabled ? (
                            <RemoveModeratorIcon />
                          ) : (
                            <SecurityIcon style={{ fontSize: "1.563rem" }} />
                          )}{" "}
                          {magicLinkUser?.isMfaEnabled
                            ? "Disable MFA"
                            : "Enable MFA"}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={styles.rightWallet}
                // styles={{
                //   flex: screenWidth < 1061 ? "0 0 50%" : null,
                // }}
              >
                {" "}
                {/* <div className={styles.profilePic}>
                           
                          </div> */}
                <div
                  className={styles.cryptoRowTwo}
                  style={{ flex: "1 0 50% " }}
                >
                  <div style={{ display: "flex" }}>
                    <h3>
                      {usdcWalletBalance !== null
                        ? usdcWalletBalance == 0
                          ? "0.00"
                          : usdcWalletBalance.toFixed(2)
                        : "-"}
                    </h3>
                    <img
                      src="/images/usdc-logo.png"
                      alt="wallet"
                      className={styles.usdcLogo}
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    />
                  </div>
                  <div>
                    {/* <p>USDC Token balance</p> */}
                    <h3 className={styles.flexCenter}>
                      {loginWith == "metamask"
                        ? userAccount?.balance
                          ? Number(userAccount?.balance)?.toFixed(2)
                          : "0.00"
                        : magicLinkUser?.magicBalance
                        ? Number(magicLinkUser?.magicBalance).toFixed(2)
                        : "0"}{" "}
                      <img
                        src={`/images/matic.svg`}
                        alt="matic"
                        className={styles.maticSymbol}
                      />
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          // fontSize: screenWidth < 1061 ? "1em" : "",
                          fontWeight: "600",
                        }}
                      >
                        {loginWith == "metamask"
                          ? userAccount?.balance
                            ? (
                                Number(userAccount?.balance) / euroValue
                              ).toFixed(2)
                            : "0"
                          : magicLinkUser?.magicBalance
                          ? (
                              Number(magicLinkUser?.magicBalance) / euroValue
                            ).toFixed(2)
                          : "0"}{" "}
                        USD
                      </p>
                    </div>
                  </div>

                  <Link to="/wallet">
                    <div className={styles.dashButton}>
                      <img src="/images/content/edit.svg" alt="edit"></img>
                      Manage balance
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceWidget;
