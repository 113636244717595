import React from "react";
import Web3 from 'web3';

const Web3signature = () => {

    const [sign, setSign] = React.useState("");
    const [value, setValue] = React.useState("");

    const handleSign = () => {
        let web3 = new Web3(Web3.givenProvider);
        web3.eth.personal.sign("Hello world", value, "").then(res=> setSign(res));
    }

    return (
        <div style={{width:"100%", border:"0.063rem solid black", padding:50}}>
            <input style={{width:"100%"}} type="text" placeholder="address" onChange={(e) => { setValue(e.target.value) }} />
            <br/>
            <button onClick={handleSign}> Get signature </button>
            <br/>
            <input style={{width:"100%"}} type="text" placeholder="signature" value={sign} />
        </div>

    )
}

export default Web3signature