import React from "react";
import cn from "classnames";
import TransactionHistoryTable from "./TransactionHistory.jsx";
import styles from "./TransactionHistory.module.sass";

function TransactionHistory({ refreshPage, screenwidth }) {
	return (
		<>
			<div className={cn("container", styles.container)}>
				<div className={styles.tableTitle}>Transaction details</div>
				<TransactionHistoryTable
					refreshPage={refreshPage}
					screenwidth={screenwidth}
				/>
			</div>
		</>
	);
}

export default TransactionHistory;
