import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import parse from "html-react-parser";
import styles from './Threedee.module.sass';


const ThreeDeeViewer = (props) => {
    // let { id }= useParams();
    const [player, setPlayer] = useState("");
    const [loading, setLoading] = useState("");

    useEffect(()=> {
        setLoading(true);
        getData();
    }, [])

    const getData = async () => {
        const data = await axios.get(`https://sketchfab.com/oembed?url=https://sketchfab.com/models/${props.id}`);
        setPlayer(data.data.html);
        setLoading(false);
    }

    // const getData = async () => {
    //     const data = await axios.get(`https://sketchfab.com/oembed?url=https://sketchfab.com/models/${id}`);
    //     setPlayer(data.data.html);
    //     setLoading(false);
    // }

    return (
        <div className={styles.t3container}>
            {!loading && parse(player)}
        </div>
    )
}

export default ThreeDeeViewer