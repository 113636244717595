import React from "react";
import cn from "classnames";
import styles from "./Banner.module.sass";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Banner({ newsInfo, setInnerHTML}) {
	// console.log("Banner", newsInfo);
	const history = useHistory()

	return (
		<>
			{newsInfo ? (
				<div className={cn("container", styles.container)}>
					<div className={styles.bannerDescription}>
						<h1>{newsInfo?.title ? newsInfo?.title : "-"}</h1>
						<div style={{ maxHeight: "30em", overflow: "hidden" }}>
							<p>
								{newsInfo?.description
									? setInnerHTML(newsInfo?.description)
									: "-"}
							</p>
						</div>
						<div
							className={styles.readMoreWrapper}
							onClick={()=> {
								history.push(`/news/${newsInfo?.id}`)
							}}
						>
							<p className={styles.readMore}>Read more</p>
							<span>
								<img
									src={"/Icons/arrow.svg"}
									alt="arrow"
									height="0.875rem"
									width="0.875rem"
								/>
							</span>
						</div>
					</div>
					<div className={styles.bannerPreview}>
						<img
							src={newsInfo.bannerUrl ? newsInfo.bannerUrl : ""}
							alt="preview"
						/>
					</div>
				</div>
			) : null}
		</>
	);
}

export default Banner;
