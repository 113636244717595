/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";

import useWindowSize from "../../common/hooks/useWindowSize";
import { current_datetime } from "../../controller/utils";
import { axiosInstance } from "../../utils/API";
import styles from "./newbidcard.module.sass";
import moment from "moment";

const BidRows = ({ productDetails, type }) => {
  const id = productDetails?.id;
  const tokenId = productDetails?.tokenId
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  var titleArray;
  if (type == "fixed") {
    titleArray = ["Info"];
  } else {
    titleArray = ["Info", "Current bids", "History"];
  }

  const [screenWidth] = useWindowSize();
  const [menuIndex, setMenuIndex] = useState(0);
  const [data, setData] = useState(null);
  const [info, setInfo] = useState(null);
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copied3, setCopied3] = useState(false);
  const handleMenu = (index) => {
    console.log(index);
    setMenuIndex(index);
  };
  let config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };
  useEffect(() => {
    console.log("stareted");
    if (type === "auction") {
      const endpoint =
        type === "auction" ? `/asset/view/auction/${id}` : `/asset/view/${id}`;

      axiosInstance.get(endpoint, config).then((res) => {
        if (menuIndex == 2) {
          if (type == "auction") setData(res.data?.result?.bids);
        } else if (menuIndex == 1) {
          if (type == "auction") setData(res.data?.result?.bids);
        } else if (menuIndex == 0) {
          setInfo(res?.data?.result);
        } else {
          setData([]);
          setInfo([]);
        }
      });
    } else {
      setInfo(productDetails);
    }

    //menuIndex = -
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIndex]);

  const copyToClipboard = (type) => {
    copy(
      type == 1
        ? process.env.REACT_APP_CONTRACT_ADDRESS_DEV
        : type == 2
        ? process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV
        : tokenId
    );
    type == 1
      ? setCopied1(true)
      : type == 2
      ? setCopied2(true)
      : setCopied3(true);
    setTimeout(
      () =>
        type == 1
          ? setCopied1(false)
          : type == 2
          ? setCopied2(false)
          : setCopied3(false),
      1000
    );
  };

  return (
    <>
      <div className={styles.header}>
        {titleArray.map((item, index) => (
          <>
            <div
              className={
                index == menuIndex ? styles.selected : styles.disSelected
              }
              key={index}
            >
              {type ==='fixed' ? <button
                onClick={() => {
                  handleMenu(index);
                }} style = {{cursor:'default'}}
              >
                {item}
              </button> : <button
                onClick={() => {
                  handleMenu(index);
                }} 
              >
                {item}
              </button>}
            </div>
          </>
        ))}
      </div>
      <div className={styles.historyContainer}>
        {data?.length == 0 && menuIndex == 2 && (
          <span style={{ paddingLeft: "0.6em" }}>No bids received.</span>
        )}
        {data && menuIndex == 2 ? (
          <>
          {console.log("historyyyyy data",data)}
            {data?.map((item, index) => (
              <div className={styles.rows} key={`ni${index}`}>
                <div className={styles.avatar}>
                  <img
                    src={
                      item?.bidder?.profilePicUrl
                        ? item?.bidder?.profilePicUrl
                        : "/images/content/Avatar.png"
                    }
                    alt="profile pic"
                    style={{
                      height: "2.5rem",
                      width: "2.5rem",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className={styles.creator}>
                  <h5>
                    Bid placed by @
                    {item?.bidder?.userName
                      ? item?.bidder?.userName
                      : item?.userId}{" "}
                  </h5>
                  <p style={{ fontSize: "0.813rem" }}>
                    {moment.utc(item?.createdAt).local().format('DD/MM/YYYY    h:mm:ss A') || "-"}
                  </p>
                  {/* <p> {moment(item?.createdAt).format("MMMM Do YYYY ")}</p> */}
                </div>
                <div className={styles.amount}>
                  <h5 className={styles.flexCenter}>
                    {/* {(item?.amount * euroValue).toFixed(2)}{" "} */}
                    {(item?.amount).toFixed(2)}
                    <img
                      src={`/images/usdc-logo.png`}
                      alt="USDC"
                      className={styles.maticSymbol}
                    />
                  </h5>
                  <p>${item?.amount}</p>
                </div>
              </div>
            ))}
          </>
        ) : null}
        {data?.[0] ? 
        (
           data?.[0] && menuIndex == 1 ? (
            <>
            <div className={styles.rows}>
                <div className={styles.avatar}>
                  <img
                    src={
                      data[0]?.bidder?.profilePicUrl
                        ? data[0]?.bidder?.profilePicUrl
                        : "/images/content/Avatar.png"
                    }
                    alt="profile pic"
                    style={{
                      height: "2.5rem",
                      width: "2.5rem",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className={styles.creator}>
                  <h5 className={styles.flexCenter}>
                    Bid placed by @
                    {data[0]?.bidder?.userName
                      ? data[0]?.bidder?.userName
                      : data[0]?.userId}{" "}
                  </h5>
                  <p style={{ fontSize: "0.813rem" }}>
                    {moment.utc(data[0]?.createdAt).local().format('DD/MM/YYYY    h:mm:ss A') || "-"}
                  </p>
                </div>
                <div className={styles.amount}>
                  <h5> 
                    {/* {(data[0]?.amount * euroValue).toFixed(2)}{" "} */}
                   {(data[0]?.amount).toFixed(2)}{" "}

                    <img
                      src={`/images/usdc-logo.png`}
                      alt="USDC"
                      className={styles.maticSymbol}
                    />
                  </h5>
                  <p>${data[0]?.amount}</p>
                </div>
                </div>
              </>
            ) : (
              menuIndex == 1 && (
            <div className={styles.rows}>
                
                <div style={{ paddingLeft: "0.6em" }}>
                  <span>No bids received.</span>
                </div>
                </div>
              )
            )
       ) :
         (
          menuIndex == 1 && (
            <span style={{ paddingLeft: "0.6em" }}>No bids received.</span>
          )
        )}

        {info && menuIndex === 0 && (
          <>
            <div className={styles.bioCard}>
              <div className={styles.bioCol}>
                <div>
                  <p>Total edition</p>
                  <h3>
                    {productDetails?.totalEdition
                      ? productDetails?.totalEdition
                      : "-"}
                  </h3>
                </div>
                <div>
                  <p>
                    Contract{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      address{" "}
                      <img
                        src="/Icons/copy.svg"
                        alt="copy"
                        style={{
                          width: "1.1em",
                          height: "1.1em",
                          marginLeft: "0.4em",
                          cursor: "pointer",
                        }}
                        onClick={() => copyToClipboard(1)}
                      />
                    </span>
                    {copied1 ? (
                      <span style={{ fontSize: "0.8em", marginLeft: "0.1em" }}>
                        Copied
                      </span>
                    ) : null}
                  </p>
                  <h3>
                    {" "}
                    <a
                      href={`${process.env.REACT_APP_POLYGON_LINK}address/${process.env.REACT_APP_CONTRACT_ADDRESS_DEV}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#000000" }}
                    >
                      {" "}
                      {screenWidth > 1060
                        ? process.env.REACT_APP_CONTRACT_ADDRESS_DEV
                        : `${process.env.REACT_APP_CONTRACT_ADDRESS_DEV?.substring(
                            0,
                            4
                          )}....${process.env.REACT_APP_CONTRACT_ADDRESS_DEV?.substr(
                            -4
                          )}`}
                      {/* {process.env.REACT_APP_CONTRACT_ADDRESS_DEV} */}
                    </a>{" "}
                  </h3>
                </div>
                <div>
                  <p style={{ whiteSpace: "nowrap" }}>
                    Token id{" "}
                    <img
                      src="/Icons/copy.svg"
                      alt="copy"
                      style={{
                        width: "1.1em",
                        height: "1.1em",
                        marginLeft: "0.4em",
                        cursor: "pointer",
                      }}
                      onClick={() => copyToClipboard(3)}
                    />
                    {copied3 ? (
                      <span style={{ fontSize: "0.8em", marginLeft: "0.1em" }}>
                        Copied
                      </span>
                    ) : null}
                  </p>
                  <h3>{productDetails?.tokenId}</h3>
                </div>
                <div>
                  <p>Blockchain</p>
                  <h3>{process.env.REACT_APP_BLOCKCHAIN}</h3>
                </div>
              </div>

              <div className={styles.bioCol}>
                <div>
                  <p>Editions left in this drop</p>
                  <h3>
                    {productDetails?.availableStock
                      ? productDetails?.availableStock
                      : "-"}
                  </h3>
                </div>
                <div>
                  <p>
                    NFT contract{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      address
                      <img
                        src="/Icons/copy.svg"
                        alt="copy"
                        style={{
                          width: "1.1em",
                          height: "1.1em",
                          marginLeft: "0.4em",
                          cursor: "pointer",
                        }}
                        onClick={() => copyToClipboard(2)}
                      />
                    </span>
                    {copied2 ? (
                      <span style={{ fontSize: "0.8em", marginLeft: "0.1em" }}>
                        Copied
                      </span>
                    ) : null}
                  </p>
                  <h3>
                    {" "}
                    <a
                      href={`${process.env.REACT_APP_POLYGON_LINK}token/${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#000000" }}
                    >
                      {" "}
                      {screenWidth > 1060
                        ? process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV
                        : `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV?.substring(
                            0,
                            4
                          )}....${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV?.substr(
                            -4
                          )}`}
                      {/* {process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV} */}
                    </a>{" "}
                  </h3>
                </div>

                <div>
                  <p>Token standard</p>
                  <h3>{process.env.REACT_APP_TOKEN_STANDARD}</h3>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BidRows;
