import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Card.module.sass";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { axiosInstance } from "../../utils/API";

import {
  checkNetwork,
  contract_auction_abi,
  current_datetime,
  getGasPrice,
  getWeb3Provider,
  PopUpAlert,
} from "../../controller/utils";
import moment from "moment";
import { formatPrice } from "./../../controller/utils";
import { useSelector } from "react-redux";
import { useCountdown } from "../../customHooks/CountDownTimer";
import Loader from "../../screens/Item/Loader";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    overflow: "hidden",
  },
  media: {
    height: 140,
    transition: "0.3s",
  },
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const CardContainer = ({
  userDbId,
  className,
  item,
  type = "",
  showLabel = false,
  price = "1",
  ethAmount = 1,
  hidePurchaseToolBar = false,
  details,
  sourcePage = null,
  auctionTabId = null,
  tabIndex = null,
  setRefresh,
  marketplace = false,
  createdId,
}) => {
  const [days, hours, minutes, seconds] = useCountdown(
    item?.auction?.endingDate
  );
  const history = useHistory();
  const walletStatus = useSelector((state) => state?.counter?.value);
  const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
  const accountDetails = useSelector((state) => state.accountReducer);
  const [active, setActive] = useState(false);
  const isCreator = accountDetails?.isCreatedByAdmin;

  let config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };

  //to check if loggedin user is the creator/artist/cocreator
  const checkIfCreatorOrArtist = (item) => {
    const productDetails = item;
    if (userDbId) {
      const creatorId = productDetails?.creatorId;
      let artistId;
      const coCreators = [];
      productDetails?.userAssetCommission?.forEach((item, index) => {
        if (item?.creatorType === "coCreator") {
          coCreators.push(item);
        } else if (item?.creatorType == "artist") {
          artistId = item;
        }
      });
      if (
        userDbId == creatorId ||
        artistId?.["userId"] == userDbId ||
        coCreators.some(
          (item) => item.hasOwnProperty("userId") && item["userId"] == userDbId
        )
      ) {
        return true;
      }

      return false;
    } else {
      return false;
    }
  };

  //Add item to wishlist
  const addToWishlist = (id) => {
    const payload = {
      assetId: id,
    };
    axiosInstance
      .post(`/user/wishlist`, payload, config)
      .then((res) => {
        if (res) {
          // setEnableLoader(false);
          // setSuccessShow(true);
          setRefresh();
          PopUpAlert("Success", "NFT added to wishlist.", "success");
        }
      })
      .catch((err) => {
        // setEnableLoader(false);
        PopUpAlert(
          "Oops",
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message,
          "error"
        );
      });
  };

  //Remove item to wishlist
  const removeFromWishlist = (id) => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      data: {
        assetId: id,
      },
    };

    axiosInstance
      .delete(`/user/wishlist/remove`, config)
      .then((res) => {
        if (res) {
          // setEnableLoader(false);
          setRefresh();
          PopUpAlert("Success", "NFT removed from wishlist.", "success");
        }
      })
      .catch((err) => {
        // setEnableLoader(false);
        PopUpAlert(
          "Oops",
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message,
          "error"
        );
      });
  };

  const handleUnlist = async (e, tokenId, id) => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    e.stopPropagation();
    // ----------------------------------------------------
    let web3 = await getWeb3Provider();
    const chainId = await web3.eth.getChainId();
    const networkVerify = checkNetwork(chainId);
    console.log("networkVerify", networkVerify);
    if (!networkVerify) return setActive(false);

    // account is an array of available accounts in connected wallet
    const account = await web3.eth.getAccounts();

    //wallet balance will return in wei (base unit of ETH)

    try {
      setActive(true);
      //calculate gasprice

      // using the event emitter

      let marketplace_contract_address =
        process.env.REACT_APP_CONTRACT_ADDRESS_DEV;

      const marketplace_contract = await new web3.eth.Contract(
        contract_auction_abi,
        marketplace_contract_address
      );

      const assetPurchaseMethod = await marketplace_contract.methods.cancel(
        tokenId
      );
      let gasEstimated = null;
      let txObject = null;

      try {
        const gasPrice = await getGasPrice(web3);
        //creating transaction object
        txObject = {
          from: account[0],
          // value: 0,
          gasPrice: gasPrice,
        };

        //estimating transaction fee for this function call
        gasEstimated = await web3.eth.estimateGas({
          to: marketplace_contract_address,
          data: assetPurchaseMethod.encodeABI(),
          ...txObject,
        });
        console.log("gasEstimated", gasEstimated);

        await assetPurchaseMethod
          .send({ ...txObject, gas: gasEstimated })
          .on("transactionHash", function (hash) {
            console.log("transaction hash", hash);
            axiosInstance
              .patch(`/asset/un-publish/${id}`, "", config)
              .then((res) => {
                PopUpAlert(
                  "Unlisted",
                  "NFT unlisted from marketplace",
                  "success"
                ).then((res) => window.location.reload());
              });
            // setTxnHash(hash);

            // setConfirmPaymentDetails({
            //   ...confirmPaymentDetails,
            //   txnHash: hash,
            // });
            // add a loader here to wait until transaction confirmation
          })
          .on("receipt", function (receipt) {
            if (receipt.status) {
              setActive(false);
            } else {
              // blockchain transaction status is fail  ed.
              setActive(false);
              PopUpAlert(
                "Alert!",
                "Transaction has been failed please try again!!",
                "error"
              );
              // .then(() => enableScroll());
            }
          })
          .on("error", (error) => {
            setActive(false);
            PopUpAlert("Alert!", "User denied transaction signature", "error");
            // .then(() => enableScroll());
          })
          .then(() => {
            //enableScroll();
            // confirmOrder(null, orderId, "failed");
          }); // If a out of gas error, the second parameter is the receipt.
      } catch (err) {
        setActive(false);
        console.log(err);
        //enableScroll();
        // confirmOrder(null, orderId, "failed");
        if (err.code === 4001) {
          PopUpAlert("Alert!", "Please confirm from your wallet", "error").then(
            (err) => {
              //enableScroll();
              window.location.reload();
            }
          );
        } else {
          PopUpAlert(
            "Alert!",
            "Something went wrong. Please try again",
            "error"
          ).then((err) => {
            //enableScroll();
            window.location.reload();
          });
        }
      }
    } catch (err) {
      console.log("Error handle pay on crypto", err);
      setActive(false);
      // if (err?.toString()?.includes("insufficient funds for transfer")) {
      // PopUpAlert(
      //   "Alert!",
      //   "You can only bid on auctions using crypto. Please topup your wallet with MATIC in order to participate in this auction.",
      //   "error"
      // ).then(() => enableScroll());
      // }
      // alert("Transaction has been failed, please try again!!");
    }

    // -------------------------------------------------------------
    // axiosInstance.patch(`/asset/un-publish/${id}`, "", config).then((res) => {
    //   PopUpAlert("Unlisted", "Item unlisted from marketplace", "success").then(
    //     (res) => window.location.reload()
    //   );
    // });
  };

  const RenderHTMLWithTags = (content) => {
    const htmlContent = content;

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  // useEffect(() => {
  //   document.getElementById("vid").play();
  // }, []);

  return (
    <>
      {item ? (
        <div
          className={cn(
            [
              "myNFT",
              "myAuction",
              "activeListing",
              "createdNFTsProfile",
            ].includes(sourcePage)
              ? ["activeListing"].includes(sourcePage)
                ? styles.cardActiveListing
                : styles.cardMyProfile
              : styles.card,
            className
          )}
          // onClick={() => {
          //   let pathName =
          //     (type === "view" && sourcePage == null) ||
          //     (sourcePage == "myAuction" && auctionTabId !== 0)
          //       ? "/item/view/" + item?.id
          //       : "/item/" + item?.id;
          //   // console.log(
          //   //   { sourcePage },
          //   //   { type },
          //   //   { auctionTabId },
          //   //   { pathName }
          //   // );
          //   history.push(pathName);
          // }}
        >
          <div
            className={styles.preview}
            onClick={() => {
              let pathName =
                (type === "view" && sourcePage != null) ||
                (sourcePage == "myAuction" && auctionTabId !== 0)
                  ? auctionTabId == 0
                    ? "/item/" + item?.id
                    : sourcePage == "myNFT"
                    ? "/item/view/" + item?.id + "?sourcePage=myNFT"
                    : "/item/view/" + item?.id
                  : "/item/" + item?.id;
              history.push(pathName);
            }}
          >
            <div className={styles.assetName}>
              {/* {item?.name} */}
              {item.purchasedBy
                ? `${item?.name} #${item.currentEdition}/${item.totalEdition}`
                : item?.name}
            </div>
            {/* {item.saleType === "auction" &&
              <div className={styles.indicator}>
                <Image
                  className={styles.hammer}
                  src="/images/hammerIcon.png"
                  srcDark="/images/hammerIcon.png"
                  alt="auction logo.jpg"
                />
              </div>
            } */}

            {item.mediaType === "audio" && (
              <div
                className="player-wrapper"
                style={{
                  position: "relative",
                  paddingTop: 0,
                  height: "92%",
                }}
              >
                <div className={styles.previewImage}>
                  <img
                    src={item?.audioThumbnail}
                    style={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      // objectFit: "scale-down",
                    }}
                    alt="thumbnail.png"
                  />
                  <audio
                    controls
                    controlsList="nodownload"
                    style={{
                      position: "absolute",
                      bottom: "0.938rem",
                      left: 0,
                      height: 35,
                      width: "100%",
                    }}
                  >
                    <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
                  </audio>
                </div>
              </div>
            )}
            {item.mediaType === "video" && (
              <div
                style={{
                  //width: sourcePage == "myAuction" ? "21em" : "23em",
                  height: "23em",
                }}
              >
                <video
                  id="vid"
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  disablePictureInPicture
                  controlsList="nodownload"
                  loop="true"
                  autoplay="autoplay"
                  muted
                  playsInline
                >
                  <source src={item?.mediaPreviewUrl} />
                  Your browser does not support the video.
                </video>
                {/* <ReactPlayer
                  url={item?.mediaPreviewUrl}
                  controls={true}
                  light={false}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "cover" }}
                  config={{
                    file: {
                      attributes: {
                        disablePictureInPicture: true,
                        controlsList: "nodownload",
                      },
                    },
                  }}
                /> */}
              </div>
            )}
            {(item?.mediaType === "image" ||
              item?.mediaType === "3D Model") && (
              <div className={styles.previewImage}>
                <img
                  src={item?.thumbnailUrl || item?.mediaPreviewUrl}
                  style={{
                    width: "100%",
                  }}
                  alt="thumbnail.png"
                />
              </div>
            )}
          </div>
          <div
            className={styles.link}
            // to={
            //   type === "view" ? "/item/view/" + item?.id : "/item/" + item?.id
            // }
          >
            <div className={styles.body}>
              <div
                className={styles.line}
                style={{
                  // flexWrap: "wrap",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  flex: !sourcePage ? "" : "0 0 100%",
                }}
              >
                <div
                  className={styles.name}
                  data-id="assetname"
                  style={
                    marketplace ? { cursor: "pointer" } : { cursor: "default" }
                  }
                  onClick={(e) => {
                    if (marketplace) {
                      const profileRedirectRoute =
                        Number(accountDetails?.id) ===
                        Number(item?.currentOwner?.id)
                          ? `/dashboard`
                          : `/viewProfile/${item?.currentOwner?.id}`;
                      history.push(profileRedirectRoute);
                      e.preventDefault();
                    }
                  }}
                >
                  {/* <img
                    src={
                      item?.currentOwner?.profilePicUrl
                        ? item?.currentOwner?.profilePicUrl
                        : "/images/content/Avatar.png"
                    }
                    height="unset"
                    width="unset"
                    alt="profilepic"
                  ></img> */}

                  {window.location.href.includes("dashboard?tab=0") ||
                  (window.location.pathname === "/dashboard" &&
                    window.location.search === "") ? (
                    <>
                      <Avatar
                        alt="profilepic"
                        src={
                          item?.currentOwner?.profilePicUrl
                            ? item?.currentOwner?.profilePicUrl
                            : "/images/content/Avatar.png"
                        }
                      />
                      <div className={styles.ownerName}>
                        {/* <h5>{marketplace ? "Creator" : "Owned by"}</h5> */}
                        Creator
                        <p>
                          @
                          {item?.currentOwner?.userName ||
                            item?.creator?.userName ||
                            item?.currentOwner?.email ||
                            item?.creator?.email}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <Avatar
                        alt="profilepic"
                        src={
                          item?.currentOwner?.profilePicUrl
                            ? item?.currentOwner?.profilePicUrl
                            : "/images/content/Avatar.png"
                        }
                      />
                      <div className={styles.ownerName}>
                        {/* <h5>{marketplace ? "Creator" : "Owned by"}</h5> */}
                        Owner
                        <p>
                          @
                          {item?.currentOwner?.userName ||
                            item?.creator?.userName ||
                            item?.currentOwner?.email ||
                            item?.creator?.email}
                        </p>
                      </div>
                    </>
                  )}

                  <div
                    className={styles.love}
                    style={{
                      display: [
                        "myNFT",
                        "myAuction",
                        "activeListing",
                        "createdNFTsProfile",
                        "sourcePage",
                      ].includes(sourcePage)
                        ? "none"
                        : "flex",
                    }}
                  >
                    <div
                      className={styles.loveIcon}
                      onClick={(e) => {
                        if (walletStatus) {
                          console.log("hii");
                          item?.isWishlist
                            ? removeFromWishlist(item?.id)
                            : addToWishlist(item?.id);
                        } else {
                          PopUpAlert(
                            "Alert",
                            "Login before adding to wishlist.",
                            "error"
                          ).then(() =>
                            document.getElementById("connectWallet").click()
                          );
                        }
                        e.stopPropagation();
                      }}
                    >
                      <img
                        src={
                          item?.isWishlist
                            ? "/Icons/blackLove.png"
                            : "/Icons/love.svg"
                        }
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    padding: "0.8em 1.35em",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {/* //check if it is auction. If auction then show bid else show regularPrice */}
                  <p className={styles.priceLabel}>
                    {details?.orderInfo &&
                    details?.orderInfo?.type !== "auction" &&
                    item?.saleType != "fixed"
                      ? ["activeListing"].includes(sourcePage)
                        ? "Resell price"
                        : "Price"
                      : (["myNFT"].includes(sourcePage) &&
                          item?.saleType != "fixed") ||
                        (["myAuction"].includes(sourcePage) &&
                          item?.saleType != "fixed" &&
                          auctionTabId == 1)
                      ? details?.orderInfo?.type != "auction"
                        ? "Winning bid"
                        : ""
                      : item?.saleType != "fixed"
                      ? "Current bid"
                      : ""}
                    {item?.saleType == "fixed" && !details?.orderInfo
                      ? ["activeListing"].includes(sourcePage)
                        ? "Resell price"
                        : "Price"
                      : ["myAuction"].includes(sourcePage) &&
                        auctionTabId == 1 &&
                        details?.orderInfo?.type == "auction"
                      ? "Winning Bid"
                      : ""}
                  </p>
                  {sourcePage != "myAuction" && (
                    <div className={styles.priceSection}>
                      {/* <h5>
                      {item?.saleType === "auction" && type !== "view"
                        ? (
                            (item.auction?.currentBid ||
                              item.auction?.openingPrice) * euroValue
                          ).toFixed(2)
                        : (item.regularPrice * euroValue).toFixed(2)}
                      <></> MATIC
                    </h5> */}
                      <h5 className={styles.priceAmount}>
                        {" "}
                        
                        {item?.isAirdropNft&&!item?.isOnSell?"FREE":<>
                        $
                        {item.saleType === "auction" && type !== "view"
                          ? formatPrice(
                              (
                                item.auction?.currentBid ||
                                item.auction?.openingPrice
                              )?.toFixed(2)
                            )
                          : item?.isOnSell && sourcePage != "myNFT"
                          ? formatPrice(item.resellPrice?.toFixed(2))
                          : formatPrice(item.regularPrice?.toFixed(2))}
                      
                      </>}
                      </h5>
                    </div>
                  )}
                  {sourcePage == "myAuction" && (
                    <div className={styles.priceSection}>
                      {/* <h5>
                      {details?.saleType === "auction" && type !== "view"
                        ? (details?.currentBid).toFixed(2)
                        : (details?.currentBid * euroValue).toFixed(2)}
                      MATIC
                    </h5> */}
                      <h5 className={styles.priceAmount}>
                        {" "}
                        $
                        {details?.orderInfo?.type === "auction"
                          ? formatPrice(
                              details?.orderInfo?.totalPrice.toFixed(2)
                            )
                          : formatPrice(details?.bids[0]?.amount.toFixed(2))}
                      </h5>
                    </div>
                  )}
                </div>

                {sourcePage != "myAuction" && (
                  <div
                    style={{
                      padding: "0.8em 1.35em",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p>Date listed :</p>
                    {/* {console.log(`${current_datetime(item?.createdAt)?.split(" ")[0]}`, moment.utc(item?.createdAt).local().format('DD/MM/YYYY    h:mm:ss A'), 'dateee')} */}
                    {moment.utc(item?.createdAt).local().format('DD/MM/YYYY')}
                  </div>
                )}

                {item?.saleType == "auction" && !hidePurchaseToolBar && (
                  <>
                    <div className={styles.auctionContainer}>
                      {moment(item?.auction?.startDate) < moment() && (
                        <div className={styles.timeleft}>
                          <img
                            src="/images/content/timer.png"
                            alt="auction-timer"
                          />
                          {moment(item?.auction?.startDate) < moment() ? (
                            <span style={{ textSize: "0.8em" }}>
                              {days ? `${days}d` : ``}{" "}
                              {hours || hours == 0 ? `${hours}h` : ``}{" "}
                              {minutes || minutes == 0 ? `${minutes}m` : ``}{" "}
                              {seconds || seconds == 0 ? `${seconds}s` : ``}
                            </span>
                          ) : (
                            <></>
                            // <span style={{ textSize: "0.8em" }}>
                            //   Auction not started yet
                            // </span>
                          )}
                        </div>
                      )}

                      <div
                        className={styles.bidNowButton}
                        onClick={() => {
                          if (!walletStatus) {
                            // PopUpAlert(
                            //   "Alert",
                            //   "No wallet connection found.",
                            //   "error"
                            // ).then((res) => {
                            document.getElementById("connectWallet").click();
                            // });
                            return false;
                          } else {
                            let pathName =
                              (type === "view" && sourcePage == null) ||
                              (sourcePage == "myAuction" && auctionTabId !== 0)
                                ? "/item/view/" + item?.id
                                : "/item/" + item?.id;
                            history.push(pathName);
                          }
                        }}
                      >
                        {moment(item?.auction?.startDate) < moment() ? (
                          <button
                            style={{
                              padding: "0px 0.625rem",
                              borderRadius: "0.375rem",
                              cursor: "pointer",
                            }}
                          >
                            Place Bid
                          </button>
                        ) : (
                          <button
                            style={{
                              padding: "0px 0.625rem",
                              borderRadius: "0.375rem",
                              cursor: "pointer",
                            }}
                          >
                            Auction hasn't started yet.
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {item?.saleType == "fixed" && !hidePurchaseToolBar && (
                  <>
                    <div
                      className={styles.fixedContainer}
                      style={{
                        padding: "0px 0.625rem",
                        borderRadius: "0.375rem",
                      }}
                      onClick={() => {
                        if (!walletStatus) {
                          // PopUpAlert(
                          //   "Alert",
                          //   "No wallet connection found",
                          //   "error"
                          // ).then((res) => {
                          document.getElementById("connectWallet").click();
                          // });
                          return false;
                        } else {
                          let pathName =
                            (type === "view" && sourcePage == null) ||
                            (sourcePage == "myAuction" && auctionTabId !== 0)
                              ? "/item/view/" + item?.id
                              : "/item/" + item?.id;
                          history.push(pathName);
                        }
                      }}
                    >
                      <button styles={{ cursor: "pointer" }}>
                        {item?.isOnSell &&
                        walletId == item?.currentOwner?.publicAddress
                          ? "View"
                          : checkIfCreatorOrArtist(item)
                          ? "You are the creator"
                          : item?.isAirdropNft === true && item?.isOnSell !== true ? item?.isAirdropRequested? 'You have already requested' :'Request Asset' : "Buy Now"}
                      </button>
                    </div>
                  </>
                )}
                <div style={{ padding: "0px 0.75rem", width: "100%" }}>
                  {["activeListing"].includes(sourcePage) && (
                    <div
                      data-id="liststatus"
                      className={styles.line}
                      style={{
                        marginTop: 5,
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      {!item?.isOnSell && item?.status == "inactive" && (
                        <div
                          className={styles.price}
                          style={{
                            // boxShadow: "inset 0 0 0 0.063rem #00008b",
                            // color: item.isOnSell ? "#45B26B" : "#ff4040",
                            color: "black",
                            flexBasis: "100%",
                            marginBottom: 5,
                            textAlign: "center",
                            borderRadius: "0.625rem",
                            height: "2.5rem",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {"Not listed on marketPlace"}
                        </div>
                      )}
                      {item.isOnSell && (
                        <button
                          className={"button-small"}
                          style={{
                            background: "black",
                            borderRadius: "0.625rem",
                            width: "100%",
                            marginBottom: "0.625rem",
                            fontSize: "0.813rem",
                            fontWeight: 700,
                            fontFamily: "Arial",
                          }}
                          onClick={(e) =>
                            handleUnlist(e, item.tokenId, item.id)
                          }
                        >
                          Unlist from Marketplace
                        </button>
                      )}
                    </div>
                  )}
                </div>

                {/* <div className={styles.price}
                  style={{
                    flexBasis: "45%",
                    boxShadow: "inset 0 0 0 0.063rem #ff7acb",
                    color: "#ff7acb"
                  }}
                  data-id="price123"
                >
                  &#36;
                  {item.saleType === "auction" && type !== "view" ?
                    formatPrice((item.auction?.currentBid || item.auction?.openingPrice)?.toFixed(0)) : formatPrice((item.regularPrice)?.toFixed(0))}
                </div> */}
                {/* <div className={styles.price} style={{ marginLeft: 5 }} data-id="ethprc">
                  {item.saleType === "auction" && type !== "view" ?
                    (item?.ethAmount || ethAmount).toFixed(4) : (item?.ethAmount || ethAmount)?.toFixed(4)}
                  <span style={{ fontSize: 8, marginLeft: 2, textTransform: "none" }}>{item.saleType === "auction" ? "wETH" : "ETH"}</span>
                </div> */}
              </div>
              <div
                className={styles.line}
                // style={{ width: !sourcePage ? "" : "100%" }}
              >
                <div className={styles.users}>
                  {item.users?.map((x, index) => (
                    <div className={styles.avatar} key={index}>
                      <img src={x.avatar} alt="Avatar" />
                    </div>
                  ))}
                </div>
                {/* <div className={styles.counter} style={{ fontSize: 10, minHeight: 40 }} data-id="shrtdes">
                  {type !== "view" && (
                    (item?.shortDescription)?.length > 63
                      ? (item?.shortDescription)?.substring(0, 62) + "..."
                      : item?.shortDescription
                  )
                  }
                  <br />
                  <b>Creator</b> : &nbsp;
                  {type !== "view" && (
                    (item?.creatorName)?.length > 33
                      ? (item?.creatorName)?.substring(0, 30) + "..."
                      : item?.creatorName || "-"
                  )
                  }
                  {
                    type === "view" &&
                    <p>Purchase Date : {current_datetime(item?.createdAt)}</p>
                  }
                </div> */}
              </div>

              {/* {["activeListing"].includes(sourcePage) && (
                <div
                  data-id="liststatus"
                  className={styles.line}
                  style={{ marginTop: 5, flexWrap: "wrap" }}
                >
                  <div
                    className={styles.price}
                    style={{
                      // boxShadow: "inset 0 0 0 0.063rem #00008b",
                      color: item.isOnSell ? "#45B26B" : "#ff4040",
                      flexBasis: "100%",
                      marginBottom: 12,
                    }}
                  >
                    {item.isOnSell
                      ? "Listed on MarketPlace"
                      : "Not Listed on MarketPlace"}
                  </div>
                  {item.isOnSell && (
                    <button
                      className={"button-small"}
                      style={{
                        background: "#ee3951",
                        borderRadius: 3,
                      }}
                      onClick={(e) => handleUnlist(e, item.id)}
                    >
                      Unlist from MarketPlace
                    </button>
                  )}
                </div>
              )} */}
            </div>
            {/* <div className={styles.foot}>
              <div className={styles.status}>
                <Icon name="candlesticks-up" size="20" />
                Highest bid <span>{item.highestBid}</span>
              </div>
              <div
                className={styles.bid}
                dangerouslySetInnerHTML={{ __html: item.bid }}
              />
            </div> */}
          </div>
        </div>
      ) : (
        <></>
      )}
      <Loader
        active={active}
        customize={true}
        title={"Unlisting in progress"}
        content={"Please wait"}
      />
    </>
  );
};

export default CardContainer;
