import styles from "./viewprofile.module.sass";
import cn from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import ProfileDash from "./ProfileDash";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/API";
import ProfileCardArea from "./ProfileCardArea";
import ReactPaginate from "react-paginate";
import Loader from "../Item/Loader";
import { CircularProgress } from "@material-ui/core";
import { PopUpAlert } from "../../controller/utils";
// import { CoverImg }  from '/images/content/profile-placeholder.png';
const ViewProfile = () => {
  const name = useParams();
  const history = useHistory();
  const [ownedAsset, setOwnedAsset] = useState([]);
  const [createdNfts, setCreatedNfts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [createdLoader, setCreatedLoader] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [count, setCount] = useState(0);
  const [params, setParams] = useState({
    limit: 8,
    page: 1,
  });
  const [profileDetails, setProfileDetails] = useState({});
  const getProfileAndOwnedNfts = async () => {
    if (currentTab == 0 ) {
      setCreatedLoader(true);
      let newArray = [];
      const sendPayload = { ...params, type: "owned" };
      const config = {
        params: sendPayload,
      };
      try {
        const response = await axiosInstance.get(
          `/user/artist/${name?.name}`,
          config
        );
        let originalArray = response?.data?.result?.ownedAssets?.rows;
        originalArray.forEach((item, index) => {
          let obj = { Asset: item };
          newArray.push(obj);
        });
        console.log(newArray);
        setOwnedAsset(newArray);
        setCount(response?.data?.result?.ownedAssets?.totalPages);
        setCreatedLoader(false);
      } catch (err) {
        PopUpAlert("Error", err?.response?.data?.message, "error").then(
          (selected) => {
            history.goBack();
          }
        );
      }
    } else {
      setCreatedLoader(true);
      const sendPayload = { ...params, type: "created" };
      const config = {
        params: sendPayload,
      };
      try {
        const response = await axiosInstance.get(
          `/user/artist/${name?.name}`,
          config
        );
        setProfileDetails(response?.data?.result?.artistDetails);
        setCreatedNfts(response?.data?.result?.createdAssets?.rows);
        setCount(response?.data?.result?.createdAssets?.totalPages);
        setCreatedLoader(false);
      } catch (err) {
        PopUpAlert("Error", err?.response?.data?.message, "error").then(
          (selected) => {
            history.goBack();
          }
        );
      }
    }
  };
  const handlePageClick = ({ selected: selectedPage }) => {
    setParams({ ...params, page: selectedPage + 1 });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    
    getProfileAndOwnedNfts();
  }, [name, currentTab, params]);

  useEffect(() => {
    setParams({ ...params, page: 1 });
  }, [currentTab]);

  const checkIfEmpty = (list) => {
    if (list.length == 0) {
      return true;
    } else {
      return false;
    }
  };
  const showPagination = () => {
    if (currentTab == 0 && ownedAsset.length == 0) {
      return false;
    } else if (currentTab == 1 && createdNfts.length == 0) {
      return false;
    } else {
      return true;
    }
  };
  const goTo = (url) => {
    window.open(url, "_blank");
  };


  
  return (
    <>
      <div className={cn("section", styles.section)}>
        {loader && (
          <div className={styles.loaderContent}>
            <CircularProgress color="black" />
          </div>
        )}

        {!loader && (
          <>
            <div className={styles.ProfileInfoWrapper}>
              <div className={styles.cover}></div>
              <img
                src={
                  profileDetails?.coverPicUrl
                    ? profileDetails?.coverPicUrl
                    : "/images/profile-bg.png"
                }
                className={styles.coverImageSection}
                alt="CoverImage"
              />
              {/* <div className={styles.profileImage}></div> */}
              <div className={styles.profileBorder}></div>
              <div className={styles.profileInfoSection}>
                <div className={styles.infoContainer}>
                  <div
                    className={styles.profileImage}
                    style={{
                      backgroundImage: `url(${
                        profileDetails?.profilePicUrl
                          ? profileDetails?.profilePicUrl
                          : "/images/content/Avatar.png"
                      })`,
                    }}
                  ></div>

                  <div className={styles.profileDetailsInfo}>
                    <h3>
                      {`${profileDetails?.firstName ? profileDetails?.firstName : ''} ${profileDetails?.lastName ? profileDetails?.lastName : ''}`}{" "}
                    </h3>
                    <p>{`@${profileDetails?.userName ? profileDetails?.userName : ''}`}</p>
                  </div>
                </div>
                <div className={styles.bioDesc}>
                  <p>{profileDetails?.description?.length > 200 && profileDetails?.description?.slice(0, 200) + '...'}</p>
                  <p>{profileDetails?.description?.length < 200 && profileDetails?.description}</p>

                  <div className={styles.profileSocialMediaLinks}>
                    {profileDetails?.facebookUrl && (
                      <a
                        href={`${profileDetails?.facebookUrl}`}
                        target="_blank"
                      >
                        <img
                          src={"/Icons/facebookSmallIcon.svg"}
                          alt="facebook"
                          height="1.25rem"
                          width="1.25rem"
                        />
                      </a>
                    )}
                    {profileDetails?.twitterUrl && (
                      <a href={`${profileDetails?.twitterUrl}`} target="_blank">
                        <img
                          src="/images/twitter 1.svg"
                          alt="twitter"
                          height="1.25rem"
                          width="1.25rem"
                        />
                      </a>
                    )}{" "}
                    {profileDetails?.instagramUrl && (
                      <a
                        href={`${profileDetails?.instagramUrl}`}
                        target="_blank"
                      >
                        <img
                          src="/images/instagramsvg.svg"
                          alt="LinkedIn"
                          height="1.25rem"
                          width="1.25rem"
                        />
                      </a>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className={cn("container", styles.container)}>
              <div className={styles.dashtab}>
                <ProfileDash setCurrentTab={setCurrentTab} currentTab={currentTab} />
              </div>
              {(ownedAsset || createdNfts) && (
                <ProfileCardArea
                  ownedAsset={ownedAsset}
                  createdNfts={createdNfts}
                  currentTab={currentTab}
                  checkIfEmpty={checkIfEmpty}
                  createdLoader={createdLoader}
                  profileDetails={profileDetails}
                />
              )}

              {}
              {showPagination() && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={count}
                  forcePage={params?.page - 1}
                  onPageChange={handlePageClick}
                  containerClassName={styles.pagination}
                  previousLinkClassName={cn(
                    styles.pagination__link,
                    "page_switch"
                  )}
                  nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                  pageClassName={"page_no"}
                  disabledClassName={styles.pagination__link__disabled}
                  activeClassName={styles.pagination__link__active}
                  // pageRangeDisplayed={width < 510 ? 1 : 3}
                  // marginPagesDisplayed={width < 510 ? 1 : 3}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewProfile;
