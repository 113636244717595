import React, { useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import styles from "./CirclePayment.module.sass";
import TopUpWalletWert from "../WertPayment/topUpWalletWert";
import Modal from "../../components/Modal";
import Select from "@material-ui/core/Select";
import SelectWallet from "./SelectWallet/SelectWallet";
import { Button } from "@material-ui/core";


function WalletDisplayBar({ openWithdrawModal, usdcWalletBalance }) {
	let circleBalance = useSelector((state) => state.circlePayment.balance);
	let circlecurrency = useSelector((state) => state.circlePayment.currency);
	const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
	const usdcWalletBalanceAmount = useSelector((state) => state?.usdc?.amount)

	const [openTopUpModal, setOpenTopUpModal] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState('usdc')

	const [wallet, setWallet] = useState('USDC');
	const [openWert,setOpenWert] =useState(false)

	return (
		<div className={cn("wrapper", styles.wrapper)}>
			<div className={styles.walletInfo}>
				<div className={styles.WalletDisplayBarIcon}>
					<img src={"/images/usdc-logo.png"} alt="wallet" />
				</div>
				<div className={styles.WalletDisplayBarBalance}>
					<div className={styles.WalletDisplayBarBalanceText}>
						{/* {circleBalance ? circleBalance : "0"} {circlecurrency} */}
						<h3>{usdcWalletBalanceAmount.toFixed(2)} USDC</h3>
						{/* {usdcWalletBalance!==null ? usdcWalletBalance==0?usdcWalletBalance: usdcWalletBalance.toFixed(2) : "-"}  */}
					</div>
					<div className={styles.WalletDisplayBarBalanceLabel}>
						USDC Token balance
					</div>
				</div>
			</div>
			<div className={styles.WalletDisplayBarAction}>
				<div className={styles.button} onClick={() => openWithdrawModal()}>
					Withdraw
				</div>
				<div className={styles.button} onClick={() => setOpenTopUpModal(true)}>
					Top Up Wallet
				</div>
				{openWert ? (

						<TopUpWalletWert
						setOpenTopUpModal={setOpenTopUpModal}
						walletId={walletId}
						wallet={wallet}
						/>
					) : (
						""
				)}
			{console.log(wallet)}
			<Modal
				visible={openTopUpModal}
				onClose={() => setOpenTopUpModal(false)}
				containerClassName={styles.modalContainer}
				outerClassName={styles.modal}
			>
				<SelectWallet setWallet={setWallet} wallet = {wallet} setOpenTopUpModal= {setOpenTopUpModal} setOpenWert={setOpenWert}/>
			</Modal>
			</div>
		</div>
	);
}

export default WalletDisplayBar;
