import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import RecentSold from "./RecentSold/index";
import Header from "../../components/Header";
import { SectionsContainer } from "react-fullpage";
import styles from "./Home.module.sass";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import Footers from "../../components/Footer";
const Home = () => {
  const [options, setOptions] = useState({
    activeClass: "active",
    arrowNavigation: false,
    delay: 500,
    navigation: false,
    scrollBar: false,
    sectionPaddingTop: "3.125rem",
    sectionPaddingBottom: "3.125rem",
    verticalAlign: false,
  });
  const [anchorData, setAnchorData] = useState(["1", "2"]);
  const { search } = useLocation();
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const history = useHistory();

  // const [backFromMarketPlace, setBackFromMarketPlace] = useState(false);

  let screenToIncludeHeader = [2, 3, 4];
  useEffect(() => {
    document.getElementById("bannerVideo").play();
    setOptions({ ...options, anchors: anchorData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To implement header according to the dynamic scroll index.
  useEffect(() => {
    setCurrentScreenIndex(window.location.href?.split("#")?.[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString.parse(search)]);

  // useEffect(() => {
  //   setUrlFlag(true);
  //   window.history.pushState(null, null, document.URL);
  //   window.addEventListener("popstate", function (event) {
  //     setUrlFlag(true);
  //     window.location.replace(process.env.REACT_APP_SERVER_URL);
  //   });
  // }, []);

  useEffect(() => {
    history.push("/");
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = (title) => {
    let data = anchorData;
    if (title === "Most Viewed") {
      data.push("4");
    } else {
      data.push("3");
    }
    setAnchorData(data);
    setOptions({ ...options, anchors: data });
  };

  return (
    <>
      {screenToIncludeHeader.includes(Number(currentScreenIndex)) && <Header />}
      {window.innerWidth < 600 ? (
        <Sections
          currentScreenIndex={currentScreenIndex}
          handleAdd={handleAdd}
          anchorData={anchorData}
        />
      ) : (
        // <SectionsContainer {...options} navigation={false}>
        // <div style = {{height: '100%',
        // 	width: '100%',
        // 	position: relative,
        // 	transform: translate3d(0, -916, 0),
        // 	transition: 'all 500ms ease 0s'}}>
        <Sections
          currentScreenIndex={currentScreenIndex}
          handleAdd={handleAdd}
          anchorData={anchorData}
        />
        // </div>
      )}
    </>
  );
};

const Sections = ({ currentScreenIndex, handleAdd, anchorData }) => {
  return (
    <>
      <div className={styles.bannerVideoWrapper}>
        <video
          id="bannerVideo"
          autoPlay
          muted
          playsInline
          loop
          style={{
            position: "absolute",
            height: "100%",
            objectFit: "cover",
            width: "100vw",
          }}
        >
          <source src={process.env.REACT_APP_HOME_VIDEO} type="video/mp4" />
        </video>
      </div>
      <Header sourcePage={"home"} />
      <Hero currentScreenIndex={currentScreenIndex} />
      {/* <Selection /> */}
      {/* <Popular /> */}
      <RecentSold
        fixed={true}
        title={"Latest Releases"}
        handleAdd={handleAdd}
        anchorData={anchorData}
      />

      {/* <RecentSold title={"Recently Sold"}/> */}
      <RecentSold
        title={"Most viewed"}
        fixed={true}
        mode={3}
        handleAdd={handleAdd}
        anchorData={anchorData}
      />

      {/* <HotBid classSection="section" /> */}
      {/* <Collections /> */}
      {/* <Discover /> */}
      {/* <Description /> */}
      <Footers />
    </>
  );
};

export default Home;
