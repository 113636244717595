import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { axiosInstance, axiosInstanceBlockChain } from "../../utils/API";
import Web3 from "web3";
import { getBalance } from "../../../src/controller/utils";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import PurchaseInfo from "./PurchaseInfo";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Link } from "react-router-dom";
import Counter from "../counter/Counter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  contract_auction_abi,
  PopUpAlert,
  connector,
  formatPrice,
  getGasPrice,
  getWeb3Provider,
  ASLY_MARKETPLACE_ABI,
  decryptData,
  LiiMarketPlaceABI,
  LiiErc20_contract_abi,
} from "../../controller/utils";
import Lightbox from "./Lightbox";
import Loader from "./Loader";
import BillingDetails from "./Payment/BillingDetails/index";
import { checkNetwork } from "./../../controller/utils";
import { circleBidButton, decrement } from "../../redux/counterSlice";
import BidRows from "./NewBidHistory";
import BidConfirmationModal from "../Auction/PlaceBid";
import SuccessPopup from "./SuccessPopup";
import SocialShare from "./SocialShare";
import useWindowSize from "../../common/hooks/useWindowSize";
import { Checkbox } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import WertWidgetDiv from "../WertPayment/wertwidget.js";
import Variants from "../../components/ExploreSkeleton";
import VariantsMobile from "../../components/ExploreSkeletonMobile";
import { getUsdcBalanceFromAccount } from "../../utils/balance.js";

let navLinks;

const Item = (props) => {
  if (props?.mode !== "view") {
    navLinks = ["Info", "Description", "Creator"];
  } else {
    navLinks = ["Info", "Description", "Creator", "Purchase info"];
  }

  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const accountDetails = useSelector((state) => state.accountReducer);
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  const userEmail = useSelector((state) => state?.accountReducer?.email);
  // const userAccount = useSelector((state) => state?.counter?.user?.account);
  const isUserVerified = useSelector((state) => state?.accountReducer?.status);
  const isLogin = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();
  const userAccount = useSelector((state) => state?.counter?.user);
  const loginWith = localStorage.getItem("network");
  const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

  let provider, account, balance, check;

  if (!connector.connected) {
    // create new session
    connector.createSession();
  }

  const { id } = useParams();
  const history = useHistory();
  const walletStatus = useSelector((state) => state.counter.value);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [bidInfo, setBidInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  // const [txnHash, setTxnHash] = useState(null);
  const [confirmPaymentDetails, setConfirmPaymentDetails] = useState({
    txnHash: null,
    orderId: null,
  });
  const [transactionFlag, setTransactionFlag] = useState(false);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [active, setActive] = useState(false);
  const [isUpcomingBid, setIsUpcomingBid] = useState(false);
  const [productPreviews, setProductPreviews] = useState([]);
  const [shortLimit, setShortLimit] = useState(450);
  const [bidConfirmation, setBidConfirmation] = useState(false);
  const [currentBidAmount, setCurrentBidAmount] = useState(0);
  const [successPopupAmount, setSuccessPopupAmount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [creatorDetails, setCreatorDetails] = useState(null);
  const [modalMessage, setModalMessage] = useState({
    action: null,
    message: "",
  });

  const [successShow, setSuccessShow] = useState(false);
  const [assetType, setAssetType] = useState("fixed");
  const [shareOption, setShareOption] = useState(false);
  const [screenWidth] = useWindowSize();
  const currentPaymentType = useSelector(
    (state) => state.counter.currentPaymentType
  );
  const userId = JSON.parse(localStorage.getItem("cs_details"))?.account;
  const userDbId = JSON.parse(localStorage.getItem("cs_details"))?.userId;
  const magicLinkActive = useSelector(
    (state) => state.counter?.magicUser?.email || ""
  );

  const userAccountDetails = useSelector((state) => state.counter.user);

  const callSetSuccessShow = ()=>{
    setSuccessShow(true);
  }
  
  const updateFixedOrderStatus = (assetId, orderId, transactionId)=> {
    const body = { assetId, orderId, transactionId };
    console.log("updateFixedOrderStatus body ", body);
    axiosInstance
    .post(`/asset/wert/pending`,body, config)
    .then((res) => {
      console.log('updateFixedOrderStatus ',res)
    })
    .catch((err) => {
      console.error("Error", err);
    });
  }

  const { search } = useLocation();
  const urlParam = queryString.parse(search);
  let circleBalance = useSelector((state) => state.circlePayment.balance);

  const [wert, setWert] = useState({
    active: false,
    mode: "",
    saleType: "fixed",
    bidAmountFiat: null,
    bidAmountCrypto: null,
    processWertResult: null,
  });

  const [prePurchaseDetails, setPrePurchaseDetails] = useState({});
  const [closeWert, setCloseWert] = useState(false)
  const [purchaseMessage, setPurchaseMessage] = useState({
    title: null,
    message: null
  })

  let config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };
  useEffect(() => {
    setCurrentBidAmount(0);
  }, []);

  useEffect(() => {
    if (!open) {
      enableScroll();
    } else {
      disableScroll();
    }
  }, [open]);
  const [checked, setChecked] = React.useState(true);

  const handleCheckboxCheck = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (id) {
      getProductDetails(id);
      const detailInterval = setInterval(
        () => getProductDetails(id),
        30000 * 3
      );
      return () => clearInterval(detailInterval);
    }
  }, [id, refresh]);

  const getLastBidderDetails = () => {
    axiosInstance
      .get(`asset/auction/last/bidder-details?assetId=${id}`, config)
      .then((res) => {
        if (
          res.data.result.length > 0 &&
          res.data.result[0].status == "success"
        ) {
          dispatch(circleBidButton(true));
        } else if (res.data.result.length == 0) {
          dispatch(circleBidButton(true));
        } else {
          dispatch(circleBidButton(false));
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  useEffect(() => {
    console.log("Item.product Dteails", productDetails);
    if (productDetails?.saleType == "auction") {
      const interval = setInterval(() => {
        getLastBidderDetails();
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [productDetails]);

  useEffect(() => {
    const confirmTransaction = async () => {
      console.log("jkj doubt wert payment metamask")
      if (confirmPaymentDetails.txnHash && confirmPaymentDetails.orderId) {
        await confirmOrder(
          confirmPaymentDetails.txnHash,
          confirmPaymentDetails?.orderId,
          "success"
        );
        // history.push("/orderDetails");
      }
    };
    confirmTransaction();
  }, [transactionFlag]);

  const checkbalance = (balance) => {
    return balance >= productDetails.salePrice ? true : false;
  };

  // call this to Disable
  function disableScroll() {
    document.body.style.overflow = "hidden";
  }

  // call this to Enable
  function enableScroll() {
    document.body.style.overflow = "scroll";
  }

  const handleWalletCheckout = async (paymentType) => {
    const commidityAmount = productDetails?.isOnSell
      ? productDetails?.resellPrice
      : productDetails?.regularPrice;
    const cryptoAccountBalance =
      loginWith === "metamask"
        ? userAccount?.balance
          ? Number(userAccount?.balance)?.toFixed(2)
          : "0"
        : Number(
          magicLinkUser?.magicBalance
            ? Number(magicLinkUser?.magicBalance).toFixed(2)
            : "0"
        );
    const accountBalance =
      paymentType === "wallet" ? circleBalance : cryptoAccountBalance;
    disableScroll();
    setAssetType("fixed");
    let price = productDetails?.isOnSell
      ? productDetails?.resellPrice / euroValue
      : productDetails?.regularPrice / euroValue;
    setSuccessPopupAmount(price);
    let magiclink = magicLinkActive ? true : false;
    if (magiclink && paymentType != "wallet") paymentType = "magiclink";
    if (
      !accountDetails?.firstName ||
      !accountDetails?.lastName ||
      !accountDetails?.email
    ) {
      PopUpAlert(
        "Profile Incomplete.",
        "Please complete your profile.",
        "info"
      ).then(() => {
        enableScroll();
        history.push("/my-account");
      });
    } else if (
      (accountDetails?.isKYCLimitExceeded &&
        Number(accountDetails?.kycStatus) != 1) ||
      (Number(accountDetails?.kycStatus) != 1 && commidityAmount > 2000)
    ) {
      PopUpAlert(
        "Alert",
        "Exceeded $200 transaction limit. Verify KYC to proceed.",
        "error"
      ).then(() => {
        history.push("/dashboard");
      });
      // } else if (accountBalance < commidityAmount) {
      //   PopUpAlert(
      //     "Alert",
      //     "You don't have sufficient balance to purchase this product. Please topup your wallet.",
      //     "error"
      //   ).then(() => {
      //     history.push("/circlePayment");
      //   });
    } else {
      const wc_status = connector.connected;
      setOpen(false);

      let web3 = await getWeb3Provider();

      // await provider?.enable();
      // let web3 = connector.connected
      //     ? new Web3(provider)
      //     : magicLinkUser?.publicAddress
      //     ? new Web3(maticWeb3)
      //     : new Web3(Web3.givenProvider);
      if ((!web3.currentProvider && !connector.connected) || !walletStatus) {
        PopUpAlert(
          "Alert",
          "Connect your wallet or login using email.",
          "error"
        ).then((res) => {
          enableScroll();
          document.getElementById("connectWallet").click();
        });
        return;
      }
      const chainId = await web3.eth.getChainId();
      const networkVerify = await checkNetwork(chainId);
      if (!networkVerify) {
        return false;
      }
      try {
        account = wc_status ? connector.accounts : await web3.eth.getAccounts();
        balance = await web3?.eth.getBalance(account[0]);
        balance = web3?.utils.fromWei(balance, "ether");
        check = checkbalance(balance);
      } catch (error) {
        console.log("wallet connect error1 ", error)
        alert("Network Session Expired, please reconnect your wallet");
        dispatch(decrement());
        enableScroll();
        window.location.reload();
        return;
      }
      paymentType == "wallet" && setActive(true);

      let transactionObject = {
        from: account[0],
        to: productDetails.isOnSell
          ? productDetails.purchasedBy
          : process.env.REACT_APP_ADDRESS_DEV,
        value: web3?.utils.toWei(`${productDetails?.maticPrice?.toFixed(4)}`),
      };
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };
      let body = {
        salePrice: productDetails?.isOnSell
          ? productDetails?.resellPrice
          : productDetails?.regularPrice,
        saleType: productDetails?.saleType,
        status: "pending",
        quantity: 1,
        paymentType: paymentType,
        from: transactionObject.from,
        to: transactionObject.to,
        ethPrice: productDetails?.isOnSell
          ? productDetails?.resellPrice / euroValue
          : productDetails?.regularPrice / euroValue,
        paymentDetail: null,
      };
      try {
        apicall();
        async function apicall() {

          const account = await web3.eth.getAccounts();

          //wallet balance will return in wei (base unit of ETH)
          let walletBalance = await getUsdcBalanceFromAccount(account[0])
          let maticBalance = await web3.eth.getBalance(account[0]);

          console.log("matic balance", maticBalance,walletBalance)
          await getBalance();
          const matic_balance=localStorage.getItem("tokenbalance");
          console.log("385 matic_balance", matic_balance)
          if (Number(matic_balance) < 0.2 ) {
            disableScroll();
            PopUpAlert(
              "Alert!",
              "Insufficient funds in your crypto wallet. To proceed with the purchase, please ensure that you have a minimum balance of 0.2 Matic in your wallet. Kindly add funds to your wallet before proceeding.",
              "error",
              true
            ).then((res) => {
              enableScroll(); 
              if(!res.isDismissed) 
                history.push("/wallet");            
              });
            return;
          }

          if (parseInt(walletBalance) < parseInt(body?.salePrice)) {
            PopUpAlert(
              "Alert!",
              "You do not have sufficient USDC balance to purchase this product. Please topup your wallet.",
              "error",
              true
            ).then((res) => {
              enableScroll();
              // if(isDismissed == )
            if(!res.isDismissed) 
              history.push("/wallet");
            });
            //setActive(false);
            return;

          }
          // bid amount is converting to wei
          const price_amount = web3.utils.toWei(productDetails?.maticPrice?.toFixed(4).toString(), "ether");


          // if (parseInt(walletBalance) < parseInt(price_amount)) {
          //   PopUpAlert(
          //     "Alert!",
          //     "You do not have sufficient balance to purchase this product. Please topup your wallet.",
          //     "error"
          //   ).then((res) => {
          //     enableScroll();
          //     history.push("/dashboard");
          //   });
          //   //setActive(false);
          //   return;
          // }

          await axiosInstance
            .post(`/asset/checkout/${productDetails.id}`, body, config)
            .then(async (res) => {
              setConfirmPaymentDetails((prevState) => ({
                ...prevState,
                orderId: res?.data?.result?.id,
              }));
              if (paymentType === "metamask" || paymentType === "magiclink") {
                await handlePayNow(
                  productDetails?.maticPrice?.toFixed(4),
                  res?.data?.result?.id,
                  web3
                );
              } else {
                setTransactionFlag(!transactionFlag);
                setActive(false);
                setSuccessShow(true);
              }
            })
            .catch((err) => {
              PopUpAlert("Alert", err.response?.data?.message, "error").then(
                (res) => {
                  if (
                    err.response?.data?.message ===
                    "Your wallet does not have sufficient funds"
                  ) {
                    history.push("/dashboard");
                  }
                  enableScroll();
                
              setActive(false);
              enableScroll();
              getProductDetails();

              if (err.response?.data?.message ===
                "Your previous order is under process") {
              } {
                history.push('/dashboard?tab=3')
              }
}
              );
              return;
            });
        }
      } catch (error) {
        enableScroll();
        alert("Please connect your wallet.");
      }
    }
  };

  // const handlePayWithCrypto = async() => {
  //   const getUserSignature = await handleGetSignature();
  // }

  const confirmOrder = async (txnHash, orderId, status) => {
    if (txnHash || (!txnHash && status == "failed")) {
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };
      let body = {
        txHash: txnHash,
        orderId: orderId,
        status: status,
      };
      try {
        console.log("waittttttt", body)
        await axiosInstance
          .post(`/metamask/transaction/confirmation/callback`, body, config)
          .then((res) => {
            console.log("response for transaction : ", res)
          })
          .catch((err) => {
            PopUpAlert("Alert", err.response?.data?.message);
            setActive(false);
            getProductDetails();
            return;
          });
      } catch (error) {
        enableScroll();
        alert("Please connect your wallet.");
      }
    }
  };

  const handleGetSignature = async (transactionDetails) => {

    console.log("transaction details", transactionDetails)
    const payload = {
      collectableId: transactionDetails?.tokenId,
      payer: userAccountDetails?.account?.[0],
      amount:
        productDetails?.saleType == "auction"
          ? transactionDetails?.bidAmountWEI
          : transactionDetails?.regularPrice,
    };
    return new Promise((resolve, reject) => {

      let url = '/blockchain/signature/payWithCrypto'
      if (productDetails?.saleType == "auction") {
        url = '/blockchain/signature/bidWithUsdc'
      }
      //posting to asly backend
      axiosInstance.post(url, payload, config).then((res) => {
        if (res) {
          resolve(res)
        } else {
          reject(new Error("Empty response"));
        }

      }).catch((error) => {
        reject(error)
      })
    });
  };

  const getTransactionDetails = async () => {
    return new Promise((resolve, reject) => {
      try {
        axiosInstance
          .get(`/asset/buy/${productDetails?.id}`, config)
          .then((res) => {
            if (res) {
              resolve(res);
            } else {
              reject(new Error("Empty response"));
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        console.log("Wert.buyNow AccessToken not found  : ");
      }
    });
  };

  const handlePayNow = async (priceamt, orderId, web3) => {

    setAssetType("fixed");
    setSuccessPopupAmount(priceamt);
    // let web3;
    // let magiclink = magicLinkActive ? true : false;
    // if (magiclink) {
    // 	web3 = new Web3(maticWeb3);
    // } else {
    // 	web3 = new Web3(Web3.givenProvider);
    // }

    const chainId = await web3.eth.getChainId();
    const networkVerify = checkNetwork(chainId);
    if (!networkVerify) return setActive(false);

    // to address for payment
    const admin_wallet = process.env.REACT_APP_ADDRESS_DEV; // take from env

    // account is an array of available accounts in connected wallet
    const account = await web3.eth.getAccounts();

    //wallet balance will return in wei (base unit of ETH)
    let walletBalance = await web3.eth.getBalance(account[0]);

    // bid amount is converting to wei
    const price_amount = web3.utils.toWei(priceamt.toString(), "ether");
    let transactionDetails = await getTransactionDetails();
    // if (parseInt(walletBalance) < parseInt(price_amount)) {
    //   PopUpAlert(
    //     "Alert!",
    //     "You do not have sufficient balance to purchase this product. Please topup your wallet.",
    //     "error"
    //   ).then((res) => {
    //     enableScroll();
    //     history.push("/dashboard");
    //   });
    //   //setActive(false);
    //   return;
    // }
    console.log("You have enough balance")
    try {
      setActive(true);
      transactionDetails = transactionDetails?.data?.result;
      let signatureResponse = await handleGetSignature(transactionDetails);
      let userSignature = signatureResponse?.data?.result;
      let signatureDetails = signatureResponse?.data?.result?.payload

      console.log("this is user signature", signatureResponse.data)
      let marketplace_contract_address =
        process.env.REACT_APP_CONTRACT_ADDRESS_DEV;

      const marketplace_contract = await new web3.eth.Contract(
        LiiMarketPlaceABI, // Take from common Contractjson file
        marketplace_contract_address //Market place contract address
      );

      const assetPurchaseMethod = marketplace_contract.methods.buyWithUsdc(signatureDetails.payer,
        signatureDetails.collectableId,
        signatureDetails.price.toString(),
        [signatureDetails.royaltyReceiver, signatureDetails.royaltyPercentage, signatureDetails.ipfsHash],
        signatureResponse?.data?.result?.creators,
        userSignature.result
      );

      let gasEstimated = null;
      let txObject = null;
      let approvalStatus = false;



      setPurchaseMessage({
        title: 'Approve USDC Spending',
        message: 'Please check your wallet and sign the transaction to authorize USDC spending. '
      })
      // Need to approve USDC token transfer inside the contract
      const owner = userAccountDetails?.account?.[0]
      const spender = marketplace_contract_address


      const usdc_contract_address = process.env.REACT_APP_USDC_CONTRACT_ADDRESS

      const usdcContract = await new web3.eth.Contract(LiiErc20_contract_abi, usdc_contract_address);
      const amountInWei = signatureDetails.price
      console.log("amount in wei", amountInWei)
      usdcContract.methods.allowance(owner, spender).call().then(async function (approvedAmount) {

        if (approvedAmount >= amountInWei) {
          console.log(" approved NOWWWW", approvedAmount)

          approvalStatus = true;
        } else {

          console.log("no approval foundddd ")
        }

        //creating method object
        const approveMethod = usdcContract.methods.approve(spender, amountInWei);

        let gasEstimated = null;
        let txObject = null;

        await getBalance();
        const matic_balance = localStorage.getItem("tokenbalance");
        console.log("matic_balance ",matic_balance);
        if (Number(matic_balance) < 0.2 ) {
          disableScroll()
          PopUpAlert(
            "Alert!",
            "Insufficient funds in your crypto wallet. To proceed with the purchase, please ensure that you have a minimum balance of 0.2 Matic in your wallet. Kindly add funds to your wallet before proceeding.",
            "error",
            true
          ).then((res) => {
            enableScroll(); 
            if(!res.isDismissed) 
              history.push("/wallet");            
            });
          return;
        }

        try {
          const gasPrice = await getGasPrice(web3);
          console.log("On my gas calcu")
          //creating transaction object
          txObject = {
            from: account[0],
            value: 0,
            gasPrice: gasPrice,
          };
          //estimating transaction fee for this function call
          gasEstimated = await web3.eth.estimateGas({
            to: usdc_contract_address,
            data: approveMethod.encodeABI(),
            ...txObject,
          });
        } catch (err) {
          console.log("error", err)
          setActive(false)
          PopUpAlert(
            "Alert!",
            "Transaction has been failed please try again!!",
            "error"
          ).then(() => enableScroll());
        }

        //sending transaction through metamask
        // approveMethod.send({ ...txObject, gas: gasEstimated }).then(async function (result) {

        //   setPurchaseMessage({
        //     title: 'Approving USDC Spending',
        //     message: 'Please wait patiently while the transaction is being processed. This may take a moment.'
        //   })

        //   if (result.status) {
        //     approvalStatus = true;
        //     console.log("success!" + result.transactionHash);


        //     try {


        //       console.log("On my second try")

        //       const gasPrice = await getGasPrice(web3);

        //       //creating transaction object
        //       txObject = {
        //         from: account[0],
        //         value: 0,
        //         gasPrice: gasPrice,
        //       };

        //       console.log("estimating gas price -------------now")

        //       console.log("txn Object", txObject, 'to address', marketplace_contract_address)

        //       console.log("abi data", assetPurchaseMethod.encodeABI())

        //       //estimating transaction fee for this function call
        //       gasEstimated = await web3.eth.estimateGas({
        //         to: marketplace_contract_address,
        //         data: assetPurchaseMethod.encodeABI(),
        //         ...txObject,
        //       });
        //       console.log("successfully estimated gas price -------------", gasEstimated)

        //       console.log("---------------> Signature approval<------------------")

        //       setPurchaseMessage({
        //         title: 'Continue to Purchase',
        //         message: 'To complete your purchase, kindly sign the transaction from your wallet.'
        //       })

        //       await assetPurchaseMethod
        //         .send({ ...txObject, gas: gasEstimated })
        //         .on("transactionHash", function (hash) {
        //           // setTxnHash(hash);
        //           setPurchaseMessage({
        //             title: 'Transaction in Progress',
        //             message: 'Please be patient as the transaction is currently in progress. Your request is being processed.'
        //           })

        //           setConfirmPaymentDetails((prevState) => ({
        //             ...prevState,
        //             txnHash: hash,
        //           }));
        //           // setConfirmPaymentDetails({
        //           //   ...confirmPaymentDetails,
        //           //   txnHash: hash,
        //           // });
        //           // add a loader here to wait until transaction confirmation
        //         })
        //         .on("receipt", function (receipt) {
        //           if (receipt.status) {
        //             // alert("success");
        //             setActive(false);
        //             // PopUpAlert("Great!", "Order placed successfully.", "success").then(
        //             //   (res) => {
        //             //     console.log("result 1, Inside blockchain", res);
        //             // To sent transaction details to backend
        //             setTransactionFlag(!transactionFlag);
        //             // history.push("/orderDetails");
        //             //   }

        //             // );
        //             setSuccessShow(true);
        //             enableScroll();
        //             // setTransactionFlag(!transactionFlag);
        //             // receipt.transactionHash  is the transaction reciept.
        //             // transation is successful and proceed to order completion
        //           } else {
        //             // blockchain transaction status is fail  ed.
        //             setActive(false);
        //             PopUpAlert(
        //               "Alert!",
        //               "Transaction has been failed please try again!!",
        //               "error"
        //             ).then(() => enableScroll());
        //           }
        //         })
        //         .on("error", (error) => {
        //           setActive(false);
        //           PopUpAlert(
        //             "Alert!",
        //             "User denied transaction signature",
        //             "error"
        //           ).then(() => enableScroll());
        //         })
        //         .then(() => {
        //           enableScroll();

        //           // confirmOrder(null, orderId, "failed");
        //         }); // If a out of gas error, the second parameter is the receipt.

        //       setPurchaseMessage({
        //         title: null,
        //         message: null
        //       })
        //       setActive(false)

        //       console.log("---------- end of try------")
        //     } catch (err) {
        //       setActive(false);
        //       console.log(err);
        //       enableScroll();
        //       confirmOrder(null, orderId, "failed");
        //       if (err.code === 4001) {
        //         PopUpAlert("Alert!", "Please confirm from your wallet", "error").then(
        //           (err) => {
        //             enableScroll();
        //             window.location.reload();
        //           }
        //         );
        //       } else {
        //         console.log("encountered an error on trnasction", err)
        //         setActive(false)

        //         PopUpAlert(
        //           "Alert!",
        //           "Something went wrong. Please try again",
        //           "error"
        //         ).then((err) => {
        //           enableScroll();
        //           window.location.reload();
        //         });
        //       }
        //     }
        //   } else if (result.status == false) {
        //     // blockchain transaction status is failed.
        //     console.log("error code", result);
        //     setActive(false)
        //     PopUpAlert(
        //       "Alert!",
        //       "Transaction has been failed please try again!!",
        //       "error"
        //     ).then(() => enableScroll());
        //   }
        // }).catch((error) => {
        //   setActive(false)
        //   PopUpAlert(
        //     "Alert!",
        //     "Transaction has been failed please try again!!",
        //     "error"
        //   ).then(() => enableScroll());
        //   console.log("error");
        // });

        approveMethod.send({ ...txObject, gas: gasEstimated }).on('transactionHash',async function(){

          setPurchaseMessage({
            title: 'Approving USDC Spending',
            message: 'Please wait patiently while the transaction is being processed. This may take a moment.'
          })

        }).then(async function (result) {


          if (result.status) {
            approvalStatus = true;
            console.log("success!" + result.transactionHash);


            try {


              console.log("On my second try")

              const gasPrice = await getGasPrice(web3);

              //creating transaction object
              txObject = {
                from: account[0],
                value: 0,
                gasPrice: gasPrice,
              };

              console.log("estimating gas price -------------now")

              console.log("txn Object", txObject, 'to address', marketplace_contract_address)

              console.log("abi data", assetPurchaseMethod.encodeABI())

              //estimating transaction fee for this function call
              gasEstimated = await web3.eth.estimateGas({
                to: marketplace_contract_address,
                data: assetPurchaseMethod.encodeABI(),
                ...txObject,
              });
              console.log("successfully estimated gas price -------------", gasEstimated)

              console.log("---------------> Signature approval<------------------")

              setPurchaseMessage({
                title: 'Continue to Purchase',
                message: 'To complete your purchase, kindly sign the transaction from your wallet.'
              })

              await assetPurchaseMethod
                .send({ ...txObject, gas: gasEstimated })
                .on("transactionHash", function (hash) {
                  // setTxnHash(hash);
                  setPurchaseMessage({
                    title: 'Transaction in Progress',
                    message: 'Please be patient as the transaction is currently in progress. Your request is being processed.'
                  })

                  setConfirmPaymentDetails((prevState) => ({
                    ...prevState,
                    txnHash: hash,
                  }));
                  // setConfirmPaymentDetails({
                  //   ...confirmPaymentDetails,
                  //   txnHash: hash,
                  // });
                  // add a loader here to wait until transaction confirmation
                })
                .on("receipt", function (receipt) {
                  if (receipt.status) {
                    // alert("success");
                    setActive(false);
                    // PopUpAlert("Great!", "Order placed successfully.", "success").then(
                    //   (res) => {
                    //     console.log("result 1, Inside blockchain", res);
                    // To sent transaction details to backend
                    setTransactionFlag(!transactionFlag);
                    // history.push("/orderDetails");
                    //   }

                    // );
                    setSuccessShow(true);
                    enableScroll();
                    // setTransactionFlag(!transactionFlag);
                    // receipt.transactionHash  is the transaction reciept.
                    // transation is successful and proceed to order completion
                  } else {
                    // blockchain transaction status is fail  ed.
                    setActive(false);
                    PopUpAlert(
                      "Alert!",
                      "Transaction has been failed please try again!!",
                      "error"
                    ).then(() => enableScroll());
                  }
                })
                .on("error", (error) => {
                  setActive(false);
                  PopUpAlert(
                    "Alert!",
                    "User denied transaction signature",
                    "error"
                  ).then(() => enableScroll());
                })
                .then(() => {
                  enableScroll();

                  // confirmOrder(null, orderId, "failed");
                }); // If a out of gas error, the second parameter is the receipt.

              setPurchaseMessage({
                title: null,
                message: null
              })
              setActive(false)

              console.log("---------- end of try------")
            } catch (err) {
              setActive(false);
              console.log(err);
              enableScroll();
              confirmOrder(null, orderId, "failed");
              if (err.code === 4001) {
                PopUpAlert("Alert!", "Please confirm from your wallet", "error").then(
                  (err) => {
                    enableScroll();
                    window.location.reload();
                  }
                );
              } else {
                console.log("encountered an error on trnasction", err)
                setActive(false)

                PopUpAlert(
                  "Alert!",
                  "Something went wrong. Please try again",
                  "error"
                ).then((err) => {
                  enableScroll();
                  window.location.reload();
                });
              }
            }
          } else if (result.status == false) {
            // blockchain transaction status is failed.
            console.log("error code", result);
            setActive(false)
            PopUpAlert(
              "Alert!",
              "Transaction has been failed please try again!!",
              "error"
            ).then(() => enableScroll());
           }
        }).catch((error) => {
          setActive(false)
          PopUpAlert(
            "Alert!",
            "Transaction has been failed please try again!!",
            "error"
          ).then(() => enableScroll());
          console.log("error ", error);
        });


      }).catch(function (err) {
        console.log("on  error", err)
        console.log(err);
        setActive(false)

      });

    } catch (err) {
      console.log("Error handle pay on crypto", err);
      setActive(false);
    }
  };

  const getConfig = () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    return config;
  };

  // Function to get whether user verify KYC and check kyc limit reached
  const getPrePurchaseDetails = async () => {
    const config = await getConfig();
    try {
      const prePurchaseResponse = await axiosInstance.get(
        "/user/pre-purchase",
        config
      );
      console.log(
        "prepurchase.responseget ",
        prePurchaseResponse?.data?.result
      );
      setPrePurchaseDetails({
        ...prePurchaseDetails,
        ...prePurchaseResponse?.data?.result,
      });
    } catch (error) {
      // Handle errors if any
      console.error("Error fetching pre-purchase data:", error);
    }
  };

  const handleBuy = () => {
    console.log("prePurchase.handleBuy", prePurchaseDetails);
    const netTransactionAmount =
      Number(prePurchaseDetails.totalAmount) +
      Number(
        productDetails?.isOnSell
          ? productDetails?.resellPrice
          : productDetails?.regularPrice
      );
    console.log(
      "prePurchase.handleBuy 2",
      prePurchaseDetails,
      netTransactionAmount
    );
    if (
      (prePurchaseDetails?.kycStatus != 1 &&
        prePurchaseDetails.totalAmount > 200) ||
      (prePurchaseDetails?.kycStatus != 1 && netTransactionAmount > 200)
    ) {
      PopUpAlert(
        "Alert",
        "Exceeded $200 transaction limit. Verify KYC to proceed.",
        "error"
      ).then(() => {
        history.push("/dashboard");
      });
      return false;
    } else {
      window.scrollTo(0, 0);
      setOpen(true);
    }
  };

  const handleCardPayment = () => {
    const commidityAmount = productDetails?.isOnSell
      ? productDetails?.resellPrice
      : productDetails?.regularPrice;
    if (
      !accountDetails?.firstName ||
      !accountDetails?.lastName ||
      !accountDetails?.email
    ) {
      PopUpAlert(
        "Profile Incomplete.",
        "Please complete your profile.",
        "info"
      ).then(() => history.push("/my-account"));
    } else if (
      (accountDetails?.isKYCLimitExceeded &&
        Number(accountDetails?.kycStatus) != 1) ||
      (Number(accountDetails?.kycStatus) != 1 && commidityAmount > 2000)
    ) {
      PopUpAlert(
        "Alert",
        "Exceeded $200 transaction limit. Verify KYC to proceed.",
        "error"
      ).then(() => {
        history.push("/dashboard");
      });
    } else {
      const access = localStorage.getItem("userToken");
      if (access) {
        setActive(true);
        // setWert({ active: true, mode: "cardPayment" });
        setWert(prevState => ({ ...prevState, active: true, mode: "cardPayment" }));

      } else {
        setOpen(false);
        PopUpAlert(
          "info",
          "Please login and proceed to checkout",
          "error"
        ).then((res) => {
          document.getElementById("login_button").click();
        });
      }
    }
  };

  const handleCircelPayment = () => {
    const access = localStorage.getItem("userToken");
    if (access) {
      history.push({
        pathname: `/Circlepayment`,
        state: { productDetails, fromPrevious: true },
      });
    } else {
      setOpen(false);
      PopUpAlert("info", "Please login and proceed to checkout", "error").then(
        (res) => {
          document.getElementById("login_button").click();
        }
      );
    }
  };

  const handleBidplace = async (bidamt, assetId) => {
    let contract_address, erc20addess;
    if (connector.connected) {
      provider = new WalletConnectProvider({
        infuraId: "573fcc11c2d349418d454ca719d655a1",
      });
      // provider = new WalletConnectProvider({
      //   infuraId: "15565c7ed36e427f92f195059f206fe2",
      // });
    }
    let web3 = connector.connected
      ? new Web3(provider)
      : new Web3(Web3.givenProvider);

    const chainId = await web3.eth.getChainId();
    const networkVerify = checkNetwork(chainId);

    if (!networkVerify) return setActive(false);
    setActive(true);

    contract_address = process.env.REACT_APP_CONTRACT_ADDRESS_DEV;

    const auction_contract = await new web3.eth.Contract(
      contract_auction_abi,
      contract_address
    );
    const account = await web3.eth.getAccounts();
    var tokens = web3.utils.toWei(bidamt.toString(), "ether");

    try {
      const bidcontract = await web3.eth.sendTransaction({
        from: account[0],
        to: contract_address,
        value: 0,
        data: auction_contract.methods.bid(tokens, assetId).encodeABI(),
      });

      axiosInstance
        .post(
          `asset/auction/bid/${id}`,
          {
            bidAmountEth: bidamt,
            bidAmountEuro: (bidamt * euroValue)?.toFixed(2),
          },
          config
        )
        .then((res) => {
          setActive(false);
          PopUpAlert("Great!", res.data?.message, "success").then((res) =>
            window.location.reload()
          );
        })
        .catch((err) => {
          setActive(false);
          PopUpAlert("Alert!", err.response?.data?.message, "error");
        });
    } catch (err) {
      setActive(false);
      if (err.code === 4001) {
        PopUpAlert("Alert!", "Please confirm from your wallet", "error").then(
          (err) => window.location.reload()
        );
      } else {
        PopUpAlert(
          "Alert!",
          "There is a change in Bid price. Please Bid again",
          "error"
        ).then((err) => window.location.reload());
      }
    }
  };
  const setWertLoaded = (data) => {
    setModalMessage({
      ...modalMessage,
      action: "bidWert",
      message: "Please wait",
    });
    setActive(false);
  };
  const [isCheckoutApiCalled, setIsCheckoutApiCalled] = useState(false)
  const handleWertCheckOut = async (data) => {
    let { click_id, tx_id } = data;
    if (!tx_id) tx_id = "";
    let web3 = await getWeb3Provider();
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };

    let transactionObject = {
      from: userAccountDetails?.account?.[0],
      to: productDetails.isOnSell
        ? productDetails.purchasedBy
        : process.env.REACT_APP_ADDRESS_DEV,
      value: web3?.utils.toWei(`${productDetails?.maticPrice?.toFixed(4)}`),
    };

    let body = {
      salePrice: productDetails?.isOnSell
        ? productDetails?.resellPrice
        : productDetails?.regularPrice,
      saleType: productDetails?.saleType,
      status: "incomplete",
      quantity: 1,
      paymentType: "card",
      from: transactionObject.from,
      to: transactionObject.to,
      ethPrice: productDetails?.isOnSell
        ? productDetails?.resellPrice / euroValue
        : productDetails?.regularPrice / euroValue,
      paymentDetail: null,
      click_id: click_id,
    };
    let returnValue = true;
    let returnObj = {}
    if (!isCheckoutApiCalled) {
      const res = await apicall();
      return {returnValue, returnObj }
    }
    async function apicall() {
      console.log("checkout api called with click id: ", body.click_id)
      setIsCheckoutApiCalled(true)
      await axiosInstance
        .post(`/asset/checkout/${productDetails.id}`, body, config)
        .then(async (res) => {
          setIsCheckoutApiCalled(false)
          setConfirmPaymentDetails((prevState) => ({
            ...prevState,
            txnHash: tx_id,
            orderId: res?.data?.result?.id,
          }));

          setTransactionFlag(!transactionFlag);
          setActive(false);
          returnValue = true
          returnObj = res.data.result;
          // setSuccessShow(true);
        })
        .catch((err) => {
        returnValue = false;
          PopUpAlert("Alert", err.response?.data?.message, "error").then(
            (res) => {
            setActive(false);
            enableScroll();
              if (
                err.response?.data?.message ===
                "Your wallet does not have sufficient funds"
              ) {
                history.push("/dashboard");
              }
              console.log("the error from api is: ", err)
              enableScroll();
          
          if (err.response?.data?.message ===
            "Your previous order is under process") {
          } {
            // setWert({
            //   active: false,
            // })
            setWert(prevState => ({ ...prevState, active: false, mode: "cardPayment" }));

            setTimeout(() => {
              window.location.href = '/dashboard?tab=3'
              getProductDetails();

            }, 1500)

          }
        }
        );
        });
    }
    return returnValue
    // confirmOrder(null, "null", "success");
  };

  //this fucntion is used to bid an active auction
  const handleBid = async (bidAmound, assetId, bidAmountDollar = null) => {
    console.log("bid amount is:", bidAmound, "bidAmountDollar", bidAmountDollar)
    const netTransactionAmount =
      Number(prePurchaseDetails.totalAmount) + Number(bidAmountDollar);
    console.log(
      "prePurchase.handleBid.Inside Item",
      prePurchaseDetails,
      netTransactionAmount
    );
    if (
      (Number(prePurchaseDetails?.kycStatus) != 1 &&
        Number(prePurchaseDetails.totalAmount) > 200) ||
      (prePurchaseDetails?.kycStatus != 1 && netTransactionAmount > 200)
    ) {
      // PopUpAlert(
      //   "Alert",
      //   "Exceeded $2000 transaction limit. Verify KYC to proceed.",
      //   "error"
      // ).then(() => {
      //   history.push("/dashboard");
      // });
      return false;
    } else {
      disableScroll();
      if (
        !userEmail &&
        !accountDetails?.userName &&
        !accountDetails?.firstName &&
        !accountDetails.lastName &&
        localStorage.getItem("userToken")
      ) {
        PopUpAlert("Alert..", "Please complete profile.!", "info").then(
          (res) => {
            enableScroll();
            history.push("/my-account");
          }
        );
        return false;
      } else if (!userEmail && !localStorage.getItem("userToken")) {
        PopUpAlert(
          "Alert..",
          "Please connect your wallet or Login using email.",
          "error"
        ).then((res) => {
          enableScroll();
          document.getElementById("connectWallet").click();
        });
      } else if (loginWith!=='metamask' && isUserVerified != "verified") {
        PopUpAlert(
          "Alert..",
          "User is not verified. Please verify your account",
          "error"
        ).then(() => {
          enableScroll();
          history.push("/my-account");
        });
      } else {
        setAssetType("auction");
        setSuccessPopupAmount(bidAmountDollar);
        let config = {
          headers: {
            Authorization: localStorage.getItem("userToken"),
          },
        };

        let web3 = await getWeb3Provider();

        const chainId = await web3.eth.getChainId();
        const networkVerify = checkNetwork(chainId);
        if (!networkVerify) {
          //connected to wrong network
          return false;
        }

        let marketplace_contract_address =
          process.env.REACT_APP_CONTRACT_ADDRESS_DEV;

        // creating smartcontract instance
        const marketplace_contract = await new web3.eth.Contract(
          ASLY_MARKETPLACE_ABI,
          marketplace_contract_address
        );

        // account is an array of available accounts in connected wallet
        const account = await web3.eth.getAccounts();

        // if (!checkWalletBalance(account[0], bidAmound, web3)) {
        //   return false;
        // }


        console.log("this is the bid amount", bidAmound)
        // let bid_amount = (bidAmound * 10 ** 18).toString()
        // if (process.env.REACT_APP_ENVIRONMENT_MODE == 'PRODUCTION' || process.env.REACT_APP_ENVIRONMENT_MODE == 'production') {
        //   bid_amount = (bidAmound * 10 ** 6).toString()
        // }


        // Bid amount calculation with usdc
        let bid_amount = (bidAmountDollar * 10 ** 18).toString()
        if (process.env.REACT_APP_ENVIRONMENT_MODE == 'PRODUCTION' || process.env.REACT_APP_ENVIRONMENT_MODE == 'production') {
          bid_amount = (bidAmountDollar * 10 ** 6).toString()
        }
        //wallet balance will return in wei (base unit of ETH)
        // let walletBalance = await web3.eth.getBalance(account[0]);
        let walletBalance = await getUsdcBalanceFromAccount(account[0])
        if (parseInt(walletBalance) < parseInt(bidAmountDollar)) {
          PopUpAlert(
            "Alert!",
            "You do not have sufficient USDC balance to bid on this product. Please topup your wallet.",
            "error",
            true
          ).then((res) => {
            enableScroll();
            if(!res.isDismissed) 
            history.push("/wallet");
          });

          // setActive(false);
          return;
        }
        setActive(true);
        try {

          let transactionDetails = await getTransactionDetails();
          transactionDetails = transactionDetails?.data?.result;
          transactionDetails.bidAmountWEI = bid_amount;
          console.log("bidddd", bid_amount);
          let signatureResponse = await handleGetSignature(transactionDetails);
          let userSignature = signatureResponse?.data?.result;
          let signatureDetails = signatureResponse?.data?.result?.payload
          console.log("this is user signature from bidding", signatureResponse.data)

          let marketplace_contract_address =
            process.env.REACT_APP_CONTRACT_ADDRESS_DEV;

          const marketplace_contract = await new web3.eth.Contract(
            LiiMarketPlaceABI, // Take from common Contractjson file
            marketplace_contract_address //Market place contract address
          );



          console.log("payer", signatureDetails.payer, "NFTID", signatureDetails.collectableId, "PRice--", signatureDetails.price ? signatureDetails.price : signatureDetails.amount, "SIGNATURE_", userSignature.result, 'contract address', marketplace_contract_address)


          const bidWithUsdcMethod = marketplace_contract.methods.bidWithUsdc(signatureDetails.payer, signatureDetails.collectableId, signatureDetails.amount, userSignature.result);

          let gasEstimated = null;
          let txObject = null;
          let approvalStatus = false;
          let url;
          let transHash;
          const owner = userAccountDetails?.account?.[0]
          const spender = marketplace_contract_address

          const usdc_contract_address = process.env.REACT_APP_USDC_CONTRACT_ADDRESS

          const usdcContract = await new web3.eth.Contract(LiiErc20_contract_abi, usdc_contract_address);
          const amountInWei = signatureDetails.amount
          // USDC token approval
          usdcContract.methods.allowance(owner, spender).call().then(async function (approvedAmount) {

            if (approvedAmount >= amountInWei) {
              approvalStatus = true;
            }
            const approveMethod = usdcContract.methods.approve(spender, amountInWei);



            let gasEstimated = null;
            let txObject = null;

            setPurchaseMessage({
              title: ' Approve USDC Spending',
              message: 'Please check your wallet and sign the transaction to authorize USDC spending.'
            })


            try {
              const gasPrice = await getGasPrice(web3);
              console.log("On my gas calcu", account, 'addres', usdc_contract_address)
              //creating transaction object
              txObject = {
                from: account[0],
                value: 0,
                gasPrice: gasPrice,
              };
              //estimating transaction fee for this function call
              gasEstimated = await web3.eth.estimateGas({
                to: usdc_contract_address,
                data: approveMethod.encodeABI(),
                ...txObject,
              });

            } catch (err) {
              console.log("error", err)
              if (err.toString().includes('insufficient funds')) {
                PopUpAlert(
                  "Alert!",
                  "Insufficient funds in your crypto wallet. To proceed with the bid, please ensure that you have a minimum balance of 0.2 Matic in your wallet. Kindly add funds to your wallet before proceeding.",
                  "error",
                  true
                ).then((res) => {
                  enableScroll();
                  if(!res.isDismissed) 
                  history.push("/wallet");
                 
                });
                setActive(false);
              } else {
                setActive(false);
                PopUpAlert(
                  "Alert!",
                  "Transaction failed. Please try again",
                  "error"
                ).then(() => enableScroll());
              }
              //error Error: Magic RPC Error: [-32603] Error forwarded from node: insufficient funds for transfer


            }

            // sending approval//
            approveMethod.send({ ...txObject, gas: gasEstimated }).then(async function (result) {

              setPurchaseMessage({
                title: 'Approving USDC Spending',
                message: 'Please wait patiently while the transaction is being processed. This may take a moment'
              })

              if (result.status == true) {

                try {
                  const gasPrice = await getGasPrice(web3);

                  txObject = {
                    from: account[0],
                    value: 0,
                    gasPrice: gasPrice,
                  };
                  gasEstimated = await web3.eth.estimateGas({
                    to: marketplace_contract_address,
                    data: bidWithUsdcMethod.encodeABI(),
                    ...txObject,
                  });

                  console.log("---------------> EXEC NOW<------------------", marketplace_contract_address)

                  setPurchaseMessage({
                    title: 'Reminder: Continue to Purchase',
                    message: 'To complete your purchase, kindly sign the transaction from your wallet.'
                  })


                  // bidWithUsdcMethod.send({ ...txObject, gas: gasEstimated }).then(function (result) {
                  //   if (result.status == true) {
                  //     console.log("success!" + result.transactionHash);


                  //     setPurchaseMessage({
                  //       title: 'Transaction in Progress..',
                  //       message: 'Please be patient as the transaction is currently in progress. Your request is being processed.'
                  //     })



                  //     transHash = result.transactionHash;
                  //     url = `asset/auction/bid/${id}`;
                  //     // PopUpAlert(
                  //     //   "Alert!",
                  //     //   "Transaction has been failed please try again!!",
                  //     //   "error"
                  //     // ).then(() => enableScroll());

                  //   } else if (result.status == false) {
                  //     setActive(false);
                  //     url = `asset/auction/bid/${id}?isFailed=true`;
                  //   }


                  //   axiosInstance
                  //     .post(
                  //       url,
                  //       {
                  //         bidAmountEth: bidAmound.toString(),
                  //         bidAmountEuro: bidAmountDollar,
                  //         txHash: transHash ? transHash : null,
                  //         type: currentPaymentType,
                  //       },
                  //       config
                  //     )
                  //     .then((res) => {
                  //       setActive(false);
                  //       // PopUpAlert("Great!", res.data?.message, "success").then(() =>
                  //       //   window.location.reload()
                  //       // );
                  //       setSuccessShow(true);
                  //       enableScroll();
                  //     })
                  //     .catch((err) => {
                  //       setActive(false);
                  //       setPurchaseMessage({
                  //         title: null,
                  //         message: null
                  //       })
                  //       PopUpAlert(
                  //         "Alert!",
                  //         err.response?.data?.message,
                  //         "error"
                  //       ).then(() => {
                  //         enableScroll();
                  //         setPurchaseMessage({
                  //           title: null,
                  //           message: null
                  //         })
                  //         window.location.reload();
                  //       });
                  //     })
                  // })
                  //   .catch((error) => {
                  //     console.log("here is the error ", error)
                  //     setActive(false);
                  //     enableScroll();
                  //     setPurchaseMessage({
                  //       title: null,
                  //       message: null
                  //     })
                  //     PopUpAlert(
                  //       "Oops!",
                  //       "User denied transaction signature",
                  //       "error"
                  //     ).then(() => {
                  //       enableScroll();
                  //     });
                  //   });
                  bidWithUsdcMethod.send({ ...txObject, gas: gasEstimated }).on('transactionHash', async function (result) {
                    if (result) {
                      console.log("success!" + result.transactionHash);

                      setPurchaseMessage({
                        title: 'Transaction in Progress..',
                        message: 'Please be patient as the transaction is currently in progress. Your request is being processed.'
                      })

                      transHash = result;
                      url = `asset/auction/bid/${id}`;
                      // PopUpAlert(
                      //   "Alert!",
                      //   "Transaction has been failed please try again!!",
                      //   "error"
                      // ).then(() => enableScroll());

                    } else if (result) {
                      setActive(false);
                      url = `asset/auction/bid/${id}?isFailed=true`;
                    }


                    axiosInstance
                      .post(
                        url,
                        {
                          bidAmountEth: bidAmound.toString(),
                          bidAmountEuro: bidAmountDollar,
                          txHash: transHash ? transHash : null,
                          type: currentPaymentType,
                        },
                        config
                      )
                      .then((res) => {
                        setActive(false);
                        // PopUpAlert("Great!", res.data?.message, "success").then(() =>
                        //   window.location.reload()
                        // );
                        setSuccessShow(true);
                        enableScroll();
                      })
                      .catch((err) => {
                        setActive(false);
                        setPurchaseMessage({
                          title: null,
                          message: null
                        })
                        PopUpAlert(
                          "Alert!",
                          err.response?.data?.message,
                          "error"
                        ).then(() => {
                          enableScroll();
                          setPurchaseMessage({
                            title: null,
                            message: null
                          })
                          window.location.reload();
                        });
                      })
                  })
                    .catch((error) => {
                      console.log("here is the error ", error)
                      setActive(false);
                      enableScroll();
                      setPurchaseMessage({
                        title: null,
                        message: null
                      })
                      PopUpAlert(
                        "Oops!",
                        "User denied transaction signature",
                        "error"
                      ).then(() => {
                        enableScroll();
                      });
                    });
                  setPurchaseMessage({
                    title: null,
                    message: null
                  })



                } catch (error) {
                  console.log("error in approve method try", error)
                  PopUpAlert(
                    "Oops!",
                    error.includes('Bid Amount Should be greater') ? 'execution reverted: Bid Amount Should be greater than current bid' : 'Transaction failed!.',
                    "error"
                  ).then(() => {
                    enableScroll();
                  });

                  setPurchaseMessage({
                    title: null,
                    message: null
                  })
                  setActive(false);
                }
              } else {
                setActive(false);
                console.log("--------error In APPPROVAL METHOD------");
              }


            }).catch((err) => {
              setActive(false);
              PopUpAlert(
                "Oops!",
                "User denied transaction signature",
                "error"
              ).then(() => {
                enableScroll();
              });

            })
          })









          // // old method
          // let userSignature = await handleGetSignature(transactionDetails);
          // userSignature = userSignature?.data?.result;

          // const bidMethod = marketplace_contract.methods.bidWithCrypto(
          //   transactionDetails?.tokenId,
          //   userSignature
          // );

          // let gasEstimated = null;
          // let txObject = null;

          // try {
          //   const gasPrice = await getGasPrice(web3);

          //   //creating transaction object
          //   txObject = {
          //     from: account[0],
          //     value: transactionDetails?.bidAmountWEI,
          //     gasPrice: gasPrice,
          //   };
          //   //estimating transaction fee for this function call
          //   gasEstimated = await web3.eth.estimateGas({
          //     to: marketplace_contract_address,
          //     data: bidMethod.encodeABI(),
          //     ...txObject,
          //   });
          // } catch (err) {
          //   setActive(false);
          //   if (err?.toString()?.includes("insufficient funds for transfer")) {
          //     PopUpAlert(
          //       "Alert!",
          //       "You can only bid on auctions using crypto. Please topup your wallet with MATIC in order to participate in this auction.",
          //       "error"
          //     ).then(() => enableScroll());
          //   }
          //   // alert("Transaction has been failed, please try again!!");
          // }

          // let url;
          // let transHash;
          // //sending transaction through metamask
          // await bidMethod
          //   .send({ ...txObject, gas: gasEstimated })
          //   .then(function (result) {
          //     if (result.status == true) {
          //       // blockchain transaction is successful and updating product creation information to backend
          //       // update current bid info in db
          //       transHash = result.transactionHash;

          //       url = `asset/auction/bid/${id}`;

          //       setActive(false);
          //       // PopUpAlert("Great", "Order placed sucessfull", "success");
          //     } else if (result.status == false) {
          //       setActive(false);
          //       url = `asset/auction/bid/${id}?isFailed=true`;

          //       // blockchain transaction status is failed.
          //       //show a popup and donot proceed to product publish
          //       PopUpAlert(
          //         "Alert!",
          //         "Transaction has been failed please try again!!",
          //         "error"
          //       ).then(() => enableScroll());
          //     }

          //     axiosInstance
          //       .post(
          //         url,
          //         {
          //           bidAmountEth: bidAmound.toString(),
          //           bidAmountEuro: bidAmountDollar,
          //           txHash: transHash ? transHash : null,
          //           type: currentPaymentType,
          //         },
          //         config
          //       )
          //       .then((res) => {
          //         setActive(false);
          //         // PopUpAlert("Great!", res.data?.message, "success").then(() =>
          //         //   window.location.reload()
          //         // );
          //         setSuccessShow(true);
          //         enableScroll();
          //       })
          //       .catch((err) => {
          //         setActive(false);
          //         PopUpAlert(
          //           "Alert!",
          //           err.response?.data?.message,
          //           "error"
          //         ).then(() => {
          //           enableScroll();
          //           window.location.reload();
          //         });
          //       });
          //   })
          //   .catch((error) => {
          //     setActive(false);
          //     enableScroll();
          //     PopUpAlert(
          //       "Oops!",
          //       "User denied transaction signature",
          //       "error"
          //     ).then(() => {
          //       enableScroll();
          //     });
          //   });
        } catch (err) {
          setActive(false);
          console.log(err);
          if (err.code === 4001) {
            enableScroll();
            console.log("Alert!", "Install Metamsk!!", "error");
          } else {
            PopUpAlert(
              "Oops!",
              "Transaction has been failed please try again!!",
              "error"
            ).then(() => enableScroll());
            setActive(false)
          }
        }
      }
    }
  };

  const checkForUpcomingBid = (bidStartDate) => {
    let time = new Date(bidStartDate);
    var now = new Date();
    time < now ? setIsUpcomingBid(false) : setIsUpcomingBid(true);
  };

  const initiateCreatorDetails = () => {
    let userAssetCommissions = productDetails?.userAssetCommission;
    userAssetCommissions = userAssetCommissions.filter((item) =>
      ["creator", "artist"].includes(item?.creatorType)
    );
    let creatorDetailsObj = {
      creator: [],
      artist: [],
      coCreator: [],
    };
    userAssetCommissions.forEach((item, index) => {

      if (item?.creatorType == 'artist' && item?.type == "primary" || item?.creatorType == 'creator' && item?.type == "royalty") {

        creatorDetailsObj[item?.creatorType].push(item);

      }
    });
    setCreatorDetails(creatorDetailsObj);
  };

  useEffect(() => {
    if (productDetails) {
      initiateCreatorDetails();
      getPrePurchaseDetails();
    }
  }, [productDetails]);

  const getProductDetails = async (id) => {
    if (id) {
      await axiosInstance
        .get(
          `/asset/view/${id}${isLogin &&
            (!urlParam?.sourcePage || urlParam?.sourcePage != "myNFT")
            ? "?isWishlist=true"
            : ""
          }`,
          props.mode === "view" || isLogin ? config : ""
        )
        .then((res) => {
          setProductDetails(res?.data?.result);

          if (res?.data?.result.saleType == "auction") {
            getLastBidderDetails();
          }
          res?.data?.result?.auction?.startDate &&
            checkForUpcomingBid(res?.data?.result?.auction?.startDate);
          setCategories([
            {
              category: "black",
              content: res?.data?.result?.category?.name,
            },
          ]);
          setSubCategories([
            {
              category: "black",
              content: res?.data?.result?.subCategory?.name,
            },
          ]);
          setProductPreviews(res?.data?.result?.files);
          document.title = "lii" + " - " + res.data?.result?.name;
          setIsLoading(false)
        })
        .catch((err) => {
          PopUpAlert("Alert!", err.response?.data?.message, "warning").then(
            (res) => history.push("/")
          );
        });
    }
  };

  const handleClaim = async () => {
    let web3 = connector.connected
      ? new Web3(provider)
      : new Web3(Web3.givenProvider);
    // const contract_address_bid = '0x464D4cC81A27463A5960a08250A62C0662727CAA';
    // const auction_contract = await new web3.eth.Contract(contract_auction_abi, contract_address);
    const account = await web3.eth.getAccounts();

    setActive(true);
    try {
      const bidcontract = await web3.eth.sendTransaction({
        from: account[0],
        // to: contract_address,
        value: 0,
      });
      setActive(false);
      await PopUpAlert("Great", "NFT Claimed", "success");
    } catch (err) {
      setActive(false);
      PopUpAlert(
        "Alert!",
        "Something went wrong, try again in sometime",
        "warning"
      );
    }
  };

  const setBidDetails = (data) => {
    setProductDetails({ ...productDetails, bids: data?.bids });
    setBidInfo(data);
  };
  const closeSuccessModal = () => {
    setSuccessShow(false);
    if (assetType === "fixed") {
      setTransactionFlag(!transactionFlag);
      history.push("/orderDetails");
    } else {
      history.push('/dashboard?tab=2&atab=0');
    }
  };

  //Wishlist

  //Add item to wishlist
  const addToWishlist = (id) => {
    if (isLogin) {
      const payload = {
        assetId: id,
      };
      if (localStorage.getItem("userToken")) {
        axiosInstance
          .post(`/user/wishlist`, payload, config)
          .then((res) => {
            if (res) {
              // setEnableLoader(false);
              // setSuccessShow(true);
              setRefresh(!refresh);
              PopUpAlert("Success", "NFT added to wishlist.", "success");
            }
          })
          .catch((err) => {
            // setEnableLoader(false);
            PopUpAlert(
              "Oops",
              err?.response?.data?.message
                ? err.response.data.message
                : err?.message,
              "error"
            );
          });
      }
    } else {
      PopUpAlert("Alert", "Login before adding to wishlist.", "error").then(
        () => document.getElementById("connectWallet").click()
      );
    }
  };

  //Remove item to wishlist
  const removeFromWishlist = (id) => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      data: {
        assetId: id,
      },
    };

    axiosInstance
      .delete(`/user/wishlist/remove`, config)
      .then((res) => {
        if (res) {
          // setEnableLoader(false);
          setRefresh(!refresh);
          PopUpAlert("Success", "NFT removed from wishlist.", "success");
        }
      })
      .catch((err) => {
        // setEnableLoader(false);
        PopUpAlert(
          "Oops",
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message,
          "error"
        );
      });
  };

  const requestNFT = () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      }
    };
    axiosInstance.post(`/user/airdrop/${productDetails?.id}`, null, config)
      .then((res) => {
        if (res) {
          console.log('esserse', res)
          PopUpAlert("Success", "NFT requested successfully", "success");
          setTimeout(() => history.push('/dashboard?tab=9'), 4000)
        }
      })
      .catch((err) => {
        PopUpAlert(
          "Oops",
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message,
          "error"
        );
      });
  }

  const handleUnlist = async (e, tokenId, id) => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    e.stopPropagation();
    // ----------------------------------------------------
    let web3 = await getWeb3Provider();
    const chainId = await web3.eth.getChainId();
    const networkVerify = checkNetwork(chainId);
    if (!networkVerify) return setActive(false);

    // account is an array of available accounts in connected wallet
    const account = await web3.eth.getAccounts();

    //wallet balance will return in wei (base unit of ETH)

    try {
      setModalMessage({
        ...modalMessage,
        action: "unpublish",
        message: "Unlisting in progress",
      });
      setActive(true);

      //calculate gasprice

      // using the event emitter

      let marketplace_contract_address =
        process.env.REACT_APP_CONTRACT_ADDRESS_DEV;

      const marketplace_contract = await new web3.eth.Contract(
        contract_auction_abi,
        marketplace_contract_address
      );
      const assetPurchaseMethod = await marketplace_contract.methods.cancel(
        tokenId
      );
      let gasEstimated = null;
      let txObject = null;

      try {
        const gasPrice = await getGasPrice(web3);
        //creating transaction object
        txObject = {
          from: account[0],
          // value: 0,
          gasPrice: gasPrice,
        };

        //estimating transaction fee for this function call
        gasEstimated = await web3.eth.estimateGas({
          to: marketplace_contract_address,
          data: assetPurchaseMethod.encodeABI(),
          ...txObject,
        });

        await assetPurchaseMethod
          .send({ ...txObject, gas: gasEstimated })
          .on("transactionHash", function (hash) {
            // setTxnHash(hash);
            // setConfirmPaymentDetails({
            //   ...confirmPaymentDetails,
            //   txnHash: hash,
            // });
            // add a loader here to wait until transaction confirmation
          })
          .on("receipt", function (receipt) {
            if (receipt.status) {
              // alert("success");
              setActive(false);
              setModalMessage({
                ...modalMessage,
                action: null,
                message: "",
              });
              axiosInstance
                .patch(`/asset/un-publish/${id}`, "", config)
                .then((res) => {
                  PopUpAlert(
                    "Unlisted",
                    "NFT unlisted from marketplace",
                    "success"
                  ).then((res) => window.location.reload());
                });
            } else {
              // blockchain transaction status is fail  ed.
              setActive(false);
              PopUpAlert(
                "Alert!",
                "Transaction has been failed please try again!!",
                "error"
              );
              // .then(() => enableScroll());
            }
          })
          .on("error", (error) => {
            setActive(false);
            PopUpAlert("Alert!", "User denied transaction signature", "error");
            // .then(() => enableScroll());
          })
          .then(() => {
            //enableScroll();
            // confirmOrder(null, orderId, "failed");
          }); // If a out of gas error, the second parameter is the receipt.
      } catch (err) {
        setActive(false);
        console.log(err);
        //enableScroll();
        // confirmOrder(null, orderId, "failed");
        if (err.code === 4001) {
          PopUpAlert("Alert!", "Please confirm from your wallet", "error").then(
            (err) => {
              //enableScroll();
              window.location.reload();
            }
          );
        } else {
          PopUpAlert(
            "Alert!",
            "Something went wrong. Please try again",
            "error"
          ).then((err) => {
            //enableScroll();
            window.location.reload();
          });
        }
      }
    } catch (err) {
      console.log("Error handle pay on crypto", err);
      setActive(false);
    }
  };

  //to see if the user is creator artist or cocreator
  const getUserRole = () => {
    const creatorId = productDetails?.creator?.id;
    let artistId;
    productDetails?.userAssetCommission?.forEach((item, index) => {
      if (item?.creatorType == "artist") {
        artistId = item;
      }
    });

    if (userDbId == creatorId) {
      return "creator";
    } else if (artistId?.["userId"] == userDbId) {
      return "artist";
    } else {
      return "co-creator";
    }
  };

  //to check if loggedin user is the creator/artist/cocreator
  const checkIfCreatorOrArtist = () => {
    // console.log(userDbId)
    if (userDbId) {
      const creatorId = productDetails?.creator?.id;
      let artistId;
      const coCreators = [];
      productDetails?.userAssetCommission?.forEach((item, index) => {
        if (item?.creatorType === "coCreator") {
          coCreators.push(item);
        } else if (item?.creatorType == "artist") {
          artistId = item;
        }
      });
      if (
        userDbId == creatorId ||
        artistId?.["userId"] == userDbId ||
        coCreators.some(
          (item) => item.hasOwnProperty("userId") && item["userId"] == userDbId
        )
      ) {
        return true;
      }

      return false;
    } else {
      return false;
    }
  };

  // Item Buy Now button handler
  const handleBuyNowHandler = () => {
    disableScroll();
    if (!walletStatus) {
      document.getElementById("connectWallet").click();
      var body = document.querySelector("body");
      body.style.overflow = "auto";
      return false;
    } else if (
      !userEmail &&
      !accountDetails?.userName &&
      !accountDetails?.firstName &&
      !accountDetails.lastName &&
      localStorage.getItem("userToken")
    ) {
      PopUpAlert("Alert..", "Please complete profile.!", "info").then((res) => {
        enableScroll();
        history.push("/my-account");
      });
      return false;
    } else if (!userEmail && !localStorage.getItem("userToken")) {
      PopUpAlert(
        "Alert..",
        "Please connect your wallet or Login using email.",
        "error"
      ).then((res) => {
        enableScroll();
        document.getElementById("connectWallet").click();
      });
      return false;
    } else if (loginWith!=='metamask' && isUserVerified != "verified") {
      PopUpAlert(
        "Alert..",
        "User is not verified. Please verify your account",
        "error"
      ).then(() => {
        enableScroll();
        history.push("/my-account");
      });
    } else if (
      Number(productDetails?.currentOwner?.id) === Number(accountDetails?.id)
    ) {
      PopUpAlert(
        "Alert",
        "Oops, you are unable to purchase this asset because you are the owner",
        "error"
      );
    } else if (productDetails?.isAirdropNft === true && productDetails?.isOnSell !== true) {
      requestNFT()
    } else {
      handleBuy();
    }
  };

  const [usdcWalletBalance, setUsdcWalletBalance] = useState(null)
  useEffect(() => {
    fetchUsdBalance()
  }, [userAccount?.account?.[0]]);

  const fetchUsdBalance = async () => {
    if (userAccount?.account?.[0]) {
      let address = userAccount?.account?.[0];
      let balance = await getUsdcBalanceFromAccount(address);
      setUsdcWalletBalance(balance)
    }
  };

  const getPriceSection = () => {
    return (
      <>
        <div className={styles.priceMAT}>
          <p style={{ fontSize: "1.125rem" }}>
            Price
            {/* {productDetails?.isOnSell ? "Resell price" : "Price"} */}
          </p>

          <h2>
            {productDetails?.isAirdropNft === true && productDetails?.isOnSell !== true ? " FREE" : <>

              $
              {productDetails?.isOnSell
                ? formatPrice(productDetails?.resellPrice?.toFixed(2))
                : formatPrice(productDetails?.regularPrice?.toFixed(2))}

            </>}

          </h2>
        </div>
        <div className={styles.priceDollar}>
          {/* price will be shown here(fixed price. for auction counter component is used) */}
          {productDetails?.saleType === "fixed" ? (
            <>
              <div data-id="price">
                <h2 className={styles.positionRelative}>
                  {/* {productDetails?.isOnSell
                    ? (productDetails?.resellPrice * euroValue)?.toFixed(2)
                    : (productDetails?.regularPrice * euroValue)?.toFixed(2)} */}
                  {productDetails?.isOnSell
                    ? Number(productDetails?.resellPrice).toFixed(2)
                    : Number(productDetails?.regularPrice).toFixed(2)}
                  <img
                    src={`/images/usdc-logo.png`}
                    alt="USDC"
                    className={styles.maticSymbol}
                  />
                </h2>
              </div>
            </>
          ) : (
            <>
              <div
                className={styles.price}
                style={{
                  color: "#FF76DC",
                }}
                data-id="auc-price"
              >
                <h2>
                  $
                  {formatPrice(
                    (
                      productDetails?.auction?.currentBid ||
                      productDetails?.auction?.openingPrice
                    )?.toFixed(2)
                  )}
                </h2>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  // Tempory Function to handle Wert Result after completion from wert widget.. Change this later .. Done for demo

  const handleWertResult = async (tx_id) => {
    setConfirmPaymentDetails((prevState) => ({
      ...prevState,
      txnHash: tx_id,
    }));
    setTransactionFlag(!transactionFlag);
  };

  const RenderHTMLWithTags = (content) => {
    const htmlContent = content;

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  const onCloseWidget = () => {
    PopUpAlert(
      "Info",
      "Transaction in progress. This process may take some time to complete.",
      "success"
    ).then(() => history.push("/dashboard?tab=3"));
  };

  useEffect(() => {

    console.log("close wert updated!", closeWert)
  }, [closeWert])

  return (
    <>
      {isLoading ? screenWidth > 1024 ? <Variants /> : <VariantsMobile /> : ""}
      {shareOption && (
        <SocialShare
          show={shareOption}
          setShow={setShareOption}
          productDetails={productDetails}
        />
      )}
      {successShow && (
        <SuccessPopup
          productDetails={productDetails}
          amount={
            productDetails?.saleType == "fixed"
              ? productDetails?.isOnSell
                ? productDetails?.resellPrice
                : productDetails?.regularPrice
              : successPopupAmount
          }
          close={() => closeSuccessModal()}
          type={assetType}
          sourcePage={"crypto"}
        />
      )}
      {bidConfirmation ? (
        <BidConfirmationModal
          setBidConfirmation={setBidConfirmation}
          productDetails={productDetails}
          bidInfo={bidInfo}
          currentBidAmount={currentBidAmount}
          onBid={handleBid}
        />
      ) : (
        <div className={cn("section", styles.section)}>
          {productDetails && (
            <div className={cn("container", styles.container)}>
              <div
                className={styles.backArrowContainer}
                onClick={() => history.goBack()}
              >
                {/* <img
                  src={"/Icons/backArrow.svg"}
                  alt="back arrow"
                  height="1.25rem"
                  width="1.25rem"
                  style={{ marginTop: "0.25rem" }}
                /> */}
                <ArrowBackIcon />
              </div>
              <Lightbox
                productPreviews={productPreviews}
                productDetails={productDetails}
                width="100%"
              />
              <div className={styles.details}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: 15,
                    alignItems: "center",
                  }}
                >
                  {/* <div style={{ width: 40, height: 40, backgroundColor: "#ed5acd" }}></div> */}
                  <p
                    className={styles.title}
                    data-id="pro-name"
                    style={{ fontSize: "2.25rem", fontWeight: "600" }}
                  >
                    {/* {productDetails?.name} */}
                    {productDetails.purchasedBy
                      ? `${productDetails?.name} #${productDetails.currentEdition}/${productDetails.totalEdition}`
                      : productDetails?.name}
                  </p>
                </div>

                <div>
                  <>
                    <div>
                      <div className={styles.categoryContainer}>
                        <div className={styles.ownerBadgeOuter}>
                          {/* {creatorDetails?.["creator"]?.map((item, index) => (
                            <div
                              className={styles.ownerBadge}
                              onClick={() => {
                                history.push("/viewProfile/" + item?.userId);
                              }}
                            >
                              <div className={styles.profileImg}>
                                <img
                                  src={
                                    item?.User?.profilePicUrl ||
                                    "/images/content/Avatar.png"
                                  }
                                  alt="pp"
                                  style={{
                                    width: "2.188rem",
                                    height: "2.188rem",
                                    borderRadius: "50%",
                                  }}
                                ></img>
                              </div>
                              <div className={styles.ownerDetails}>
                                <h5>Creator</h5>
                                <p>
                                  @{item?.User?.userName || item?.User?.email}
                                </p>
                              </div>
                            </div>
                          ))} */}


                          {productDetails?.creator &&
                            <div
                              className={styles.ownerBadge}
                              onClick={() => {
                                history.push("/viewProfile/" + productDetails?.creator.id);
                              }}
                            >
                              <div className={styles.profileImg}>
                                <img
                                  src={
                                    productDetails?.creator?.profilePicUrl ||
                                    "/images/content/Avatar.png"
                                  }
                                  alt="pp"
                                  style={{
                                    width: "2.188rem",
                                    height: "2.188rem",
                                    borderRadius: "50%",
                                  }}
                                ></img>
                              </div>
                              <div className={styles.ownerDetails}>
                                <h5>Creator</h5>
                                <p>
                                  @{productDetails?.creator?.userName || productDetails?.creator?.email}
                                </p>
                              </div>
                            </div>
                          }


                        </div>


                        {/* current owner mapping */}
                        <div className={styles.ownerBadgeOuter}>
                          {productDetails?.currentOwner &&
                            <div
                              className={styles.ownerBadge}
                              onClick={() => {
                                history.push("/viewProfile/" + productDetails?.currentOwner.id);
                              }}
                            >
                              <div className={styles.profileImg}>
                                <img
                                  src={
                                    productDetails?.currentOwner?.profilePicUrl ||
                                    "/images/content/Avatar.png"
                                  }
                                  alt="pp"
                                  style={{
                                    width: "2.188rem",
                                    height: "2.188rem",
                                    borderRadius: "50%",
                                  }}
                                ></img>
                              </div>
                              <div className={styles.ownerDetails}>
                                <h5>Current Owner</h5>
                                <p>
                                  @{productDetails?.currentOwner?.userName || productDetails?.currentOwner?.email}
                                </p>
                              </div>
                            </div>
                          }


                        </div>


                        {/* <div className={styles.categoryBadge}>
                          {categories.map((x, index) => (
                            <Link
                              to={`/search01/${x.content}`}
                      
                              onClick={() => window.location}
                            >
                     

                              <div className={styles.creatorBadge}>
                                <div className={styles.profileImg}>
                                  <img src="/images/content/Avatar.png"></img>
                                </div>
                                <div>
                                  <h5>{productDetails?.creatorName}</h5>
                                  <p>{x.content}</p>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div> */}
                        {userEmail != productDetails?.currentOwner?.email && (
                          <div className={styles.forwardAndLove}>
                            <div
                              className={styles.love}
                              style={{
                                boxShadow:
                                  "rgba(100, 100, 111, 0.2) 0px 0.438rem 1.813rem 0px",
                              }}
                              onClick={(e) => {
                                if (productDetails?.isWishlist) {
                                  removeFromWishlist(productDetails?.id);
                                } else {
                                  addToWishlist(productDetails?.id);
                                }
                                e.stopPropagation();
                              }}
                            >
                              <div className={styles.loveIcon}>
                                <img
                                  src={
                                    productDetails?.isWishlist
                                      ? "/Icons/blackLove.png"
                                      : "/Icons/love.svg"
                                  }
                                  height={"21em"}
                                  width={"21em"}
                                  alt=""
                                ></img>
                              </div>
                            </div>
                            <div
                              className={styles.love}
                              style={{
                                boxShadow:
                                  "rgba(100, 100, 111, 0.2) 0px 0.438rem 1.813rem 0px",
                              }}
                            >
                              <div className={styles.loveIcon}>
                                <img
                                  src="/images/content/share.svg"
                                  alt={"share_icon"}
                                  height={"21m"}
                                  width={"21em"}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setShareOption(true)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className={styles.badgeContainer}>
                        {creatorDetails?.["artist"]?.map((item, index) => (
                          <div className={styles.ownerBadgeOuter}>
                            <div
                              className={styles.ownerBadge}
                              onClick={() => {
                                history.push("/viewProfile/" + item?.userId);
                              }}
                            >
                              <div className={styles.profileImg}>
                                <img
                                  src={
                                    item?.User?.profilePicUrl ||
                                    "/images/content/Avatar.png"
                                  }
                                  alt="pp"
                                  style={{
                                    width: "2.188rem",
                                    height: "2.188rem",
                                    borderRadius: "50%",
                                  }}
                                ></img>
                              </div>
                              <div className={styles.ownerDetails}>
                                <h5>Artist</h5>
                                <p>
                                  @{item?.User?.userName || item?.User?.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                        {creatorDetails?.["coCreator"]?.map((item, index) => (
                          <div className={styles.ownerBadgeOuter}>
                            <div
                              className={styles.ownerBadge}
                              onClick={() => {
                                history.push("/viewProfile/" + item?.userId);
                              }}
                            >
                              <div className={styles.profileImg}>
                                <img
                                  src={
                                    item?.User?.profilePicUrl ||
                                    "/images/content/Avatar.png"
                                  }
                                  alt="pp"
                                  style={{
                                    width: "2.188rem",
                                    height: "2.188rem",
                                    borderRadius: "50%",
                                  }}
                                ></img>
                              </div>
                              <div className={styles.ownerDetails}>
                                <h5>Co-Creator</h5>
                                <p>
                                  @{item?.User?.userName || item?.User?.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}

                        {productDetails?.UserAssetRoyaltyCommissions?.length
                          ? productDetails?.UserAssetRoyaltyCommissions?.map(
                            (el) => {
                              return (
                                <div className={styles.ownerBadgeOuter}>
                                  <div className={styles.ownerBadge}>
                                    <div className={styles.profileImg}>
                                      <img
                                        src={
                                          productDetails?.creator
                                            ?.profilePicUrl
                                            ? productDetails?.creator
                                              ?.profilePicUrl
                                            : "/images/content/Avatar.png"
                                        }
                                        alt="pp"
                                        style={{
                                          width: "2.188rem",
                                          height: "2.188rem",
                                          borderRadius: "50%",
                                        }}
                                      ></img>
                                    </div>
                                    <div className={styles.ownerDetails}>
                                      <h5>Co-Artist</h5>
                                      <p>
                                        {el?.User?.firstName ||
                                          el?.User?.userName ||
                                          el?.User?.email}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                          : null}
                        {/* <div className={styles.ownerBadgeOuter}>
                          <div className={styles.ownerBadge}>
                            <div className={styles.profileImg}>
                              <img
                                src={
                                  productDetails?.creator?.profilePicUrl
                                    ? productDetails?.creator?.profilePicUrl
                                    : "/images/content/Avatar.png"
                                }
                                alt="pp"
                                style={{
                                  width: "2.188rem",
                                  height: "2.188rem",
                                  borderRadius: "50%",
                                }}
                              ></img>
                            </div>
                            <div className={styles.ownerDetails}>
                              <h5>Artist</h5>
                              <p>@{productDetails?.creatorName}</p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      {/* <div className={styles.badgeContainer}>
                        
                      </div> */}

                      {productDetails?.description && (
                        <div>
                          {/* <h6 className={styles.shortTitle}>Short Description</h6> */}
                          <div
                            className={cn(
                              "textAlignLeft",
                              styles.subDescription
                            )}
                            data-id="shortdes"
                          >
                            {shortLimit < 451 &&
                              productDetails?.description?.length > 451 ? (
                              <>
                                {" "}
                                {RenderHTMLWithTags(
                                  productDetails?.description.substr(
                                    0,
                                    shortLimit
                                  )
                                )}
                                ...
                                {shortLimit < 451 && (
                                  <div
                                    onClick={() => setShortLimit(1501)}
                                    className={styles.readMore}
                                  >
                                    {" "}
                                    Read more
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {RenderHTMLWithTags(
                                  productDetails?.description
                                )}
                                {productDetails?.description?.length > 450 && (
                                  <div
                                    onClick={() => setShortLimit(450)}
                                    className={styles.readMore}
                                  >
                                    {" "}
                                    Read less
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </div>
                <div className={styles.info}>
                  <div style={{ marginTop: 15 }}>
                    {props?.mode !== "view" ? (
                      <>
                        {`${userId}` != productDetails?.purchasedBy ? (
                          <>
                            {/* check if you are the creator or artist or cocreator*/}
                            {checkIfCreatorOrArtist() && (
                              <p
                                style={{
                                  color: "#1d1d1d",
                                }}
                              >
                                You cannot purchase this asset as you are the{" "}
                                {getUserRole()}.
                              </p>
                            )}

                            {!checkIfCreatorOrArtist() && (
                              <>
                                {productDetails?.saleType === "fixed" && (
                                  <>
                                    {productDetails?.availableStock &&
                                      productDetails?.status !== "inactive" ? (
                                      <>
                                        {/* will add the buy now new component here


                              */}
                                        <div className={styles.priceContainer}>
                                          <div className={styles.priceSection}>
                                            {getPriceSection()}
                                          </div>
                                          <button
                                            className={
                                              styles.buttonFullWidth_buynow
                                            }
                                            disabled={
                                              !checked ||
                                              Number(
                                                productDetails?.currentOwner?.id
                                              ) === Number(accountDetails?.id)
                                            }
                                            onClick={!productDetails?.isAirdropRequested && handleBuyNowHandler}
                                          >
                                            {console.log("isAirdropRequestedisAirdropRequestedisAirdropRequestedisAirdropRequested", productDetails)}
                                            <span>
                                              {Number(
                                                productDetails?.currentOwner?.id
                                              ) === Number(accountDetails?.id)
                                                ? "Owned"
                                                : productDetails?.isAirdropNft === true && productDetails?.isOnSell !== true ? <> {productDetails?.isAirdropRequested ? "Already Requested" : "Request asset"}</> : "Buy Now"}
                                            </span>
                                          </button>
                                        </div>

                                        {/* Commenting out terms because it is not in figma */}
                                        {Number(
                                          productDetails?.currentOwner?.id
                                        ) != Number(accountDetails?.id) ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "start",
                                              marginTop: 8,
                                            }}
                                          >
                                            <Checkbox
                                              checked={checked}
                                              onChange={handleCheckboxCheck}
                                              inputProps={{
                                                "aria-label":
                                                  "primary checkbox",
                                              }}
                                              style={{ marginRight: 8 }}
                                            />
                                            <p
                                              style={{
                                                color: "#1d1d1d",
                                                // margin: "0.313rem 0px",
                                                fontSize: 12,
                                                // marginBottom: -15,
                                                // marginTop: 15,
                                                width:
                                                  screenWidth < 550 && "20rem",
                                              }}
                                            >
                                              I acknowledge that I have read and
                                              accepted the{" "}
                                              <Link to={`/TermsAndConditions`}>
                                                terms and conditions
                                              </Link>{" "}
                                            </p>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <p
                                        style={{
                                          color: "#1d1d1d",
                                        }}
                                      >
                                        Out of Stock
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <p
                            style={{
                              color: "#1d1d1d",
                            }}
                          >
                            You can't purchase this asset. This asset is already
                            owned by you.
                          </p>
                        )}
                      </>
                    ) : (
                      productDetails?.currentOwner?.id ===
                      accountDetails?.id && (
                        <div className={styles.priceContainer}>
                          {/* {!productDetails?.isOnSell && productDetails?.isDownloadable &&
                          <Link
                            to={`/resell/${id}`}
                            className={cn("button-small", styles.button)}
                            style={{ textDecoration: "none", color: "#f0f0f0", marginRight: 10 }}
                            onClick={() => window.location}
                          >
                            Download
                          </Link>
                        } */}

                          <div
                            className={styles.line}
                            style={{ marginTop: 18 }}
                          >
                            <div
                              className={styles.price}
                              style={{
                                // border: "0.063rem solid #000000",
                                borderRadius: 4,
                                margin: "0.625rem 0px",
                                // color: productDetails?.isOnSell
                                //   ? "#45B26B"
                                //   : "#ff4040",
                                color: "black",
                                padding: "0px 0.625rem",
                              }}
                            >
                              {productDetails?.status == 'active'
                                ? "Listed on marketplace"
                                : "Not listed on marketplace"}
                            </div>
                          </div>
                          <p
                            style={{
                              // color: "#45B26B",
                              color: "black",
                              marginLeft: "0.625rem",
                            }}
                          >
                            This asset is owned by you
                          </p>
                          {/* {productDetails?.isOnSell && (
                          <div
                            className={styles.priceSection}
                            style={{ marginTop: "0.625rem" }}
                          >
                            {getPriceSection()}
                          </div>
                        )} */}
                          {!productDetails?.isOnSell && productDetails?.isResellable && productDetails?.status == 'inactive' ? (
                            <Link
                              to={`/resell/${id}`}
                              className={cn("button-small", styles.button)}
                              style={{
                                borderRadius: 5,
                                textDecoration: "none",
                                color: "#f0f0f0",
                                width: "100%",
                                marginTop: "0.625rem",
                                fontWeight: 700,
                              }}
                              onClick={() => window.location}
                            >
                              {productDetails?.status == 'active'
                                ? "Edit price"
                                : "Sell on marketplace "}
                            </Link>
                          ) :
                            <>
                              {!productDetails?.isOnSell && !productDetails?.isResellable && productDetails?.status == 'inactive' &&
                                (
                                  <Link
                                    className={cn("button-small", styles.button)}
                                    style={{
                                      borderRadius: 5,
                                      textDecoration: "none",
                                      color: "#f0f0f0",
                                      width: "100%",
                                      marginTop: "0.625rem",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Not Resellable
                                  </Link>
                                )
                              }


                            </>}
                          {productDetails?.isOnSell &&
                            productDetails?.currentOwner?.id ===
                            accountDetails?.id && (
                              <div
                                className={cn("button-small", styles.button)}
                                style={{
                                  borderRadius: 5,
                                  textDecoration: "none",
                                  color: "#f0f0f0",
                                  width: "100%",
                                  marginTop: "0.625rem",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                }}
                                onClick={(e) =>
                                  handleUnlist(
                                    e,
                                    productDetails.tokenId,
                                    productDetails.id
                                  )
                                }
                              >
                                Unlist from marketplace
                              </div>
                            )}
                        </div>
                      )
                    )}
                  </div>
                  <br />
                  {!checkIfCreatorOrArtist() && (
                    <>
                      {productDetails?.saleType === "auction" &&
                        props?.mode !== "view" && (
                          <Counter
                            productDetails={productDetails}
                            onBid={handleBid}
                            claimNft={handleClaim}
                            isUpcomingBid={isUpcomingBid}
                            setBidInfo={setBidDetails}
                            setBidConfirmation={setBidConfirmation}
                            currentBidAmount={currentBidAmount}
                            setCurrentBidAmount={(amount) =>
                              setCurrentBidAmount(amount)
                            }
                            prePurchaseDetails={prePurchaseDetails}
                          />
                        )}
                    </>
                  )}
                </div>
                <div style={{ flexBasis: "100%" }}>
                  {productDetails?.id && (
                    <BidRows
                      productDetails={productDetails}
                      type={productDetails?.saleType}
                      key={productDetails?.id}
                    />
                    // <BidHistory
                    //   id={productDetails?.id}
                    //   type={productDetails?.saleType}
                    // />
                  )}
                </div>
                <br />

                {/* navbar is commented over here as it was not in figma. Starting here */}
                {/* <div className={styles.nav}>
                {navLinks.map((x, index) => (
                  <button
                    className={cn(
                      { [styles.active]: index === activeIndex },
                      styles.link
                    )}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div> */}
                {/* {activeIndex === 0 && <Info productDetails={productDetails} />}
              {activeIndex === 1 && (
                <>
                  <div className={styles.infoDesc} data-id="description">
                    {parse(
                      productDetails?.description?.substr(0, limit) ||
                        "No description"
                    )}
                    {productDetails?.description?.length > 500 && limit < 501
                      ? "..."
                      : ""}
                  </div>
                  {productDetails?.description?.length > 500 && limit < 501 && (
                    <div
                      onClick={() => setLimit(100001)}
                      className={styles.readMore}
                    >
                      {" "}
                      Read more
                    </div>
                  )}
                </>
              )}
              {activeIndex === 2 && (
                <div className={styles.info} data-id="cretor">
                  {cLimit < 501 &&
                  productDetails?.creatorDescription?.length > 253 ? (
                    <>
                      {" "}
                      {productDetails?.creatorDescription.substr(0, 250) +
                        "..."}
                      {cLimit < 501 && (
                        <div
                          onClick={() => setCLimit(1501)}
                          className={styles.readMore}
                        >
                          {" "}
                          Read more
                        </div>
                      )}
                    </>
                  ) : (
                    productDetails?.creatorDescription
                  )}
                </div>
              )}
              {activeIndex === 3 && <PurchaseInfo pur={productDetails} />} */}
                {/* ending here */}
              </div>
            </div>
          )}
        </div>
      )}

      {/* checkout modal */}
      <div
        className={styles.checkout + " " + styles.completeCheckout}
        style={{ display: open ? "flex" : "none" }}
        onClick={() => setOpen(false)}
      >
        <div
          className={styles.checkoutInner}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2>Complete checkout</h2>
          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => setOpen(false)}
          >
            <img src={"/images/closeIcon.png"} alt={"closeicon"} />
          </button>
          <div className={styles.checkoutDetail}>
            {/* <div>
              <p>Item</p>
            </div> */}
            <div>
              {productDetails?.mediaType === "audio" && (
                <div
                  className={styles.playerwrapper}
                  style={{
                    position: "relative",
                    paddingTop: 0,
                    width: screenWidth > 550 ? "200px" : "150px",
                    height: screenWidth > 550 ? "165px" : "150px",
                  }}
                >
                  {/* <div> */}
                  <img
                    src={productDetails?.audioThumbnail}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="thumbnail.png"
                  />
                  <audio
                    controls
                    controlsList="nodownload"
                    style={{
                      position: "absolute",
                      bottom: "1.563rem",
                      left: 0,
                      height: 25,
                      width: "100%",
                    }}
                  >
                    <source
                      src={productDetails?.mediaPreviewUrl}
                      type="audio/mpeg"
                    />
                  </audio>
                  {/* </div> */}
                </div>
              )}
              {productDetails?.mediaType === "video" && (
                <div
                  className={styles.playerwrapper}
                  // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                  style={{
                    position: "relative",
                    paddingTop: 0,
                    width: "200px",
                    height: "165px",
                  }}
                >
                  {/* <ReactPlayer
                    url={productDetails?.mediaPreviewUrl}
                    width={screenWidth > 550 ? "200px" : "150px"}
                    height={screenWidth > 550 ? "165px" : "150px"}
                    light={false}
                    controls={false}
                    // style={{ position: "absolute", top: "0", left: "0" }}
                    config={{
                      file: {
                        attributes: {
                          disablePictureInPicture: true,
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  /> */}
                  <video
                    id="vid"
                    width={screenWidth > 550 ? "200px" : "150px"}
                    height={screenWidth > 550 ? "165px" : "150px"}
                    style={{ objectFit: "cover", borderRadius: "0.313rem" }}
                    disablePictureInPicture
                    controlsList="nodownload"
                    loop="true"
                    autoplay="true"
                    muted
                    playsInline
                  >
                    <source src={productDetails?.mediaPreviewUrl} />
                    Your browser does not support the video.
                  </video>
                </div>
              )}
              {(productDetails?.mediaType === "image" ||
                productDetails?.mediaType === "3D Model") && (
                  <div
                    style={{
                      width: screenWidth > 550 ? "200px" : "150px",
                      height: screenWidth > 550 ? "165px" : "150px",
                    }}
                    className={styles.playerwrapper}
                  >
                    <img
                      src={
                        productDetails?.thumbnailUrl ||
                        productDetails?.mediaPreviewUrl
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="thumbnail.png"
                    />
                  </div>
                )}
              {/* <span style={{ marginLeft: 0 }}>
                {"Product Name: " + productDetails?.name}
              </span> */}
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <div className={styles.space}>
              <h4>{productDetails?.name}</h4>
              {/* <h4>{productDetails?.name+ ' #'+productDetails?.currentEdition+'/'+productDetails?.totalEdition}</h4> */}
              <div className={styles.priceWalletWrap}>
                <div className={styles.checkoutPriceInfo}>
                  <span>Price</span>
                  <p>
                    {/* <span style={{ fontWeight: "bold", marginRight: 5 }}> */}
                    $
                    {productDetails?.isOnSell
                      ? formatPrice(productDetails?.resellPrice?.toFixed(2))
                      : formatPrice(productDetails?.regularPrice?.toFixed(2))}
                    {/* </span> */}
                  </p>
                </div>
                <div className={styles.checkoutPriceInfo}>
                  <span>Your USDC Wallet Balance</span>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    {loginWith == "metamask"
                      ? usdcWalletBalance
                        ? usdcWalletBalance?.toFixed(2)
                        : "0"
                      : Number(
                        usdcWalletBalance
                          ? Number(usdcWalletBalance)?.toFixed(2)
                          : "0"
                      )}{" "}
                    <img
                      src={`/images/usdc-logo.png`}
                      alt="USDC"
                      className={styles.maticSymbol}
                      style={{ width: 23, paddingLeft: 0, marginLeft: 5 }}
                    />
                  </p>
                </div>
                {/* <div className={styles.checkoutPriceInfo}>
                  <span>Your Cash Wallet Balance</span>
                  <p>${circleBalance ? circleBalance : "0.00"}</p>
                </div> */}
              </div>

              <div className={styles.checkoutTag}>
                <button
                  className={ styles.buttonFullWidth }
                  onClick={() => {
                    disableScroll();
                    if (!walletStatus) {
                      PopUpAlert(
                        "Alert..",
                        "Please connect wallet or Login.!",
                        "info"
                      );
                    } else if (
                      !userEmail &&
                      !accountDetails?.userName &&
                      !accountDetails?.firstName &&
                      !accountDetails.lastName &&
                      !accountDetails.status &&
                      localStorage.getItem("userToken")
                    ) {
                      disableScroll();
                      PopUpAlert(
                        "Alert..",
                        "Please complete profile.!",
                        "info"
                      ).then((res) => {
                        enableScroll();
                        history.push("/my-account");
                      });
                      return false;
                    } else if (
                      !userEmail &&
                      !localStorage.getItem("userToken")
                    ) {
                      PopUpAlert(
                        "Alert..",
                        "Please connect your wallet or Login using email.",
                        "error"
                      ).then((res) => {
                        document.getElementById("connectWallet").click();
                      });
                      return false;
                    } else if (loginWith!=='metamask' && isUserVerified != "verified") {
                      PopUpAlert(
                        "Alert..",
                        "User is not verified. Please verify your account",
                        "error"
                      ).then(() => {
                        history.push("/my-account");
                      });
                    } else if (
                      Number(SERVER_ENVIORNMENT !== "DEVELOPMENT" ? 5 : 1.09) >
                      (productDetails?.isOnSell
                        ? productDetails?.resellPrice
                        : productDetails?.regularPrice)
                    ) {
                      PopUpAlert(
                        "Alert",
                        `Minimum of $${SERVER_ENVIORNMENT !== "DEVELOPMENT" ? "5" : "1.09"
                        } required to proceed with card payment`,
                        "error"
                      );
                    } else {
                      handleCardPayment();
                    }
                  }}
                  style={{ borderRadius: 5, fontSize: "0.688rem" }}
                >
                  <span>Pay with card</span>
                </button>
              <button
                  className={styles.buttonFullWidth}
                  style={{ borderRadius: 5, fontSize: "0.688rem" }}
                  onClick={() => {
                    disableScroll();
                    handleWalletCheckout("metamask");
                  }}
                >
                  <span>Pay with crypto</span>
                </button>
                {/* <button

                  className={styles.buttonFullWidth}
                  onClick={() => {
                    disableScroll();
                    if (!walletStatus) {
                      PopUpAlert(
                        "Alert..",
                        "No wallet connection found.",
                        "error"
                      ).then((res) => {
                        enableScroll();
                        document.getElementById("connectWallet").click();
                      });
                      return false;
                    } else if (isUserVerified != "verified") {
                      PopUpAlert(
                        "Alert..",
                        "User is not verified. Please verify your account",
                        "error"
                      ).then(() => {
                        enableScroll();
                        history.push("/my-account");
                      });
                    } else {
                      handleWalletCheckout("wallet");
                    }
                  }}
                  style={{ borderRadius: 5, fontSize: "0.688rem" }}
                >
                  <span>Pay with cash wallet</span>
                </button> */}
              </div>
              {wert?.active == true ? (
                <>
                  <div id="wertWidgetDiv">
                    {!closeWert &&
                      <WertWidgetDiv
                        arg={{
                          productDetails: productDetails,
                          mode: wert?.mode,
                          setOpenWertModal: setWert,
                          saleType: "fixed",
                          bidAmountFiat: wert?.bidAmountFiat,
                          bidAmountCrypto: wert?.bidAmountCrypto,
                          handleWertCheckOut: handleWertCheckOut,
                          setWertLoaded: setWertLoaded,
                          handleWertResult: handleWertResult,
                          processWertResult: handleWertCheckOut,
                          onCloseWidget: onCloseWidget,
                          closeWert: closeWert,
                          setCloseWert: setCloseWert,
                          callSetSuccessShow,
                          updateFixedOrderStatus
                        }}
                      />
                    }
                  </div>

                </>
              ) : null}
              <Alert severity="info" style={{ marginTop: "1em" }}>
                Crypto payments supports only polygon network (MATIC)
              </Alert>
            </div>
          </div>
        </div>
      </div>

      {/* Add Card Render */}
      <div
        className={styles.checkout}
        style={{ display: openAddCardModal ? "flex" : "none" }}
        onClick={() => setOpenAddCardModal(false)}
      >
        <div
          className={styles.checkoutInner}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <BillingDetails />
          {/* <h2>Add Card</h2>
          <div className={styles.PaymentMethodWrapper}>
            <table>
              <tr>
                <td>
                  <label for="name">Card No</label>
                </td>
                <td>
                  <input type="text" id="name" />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="email">CVV</label>
                </td>
                <td>
                  <input type="text" id="email" />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="telnum">Expiry Date.</label>
                </td>
                <td style={{ display: "flex", flexDirection: "row" }}>
                  <input type="number" id="telnum" style={{ width: "2.5rem" }} />
                  <input type="number" id="telnum" style={{ width: "2.5rem" }} />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="Roll No.">Name on card</label>
                </td>
                <td>
                  <input type="number" id="rollno" />
                </td>
              </tr>
            </table>
          </div> */}
          <div style={{ textAlign: "center" }} className={styles.checkoutTag}>
            <button
              className={cn("button-small", styles.button)}
              style={{ borderRadius: 5 }}
              onClick={() => setOpenAddCardModal(false)}
            >
              <span> Cancel </span>
            </button>
          </div>
        </div>
      </div>
      <Loader
        active={active}
        backGround="/images/liiLogoArabic.png"
        customize={
          loginWith === "metamask" && purchaseMessage.title && purchaseMessage.message ? true :
            modalMessage.action == "unpublish" ||
              productDetails?.saleType == "auction"
              ? true
              : false
        }
        title={loginWith === "metamask" && purchaseMessage?.title ? purchaseMessage.title :
          modalMessage.action == "unpublish"
            ? "Unlisting in progress"
            : modalMessage.action == "bidWert"
              ? "Transaction in progress"
              : "Bidding in progress"
        }
        content={loginWith === "metamask" && purchaseMessage.message ? purchaseMessage.message : "Please wait"}
      />
    </>
  );
};

export default Item;
