import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Item from "./Item";

const faqData = [
  {
    title: "General",
    icon: "home",
    items: [
      {
        question: "What is the lii Marketplace?",
        answer:
          "<p>The Marketplace is where you can buy and sell and trade your favorite NFTs. It is available to collectors 24/7 from all around the world.</p><br/><p>Please note, the Marketplace may be affected by maintenance as well as cooldown periods during times of high traffic, such as new NFT drops.</p>",
      },
      {
        question: "Is lii decentralized?",
        answer:
          "<p>While lii utilizes decentralized blockchain technology to make it easier for users to trace and verify the authenticity and scarcity of their digital collectibles. lii’s business operations are fairly centralized.</p>",
      },
      {
        question: "What blockchain does lii utilize? ",
        answer: `<p>The platform is run using Polygon Blockchain.</p><br/><p>Users can use PolygonScan to explore and search the Polygon Blockchain for transactions done on lii.</p><p><a href="https://polygonscan.com/"  target="_blank"></a></p>`,
      },
      {
        question: "Where are the NFTs stored?",
        answer: `<p>The Non Fungible Tokens live on the Polygon Blockchain. However, the digital media file is stored on IPFS.</p><br/><p>The Interplanetary File System, or IPFS, is a distributed system for storing and accessing files, websites, applications, and data.</p>`,
      },
      {
        question: "Can I withdraw money/crypto from lii?",
        answer: `<p>lii enables money withdrawals to bank accounts in <b>supported countries</b> and cryptocurrency withdrawals to <b>supported WEB3 wallets</b> such as Metamask.</p></br><p>Minimum withdrawal amount is $300, the processing fee for bank transfers is $30</p><p><ol><li>Users must complete a KYC to do any withdrawal.</li><li>The limit for USD withdrawal is $10,000 per month.</li><li>USD withdrawals can take anywhere from 10-20 days to be fully processed.</li></ol></p>`,
      },
      {
        question: "Can I withdraw my NFTs from lii?",
        answer: `<p>NFTs can be withdrawn to <b>supported WEB3 wallets</b>, but in order to protect the creator’s IP and royalties these NFTs cannot be traded outside the lii ecosystem. Your NFTs are minted and reside on the POLYGON blockchain, and are not under our custody at any time.</p>`,
      },
      {
        question: "What is an Auction?",
        answer: `<p>A public sale where the NFT is sold to the highest bidder at the time when the auction ends.</p>`,
      },
      {
        question: "What is an NFT drop? ",
        answer: `<p>An NFT drop is the release of a non-fungible token project. A drop refers to the exact date, time of the NFT becoming available for purchase.</p>`,
      },
      {
        question: "What is an NFT?",
        answer: `<p>NFT (non-fungible token) is a digital certificate of ownership which has exactly one owner, its guaranteed and visible to everyone. </p><p>NFTs can represent any digital asset like NFT, audio, or video. Also they can have offline tie-ins, for example, a new music track NFT which gives the owner concert tickets or a backstage pass.</p>`,
      },
      {
        question: "Can I upload my own NFTs?",
        answer: `<p>lii provides only curated content, to become a creator on the platform please contact our onboarding team <b>onboarding@lii.io</b></p>`,
      },
      {
        question: "What does rarity mean for NFTs?",
        answer: `<p>Typically, there are three or four rarities for NFTs on lii – Common, Rare, and Legendary, though at times we may introduce other tiers. The higher the rarity the more exclusive and unique your NFT is.</p>`,
      },
      {
        question: "How do I list my NFT for sale on the secondary market? ",
        answer: `<p>When signed in to your account on lii</p><p>Click on my lii</p><p>Go to dashboard.</p><p>Select the NFT you wish to sell.</p><p>Click “list on marketplace” 
        </p><p>Choose the asking price</p><p>Click “done”</p>`,
      },
      {
        question: "How do I pay for NFTs on lii?",
        answer: `<p>You can buy NFTs on lii using a variety of payment methods, including debit/credit cards, bank transfers, and cryptocurrency deposits.</p>`,
      },
      {
        question: "Do I need a crypto wallet when using lii? ",
        answer: `<p>YES and NO</p><br/><p>NFTs can only be stored on WEB3 wallets, lii gives its users 2 options depending on their preference:</p><br/><p>If you are an experienced crypto user, you can directly connect a <b>supported WEB3 wallet</b> and enjoy our services.</p><br/><p>Alternatively, you can log-in using your email ID. lii will create a non-custodial wallet for you which you can access through your mail. The seed phrase to this wallet is stored securely with a 3rd Party service, only you can access it at any time from the user dashboard. </p>`,
      },
      {
        question: "What is lii’s marketplace fee?",
        answer: `<p>lii charges a 10% marketplace fee on all transactions (Primary & secondary sales). This means that creators get 90% of the total sales price when collectors purchase their NFTs.</p>`,
      },
      {
        question: "What are royalty fees?",
        answer: `<p>Royalties generally pay the creator of the NFT a percentage of the sale price each time the NFT is re-sold on the secondary marketplace.</p><br/><p>Royalties are set by the creators during the minting process.</p>`,
      },
      {
        question: "How can I get a refund?",
        answer: `<p>Transactions on the blockchain cannot be reversed, and so lii does not provide refunds for purchases made on the platform.</p>`,
      },
    ],
  },
  {
    title: "Support",
    icon: "circle-and-square",
    items: [
      "How to start with Stacks",
      "Dose it suppport Dark Mode",
      "Does it support Auto-Layout",
      "What is Stacks Design System",
      "How does it work",
      "How to start with Stacks",
    ],
  },
  {
    title: "Hosting",
    icon: "lightning",
    items: [
      "How does it work",
      "How to start with Stacks",
      "Dose it suppport Dark Mode",
      "What is Stacks Design System",
    ],
  },
  {
    title: "Product",
    icon: "pen",
    items: [
      "How does it work",
      "How to start with Stacks",
      "Dose it suppport Dark Mode",
      "Does it support Auto-Layout",
      "What is Stacks Design System",
    ],
  },
];

const Hero = () => {
  const options = [];
  faqData?.map((x) => options.push(x.title));

  const [direction, setDirection] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          {/* <div className={styles.stage}>learn how to get started</div> */}
          <h3 className={cn(styles.title)}>Frequently asked questions</h3>
          {/* <div className={styles.info}>
            Join Stacks community now to get free updates and also alot of
            freebies are waiting for you or{" "}
            <a href="/#" rel="noopener noreferrer">
              Contact Support
            </a>
          </div> */}
          {/* <div className={styles.textboxBorder}>
            <input type="text"></input>
            <img src="/images/content/search.svg" alt="Search button" />
          </div> */}
        </div>

        <div className={styles.row}>
          {/* <div className={styles.col}> */}
          {/* <div className={styles.nav}>
              {items.map((x, index) => (
                <div
                  className={cn(styles.link, {
                    [styles.active]: x.title === direction,
                  })}
                  onClick={() => setDirection(x.title)}
                  key={index}
                >
                  <Icon name={x.icon} size="16" />
                  <span>{x.title}</span>
                </div>
              ))}
            </div> */}
          {/* </div> */}
          <div className={styles.col}>
            {faqData
              .find((x) => x.title === direction)
              .items.map((x, index) => (
                <Item className={styles.item} item={x} key={index} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
