import { useEffect } from "react";
import { useState } from "react";
import styles from "./viewprofile.module.sass"
const ProfileDash = ({ setCurrentTab ,currentTab}) => {
    const [menuIndex, setMenuIndex] = useState(currentTab)
    const menuItems = [
        {
            name: "Owned NFTs",
            id: 0
        },
        {
            name: "Created NFTs",
            id: 1
        }
    ]
    useEffect(() => {
        setCurrentTab(menuIndex)
    }, [menuIndex])
    return (
        <>
            <div className={styles.menuWrapper}>
                {menuItems?.map((item, index) => (
                    <div
                        className={
                            menuIndex == item?.id ? styles.selected : styles.nonSelected
                        }
                        onClick={() => {
                            setMenuIndex(item.id);
                            // setPage(1);
                            // history.push(`/dashboard?tab=${item?.id}`);
                        }}
                    >
                        {item.name}
                    </div>
                ))}
            </div>

        </>
    );
}

export default ProfileDash;