import { Box, Modal } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "./AddWireAccount.module.sass";
import { axiosInstance } from "../../../utils/API";
import { PopUpAlert } from "../../../controller/utils";
import axios from "axios";
import { country } from "../../../controller/utils";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../Item/Loader.jsx";
import { CircularProgress } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import { decryptData } from "../../utils/decrypt";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
	formControlRoot: {
		width: "100%",
	},
});

const AddWireAccount = ({
	visible,
	closeModal,
	refreshPage,
	setRefreshPage,
}) => {
	const [fields, setFields] = useState({
		accountType: "US-BANK",
	});
	const [error, setError] = useState({});
	const [ipAddress, setIpAddress] = useState(null);
	const [districtFieldShow, setDistrictFieldShow] = useState(false);
	const [stateList, setStateList] = useState(null);
	const [enableLoader, setEnableLoader] = useState(false);
	const secretKey = useSelector((state) => state?.keyVal?.keyData);
	const bankDetails = [
		"US-BANK",
		"NON-US-BANK-IBAN-SUPPORTED",
		"NON-US-BANK-NON-IBAN-SUPPORTED",
	];
	const classes = useStyles();

	useEffect(() => {
		getIP();
	}, []);

	const onHandleChange = (e) => {
		let value = e.target.value;
		let name = e.target.name;
		// //console.log('name>>>>',name);
		// if (name === "card_number") {
		//   // //console.log('cardNUmberFormat(value)',cardNUmberFormat(value));
		//   value = cardNUmberFormat(value);
		// } else if (name === "card_expiry") {
		//   value = expireValid(value);
		// } else if (name === "country") {
		//   if (value === "US" || value === "CA") {
		//     if (value === "US") {
		//       setStateList(UsStateList);
		//     } else if (value === "CA") {
		//       setStateList(canadaStateList);
		//     }
		//     setDistrictFieldShow(true);
		//   } else {
		//     setDistrictFieldShow(false);
		//   }
		// } else if (name === "card_cvv") {
		//   value = cvvValidation(value);
		// }

		setFields({ ...fields, [name]: value });
	};
	//   const validationField = () => {
	//     let field = fields;
	//     let errors = {};
	//     let formIsValid = true;

	//     //Name
	//     // if (!field["name"]) {
	//     //   formIsValid = false;
	//     //   errors["name"] = "Please enter your name";
	//     // }
	//     if (!field["firstName"]) {
	//       formIsValid = false;
	//       errors["firstName"] = "Please enter your first name";
	//     }
	//     if (!field["lastName"]) {
	//       formIsValid = false;
	//       errors["lastName"] = "Please enter your last name";
	//     }
	//     if (!field["email"]) {
	//       formIsValid = false;
	//       errors["email"] = "Please enter your email id";
	//     }
	//     if (typeof field["email"] !== "undefined") {
	//       if (
	//         !field["email"].match(
	//           /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
	//         )
	//       ) {
	//         formIsValid = false;
	//         errors["email"] = "Please enter valid email id";
	//       }
	//     }
	//     if (!field["city"]) {
	//       formIsValid = false;
	//       errors["city"] = "Please enter your city";
	//     }
	//     if (!field["country"]) {
	//       formIsValid = false;
	//       errors["country"] = "Please enter your country";
	//     }
	//     if (!field["postalCode"]) {
	//       formIsValid = false;
	//       errors["postalCode"] = "Please enter your postal code";
	//     }
	//     if (!field["address_one"]) {
	//       formIsValid = false;
	//       errors["address_one"] = "Please enter your address";
	//     }
	//     if (!field["address_two"]) {
	//       formIsValid = false;
	//       errors["address_two"] = "Please enter your address";
	//     }
	//     if (!field["card_number"]) {
	//       formIsValid = false;
	//       errors["card_number"] = "Please enter your card number";
	//     }
	//     if (!field["card_name"]) {
	//       formIsValid = false;
	//       errors["card_name"] = "Please enter name on your card.";
	//     }
	//     if (typeof field["card_number"] !== "undefined") {
	//       const value = field["card_number"].replace(/[^\d]/g, "");
	//       if (value?.length < 15) {
	//         formIsValid = false;
	//         errors["card_number"] = "Please enter maximum 16 digits";
	//       }
	//     }
	//     if (districtFieldShow) {
	//       if (!field["district"]) {
	//         formIsValid = false;
	//         errors["district"] = "Please select your district";
	//       }
	//     }

	//     if (!field["card_expiry"]) {
	//       formIsValid = false;
	//       errors["card_expiry"] = "Please enter your card expiry";
	//     }
	//     //   if (typeof field["card_expiry"] !== "undefined") {
	//     //     const value = field['card_expiry'].replace(/[^\d]/g, '');
	//     //     if (value.length < 15) {
	//     //       formIsValid = false;
	//     //       errors["card_expiry"] = "Please enter maximum 16 digits";
	//     //     }
	//     //   }

	//     if (!field["card_cvv"]) {
	//       formIsValid = false;
	//       errors["card_cvv"] = "Please enter your card cvv";
	//     }
	//     setError(errors);
	//     return formIsValid;
	//   };

	const getIP = async () => {
		let result = await axios.get("https://api.ipify.org?format=json");
		setIpAddress(result?.data?.ip);
	};

	const onHandleSubmit = async (e) => {
		setEnableLoader(true);
		const CIRCLE_PUBLIC_KEY = process.env.REACT_APP_CIRCLE_PUBLIC_KEY;
		// if ("validationField" == "validationField") {
		//console.log('feil',fields);
		try {
			// const url = process.env.REACT_APP_CIRCLE_API_URL + "encryption/public";
			// // const key = await decryptData(CIRCLE_PUBLIC_KEY);
			// const key = CIRCLE_PUBLIC_KEY;
			// let configs = {
			//   headers: {
			//     Accept: "application/json",
			//     Authorization: "Bearer " + key,
			//   },
			// };
			// const result = await axios.get(url, configs);
			// const publicKeyArmored = result?.data?.data?.publicKey;
			// const keyId = result?.data?.data?.keyId;

			// const cardDetails = {
			//   number: fields?.card_number?.trim().replace(/\D/g, ""),
			//   cvv: fields?.card_cvv,
			// };
			// const message = await openpgp.createMessage({
			//   text: JSON.stringify(cardDetails),
			// });
			// const publicKey = await openpgp.readKey({
			//   armoredKey: window.atob(publicKeyArmored),
			// });
			// const encryptedData = await openpgp.encrypt({
			//   message,
			//   encryptionKeys: publicKey,
			// });
			// const expiry = fields?.card_expiry.split("/");
			const data = {
				accountType: fields?.accountType,
				billingDetails: {
					name: `${fields?.accountName}`,
					city: fields?.city,
					country: fields?.country,
					line1: fields?.address_one,
					line2: fields?.address_two ? fields?.address_two : "",
					district: fields?.district,
					postalCode: fields?.postalCode,
				},
				bankAddress: {
					bankName: fields?.bankName,
					city: fields?.bankCity,
					country: fields?.bankCountry,
					line1: fields?.bankAddressOne,
					line2: fields?.bankAddressTwo,
					district: fields?.bankDistrict,
				},
				idempotencyKey: "",
				accountNumber: fields?.accountNumber,
				routingNumber: fields?.routingNumber,
			};

			let ibanData = {
				accountType: fields?.accountType,
				billingDetails: {
					name: `${fields?.accountName}`,
					city: fields?.city,
					country: fields?.country,
					line1: fields?.address_one,
					line2: fields?.address_two ? fields?.address_two : "",
					district: fields?.district,
					postalCode: fields?.postalCode,
				},
				bankAddress: {
					bankName: fields?.bankName,
					city: fields?.bankCity,
					country: fields?.bankCountry,
					line1: fields?.bankAddressOne,
					line2: fields?.bankAddressTwo,
					district: fields?.bankDistrict,
				},
				iban: fields?.iBan,
			};

			let config = {
				headers: {
					Authorization: localStorage.getItem("userToken"),
				},
			};
			await axiosInstance
				.post(
					"/banks/wires",
					fields?.accountType === bankDetails[1] ? ibanData : data,
					config
				)
				.then((res) => {
					setEnableLoader(false);
					PopUpAlert("success", "Account Added Successfully", "success");
					closeModal();
					setRefreshPage(!refreshPage);
				})
				.catch((err) => {
					console.log("Show popup", err);
					setEnableLoader(false);
					PopUpAlert(
						"Alert",
						err?.message ? err?.message : err?.data?.response?.message,
						"error"
					);
				});
		} catch (err) {
			setEnableLoader(false);
			PopUpAlert(
				"Alert",
				err?.message ? err?.message : err?.data?.response?.error,
				"error"
			);
		}
		// }
	};
	return (
		<>
			<Modal open={visible} onClose={closeModal} style={{ zIndex: "1000" }}>
				<Box className={styles.modalDialog}>
					<div className={styles.addCardModal}>
						<button className={styles.close} onClick={closeModal}>
							&#10006;
						</button>
						<h2>Add a bank account</h2>
						<form>
							<div className={styles.formGroup}>
								<label>Account type</label>
								<FormControl
									variant="outlined"
									classes={{
										root: classes.formControlRoot,
									}}
								>
									<InputLabel
										disableAnimation={true}
										shrink={false}
									></InputLabel>
									<Select
										native
										name="accountType"
										value={fields?.accountType}
										onChange={(e) => onHandleChange(e)}
										inputProps={{
											name: "accountType",
											id: "outlined-age-native-simple",
										}}
										InputLabelProps={{ shrink: false }}
									>
										{/* <option aria-label="None" value="" /> */}
										{bankDetails?.map((item) => (
											<option value={item}>{item}</option>
										))}

										{/* <option value={"NON-US-BANK-IBAN-SUPPORTED"}>
                    NON-US-BANK-IBAN-SUPPORTED
                  </option>
                  <option value={"NON-US-BANK-NON-IBAN-SUPPORTED"}>
                    NON-US-BANK-NON-IBAN-SUPPORTED
                  </option> */}
									</Select>
								</FormControl>
								{error?.accountType && (
									<label className={styles.error}>{error?.accountType}</label>
								)}
							</div>
							{fields?.accountType !== "NON-US-BANK-IBAN-SUPPORTED" ? (
								<>
									<div className={styles.formGroup}>
										<label>Account number</label>
										<input
											type="text"
											className={styles.formControl}
											name="accountNumber"
											value={fields?.accountNumber}
											placeholder={"12340010"}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.accountNumber && (
											<label className={styles.error}>
												{error?.accountNumber}
											</label>
										)}
									</div>
									<div className={styles.formGroup}>
										<label>Routing number</label>
										<input
											type="text"
											className={styles.formControl}
											name="routingNumber"
											value={fields?.routingNumber}
											placeholder={"121000248"}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.routingNumber && (
											<label className={styles.error}>
												{error?.routingNumber}
											</label>
										)}
									</div>
								</>
							) : null}

							{fields?.accountType === "NON-US-BANK-IBAN-SUPPORTED" ? (
								<div className={styles.formGroup}>
									<label>IBAN</label>
									<input
										type="text"
										className={styles.formControl}
										name="iBan"
										value={fields?.iBan}
										placeholder={"DE31100400480532013000"}
										onChange={(e) => onHandleChange(e)}
									/>
									{error?.iBan && (
										<label className={styles.error}>{error?.iBan}</label>
									)}
								</div>
							) : null}

							<div className={styles.formGroup}>
								<label>Name</label>
								<input
									type="text"
									className={styles.formControl}
									name="accountName"
									value={fields?.accountName}
									onChange={(e) => onHandleChange(e)}
								/>
								{error?.accountName && (
									<label className={styles.error}>{error?.accountName}</label>
								)}
							</div>

							<span className={styles.wireWithDrawLabel}>Bank details</span>
							{/* <div className={styles.formGroup}>
              <label>Bank Name</label>
              <input
                type="text"
                className={styles.formControl}
                name="bankName"
                value={fields?.bankName}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.bankName && (
                <label className={styles.error}>{error?.bankName}</label>
              )}
            </div> */}
							<div className={styles.row}>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Bank name</label>
										<input
											type="text"
											className={styles.formControl}
											name="bankName"
											value={fields?.bankName}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.bankName && (
											<label className={styles.error}>{error?.bankName}</label>
										)}
									</div>
								</div>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Address one</label>
										<input
											type="text"
											className={styles.formControl}
											name="bankAddressOne"
											value={fields?.bankAddressOne}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.bankAddressOne && (
											<label className={styles.error}>
												{error?.bankAddressOne}
											</label>
										)}
									</div>
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Address two</label>
										<input
											type="text"
											className={styles.formControl}
											name="bankAddressTwo"
											value={fields?.bankAddressTwo}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.bankAddressTwo && (
											<label className={styles.error}>
												{error?.bankAddressTwo}
											</label>
										)}
									</div>
								</div>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>City</label>
										<input
											type="text"
											className={styles.formControl}
											name="bankCity"
											value={fields?.bankCity}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.bankCity && (
											<label className={styles.error}>{error?.bankCity}</label>
										)}
									</div>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>District</label>
										<input
											type="text"
											className={styles.formControl}
											name="bankDistrict"
											value={fields?.bankDistrict}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.bankDistrict && (
											<label className={styles.error}>
												{error?.bankDistrict}
											</label>
										)}
									</div>
								</div>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Country</label>
										<select
											className={styles.formControl}
											name="bankCountry"
											value={fields?.bankCountry}
											onChange={(e) => onHandleChange(e)}
										>
											<option value="">Select the country</option>
											{country?.map((each, i) => (
												<option key={i} value={each?.code}>
													{each?.name}
												</option>
											))}
										</select>
										{error?.bankCountry && (
											<label className={styles.error}>
												{error?.bankCountry}
											</label>
										)}
									</div>
								</div>
							</div>
							{/* {districtFieldShow && (
              <div className={styles.formGroup}>
                <label>District</label>
                <select
                  className={styles.formControl}
                  name="bankDistrict"
                  value={fields?.bankDistrict}
                  onChange={(e) => onHandleChange(e)}
                >
                  <option value="">Select the Country</option>
                  {stateList?.map((each, i) => (
                    <option key={i} value={each?.code}>
                      {each?.name}
                    </option>
                  ))}
                </select>
                {error?.bankDistrict && (
                  <label className={styles.error}>{error?.bankDistrict}</label>
                )}
              </div>
            )} */}
							{/* <div className={styles.formGroup}>
              <label>Postal Code</label>
              <input
                type="text"
                className={styles.formControl}
                name="postalCode"
                value={fields?.postalCode}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.postalCode && (
                <label className={styles.error}>{error?.postalCode}</label>
              )}
            </div> */}
							<div className={styles.wireWithDrawLabel}>Billing address</div>

							<div className={styles.row}>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Address one</label>
										<input
											type="text"
											className={styles.formControl}
											name="address_one"
											value={fields?.address_one}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.address_one && (
											<label className={styles.error}>
												{error?.address_one}
											</label>
										)}
									</div>
								</div>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Address two</label>
										<input
											type="text"
											className={styles.formControl}
											name="address_two"
											value={fields?.address_two}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.address_two && (
											<label className={styles.error}>
												{error?.address_two}
											</label>
										)}
									</div>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>City</label>
										<input
											type="text"
											className={styles.formControl}
											name="city"
											value={fields?.city}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.city && (
											<label className={styles.error}>{error?.city}</label>
										)}
									</div>
								</div>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Country</label>
										<select
											className={styles.formControl}
											name="country"
											value={fields?.country}
											onChange={(e) => onHandleChange(e)}
										>
											<option value="">Select the country</option>
											{country?.map((each, i) => (
												<option key={i} value={each?.code}>
													{each?.name}
												</option>
											))}
										</select>
										{error?.country && (
											<label className={styles.error}>{error?.country}</label>
										)}
									</div>
								</div>
							</div>
							{/* {districtFieldShow && (
              <div className={styles.formGroup}>
                <label>District</label>
                <select
                  className={styles.formControl}
                  name="district"
                  value={fields?.district}
                  onChange={(e) => onHandleChange(e)}
                >
                  <option value="">Select the Country</option>
                  {stateList?.map((each, i) => (
                    <option key={i} value={each?.code}>
                      {each?.name}
                    </option>
                  ))}
                </select>
                {error?.district && (
                  <label className={styles.error}>{error?.district}</label>
                )}
              </div>
            )} */}
							<div className={styles.row}>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>District</label>
										<input
											type="text"
											className={styles.formControl}
											name="district"
											value={fields?.district}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.district && (
											<label className={styles.error}>{error?.district}</label>
										)}
									</div>
								</div>
								<div className={styles.col}>
									<div className={styles.formGroup}>
										<label>Postal code</label>
										<input
											type="text"
											className={styles.formControl}
											name="postalCode"
											value={fields?.postalCode}
											onChange={(e) => onHandleChange(e)}
										/>
										{error?.postalCode && (
											<label className={styles.error}>
												{error?.postalCode}
											</label>
										)}
									</div>
								</div>
							</div>

							<div className={`${styles.formGroup} ${styles.submitButton}`}>
								<button
									type="button"
									className={styles.formBtn}
									onClick={(e) => onHandleSubmit(e)}
								>
									Submit
								</button>
							</div>
						</form>
					</div>
				</Box>
			</Modal>
			{enableLoader && (
				<div className={styles.loaderContent}>
					<CircularProgress color="black" />{" "}
				</div>
			)}
		</>
	);
};

export default AddWireAccount;
