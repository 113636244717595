import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: "",
    user: {},
    euroValue: "",
    magicUser: null,
    previousPaymentMethord: null,
    currentPaymentType: null,
    bidButtonStatus:false,
    accountUserName:""
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = true;
    },
    decrement: (state) => {
      state.value = false;
      state.user = {};
    },
    details: (state, data) => {
      state.user.account = data.payload.account;
      state.user.balance = data.payload.balance;
    },
    setBalance: (state, data) => {
      state.user.balance = data.payload.balance;
    },
    setEuroValue: (state, data) => {
      console.log("store", data);
      state.euroValue = data.payload;
    },
    setMagicUser: (state, m_user) => {
      state.magicUser = m_user.payload;
    },
    updatePreviousPaymentMethord: (state, data) => {
      state.previousPaymentMethord = data.payload;
    },
    updateCurrentPaymentMethord: (state, data) => {
      state.currentPaymentType = data.payload;
      console.log("CURRENT slice payload",data.payload)
    },
    circleBidButton: (state, data) => {
      state.bidButtonStatus = data.payload;
      console.log("bidButtonStatus slice payload",data.payload)
    },
    setAccountUserName :(state,data)=>{
      state.accountUserName = data.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  details,
  setProvider,
  setEuroValue,
  setMagicUser,
  setBalance,
  updatePreviousPaymentMethord,
  updateCurrentPaymentMethord,
  circleBidButton,
  setAccountUserName
} = counterSlice.actions;

export default counterSlice.reducer;
