import React, { useState, useEffect } from "react";
import styles from "./CardPayment.module.sass";
import { axiosInstance } from "../../../utils/API";
import { PopUpAlert, connector } from "../../../controller/utils";
import { useHistory } from "react-router";
import Web3 from "web3";
import * as openpgp from "openpgp";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "../../Item/Loader.jsx";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

function CardPayment({ closePopup, refreshPage, setRefreshPage }) {
  const [fields, setFields] = useState({});
  const [error, setError] = useState({});
  const [ipAddress1, setIpAddress1] = useState(null);
  // const [ipAddress2, setIpAddress2] = useState(null);
  // const [ipAddress3, setIpAddress3] = useState(null);
  const [isTermsAgreed, setIsTermsAgreed] = useState(true);
  const [enableLoader, setEnableLoader] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const userEmail = useSelector((state) => state?.accountReducer?.email);
  const history = useHistory();

  if (!connector.connected) {
    // create new session
    connector.createSession();
  }

  useEffect(() => {
    const object = history?.location?.state?.productDetails;
    setProductDetails(object);
    getIP0();
    // getIP1();
    // getIP2();
    // getIP3();
  }, []);

  const getIP0 = async () => {
    let result = await axiosInstance.get("/getIp");
    setIpAddress1(result?.data?.ip);
  };

  // const getIP1 = async () => {
  //   let result = await axios.get("https://api.ipify.org?format=json");
  //   setIpAddress1(result?.data?.ip);
  // };

  // const getIP2 = async () => {
  //   let result = await axios.get("http://ip-api.com/json/");
  //   setIpAddress2(result?.data?.query);
  // };

  // const getIP3 = async () => {
  //   let result = await axios.get(
  //     "https://ipgeolocation.abstractapi.com/v1/?api_key=23a6bb5e8371490b98182c6ce55b67d9"
  //   );
  //   setIpAddress3(result?.data?.ip_address);
  // };

  const onHandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "card_number") {
      //   // //console.log("card",card,card?.userEmail);
      setFields({ ...fields, [name]: value });
      //   setCardInfo(card);
    } else if (name === "cvv") {
      value = cvvValidation(value);
      setFields({ ...fields, [name]: value });
    } else if (name === "card_expiry") {
      value = expireValid(value);
      setFields({ ...fields, [name]: value });
    } else {
      setFields({ ...fields, [name]: value });
    }
  };
  const cvvValidation = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue?.length;

    // if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 3) {
      return currentValue;
    }
    return `${currentValue.slice(0, 3)}`;
  };

  const expireValid = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue?.length;
    console.log("lenght", currentValue?.length);

    // if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 2) {
      return currentValue;
    }
    return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 6)}`;
  };

  // const isNumber = (evt) => {
  //   evt = evt ? evt : window.event;
  //   var charCode = evt.which ? evt.which : evt.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // };

  const validationField = () => {
    let field = fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if (!field["price"]) {
      formIsValid = false;
      errors["price"] = "Please enter a valid amount.";
    }

    if (isNaN(field["price"])) {
      formIsValid = false;
      errors["price"] = "Please enter a valid amount.";
    }
    if (!field["card_number"]) {
      formIsValid = false;
      errors["card_number"] = "Please enter card number";
    }

    if (isNaN(field["card_number"])) {
      formIsValid = false;
      errors["card_number"] = "Please enter a valid card number";
    }

    if (!field["cardholders_name"]) {
      formIsValid = false;
      errors["cardholders_name"] = "Please enter valid card holder name.";
    }
    if (!field["card_expiry"]) {
      formIsValid = false;
      errors["card_expiry"] = "Please enter valid expiry date.";
    }

    if (!field["card_cvv"]) {
      formIsValid = false;
      errors["card_cvv"] = "Please enter your cvv";
    }

    if (isNaN(field["card_cvv"])) {
      formIsValid = false;
      errors["cvv"] = "Please enter a valid cvv";
    }

    setError(errors);
    return formIsValid;
  };

  const addCard = async (e) => {
    const CIRCLE_PUBLIC_KEY = process.env.REACT_APP_CIRCLE_PUBLIC_KEY;

    if (validationField()) {
      try {
        const url = process.env.REACT_APP_CIRCLE_API_URL + "encryption/public";
        // const key = await decryptData(CIRCLE_PUBLIC_KEY);
        const key = CIRCLE_PUBLIC_KEY;
        let configs = {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + key,
          },
        };
        const result = await axios.get(url, configs);
        const publicKeyArmored = result?.data?.data?.publicKey;
        const keyId = result?.data?.data?.keyId;

        const cardDetails = {
          number: fields?.card_number?.trim().replace(/\D/g, ""),
          cvv: fields?.card_cvv,
        };
        const message = await openpgp.createMessage({
          text: JSON.stringify(cardDetails),
        });
        const publicKey = await openpgp.readKey({
          armoredKey: window.atob(publicKeyArmored),
        });
        const encryptedData = await openpgp.encrypt({
          message,
          encryptionKeys: publicKey,
        });

        const expiry = fields?.card_expiry.split("/");
        const data = {
          cardName: fields?.cardholders_name,
          billingDetails: {
            name: fields?.cardholders_name,
            city: "Nil",
            country: "US",
            line1: "Nil",
            line2: "Nil",
            district: "CA",
            postalCode: "000000",
          },
          metadata: {
            email: userEmail,
            // phoneNumber:'',
            sessionId: "DE6FA86F60BB47B379307F851E238617",
            ipAddress: ipAddress1,
          },
          idempotencyKey: "",
          keyId: keyId,
          encryptedData: window.btoa(encryptedData),
          expMonth: parseInt(expiry[0]),
          expYear: parseInt(expiry[1]),
        };
        let config = {
          headers: {
            Authorization: localStorage.getItem("userToken"),
          },
        };
        const cardEncription = await axiosInstance
          .post("/cards", data, config)
          .catch((err) => {
            PopUpAlert("Alert", err?.response?.data?.message, "error").then(
              () => {
                return false;
              }
            );
          });

        return cardEncription?.data?.result;
      } catch (err) {
        PopUpAlert(
          "Alert",
          err?.message ? err?.message : err?.data?.response?.error,
          "error"
        );
        return false;
      }
    } else {
      PopUpAlert("Alert", "Please fill all fields.", "error").then(() => {
        return false;
      });
    }
  };

  const submitHandle = async () => {
    if (!isTermsAgreed) {
      setEnableLoader(false);
      PopUpAlert("Alert", "Please accept terms and condition.", "error");

      return false;
    }
    const cardInfo = await addCard();
    if (cardInfo) {
      let account, balance, check, provider;
      // if (validationField()) {
      // if (1 == 1) {
      // await provider?.enable();
      const wc_status = connector.connected;
      let web3 = connector.connected
        ? new Web3(provider)
        : new Web3(Web3.givenProvider);

      // try {
      //   account = wc_status ? connector.accounts : await web3.eth.getAccounts();
      //   balance = await web3?.eth.getBalance(account[0]);
      //   balance = web3.utils.fromWei(balance, "ether");
      //   // check = checkbalance(balance);
      // } catch {
      //   alert("Network Session Expired, please reconnect your wallet");
      //   // dispatch(decrement());
      //   setEnableLoader(false);
      //   window.location.reload();
      //   return;
      // }

      // let currentAccount = null;
      // await window.ethereum
      //   .request({ method: "eth_accounts" })
      //   .then((account) => {
      //     currentAccount = account;
      //   })
      //   .catch((err) => {
      //     // Some unexpected error.
      //     // For backwards compatibility reasons, if no accounts are available,
      //     // eth_accounts will return an empty array.
      //     alert("Error, while fetching account details");
      //     setEnableLoader(false);
      //     console.error(err);
      //   });

      const url = process.env.REACT_APP_CIRCLE_API_URL + "encryption/public";
      // const key = decryptData(process.env?.REACT_APP_CIRCLE_PUBLIC_KEY);
      const authorizationKey = `Bearer ${process.env.REACT_APP_CIRCLE_PUBLIC_KEY}`;
      let configs = {
        headers: {
          Accept: "application/json",
          Authorization: authorizationKey,
        },
      };
      const result = await axios.get(url, configs);
      const publicKeyArmored = result?.data?.data?.publicKey;
      const keyId = result?.data?.data?.keyId;

      const cardDetails = {
        cvv: fields?.card_cvv,
      };
      const message = await openpgp.createMessage({
        text: JSON.stringify(cardDetails),
      });
      const publicKey = await openpgp.readKey({
        armoredKey: window.atob(publicKeyArmored),
      });
      const encryptedData = await openpgp.encrypt({
        message,
        encryptionKeys: publicKey,
      });
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };

      const uuid = uuidv4();

      let body = {
        metadata: {
          email: userEmail,
          phoneNumber: "+14155555555",
          sessionId: "DE6FA86F60BB47B379307F851E238617",
          ipAddress: ipAddress1,
        },
        amount: {
          amount: `${Number(fields?.price)}`,
          currency: "USD",
        },
        autoCapture: true,
        source: {
          id: cardInfo?.id,
          type: "card",
        },
        idempotencyKey: uuid,
        keyId: "key1",
        verification: "cvv",

        description: "Payment",
        encryptedData: window.btoa(encryptedData),
        channel: "",
      };

      await axiosInstance
        .post(`/payments?actionType=deposit`, body, config)
        .then((res) => {
          if (res) {
            closePopup();
            setRefreshPage(!refreshPage);
            PopUpAlert("Success", "Transaction Complete!", "success").then(
              (res) => {
                setEnableLoader(false);
                history.push("/Circlepayment");
              }
            );
          }
        })
        .catch((err) => {
          PopUpAlert(
            "Alert",
            err?.response?.data?.message
              ? err.response.data.message
              : err?.message,
            "error"
          ).then(() => setEnableLoader(false));
        });
    } else {
      setEnableLoader(false);
    }
  };

  return (
    <section className="section" style={{ background: "#ffffff" }}>
      <div className="container">
        <div className={styles.checkout}>
          <div className={styles.supportedCardsWrap}>
            <h3>Supported Cards</h3>
            <div>
              <img src="/images/visa.png" alt="card" />
              <img src="/images/masterCard.png" alt="card" />
              <img src="/images/maestro.png" alt="card" style={{ width: 43 }} />
              <img src="/images/americanExpress.png" alt="card" />
            </div>
          </div>
          <form>
            <div className={styles.formGroup}>
              <label className={styles.cardLabel}>Amount payable</label>
              <input
                type="number"
                className={styles.formControl}
                name="price"
                value={fields?.price}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.price && (
                <label className={styles.error}>{error?.price}</label>
              )}
            </div>
            <div className={styles.formGroup}>
              <label className={styles.cardLabel}>Card number</label>
              <input
                type="number"
                className={styles.formControl}
                name="card_number"
                value={fields?.card_number}
                placeholder="4007400000000007"
                onChange={(e) =>
                  e.target.value.length <= 16 && onHandleChange(e)
                }
              />
              {error?.card_number && (
                <label className={styles.error}>{error?.card_number}</label>
              )}
            </div>
            <div className={styles.formGroup}>
              <label className={styles.cardLabel}>Card holders name</label>
              <input
                type="text"
                className={styles.formControl}
                name="cardholders_name"
                value={fields?.cardholders_name}
                onChange={(e) => onHandleChange(e)}
                placeholder="JIM CARREY"
              />
              {error?.cardholders_name && (
                <label className={styles.error}>
                  {error?.cardholders_name}
                </label>
              )}
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.formGroup}>
                  <label className={styles.cardLabel}>Expiration date</label>
                  <input
                    type="text"
                    className={styles.formControl}
                    placeholder="MM/YYYY"
                    name="card_expiry"
                    value={fields?.card_expiry}
                    onChange={(e) => onHandleChange(e)}
                  />
                  {error?.card_expiry && (
                    <label className={styles.error}>{error?.card_expiry}</label>
                  )}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.formGroup}>
                  <label className={styles.cardLabel}>Cvv</label>
                  <input
                    type="password"
                    className={styles.formControl}
                    name="card_cvv"
                    placeholder="XXX"
                    value={fields?.card_cvv}
                    onChange={(e) => onHandleChange(e)}
                  />
                  {error?.card_cvv && (
                    <label className={styles.error}>{error?.card_cvv}</label>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <Alert severity="info">
                Card processing fees are 2.9% + $0.3 per transaction.
              </Alert>
              <div className={styles.agreeBoxWrapper}>
                <Checkbox
                  defaultChecked
                  color="default"
                  checked={isTermsAgreed}
                  onChange={(e) => setIsTermsAgreed(!isTermsAgreed)}
                  inputProps={{ "aria-label": "checkbox with default color" }}
                />
                <p className={styles.agreeBoxText}>
                  By submitting, I authorize lii NFT platform FZCO, to
                  electronically charge my credit/debit card (and, if necessary,
                  electronically credit my card) using the card indicated by me.
                  I understand that this authorization will remain in full force
                  in accordance with the lii terms and conditions. I agree that
                  credit/debit card transactions I authorize, comply with all
                  applicable law
                </p>
              </div>
              {/* <label className={styles.cardLabel}>Card Number</label> */}
            </div>
            {/* <div className={styles.formGroup}>
                <label className={styles.cardLabel}>CVV</label>
                <input
                  type="password"
                  className={styles.formControl}
                  name="cvv"
                  value={fields?.cvv}
                  onChange={(e) => handleChange(e)}
                />
                {error?.cvv && (
                  <label className={styles.error}>{error?.cvv}</label>
                )}
              </div> */}
            {/* <div className={styles.formGroup}>
                <label className={styles.cardLabel}>Email</label>
                <input
                  type="email"
                  className={styles.formControl}
                  name="email"
                  value={fields?.email}
                  onChange={(e) => handleChange(e)}
                />
                {error?.email && (
                  <label className={styles.error}>{error?.email}</label>
                )}
              </div> */}
            <div
              className={styles.formGroup}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2.25rem",
              }}
            >
              <button
                type="button"
                className={styles.formBtn}
                onClick={(e) => submitHandle(e)}
                // disabled={!isTermsAgreed}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Loader active={enableLoader} type={"no"} />
    </section>
  );
}

export default CardPayment;
