import "./Faqs.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useHistory } from "react-router";

const Faqs = () => {
  let history = useHistory();
  // useEffect(()=> {
  //     window.location.reload();
  // }, [history])

  return (
    <div id="container123">
      <div>
        <div className="tabs">
          <span className="title123" id="setqs1">
            <h3>Buying NFTs On lii.com</h3>
          </span>
          <span className="title123" id="setqs2">
            <h3>Tell me all About NFTs?</h3>
          </span>
          {/* <span className="title" id="setqs3"><h3>Techy Terms</h3></span> */}
        </div>
        <div className="qandas">
          <div className="setqs1">
            <ul className="faq">
              <li
                className="q"
                // style={{ textAlign: "left", position: "relative", marginBottom: 20 }}
              >
                {/* <p 
                                style={{ display: "inline-block", position: "absolute", left: 20 }}> */}
                Why Should I Buy an NFT From lii?
                {/* </p> */}
                <img src="/images/arrow.png" style={{ marginRight: 2 }} />
              </li>
              <li className="a">
                lii is the NFT Marketplace editions.NFTs on lii.com
              </li>
              <li className="q">
                <img src="/images/arrow.png" />
                What Do I Need to Buy an NFT on lii?
              </li>
              <li className="a">
                It’s easy to buy an NFT on lii.com Simply connect your wallet
                by clicking on the 'Connect Wallet' at the top right-hand corner
                of the marketplace. Choose Metamask or you can choose Wallet
                Connect to connect other wallets. Once you have successfully
                connected your wallet and have sufficient cryptocurrency, you
                will be able to use it for your purchases within the platform.
              </li>

              <li className="q">
                <img src="/images/arrow.png" /> How do I get a Crypto wallet?
              </li>
              <li className="a">
                You can get a Metamask Wallet at:
                <a href="https://metamask.io/" target="_blank">
                  {" "}
                  https://metamask.io/{" "}
                </a>
                other popular wallet options are also available. Please visit{" "}
                <a
                  href="https://walletconnect.com/registry/wallets"
                  target="_blank"
                >
                  Wallet Connect
                </a>{" "}
                to see other wallet choices that you can use to connect to
                lii.com
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                What are Fixed Price and Auction Sales on lii?
              </li>
              <li className="a">
                <p>
                  Once you have your wallet connected, there are two ways you
                  can acquire NFTs on lii.com; Fixed Price Sales or Auctions.
                </p>
                <p>
                  <u>FIXED PRICE Sales on lii.com</u>
                </p>
                <p>
                  There are some NFTs that will be offered for a Fixed Price.
                  Please note that while the total number of editions may not be
                  sold all at once, Fixed Price Sales and prices may vary due to
                  the demand at that time.
                </p>
                <p>
                  <u>AUCTION Sales on lii.com</u>
                </p>
                <p>
                  There are some NFTs that will be offered through an Auction
                  bidding process. These artworks could have multiple total
                  editions which will of course be made known to the buyers at
                  the time of the sale. Please note that only one edition of the
                  NFT will only be sold through an Auction.{" "}
                </p>
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                Buying a FIXED PRICE NFT on lii.com
              </li>
              <li className="a">
                <p>
                  Buying a Fixed Price NFT is easy. Simply choose the NFT that
                  you would like to purchase and click on the BUY NOW button. It
                  will then take you to our payment processing partner UTRUST
                  who offer 4 different crypto currencies for payments Bitcoin,
                  Ethereum, Tether and Utrust Token. You may choose any one of
                  these currencies to make your payment. It will then display
                  two options to either scan a QR code or manually copy the
                  payment link.
                </p>
                <p>
                  Once your payment process has begun, it will automatically
                  redirect you back to lii.com
                </p>
                <p>
                  Once your payment has been processed and confirmed, your newly
                  purchased NFT from lii.com will be transferred to your
                  Wallet!
                </p>
                <p>
                  Once you have it, it is yours to keep or trade on any of the
                  well know NFT trading marketplaces such as OpenSea.io
                </p>
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                Bidding for an NFT at an Auction on lii.com
              </li>
              <li className="a">
                <p>
                  Bidding for an NFT at an auction is easy. Simply choose the
                  NFT that you would like to bid on, offer your bid in Euros and
                  the equivalent estimated ETH at that time will be displayed.
                  Because lii NFTs are minted on the Polygon Network, you will
                  need Wrapped Ethereum (WETH) in your wallet to complete the
                  bid. If you have Ethereum, you can easily convert it to WETH
                  using the option for the conversion bridge via lii.com or you
                  can purchase WETH from a Crypto Exchange and come back to bid
                  for the NFT.
                </p>
                <p>
                  When you click on BID NOW you will then need to give lii.com
                  some basic details so that we are able to keep you informed
                  whether you’ve been outbidded or have won the Auction. Once
                  you confirm your bid, your WETH is blocked.
                </p>
                <p>
                  If you are outbidded, you will receive a notification from
                  lii via email and your WETH will be released.
                </p>
                <p>
                  When your bid wins and your payment has been processed and
                  confirmed, you will be notified and your newly purchased NFT
                  from lii.com will be transferred to your Wallet!
                </p>
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                Do I Need to Create My Profile?
              </li>
              <li className="a">
                It is up to you. However, if you are bidding for an NFT in an
                auction, you will be asked to provide some details including
                your email address so that we’re able to notify you whether you
                have been outbidded or if yours was the winning bid.
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                What is an NFT Collection?
              </li>
              <li className="a">
                lii offers unique digital twins of rich,ts and relics as well
                as original artworks by modern digital artists. Some artworks
                are a part of a collection such as the introductory collection
                of 20 paintings of Saint Francis Xavier. The first four NFTs to
                be Auctioned are part
              </li>

              {/* <li className="q"><img src="/images/arrow.png" />What is an NFT Edition?</li>
                            <li className="a">An 'edition' is the unique copy out of the total number of 'editions' or copies that the NFT represents. In the traditional sense it is like the total number of prints that an artist authorizes to sell and the 23/100 that that buyer purchases is the 23rd unique edition of that total number of 100 prints ever created.</li>

                            <li className="q"><img src="/images/arrow.png" />Where is the NFT Stored?</li>
                            <li className="a">lii.com mints its NFTs on the Polygon Network and reside there permanently. The NFT Token is stored in the buyer's crypto wallet (digital blockchain wallet). The Digital Artwork that the token represents is stored on Pinata.cloud and has an IPFS address. At present lii allows buyers to connect their Metamask or other wallets that connect via WalletConnect. lii.com will continue to integrate and accept additional wallets.</li>

                            <li className="q"><img src="/images/arrow.png" />What Are My Rights as An Owner?</li>
                            <li className="a">Please read the <Link to="/TermsAndConditions">Terms & Conditions</Link> associated with owning an NFT purchased from lii.com</li>

                            <li className="q"><img src="/images/arrow.png" />Displaying My NFT?</li>
                            <li className="a">The NFT belongs to you and the token will appear in your wallet. You may trade or display under the <Link to="/TermsAndConditions">Terms & Conditions</Link>.</li>

                            <li className="q"><img src="/images/arrow.png" />Where and How Can I Sell My NFT?</li>
                            <li className="a">You can trade your NFTs on any of the popular NFT trading platforms by simply connecting your wallet and following the steps prescribed. Please be sure to read their terms and conditions and fees associated with their service.</li>

                            <li className="q"><img src="/images/arrow.png" />Are There any Royalties Paid at the Time of Resale?</li>
                            <li className="a">The owner of the NFT purchased from lii.com can trade their NFT on any other popular trading platform. Every time a trade concludes, there are Royalties that lii will collect on the total value of the sale independent of the fees charged by the trading platform for facilitating and/or processing the transaction. The Royalties at present are set at 10%. Please see <Link to="/TermsAndConditions">Terms & Conditions</Link>.</li>

                            <li className="q"><img src="/images/arrow.png" />Can I Return My NFT?</li>
                            <li className="a">Sorry. All lii.com NFT sales are final and there are NO returns and/or refunds.</li> */}
            </ul>
          </div>
          <div className="setqs2">
            <ul className="faq">
              <li
                className="q"
                // style={{ textAlign: "left", position: "relative", marginBottom: 20 }}
              >
                {/* <p style={{ display: "inline-block", position: "absolute", left: 20 }}> */}
                Tell Me More About NFTs?
                {/* </p> */}
                <img src="/images/arrow.png" />
              </li>
              <li className="a">
                NFT stands for non-fungible token. It is a unique digital
                certificate, registered on a blockchain that records ownership
                of an asset such as an artwork or a collectible. Each NFT is a
                unique, irreplaceable identifier that cannot be changed, which
                is crucial to prove authenticity. NFTs are underpinned by smart
                contracts which use certain standards. lii (ARTNK) uses the
                ERC-721 NFT token standard.{" "}
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                Why buy an NFT?
              </li>
              <li className="a">
                Buying an NFT is 'truly' buying the unique, certified artwork
                that it is associated with and proves authenticity of the artist
                and ownership. Unlike physical certificates, NFTs live
                permanently on a blockchain, and owners don't need to worry
                about preserving or damaging the digital artwork. Buyers of NFTs
                can not only preserve the ownership but also trade it and track
                its provenance.
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                How does it work?
              </li>
              <li className="a">
                With every artwork that is purchased, lii will mint an NFT that
                creates the unequivocal claim on the ownership of that artwork
                based on the{" "}
                <Link to="/TermsAndConditions">Terms & Conditions</Link> stated.
                Because the NFTs are on a blockchain ledger it is possible to
                track it's provenance, original ownership and history of trades,
                edition number and the total copies ever to be minted.
              </li>

              <li className="q">
                <img src="/images/arrow.png" />
                What is an NFT Token?
              </li>
              <li className="a">
                This is the unique ERC 721 non fungible token issued to the
                buyer of the NFT by lii.com representing the authentic digital
                artwork that has been purchased. This token is transferred the
                buyer's blockchain wallet. The buyer is henceforth responsible
                for the security of that token.
              </li>
            </ul>
          </div>
          {/* <!--<div className="setqs3" >
                        <ul className="faq">
                            <li class="q"><img src="img/arrow.png"/>Blockchain</li>
                            <li class="a">Blockchain is a peer-to-peer decentralized distributed ledger technology that makes the records of any digital asset transparent and unchangeable and works without involving any third-party intermediary. The original and most popular Blockchain as it is known today is Bitcoin.</li>

                            <li class="q"><img src="img/arrow.png"/>Ethereum</li>
                            <li class="a">Inspired by Bitcoin, Ethereum was invented in 2016 as a decentralized, open source blockchain with smart contract functionality. Ether (ETH or Ξ) is the native cryptocurrency of the platform. Among the tokens on the Ethereum Blockchain the most popular non fungible token use is the ERC 721 standard, which is also used by lii for its NFTs.</li>

                            <li class="q"><img src="img/arrow.png"/>Smart Contract</li>
                            <li class="a">A smart contract is a computer program or a transaction protocol which is intended to automatically execute, control or document legally relevant events and actions according to the terms of a contract or an agreement. The objectives of smart contracts are the reduction of need in trusted intermediators, arbitrations and enforcement costs, fraud losses, as well as the reduction of malicious and accidental exceptions.</li>

                            <li class="q"><img src="img/arrow.png"/>ERC-721 </li>
                            <li class="a">ERC-721 is a free, open standard that describes how to build non-fungible or unique tokens on the Ethereum blockchain. While most tokens are fungible (every token is the same as every other token), ERC-721 tokens are all unique. Think of them like rare, one-of-a-kind collectable.</li>

                            <li class="q"><img src="img/arrow.png"/>Gas Fees</li>
                            <li class="a">Gas fees essentially are the transaction fees that users pay to miners on a blockchain protocol to have their transaction included in the 'block'. The system works on a standard supply and demand mechanism. However, at lii.com you will not be charged any Gas fees for minting the NFT that you have purchased.</li>

                            <li class="q"><img src="img/arrow.png"/>Polygon Layer 2</li>
                            <li class="a">The Polygon Network blockchain is a Layer 2 Ethereum scaling solution. Polygon has contributed considerably to the Ethereum ecosystem. Polygon’s ecosystem is one of the largest to host NFT projects and platforms due to its transaction efficiencies and connectivity to the Ethereum mainnet. Polygon’s native Crypto currency is MATIC.</li>


                            <li class="q"><img src="img/arrow.png"/>Wrapped ETH (WETH)</li>
                            <li class="a">WETH and ETH are always worth the same. WETH is used to bid for Auctions on lii. Because decentralized platforms running on Ethereum use smart contracts to facilitate trades directly between users, every user needs to have the same standardized format for every token they trade. This ensures tokens don’t get lost in translation. You can convert ETH to WETH on lii.com via the Polygon Bridge or through a Crypto Exchange that you use. </li>

                            <li class="q"><img src="img/arrow.png"/>Minting an NFT</li>
                            <li class="a"><p>Minting is the process behind how the NFT becomes part of the Ethereum blockchain. Minting an NFT is how your digital NFT becomes a part of the Ethereum blockchain – a public ledger that is unchangeable and tamper-proof. Like the way that metal coins are minted and added into circulation, NFTs are also tokens that get “minted” once they are created.</p><p>When an NFT is minted, the following items are defined and recorded on-chain: artwork details, artwork data (e.g., some information about the edition), total editions, and the IPFS hash.</p></li>

                            <li class="q"><img src="img/arrow.png"/>IPFS</li>
                            <li class="a">The Inter Planetary File System (IPFS) is a protocol and peer-to-peer network for storing and sharing data in a distributed file system. IPFS uses content-addressing to uniquely identify each file in a global namespace connecting all computing devices.</li>

                            <li class="q"><img src="img/arrow.png"/>Pinata</li>
                            <li class="a">Pinata is a data storage platform that lii uses to store the digital artworks associated with the NFT and manage files on IPFS. Visit <a href="https://www.pinata.cloud" target="_blank">www.pinata.cloud<a/></li>

                            <li class="q"><img src="img/arrow.png"/>HASH</li>
                            <li class="a">Part of the suite of cryptographic protections that underpin the integrity of the blockchain; they ensure only authorized changes are permitted on the chain.</li>

                            <li class="q"><img src="img/arrow.png"/>Lazy Minting NFTs</li>
                            <li class="a">This is a process that enables 'minting' the NFT token on the blockchain after the confirmation of the purchase. lii.com uses Lazy Minting.</li>

                            <li class="q"><img src="img/arrow.png"/>Crypto Wallet</li>
                            <li class="a">Crypto wallets keep your public and private keys and the phrases and passwords that give you access to your cryptocurrencies and allow you to send and receive cryptocurrencies like Bitcoin and Ethereum. Popular forms of Crypto wallets are typically software based such as Metamask. There are hardware-based wallets available as well like Ledger.com that offer secure devices.</li>

                            <li class="q"><img src="img/arrow.png"/>Metamask</li>
                            <li class="a">MetaMask is a software cryptocurrency wallet used to interact with the Ethereum blockchain. It allows users to access their Ethereum wallet through a browser extension or mobile app, which can then be used to interact with decentralized applications. MetaMask is developed by ConsenSys Software Inc., a blockchain software company focusing on Ethereum-based tools and infrastructure. Visit <a href="https://www.metamask.io" target="_blank">www.metamask.io</a></li>

                            <li class="q"><img src="img/arrow.png"/>WalletConnect</li>
                            <li class="a">WalletConnect is an open source protocol for connecting decentralised applications to mobile wallets with QR code scanning or deep linking. A user can interact securely with any Dapp from their mobile phone, making WalletConnect wallets a safer choice compared to desktop or browser extension wallets. Visit <a href="https://www.walletconnect.com" target="_blank">www.walletconnect.com</a></li>

                            <li class="q"><img src="img/arrow.png"/>UTRUST</li>
                            <li class="a">Utrust is the leading cryptocurrency payment solution designed to modernize the finance and payments industry and solve the problems of traditional payment methods by offering instant transactions and immediate crypto-to-cash settlements for the merchant. Visit <a href="https://www.utrust.com" target="_blank">www.utrust.com</a></li>


                        </ul> */}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
