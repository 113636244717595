import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./NewsDetailPage.module.sass";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { axiosInstance } from "../../utils/API";

export default function NewsDetailPage() {
	let setInnerHTML = (content) => {
		return (
			<div
				className={styles.content}
				dangerouslySetInnerHTML={{ __html: content }}
			></div>
		);
	};

	const [news , setNews] = useState({
		title: "",
		description: "",
		bannerUrl: "",
		date: "",
	})

	const history = useHistory()

	const {id} = useParams();
	

	useEffect(()=> {
		axiosInstance.get("/asset/news/all").then((res)=> {
			
			
			const value = res.data?.result?.news?.rows?.find((ele)=> ele.id == id )
			if(value) {
				setNews({
					title: value.title,
					description: value.description,
					bannerUrl: value.bannerUrl,
					date: value.data,
				})
			}
		})
	}, [id])
		
		return (
		<>
		<div
			className={cn("container", styles.container)}
			style={{ margin: "3.125rem auto", flexWrap: "wrap" }}
		>
			<div
				className={cn("container", styles.container)}
				style={{ flexWrap: "wrap" }}
			>
				<div
					className={styles.backArrowCircle}
					onClick={() => history.push('/news')}
				>
					<img
						src="/images/content/backArrow.svg"
						
						style={{ marginRight: "0.625rem", opacity: "100%" ,	height:"3.25rem",
						width:"3.25rem" }}
						alt="back"
					/>
				</div>

				<h1>{news?.title}</h1>
				<div className={styles.contentWrapper}>
					<div className={styles.round}>
						<img
							src={news?.bannerUrl}
							style={{ marginRight: "0.625rem", opacity: "100%" }}
							alt="back"
							height={"6.25rem"}
						/>
					</div>

					<p className={styles.newsContent}>
						{setInnerHTML(news?.description)}
					</p>
				</div>
			</div>
		</div>
		</>
	);
}
