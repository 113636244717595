import React from "react";
import cn from "classnames";
import styles from "./Resell.module.sass";
import Image from "../Image";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import { axiosInstance } from "../../utils/API";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getBalance } from "../../../src/controller/utils";
import {
  checkNetwork,
  nft_contract_abi,
  PopUpAlert,
  getWeb3Provider,
} from "../../controller/utils";
import Web3 from "web3";
import useWindowSize from "../../common/hooks/useWindowSize";
import Loader from "../../screens/Item/Loader";
import TopUpWalletWert from "../../screens/WertPayment/topUpWalletWert";

const ResellForm = ({ className, value }) => {
  const [item, setItem] = React.useState();
  const [active, setActive] = React.useState(false);
  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);
  const [details, setDetails] = React.useState({
    salePrice: "",
    saleType: "",
    currentPrice: "",
  });
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
  const [screenWidth] = useWindowSize();

  const { id } = useParams();
  const history = useHistory();

  const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

  let config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };

  const getProductDetails = async (id) => {
    await axiosInstance
      .get(`/asset/view/${id}`, config)
      .then((res) => {
        setItem(res?.data?.result);
        setDetails({
          ...details,
          currentPrice: res?.data?.result?.regularPrice,
          saleType: res?.data?.result?.saleType,
          assetId: res?.data?.result?.tokenId,
        });
      })
      .catch(() => { });
  };

  React.useEffect(() => {
    getProductDetails(id);
  }, []);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };
  const handleResaleProduct = async (
    assetId,
    basePrice,
    salePrice,
    purchaseType
  ) => {
    //for auction purchaseType =2, basePrice = base amount, salePrice= buy now price
    //for fixed price product resale purchaseType =1, basePrice = product price, salePrice= product price ie, basePrice = salePrice
    let web3 = await getWeb3Provider();

    const chainId = await web3.eth.getChainId();
    const networkVerify = checkNetwork(chainId);
    if (!networkVerify) {
      Swal.fire("Alert", " Please switch to polygon network", "info");
      return;
    }
    setActive(true);

    // get nft smartcontract address from env file
    const contract_nft_address = process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV;
    // const contract_auction_address = process.env.REACT_APP_AUCTION_CONTRACT_ADDRESS;
    const contract_auction_address = process.env.REACT_APP_CONTRACT_ADDRESS_DEV;
    // console.log('contract_nft_address',contract_nft_address,contract_auction_address);
    // encoding addition parameter (resale details)
    const basePriceMatic = (basePrice * euroValue).toFixed(4);
    const salePriceMatic = (salePrice * euroValue).toFixed(4);

    const base_price = web3.utils.toWei(basePriceMatic.toString(), "ether");
    const sale_price = web3.utils.toWei(salePriceMatic.toString(), "ether");
    const resale_data = web3.eth.abi.encodeParameters(
      ["uint256", "uint256", "uint256"],
      [purchaseType, base_price, sale_price]
    );

    // creating smartcontract instance
    const nft_contract = await new web3.eth.Contract(
      nft_contract_abi,
      contract_nft_address
    );

    // account is an array of available accounts in connected wallet
    const account = await web3.eth.getAccounts();
    await getBalance();
    const matic_balance=localStorage.getItem("tokenbalance");
    console.log("122 matic_balance ",matic_balance);
    if(Number(matic_balance)<0.2)   { 
      Swal.fire({
        html: "Insufficient funds in your crypto wallet. To proceed with the resale, please ensure that you have a minimum balance of 0.2 Matic in your wallet. Kindly add funds to your wallet before proceeding.",
        icon: "info",
        confirmButtonText: "Topup wallet",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#B8B8B8",
        customClass: {
          confirmButton: "border: none",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenTopUpModal(true);
          // redirected = true;
          // window.open("https://metamask.io/download", "_blank");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setActive(false);
        }
      });
   
     }else{
      try {
        //calculate gasprice
        async function getGasPrice() {
          const gasPrice = await web3.eth.getGasPrice();
          return web3.utils.toBN(gasPrice).add(web3.utils.toBN("20000000000"));
        }
  
        //creating resale function object
        // safeTransferFrom(address from, address to, uint256 tokenId, bytes calldata data) external;
        const resaleMethod = nft_contract.methods.safeTransferFrom(
          account[0],
          contract_auction_address,
          assetId,
          resale_data
        );
  
        let gasEstimated = null;
        let txObject = null;
  
        try {
          const gasPrice = await getGasPrice();
  
          //creating transaction object
          txObject = {
            from: account[0],
            value: 0,
            gasPrice: gasPrice,
          };
          //estimating transaction fee for this function call
          try {
            gasEstimated = await web3.eth.estimateGas({
              to: contract_nft_address,
              data: resaleMethod.encodeABI(),
              ...txObject,
            });
          } catch (error) {
            console.log("resale gas estimation issue : ", { error }, error.code);
            setActive(false);
            console.log("Resale error : ", { error });
            PopUpAlert(
              "Alert!",
              "Transaction has been failed please try again!",
              "error"
            );
            return false;
          }
        } catch (err) {
          setActive(false);
          console.log("Resale error : ", { err });
          PopUpAlert(
            "Alert!",
            "Transaction has been failed please try again!",
            "error"
          );
        }
  
        //sending resale transaction through metamask
        resaleMethod
          .send({ ...txObject, gas: gasEstimated })
          .then(function (result) {
            if (result.status == true) {
              //blockchain transaction is successful and updating resale information to backend
              let config = {
                headers: {
                  Authorization: localStorage.getItem("userToken"),
                },
              };
              if (purchaseType === 1) {
                let body = {
                  resellPrice: details?.salePrice,
                  saleType: "fixed",
                  maticPrice: salePriceMatic,
                };
                axiosInstance
                  .patch(`/asset/publish/${id}`, body, config)
                  .then((res) => {
                    setActive(false);
                    PopUpAlert("Great!", "NFT listed for resale", "success");
                    history.replace("/search01/all");
                  });
              }
              console.log("Resale success!" + result.transactionHash);
            } else if (result.status == false) {
              // blockchain transaction status is failed.
              setActive(false);
              PopUpAlert(
                "Alert!",
                "Transaction has been failed please try again!",
                "error"
              );
            }
          })
          .catch((error) => {
            setActive(false);
            PopUpAlert("Alert!", "User denied transaction signature", "error");
          });
      } catch (err) {
        setActive(false);
        console.log(err);
        if (err.code === 4001) {
          PopUpAlert("Alert!", "Please confirm from your wallet", "error").then(
            (err) => window.location.reload()
          );
        } else {
          PopUpAlert("Alert!", "There is something went wrong", "error").then(
            (err) => window.location.reload()
          );
        }
      }
     }
   
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (details?.salePrice < Number(SERVER_ENVIORNMENT !== "DEVELOPMENT" ? 5 : 1.09)) {
      Swal.fire({
        html: `Minimum of $${SERVER_ENVIORNMENT !== "DEVELOPMENT" ? "5" : "1.09"} is required as resell value`,
        icon: "info",
        confirmButtonText: "Ok",
      })
    }
    else {
      handleResaleProduct(
        details?.assetId,
        details?.currentPrice,
        details?.salePrice,
        1
      );
    }
  };

  const onCloseWidget = () => {
    setActive(false);
    PopUpAlert(
      "Info",
      "Transaction in progress. This process may take some time to complete.",
      "success"
    );
  };

  return (
    // <div className={cn("section", styles.section)}>
    <div className={"container " + styles.container}>
      <div
        className={styles.title}
        style={{
          borderBottom: "0.063rem solid rgb(153, 153, 153)",
          width: "100%",
          padding: "1.25rem 0px",
          marginBottom: "1.25rem",
        }}
      >
        <div
          className={styles.backArrowCircle}
          onClick={() => history.goBack()}
        >
          <img
            src={"/Icons/backArrow.svg"}
            alt="back arrow"
            style={{
              height:"1.25rem",
              width:"1.25rem"
            }}
           
          />
        </div>
        <div className={styles.pageTitle}>Resell asset</div>
      </div>
      <div style={{ marginBottom: 20 }} className={styles.mediaWrapper}>
        {item?.mediaType === "audio" && (
          <div
            className="player-wrapper"
            style={{ position: "relative", paddingTop: 0 }}
          >
            <div>
              {/* <div
                className={styles.imageContainer}
                style={{
                  height: "25em",
                  width: "25em",
                  backgroundImage: `url(${item?.audioThumbnail})`,
                }}
              ></div> */}
              <img
                src={item?.audioThumbnail}
                style={{
                  width: "100%",
                  height: "308px",
                  // objectFit: "cover"
                  objectFit: "scale-down",
                }}
                alt="thumbnail.png"
              />
              <audio
                controls
                controlsList="nodownload"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 35,
                  width: "100%",
                }}
              >
                <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
              </audio>
            </div>
          </div>
        )}
        {item?.mediaType === "video" && (
          <div
            className="player-wrapper"
          // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
          // style={{ position: "relative", paddingTop: "56%" }}
          >
            <video
              id="vid"
              width={screenWidth > 550 ? "350px" : "150px"}
              height={screenWidth > 550 ? "20rem" : "150px"}
              style={{ objectFit: "cover", borderRadius: "0.313rem" }}
              disablePictureInPicture
              controlsList="nodownload"
              loop="true"
              autoplay="true"
              muted
              playsInline
              controls
            >
              <source src={item?.mediaPreviewUrl} />
              Your browser does not support the video.
            </video>
            {/* <ReactPlayer
              url={item?.mediaPreviewUrl}
              width="100%"
              height="100%"
              controls={true}
              light={false}
              style={{ position: "absolute", top: "0", left: "0" }}
              config={{
                file: {
                  attributes: {
                    disablePictureInPicture: true,
                    controlsList: "nodownload",
                  },
                },
              }}
              
            /> */}
          </div>
        )}
        {(item?.mediaType === "image" || item?.mediaType === "3D Model") && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className={styles.imageContainer}
              style={{
                height: "26em",
                width: "26em",
                backgroundImage: `url(${item?.thumbnailUrl || item?.mediaPreviewUrl
                  })`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></div>
            {/* <img
              src={item?.thumbnailUrl || item?.mediaPreviewUrl}
              style={{
                width: "100%",
                height: 180,
                objectFit: "scale-down",
              }}
              alt="thumbnail.png"
            /> */}
          </div>
        )}
        <p style={{ fontSize: "1.125rem", marginTop: 4, fontWeight: 500 }}>
          {item?.purchasedBy
            ? `${item?.name} #${item?.currentEdition}/${item?.totalEdition}`
            : item?.name}
        </p>
      </div>
      <form
        className={cn(styles.form, className)}
        action=""
        onSubmit={handleSubmit}
      >
        <div className={styles.fieldWrapper}>
          <label className={styles.priceField} htmlFor={"currentprice"}>
            Current price (&#36;):{" "}
          </label>
          <div className={styles.inputWrapper}>
            <div>
              <input
                className={styles.input}
                value={details.currentPrice}
                name={"currentprice"}
                disabled
              />
              {/* <Image className={styles.btn} alt="logo.png"
                src="/images/euro.png"
                srcDark="/images/euro.png">
              </Image> */}
            </div>
            <p className={styles.inputETH}>
              <img
                src={`/images/usdc-logo.png`}
                alt="USDC"
                style={{ width: 14, height: 14, margin: "0px 0.25rem" }}
              />
              {/* <img
                src="/images/polygon.svg"
                alt="ETH"
                style={{ width: 18, height: 25, margin: "0px 0.188rem" }}
              /> */}
              {/* {details?.currentPrice
                ? (details.currentPrice * euroValue)?.toFixed(2)
                : 0} */}
                  {details?.currentPrice
                ? (details.currentPrice )
                : 0}
            </p>
          </div>
        </div>
        {/* {details.saleType === "fixed" && */}

        <div className={styles.fieldWrapper}>
          <label className={styles.priceField} htmlFor={"currentprice"}>
            Updated price (&#36;):{" "}
          </label>
          <div className={styles.inputWrapper}>
            <div style={{ position: "relative" }}>
              <input
                className={styles.input}
                value={details.salePrice}
                onChange={(e) =>
                  e.target.value >= 0 &&
                  setDetails({
                    ...details,
                    salePrice: e.target.value,
                  })
                }
                onKeyDown={formatInput}
                name={"salePrice"}
                type="number"
                min="0.000001"
                required
                step="any"
              />
              {/* <Image className={styles.btn} alt="logo.png"
                src="/images/euro.png"
                srcDark="/images/euro.png">
              </Image> */}
            </div>
            <p className={styles.inputETH}>
              {/* <img
                src="/images/polygon.svg"
                alt="ETH"
                style={{ width: 18, height: 25, margin: "0px 0.188rem" }}
              /> */}
              <img
                src={`/images/usdc-logo.png`}
                alt="USDC"
                style={{ width: 14, height: 14, margin: "0px 0.25rem" }}
              />
              {/* {details?.salePrice
                ? (details?.salePrice * euroValue)?.toFixed(2)
                : 0} */}
                  {details?.salePrice
                ? (details?.salePrice )
                : 0}
            </p>
          </div>
        </div>

        {/* } */}
        <div className={styles.fieldWrapper}>
          <label className={styles.priceField} htmlFor={"currentprice"}>
            Sale type:{" "}
          </label>
          <select
            className={styles.input + " " + styles.input123}
            value={details.saleType}
            onChange={handleChange}
            name={"saleType"}
          >
            {/* <option value="auction">Auction</option> */}
            <option value="fixed">Fixed</option>
          </select>
        </div>

        {/* {details.saleType === "auction" &&
          <>
            <div>
              <label className={styles.priceField} htmlFor={"currentprice"}>Opening price: </label>
              <input
                className={styles.input}
                value={details.opening_price}
                name={"opening_price"}
                // disabled
                onChange={handleChange}
              />
            </div>
            <div>
              <label className={styles.priceField} htmlFor={"currentprice"}>Lowest price to accept: </label>
              <input
                className={styles.input}
                value={details.lowest_price}
                name={"lowest_price"}
                // disabled
                onChange={handleChange}
              />
            </div>
            <div>
              <label className={styles.priceField} htmlFor={"currentprice"}>Bid increment: </label>
              <input
                className={styles.input}
                value={details.increment}
                name={"increment"}
                // disabled
                onChange={handleChange}
              />
            </div>
          </>
        } */}

        <button
          className={"button-small"}
          type="submit"
          style={{
            borderRadius: "0.313rem",
            color: "rgb(240, 240, 240)",
            marginLeft: "0.75em",
          }}
        >
          Publish
        </button>
      </form>
      {openTopUpModal ? (
        <TopUpWalletWert
          setOpenTopUpModal={setOpenTopUpModal}
          onCloseWidget={onCloseWidget}
          walletId={walletId}
        />
      ) : (
        ""
      )}
      <Loader
        active={active}
        customize={true}
        title={"Transaction in progress"}
        content={"Listing for resale in progress"}
        backGround={"/images/liiLogoArabic.png"}
      />
    </div>
    // </div>
  );
};

export default ResellForm;
