import { useEffect } from "react";

export const useDebounce = (callback, dependencyArray, delay) => {
	useEffect(() => {
		const handler = setTimeout(() => callback(), delay);

		return () => clearTimeout(handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...(dependencyArray || []), delay]);
};
