import React, { useState, useEffect, useLayoutEffect } from "react";
import styles from "./collectionBannerInfo.module.sass";
import Card from "../../../components/Card";
import { axiosInstance } from "../../../utils/API";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";
import cn from "classnames";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function CollectionBannerInfo() {
  const [collectionDetails, setCollectionDetails] = useState({});
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const status = useSelector((state) => state.counter.value);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const [collectionParam, setCollectionParam] = useState({
    limit: 8,
    page: 1,
  });
  const [screenWidth] = useWindowSize();
  const location = useLocation();

  const collectionCreator = collectionDetails?.collectionCommisionData?.filter(
    (item) => item?.creatorType === "creator"
  );

  useEffect(() => {
    const pathname = location.pathname;
    const id = pathname.split("/").pop();
    if (id) fetchCollectionById(id);
  }, [refresh, collectionParam]);

  // Fetch collection details from BE by Id
  const fetchCollectionById = async (id) => {
    if (status) {
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };

      try {
        const response = await axiosInstance.get(
          `user/getCollection/${id}?page=${collectionParam?.page}&limit=${collectionParam?.limit}`,
          config
        );
        const { data } = response;
        if (data?.result) {
          const { assetsPagination } = data?.result;
          setCollectionDetails(data?.result);
          setCount(assetsPagination?.assetTotalPages);
        } else {
          console.log("No data available.");
        }
      } catch (error) {
        if (error.response) {
          console.log("API Error:", error.response.data);
        } else if (error.request) {
          console.log("Network Error:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      }
    } else {
      try {
        const response = await axiosInstance.get(
          `user/getCollection/${id}?page=${collectionParam?.page}&limit=${collectionParam?.limit}`
        );
        const { data } = response;
        if (data?.result) {
          const { assetsPagination } = data?.result;
          setCollectionDetails(data?.result);
          setCount(assetsPagination?.assetTotalPages);
        } else {
          console.log("No data available.");
        }
      } catch (error) {
        if (error.response) {
          console.log("API Error:", error.response.data);
        } else if (error.request) {
          console.log("Network Error:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      }
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCollectionParam({ ...collectionParam, page: selectedPage + 1 });
  };

  console.log("ssss", status);

  return (
    <div className={styles.CollectionBannerInfoContainer}>
      <div className={styles.collectionContainer}>
        <div className={`${styles.dashBoardWrapper} ${styles.info}`}>
          <div className={styles.imageHolder}>
            {collectionDetails?.imagePath ? (
              <img src={collectionDetails?.imagePath} alt="profile"></img>
            ) : (
              <img src="/images/noimagefound.png" alt="avatar" />
            )}
          </div>
          <div className={styles.collectionInfo}>
            <h2>{collectionDetails?.name}</h2>
            <div className={styles.subText}>
              {collectionDetails?.description}
            </div>
            <table className={styles.infoTable}>
              <tr>
                <td>
                  <h4>Created Date</h4>
                </td>
                <td>
                  <h4>
                    :{" "}
                    {/* {moment(collectionDetails?.createdAt).format("DD-MM-YYYY")} */}
                  </h4>
                </td>
                <td>
                  <h4>
                    {/* {console.log(moment(collectionDetails?.createdAt).format("DD-MM-YYYY"),  moment.utc(collectionDetails?.createdAt).local().format('DD-MM-YYYY'), 'date collection')} */}

                    {moment.utc(collectionDetails?.createdAt).local().format('DD-MM-YYYY')}
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>No of Assets</h4>
                </td>
                <td>
                  <h4>
                    :{" "}
                    {/* {collectionDetails.assets
                      ? collectionDetails?.assetsPagination?.totalCount
                      : "-"} */}
                  </h4>
                </td>
                <td>
                  <h4>
                   
                    {collectionDetails.assets
                      ? collectionDetails?.assetsPagination?.totalCount
                      : "-"}
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>Creator</h4>
                </td>
                <td>
                  <h4>
                    :{" "}
                    {/* {collectionCreator?.[0]?.userData?.firstName
                      ? `${collectionCreator?.[0]?.userData?.firstName} ${collectionCreator?.[0]?.userData?.lastName}`
                      : collectionCreator?.[0]?.userData?.email} */}
                  </h4>
                </td>
                <td>
                  <h4>
                  
                    {collectionCreator?.[0]?.userData?.firstName
                      ? `${collectionCreator?.[0]?.userData?.firstName} ${collectionCreator?.[0]?.userData?.lastName}`
                      : collectionCreator?.[0]?.userData?.email}
                  </h4>
                </td>
              </tr>
            </table>

            {/* <h4>Created Date : </h4>
            <h4>No of Assets :</h4>
            <h4>Creator :</h4> */}
          </div>
        </div>
        <div className={styles.collectionBody}>
          <div className={styles.menuWrapper}>
            <div className={styles.selected}>
              <h1>Assets</h1>
            </div>
          </div>

          <div className={styles.assetContainer}>
            <div className={styles.collectionCardContainer}>
              {collectionDetails?.assets?.length > 0 &&
                collectionDetails?.assets
                  ?.slice(0, 4)
                  .map((x, index) => (
                    <Card
                      className={styles.collectionCard}
                      item={x}
                      key={index}
                      price={euroValue}
                      marketplace={true}
                      setRefresh={() => setRefresh(!refresh)}
                      sourcePage={"collectionDetails"}
                    />
                  ))}
            </div>
            <div className={styles.collectionCardContainer}>
              {collectionDetails?.assets?.length > 4 &&
                collectionDetails?.assets
                  ?.slice(4, 8)
                  .map((x, index) => (
                    <Card
                      className={styles.collectionCard}
                      item={x}
                      key={index}
                      price={euroValue}
                      marketplace={true}
                      setRefresh={() => setRefresh(!refresh)}
                      sourcePage={"collectionDetails"}
                    />
                  ))}
            </div>

            {collectionDetails?.assets?.length === 0 ? (
              <span>No assets found.</span>
            ) : (
              ""
            )}
          </div>
          <div className={styles.paginationContainer}>
            {collectionDetails?.assets?.length > 0 && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={count}
                onPageChange={handlePageClick}
                forcePage={collectionParam.page - 1}
                containerClassName={styles.pagination}
                previousLinkClassName={cn(
                  styles.pagination__link,
                  "page_switch"
                )}
                // previousClassName={cn(styles.pagination__button, "page_switch")}
                nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                pageClassName={"page_no"}
                disabledClassName={styles.pagination__link__disabled}
                activeClassName={styles.pagination__link__active}
                pageRangeDisplayed={screenWidth < 640 ? 1 : 3}
                marginPagesDisplayed={screenWidth < 640 ? 1 : 4}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionBannerInfo;
