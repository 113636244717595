import styles from "./index.module.sass"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { magic } from "../../controller/utils";
import { setMagicUser } from "../../redux/counterSlice";
import { Facebook, Twitter, Google, Discord, Twitch } from "react-bootstrap-icons";
const SocialLogin = () => {
    const magicUser = useSelector((state) => state.counter.magicUser);
    const dispatch = useDispatch()
    useEffect(() => {
        if (!magicUser) {
            magic.user.logout().then((res) => {
                dispatch(setMagicUser(null))
            }
            )
        }
    }, [])
    const handleSocialMediaLogin = async (type) => {
        const didToken = await magic.oauth.loginWithRedirect({
            provider: type,
            redirectURI: `${window.location.origin}/`

        });
    }


    const loginTypes = [
        {
            text: "Login With Google",
            provider: "google",
            image: <Google />
        },
        {
            text: "Login With Facebook",
            provider: "facebook",
            image: <Facebook />
        },
        // {
        //     text: "Login With Discord",
        //     provider: "discord",
        //     image: <Discord />
        // },
        {
            text: "Login With Twitter",
            provider: "twitter",
            image: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>'
        },
        {
            text: "Login With Twitch",
            provider: "twitch",
            image: <Twitch />
        },

    ]


    return (
        <>
            <div className={styles.formHeader}>
                <h3 className="form-header">Social Login</h3>
                <p className={styles.subheading}>Login to lii with your social media accounts</p>
                <div className={styles.buttons}>
                    {loginTypes.map((item, index) => (
                        <>
                            <div className={styles.button} key={index} onClick={() => {
                                handleSocialMediaLogin(item.provider)
                            }}>
                                <div className={styles.image}>
                                    {item.image}
                                </div>

                                <div className={styles.text}>
                                    {item.text}
                                </div>

                            </div>
                        </>


                    ))}
                </div>
            </div>
        </>
    );
}

export default SocialLogin;