import React, { useState, useEffect } from "react";
import styles from "./Auction.module.sass";
import cn from "classnames";
import { useSelector } from "react-redux";
import BidCards from "./BidsCards";

const Auction = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const loginStatus = useSelector((state) => state.counter.value);

  let navLinks = ["Bids active", "Bids won", "Bids lost"];

  return (
    <div className={cn("container", styles.container, "auction")}>
      <div
        className={styles.title}
        style={{
          borderBottom: "0.063rem solid #999",
          width: "100%",
          padding: "1.25rem 0px",
        }}
      >
        Auction
      </div>

      {loginStatus ? (
        <div className={styles.wrapper}>
          <div className={styles.nav}>
            {navLinks.map((x, index) => (
              <button
                className={cn(
                  { [styles.active]: index === activeIndex },
                  styles.link
                )}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div>Login to view Details</div>
      )}
      <BidCards index={activeIndex} />
    </div>
  );
};

export default Auction;
