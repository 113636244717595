import { createSlice } from "@reduxjs/toolkit";


export const profileSlice = createSlice({
    name :"profile",
    initialState : {
        id: "",
        firstName: "",
        lastName: "",
        password: null,
        userName: "",
        email: "",
        publicAddress: "",
        profilePicUrl: null,
        twitterUrl: "",
        facebookUrl: "",
        instagramUrl: "",
        loginWith: "",
        circlePaymentIds: null,
        status: "",
        country: "",
        mobileNumber: "",
        dob: "",
        description: null,
        kycApplicantId: "",
        emailVerified: false,
        kycStatus: -1,
        isBlock: false,
        isDeleted: false,
        isCreatedByAdmin: false,
        coverPicUrl: null,
        createdAt: "",
        updatedAt: "",
        circleWallet: {
            id: '',
            walletId: ""
        },
        totalOrderAmount: 0,
        isKYCLimitExceeded: false
    },
    reducers : {
        setProfileDetails: (state, data) => {
            state.id = data?.payload?.id;
            state.firstName= data?.payload?.firstName;
            state.lastName= data?.payload?.lastName;
            state.password= data?.payload?.password;
            state.userName= data?.payload?.userName;
            state.email= data?.payload?.email;
            state.publicAddress = data?.payload?.publicAddress;
            state.profilePicUrl = data?.payload?.profilePicUrl;
            state.twitterUrl = data?.payload?.twitterUrl;
            state.facebookUrl = data?.payload?.facebookUrl;
            state.instagramUrl = data?.payload?.instagramUrl;
            state.loginWith = data?.payload?.loginWith;
            state.circlePaymentIds = data?.payload?.circlePaymentIds;
            state.status = data?.payload?.status;
            state.country = data?.payload?.country;
            state.mobileNumber = data?.payload?.mobileNumber;
            state.dob = data?.payload?.dob;
            state.description = data?.payload?.description;
            state.kycApplicantId = data?.payload?.kycApplicantId;
            state.emailVerified = data?.payload?.emailVerified;
            state.kycStatus = data?.payload?.kycStatus;
            state.isBlock = data?.payload?.isBlock;
            state.isDeleted = data?.payload?.isDeleted;
            state.isCreatedByAdmin = data?.payload?.isCreatedByAdmin;
            state.coverPicUrl = data?.payload?.coverPicUrl;
            state.createdAt = data?.payload?.createdAt;
            state.updatedAt = data?.payload?.updatedAt;
            state.circleWallet = data?.payload?.circleWallet;
            state.totalOrderAmount = data?.payload?.totalOrderAmount;
            state.isKYCLimitExceeded = data?.payload?.isKYCLimitExceeded;
        }
    }
   
}
)

export const {setProfileDetails} = profileSlice.actions;
export default profileSlice.reducer

