import React from "react";
import styles from "./Billing.module.sass";
import cn from "classnames";

function BillingDetails({ className, items }) {

  return (
    <div className={cn(styles.wrapper, className)}>
    <div className={cn(styles.header, className)}>
      Billing Details details.
    </div>


    
      <table>
        <tr>
          <td>
            <label for="name">Cardholder name</label>
          </td>
          <td>
            <input type="text" className={cn(styles.billingInput, className)} />
          </td>
        </tr>
        <tr>
          <td>
            <label for="email">Address Line1</label>
          </td>
          <td>
            <input type="text" className={cn(styles.billingInput, className)} />
          </td>
        </tr>
        <tr>
          <td>
            <label for="telnum">Address Line 2</label>
          </td>
          <td>
            <input type="text" className={cn(styles.billingInput, className)} />
          </td>
        </tr>
        <tr>
          <td>
            <label for="Roll No.">Postal Code</label>
          </td>
          <td>
            <input
              type="number"
              className={cn(styles.billingInput, className)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="City">City</label>
          </td>
          <td>
            <input type="text" className={cn(styles.billingInput, className)} />
          </td>
        </tr>
        <tr>
          <td>
            <label for="District">District</label>
          </td>
          <td>
            <input type="text" className={cn(styles.billingInput, className)} />
          </td>
        </tr>
        <tr>
          <td>
            <label for="Roll No.">Country code</label>
          </td>
          <td>
            <input type="text" className={cn(styles.billingInput, className)} />
          </td>
        </tr>
        <tr>
          <td>
            <label for="phoneno">Phone</label>
          </td>
          <td>
            <input
              type="number"
              className={cn(styles.billingInput, className)}
            />
          </td>
        </tr>
      </table>
      {/* <TextField required id="standard-required" label="Cardholders name" />
      <TextField required id="standard-required" label="Address Line 1" />
      <TextField required id="standard-required" label="Address Line 2" /> */}
    </div>
  );
}

export default BillingDetails;
