import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";

import styles from "./PlaceBid.module.sass";
import { PopUpAlert } from "../../controller/utils";
import useWindowSize from "../../common/hooks/useWindowSize";
import PaymentOptionModal from "../../components/PaymentOptionModal/PaymentOptionModal";
import { useHistory } from "react-router-dom";
// import Countdown from "react-countdown";

const PlaceBid = (props) => {
  const {
    setBidConfirmation,
    productDetails,
    currentBidAmount,
    bidInfo,
    onBid,
  } = props;

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [screenWidth] = useWindowSize();
  const circleBalance = useSelector((state) => state.circlePayment.balance);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const userAccount = useSelector((state) => state?.counter?.user);
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  const usdcWalletBalance = useSelector((state) => state?.usdc?.amount)
  const [open, setOpen] = useState(false);
  const loginWith = localStorage.getItem("network");

  const history = useHistory();


  useEffect(() => {
    const timegetter = setInterval(() => {
      if (timeLeft(productDetails?.auction?.endingDate)) {
        const { days, hours, minutes, seconds } = timeLeft(
          productDetails?.auction?.endingDate
        );
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    }, 1000);
    return () => clearInterval(timegetter);
  }, [productDetails?.auction?.endingDate]);

  const timeLeft = (endingDate) => {
    if (endingDate) {
      var now = new Date();
      var diff = new Date(endingDate) - now;
      var days = Math.floor(diff / 8.64e7);
      var hours = Math.floor((diff % 8.64e7) / 3.6e6);
      var minutes = Math.floor((diff % 3.6e6) / 6e4);
      var seconds = Math.floor((diff % 6e4) / 1000);

      if (days > 0) {
        return { days, hours, minutes, seconds };
      }

      if (hours < 0 || minutes < 0 || seconds < -1) {
        // setExpired(true);
        return {days:0, hours: 0, minutes: 0, seconds: 0 };
      }
      return { days:0, hours, minutes, seconds };
    }
    return null;
    
  };

  const openPaymentModal = () => {
    setOpen(true);
  };
  const handleBid = (e) => {
    e.preventDefault();
    if (isNaN(currentBidAmount)) {
      PopUpAlert(
        "Alert",
        `Bid amount must be greater than or equal to
        ${
          ((productDetails?.auction?.currentBid + (productDetails?.auction?.currentBid*0.1)).toFixed(2)||
          productDetails?.auction?.openingPrice).toFixed(2)
        }`,
        "error"
      );
      return;
    }
    if (productDetails?.auction?.bidIncrement) {
      if (
        currentBidAmount <
        (productDetails?.auction?.currentBid ||
          productDetails?.auction?.openingPrice) +
          productDetails?.auction?.bidIncrement
      ) {
        PopUpAlert(
          "Alert",
          `Bid amount must be ${
            ((productDetails?.auction?.currentBid ||
              productDetails?.auction?.openingPrice) +
            productDetails?.auction?.bidIncrement).toFixed(2)
          } or greater.`,
          "error"
        );
        return;
      }
    } else {
      if (productDetails?.auction?.currentBid && currentBidAmount < (productDetails?.auction?.currentBid + (productDetails?.auction?.currentBid*0.1))) {
        PopUpAlert(
          "Alert",
          `Bid amount must be greater than or equal to
        ${
          (productDetails?.auction?.currentBid + (productDetails?.auction?.currentBid*0.1)).toFixed(2)
        }`,
          "error"
        );
        return;
      } else if(!productDetails?.auction?.currentBid && currentBidAmount < productDetails?.auction?.openingPrice){
        PopUpAlert(
          "Alert",
          `Bid amount must be greater than or equal to
        ${(productDetails?.auction?.openingPrice).toFixed(2)}`,
          "error"
        );
        return;
      }
    }

    onBid(
      (currentBidAmount * euroValue)?.toFixed(4),
      productDetails?.id,
      currentBidAmount
    );
  };
  // const [productDetails, setProductDetails] = useState({
  //   mediaType: "video",
  // });

  const getHeading = () => {
    return (
      <div className={styles.col_1}>
        <h1 className={styles.placeABidHeading}>Place a bid</h1>
        <h5 className={styles.placeABidSubHeading}>Auction</h5>
      </div>
    );
  };

  const getOwnerAndArtist = (label, userName, userId) => {
    return (
      <div onClick = {() => {
        history.push("/viewProfile/" + userId);
      }}>
      <div className={styles.rows}>
        <div className={styles.avatar}>
          <img src="/images/content/Avatar.png" alt="avatar" />
        </div>
        <div className={styles.creator}>
          <h5>{label}</h5>
          <div className={styles.creatorName}>@{userName}</div>
        </div>
      </div>
      </div>
    );
  };

  const getAsset = () => {
    return (
      <div>
        {productDetails?.mediaType === "audio" && (
          <div
            className={styles.playerwrapper}
            style={{
              position: "relative",
              paddingTop: 0,
              width: screenWidth > 766 ? "100%" : "150px",
              height: screenWidth > 766 ? "32.5rem" : "150px",
            }}
          >
            {/* <div> */}
            <img
              src={productDetails?.audioThumbnail}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderTopLeftRadius: "0.625rem",
                borderTopRightRadius: "0.625rem",
              }}
              alt="thumbnail.png"
            />
            <audio
              controls
              controlsList="nodownload"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                height: 25,
                width: "100%",
              }}
            >
              <source src={productDetails?.mediaPreviewUrl} type="audio/mpeg" />
            </audio>
            {/* </div> */}
          </div>
        )}
        {productDetails?.mediaType === "video" && (
          <div
            className={styles.playerwrapper}
            // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
            style={{
              position: "relative",
              paddingTop: 0,
              width: screenWidth > 766 ? "100%" : "150px",
              height: screenWidth > 766 ? "32.5rem" : "150px",
            }}
          >
            <ReactPlayer
              url={productDetails?.mediaPreviewUrl}
              //   url={productDetails?.mediaPreviewUrl}
              width="100%"
              height="100%"
              controls={true}
              light={false}
              // style={{ position: "absolute", top: "0", left: "0" }}
              className={styles.videoWrap}
              config={{
                file: {
                  attributes: {
                    disablePictureInPicture: true,
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        )}
        {(productDetails?.mediaType === "image" ||
          productDetails?.mediaType === "3D Model") && (
          <div
            style={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              width: screenWidth > 766 ? "100%" : "150px",
              height: screenWidth > 766 ? "32.5rem" : "150px",
            }}
            className={styles.playerwrapper}
          >
            <img
              src={
                productDetails?.thumbnailUrl || productDetails?.mediaPreviewUrl
              }
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderTopLeftRadius: "0.625rem",
                borderTopRightRadius: "0.625rem",
                borderBottomRightRadius: screenWidth > 766 ? "0px" : "0.625rem",
                borderBottomLeftRadius: screenWidth > 766 ? "0px" : "0.625rem",
              }}
              alt="thumbnail.png"
            />
          </div>
        )}
        {/* <span style={{ marginLeft: 0 }}>
    {"Product Name: " + productDetails?.name}
  </span> */}
      </div>
    );
  };

  const getProductName = () => {
    return (
      <div className={styles.productTitle}>
        {productDetails?.name ? productDetails?.name : "-"}
      </div>
    );
  };

  const getBidInfo = () => {
    return (
      <>
        {bidInfo?.currentBidderInfo ? (
          <>
            <div className={styles.bidPlacedBy}>
              Highest bid placed by{" "}
              <span>
                @
                {bidInfo?.currentBidderInfo
                  ? bidInfo?.currentBidderInfo?.firstName
                  : "-"}{" "}
              </span>
            </div>
            <div className={styles.bidPlacedAmount}>
              <div className={styles.bidPlacedAmountCrypto}>
                $ {bidInfo?.bids ? bidInfo?.bids?.[0]?.amount : "-"}
              </div>
              <div className={styles.bidPlacedAmountDollar}>
                {/* {bidInfo?.bids
                  ? (bidInfo?.bids?.[0]?.amount * euroValue)?.toFixed(2)
                  : "-"}{" "} */}
                  {bidInfo?.bids
                  ? (bidInfo?.bids?.[0]?.amount )
                  : "-"}{" "}
                <img
                  src={`/images/usdc-logo.png`}
                  alt="USDC"
                  className={styles.maticSymbol}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.bidPlacedBy}>No bids received.</div>
        )}
      </>
    );
  };

  const getTime = () => {
    return (
      <>
        <div className={styles.auctionEndLabel}>Auction ends in</div>
        <div className={styles.auctionEndTimer}>
        <div className={styles.days}>
            <div className={styles.auctionTimerContent}>{days}</div>
            <div className={styles.auctionTimerLabel}>Days</div>
          </div>
          <div className={styles.hours}>
            <div className={styles.auctionTimerContent}>{hours}</div>
            <div className={styles.auctionTimerLabel}>Hours</div>
          </div>
          <div className={styles.minutes}>
            <div className={styles.auctionTimerContent}>{minutes}</div>
            <div className={styles.auctionTimerLabel}>Minutes</div>
          </div>
          <div className={styles.seconds}>
            <div className={styles.auctionTimerContent}>{seconds}</div>
            <div className={styles.auctionTimerLabel}>Seconds</div>
          </div>
        </div>
      </>
    );
  };

  const getBidButton = () => {
    return (
      <>
        <button
          className={styles.buttonFullWidth_buynow}
          onClick={openPaymentModal}
        >
          <span>Place a bid</span>
        </button>
        <div className={styles.warningMessage}>
          Users cannot participate in an auction using their credit cards, you
          can either use crypto wallet (MATIC) or cash wallet (USD) for the
          bidding.
        </div>
      </>
    );
  };

  const getYourBid = () => {
    return (
      <div className={styles.col_4}>
        <div className={styles.amountLabel}>Your bid</div>
        <div className={styles.totalAmount}>
          <span className={styles.amountUnit}>$</span>
          <span className={styles.amountNumbers}>
            {currentBidAmount ?? currentBidAmount}
          </span>
        </div>
        <div className={styles.amountBalance}>
          {/* <span className={styles.balanceLabel}>
            {" "}
            {screenWidth > 766 ? (
              <>Your crypto wallet balance</>
            ) : (
              <>Your balance</>
            )}
          </span> */}
          {/* <span className={styles.amountBalanceNumber}>
            {loginWith == "metamask"
              ? userAccount?.balance
                ? Number(userAccount?.balance)?.toFixed(2)
                : "0"
              : Number(
                  magicLinkUser?.magicBalance
                    ? Number(magicLinkUser?.magicBalance).toFixed(2)
                    : "0"
                )}
            <img
              src={`/images/usdc-logo.png`}
              alt="USDC"
              className={styles.maticSymbol2}
            />
          </span> */}
        </div>
        {/* <div className={styles.amountBalance}>
          <span className={styles.balanceLabel}>
            {" "}
            {screenWidth > 766 ? (
              <>Your cash wallet balance</>
            ) : (
              <>Cash balance</>
            )}
          </span>
          <span className={styles.amountBalanceNumber}>
            <span>{circleBalance ? circleBalance : "0"}</span>{" "}
            $
          </span>
        </div> */}
      </div>
    );
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row_1}>
          <div
            className={styles.backArrowCircle}
            onClick={() => setBidConfirmation(false)}
          >
            <img
              src={"/Icons/backArrow.svg"}
              alt="back arrow"
              style={{
                height:"1.25rem",
                width:"1.25rem"
              }}
            />
          </div>
        </div>

        {screenWidth > 766 ? (
          <>
            <div className={styles.row_2}>
              {getHeading()}
              <div className={styles.col_2}>{getBidInfo()}</div>
              <div className={styles.col_3}>{getTime()}</div>
              {getYourBid()}
              {getBidButton()}
            </div>
            <div className={styles.row_3}>
              <div className={styles.productDetails}>
                {getAsset()}
                <div className={styles.productInfo}>
                  {getProductName()}
                  <div className={styles.additionalDetails}>
                    {getOwnerAndArtist(
                      "Owner",
                      productDetails?.currentOwner?.userName
                        ? productDetails?.currentOwner?.userName
                        : "-",
                        productDetails?.currentOwner?.id
                    )}
                    {
                      productDetails?.userAssetCommission?.map((item)=> {
                        if(item?.creatorType=='artist' || item?.creatorType=='coCreator' )
                        return getOwnerAndArtist(
                          "Artist",
                          item?.User?.userName || item?.User?.email
                            ? item?.User?.userName || item?.User?.email
                            : "-",
                            item?.userId
                        )
                        
                        })
                    }
          
                    
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {getHeading()}
            <div className={styles.mobile_image_container}>
              {getAsset()}
              {getProductName()}
            </div>
            <div className={styles.additionalDetails}>
              {getOwnerAndArtist(
                "Owner",
                productDetails?.currentOwner?.userName
                  ? productDetails?.currentOwner?.userName
                  : "-",
                  productDetails?.currentOwner?.id
              )}
              {  productDetails?.userAssetCommission?.map((item)=> {
                        if(item?.creatorType=='artist' || item?.creatorType=='coCreator' )
                        return getOwnerAndArtist(
                          "Artist",
                          item?.User?.userName || item?.User?.email
                            ? item?.User?.userName || item?.User?.email
                            : "-",
                            item?.userId
                        )
                        
                        })}
            </div>
            <div className={styles.mobile_col_2}>{getBidInfo()}</div>
            <div className={styles.mobile_col_3}>{getTime()}</div>
            <div style={{ paddingTop: "1rem" }}>{getYourBid()}</div>
            {getBidButton()}
          </>
        )}
      </div>
      <PaymentOptionModal
        open={open}
        setOpen={setOpen}
        productDetails={productDetails}
        handleBid={handleBid}
        bidAmountEth={(currentBidAmount * euroValue)?.toFixed(4)}
        bidAmountEuro={currentBidAmount}
        cashWalletBalance={
          userAccount?.balance ? Number(circleBalance)?.toFixed(3) : "0"
        }
        cryptoBalance={
          loginWith == "metamask"
            ? usdcWalletBalance
              ? Number(usdcWalletBalance)?.toFixed(2)
              : "0"
            : Number(
              usdcWalletBalance
                  ? Number(usdcWalletBalance)?.toFixed(2)
                  : "0"
              )
        }
        // id={id}
        // setActive={setActive}
        // account={account}
        // balance={balance}
        // transactionFlag={transactionFlag}
        // setTransactionFlag={setTransactionFlag}
        // setSuccessShow={setSuccessShow}
        // confirmOrder={confirmOrder}
        // getProductDetails={getProductDetails}
      />
    </div>
  );
};

export default PlaceBid;
