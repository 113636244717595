import React, { useEffect, useState } from "react";
import styles from "./VerifyKYCButton.module.sass";
import useSumsubWebSDK from "../../customHooks/useSumsubWebSDK";
import { axiosInstance } from "../../utils/API";
// import FullScreenModal from "../../common/FullScreenModal/FullScreenModal";
import FullScreenModal from "../../common/FullScreenModal/FullScreenModal";
import SumSubWidget from "./SumSubWidget";
import TransparentModal from "../../components/TransparentModal";

function VerifyKYCButton({ KYCModalOpen, setKYCModalOpen, mode }) {
  useSumsubWebSDK();
  const [isKycComplete, setKycComplete] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [mountKYCModal, setMountKYCModal] = useState(false);
  const [mountLoadingModal, setMountLoadingModal] = useState(false);

  let config;
  if (localStorage.getItem("userToken")) {
    config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
  }

  useEffect(() => {
    if (setKYCModalOpen) {
      setMountLoadingModal(true)
      fetchNewAccessTokenFromBackend(config);
      setTimeout(() => {
        setMountKYCModal(true);
        setMountLoadingModal(false)
      }, 2000);
    }
  }, [setKYCModalOpen]);

  const handleKycVerificationSuccess = () => {
    setKycComplete(true);
  };

  const fetchNewAccessTokenFromBackend = async (config) => {
    const apiEndPoint =
      mode === "kyc" ? "kyc/create/token" : "/email/create/token";
    try {
      const response = await axiosInstance.post(apiEndPoint, "", config);
      if (response.status === 200) {
        setAccessToken(response.data.result.token);
        // return response.data.result.token;
      } else {
        // Handle any other status codes or error scenarios as needed
        throw new Error("Failed to fetch new access token from the backend.");
      }
    } catch (error) {
      console.error("Error fetching new access token:", error);
      throw error;
    }
  };

  if(mountLoadingModal) {
    return <TransparentModal/>
  }

  return (
    <>
      {/* Conditionally render the FullScreenModal */}
      {mountKYCModal && !isKycComplete && (
        <FullScreenModal
          onClose={() => setKYCModalOpen(false)}
          forceOpen={KYCModalOpen}
        >
          <SumSubWidget
            fetchNewAccessTokenFromBackend={fetchNewAccessTokenFromBackend}
            accessToken={accessToken}
            onKycVerificationSuccess={handleKycVerificationSuccess}
          />
        </FullScreenModal>
      )}

      {/* Show a success message after KYC verification is complete */}
      {isKycComplete && <p>KYC Verification Complete!</p>}
    </>
  );
}

export default VerifyKYCButton;
