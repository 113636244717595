import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 970,
  },
  desc:{
    width:'200px'
  },
  col:{
    fontWeight:'bold',

  }
});



const col = ['Asset Name', "Commission Amount", "Commission Type", "Status","Remark" ]




export default function CustomTable(props) {
  const classes = useStyles();
  const {data} = props
 

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          
            
            {col.map(e=>
            <TableCell className={classes.col} align="center">{e}</TableCell>
                )}
           
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center" component="th" scope="row">
              <a
                  href={`
                  ${process.env.REACT_APP_POLYGON_LINK}tx/${row?.order?.orderTx}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { row?.asset.currentEdition ?
                    row?.asset?.name :
                    // row?.asset?.name + " #" + row?.asset?.currentEdition+ "/" +row?.asset?.totalEdition:
                    row?.asset?.name }
              </a>
              </TableCell>
              
             
              <TableCell align="center">$
                        {row?.paidAmount
                          ? `${row?.paidAmount} (${row?.userAssetCommission?.percentage}%)`
                          : "-"}
              </TableCell>
              <TableCell align="center">
                {row?.userAssetCommission?.type}
              </TableCell>
                        {/* <TableCell align="right">$
                        
                        {row?.userAssetCommission?.capAmount
                          ? Number(row?.userAssetCommission?.capAmount)?.toFixed(
                              4
                            )
                          : "-"}
                        </TableCell> */}
                        <TableCell align="center">
                        
                        {row?.status}
                        </TableCell>
                        <TableCell align="center" width = '30%'>
                        
                        {row?.remark ? row?.remark : "-"}
                        </TableCell>
                       
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}