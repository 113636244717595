import styles from "./about.module.sass";
import cn from "classnames";

const About = () => {
	return (
		<div className={cn("section", styles.section)}>
			<div className="container">
				<h2>About lii</h2>
			</div>
			<div className={cn("container", styles.container)}>
				{/* <h2>About lii</h2>
        <div className="about section" >
          lii is the digital marketplace for  
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore 
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip 
          ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse 
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia 
          deserunt mollit anim id est laborum and it utilizes Polygon Layer 2 Ethereum.
        </div> */}
				<div className={styles.aboutSection}>
					<div className={styles.aboutText}>
						<p>
							lii is the digital marketplace for Lorem ipsum dolor sit amet,
							consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
							labore et dolore magna aliqua. Ut enim ad minim veniam, quis
							nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
							occaecat cupidatat non proident, sunt in culpa qui officia
							deserunt mollit anim id est laborum and it utilizes Polygon Layer
							2 Ethereum.
						</p>
					</div>

					<div className={styles.count}>
						<div className={styles.values}>
							<p>Total artist</p>
							<h5>10,000</h5>
						</div>
						<div className={styles.values}>
							<p>Total user</p>
							<h5>40,000</h5>
						</div>
						<div className={styles.values}>
							<p>Artworks done</p>
							<h5>10,040</h5>
						</div>
					</div>
				</div>
				<div className={styles.sampleCards}>
					<div className={cn(styles.col, styles.one)}>
						<div className={styles.sampleNFT}>
							<img
								src="/images/content/samplenft.png"
								alt="sampleNFT"
								className={styles.assetImg}
							/>
							<div className={styles.userCard}>
								<img src="/images/content/Avatar.png" />

								<div>
									<p>Owned by</p>
									<h5>@username</h5>
								</div>
							</div>
						</div>
					</div>
					<div className={cn(styles.col, styles.two)}>
						<div className={styles.sampleNFT}>
							<img
								src="/images/content/samplenft2.png"
								alt="sampleNFT"
								className={styles.assetImg}
							/>
							<div className={styles.userCardTwo}>
								<img src="/images/content/Avatar.png" />

								<div>
									<p>Owned by</p>
									<h5>@username</h5>
								</div>
							</div>
						</div>
					</div>
					<div className={cn(styles.col, styles.one)}>
						<div className={styles.sampleNFT}>
							<img
								src="/images/content/samplenft.png"
								alt="sampleNFT"
								className={styles.assetImg}
							/>
							<div className={styles.userCard}>
								<img src="/images/content/Avatar.png" />

								<div>
									<p>Owned by</p>
									<h5>@username</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
