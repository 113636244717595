import React from "react";
import cn from "classnames";
import styles from "./CirclePayment.module.sass";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardTransfer from "./Deposit/CardPayment";
import QrcodeTransfer from "./cryptoPayment";
import BankTransfer from "./Deposit/BankTransfer";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  MuiAppBarRoot: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    boxShadow: "none !important",
    border: "none",
  },

  MuiTabRoot: {
    padding: "0.375rem 0.75rem",
    overflow: "hidden",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "center",
    flexShrink: "0",
    fontWeight: "500",
    whiteSpace: "normal",
    minWidth: "0px",
    color: "#BFBFBF",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontFamily: "'Poppins'",
    lineHeight: "1.5rem",
    textTransform: "capitalize",
    height: "auto",
  },

  tabs: {
    "& MuiPaper-root.MuiAppBar-root": {
      width: "45%",
      border: "0.063rem solid #ffffff",
      borderRadius: "0.313rem",
    },

    "& .MuiTabs-flexContainer": {
      background: "#FFFFFF",
      color: "#000000",
      display: "flex",
      padding: "0.438rem 0px",
      justifyContent: "space-evenly",
      boxShadow: "0px 0.563rem 0.938rem rgb(0 0 0 / 8%)",
      border: "0.063rem solid #ffffff",
      borderRadius: "0.375rem",
    },

    "& .MuiTabs-fixed": {
      // background: "#FFFFFF",
      color: "#000000",
      display: "flex",
      justifyContent: "space-evenly",
      // boxShadow: "0px 0.563rem 0.938rem rgb(0 0 0 / 8%)",
    },

    "& .MuiButtonBase-root.MuiTab-root": {
      fontFamily: "'Poppins'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "0.75rem",
      lineHeight: "1.5rem",
      textTransform: "capitalize",
      color: "#BFBFBF;",
    },
    "& .MuiTabs-indicator": {
      display: "none",
      //backgroundColor: "orange"
    },
    "& .Mui-selected": {
      background: "#000000",
      borderRadius: "0.625rem",
      // textDecoration: "underline"
    },
    boxShadow: "none",
    background: "#FFFFFF",
  },
  tabTwo: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: "green",
    },
  },
  tabThree: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: "yellow",
    },
  },
  tabPanel: {
    backgroundColor: "rgba(1,1,1,0.1)",
    paddingTop: 12,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Deposit({ closePopup, refreshPage, setRefreshPage }) {
  const [tabSelected, setTabSelected] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  return (
    <div className={styles.depoistPopupContainer}>
      <div className={cn("transferWrapper", styles.transferWrapper)}>
        <div className={styles.TransferModal} style={{ color: "#000000" }}>
          <div className={styles.depositContainer}>
            <div className={styles.TransferModalTabSection}>
              <div className={styles.TransferModalHeader}>
                <span className={styles.transferModalLabel}>Deposit</span>
                <span
                  className={styles.transferModalCloseButton}
                  onClick={() => closePopup()}
                >
                  x
                </span>
              </div>
              <div className={styles.transferModalDescription}>
                Select a method to make a deposit
              </div>
              <div className={styles.transferModalTab}>
                <AppBar
                  position="static"
                  color="default"
                  className={classes.tabs}
                >
                  <Tabs
                    value={tabSelected}
                    onChange={handleChange}
                    className={classes.MuiAppBarRoot}
                  >
                    {/* <Tab
                      label="Wire/Bank Transfer"
                      {...a11yProps(0)}
                      disabled={true}
                    /> */}
                    <Tab label="Debit/Credit card" {...a11yProps(1)} />
                    <Tab label="Crypto" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={tabSelected} index={-1}>
                  <BankTransfer
                    setRefreshPage={setRefreshPage}
                    refreshPage={refreshPage}
                  />
                </TabPanel>
                <TabPanel value={tabSelected} index={0}>
                  <CardTransfer
                    closePopup={() => closePopup()}
                    setRefreshPage={setRefreshPage}
                    refreshPage={refreshPage}
                  />
                </TabPanel>
                <TabPanel value={tabSelected} index={1}>
                  <QrcodeTransfer
                    setRefreshPage={setRefreshPage}
                    refreshPage={refreshPage}
                  />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
