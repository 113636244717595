import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../common/hooks/useWindowSize";
import {
  formatPrice,
  PopUpAlert,
  contract_auction_abi,
  contract_erc20token_abi,
  maticWeb3,
  connector,
  getGasPrice,
  getBalance,
  getWeb3Provider,
  checkNetwork,
} from "../../controller/utils";
import styles from "../../screens/Item/Item.module.sass";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  decrement,
  updateCurrentPaymentMethord,
} from "../../redux/counterSlice";
import { axiosInstance } from "../../utils/API";
import Loader from "../../screens/Item/Loader";
import SuccessPopup from "../../screens/Item/SuccessPopup";
import { Alert } from "@material-ui/lab";
import WertWidgetDiv from "../../screens/WertPayment/wertwidget";

const PaymentOptionModal = (props) => {
  const {
    productDetails,
    // setSuccessShow,
    getProductDetails,
    open,
    setOpen,
    handleBid,
    bidAmountEth,
    bidAmountEuro,
    cashWalletBalance,
    cryptoBalance,
  } = props;
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [screenWidth] = useWindowSize();
  const [assetType, setAssetType] = useState("fixed");
  const [successShow, setSuccessShow] = useState(false);
  const [openWertModal, setOpenWertModal] = useState({
    active: false,
    mode: "",
    bidAmountFiat: "",
    bidAmountCrypto: "",
    bidAmountFiatWEI: "",
  });
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const magicLinkActive = useSelector(
    (state) => state.counter?.magicUser?.email || ""
  );
  const accountDetails = useSelector((state) => state.accountReducer);
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  const userEmail = useSelector((state) => state?.accountReducer?.email);
  // const userAccount = useSelector((state) => state?.counter?.user?.account);
  const isUserVerified = useSelector((state) => state?.accountReducer?.status);
  const isLogin = useSelector((state) => state.counter.value);
  const walletStatus = useSelector((state) => state.counter.value);
  let circleBalance = useSelector((state) => state.circlePayment.balance);
  const dispatch = useDispatch();
  const [successPopupAmount, setSuccessPopupAmount] = useState(0);
  const currentPaymentType = useSelector(
    (state) => state.counter.currentPaymentType
  );
  let loginWith = localStorage.getItem("network");

  const config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };
  let check;
  // call this to Disable
  function disableScroll() {
    document.body.style.overflow = "hidden";
  }

  // call this to Enable
  function enableScroll() {
    document.body.style.overflow = "scroll";
  }
  const checkbalance = (balance) => {
    return balance >= productDetails.salePrice ? true : false;
  };
  const callSetSuccessShow = (saleType)=>{
    setSuccessShow(true);
    setAssetType(saleType)
  }
  
  const wertHookSubstitueAuction = (click_id, transaction_id) => {
    let url = `wert/auction`;

    const postData = {
      click_id,
      transaction_id
    };

    axiosInstance
      .post(url, postData, config)
      .then((res) => {
        console.log('wertHookSubstitueAuction call success ',res);
      })
      .catch((err) => {
        console.error('wertHookSubstitueAuction call failed ',err);
      });
  }

  const closeSuccessModal = () => {
    setSuccessShow(false);
    if (assetType === "fixed") {
      // setTransactionFlag(!transactionFlag);
      window.location.reload();
    } else {
      history.push('/dashboard?tab=2&atab=0')
    }
  };
  const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

  const handleBidWertCard = async (mode, fiatAmount, cryptoAmount) => {
    console.log('fiatAmount', fiatAmount)
    if (
      Number(SERVER_ENVIORNMENT === "DEVELOPMENT" || SERVER_ENVIORNMENT === "UAT" ? 5 : 1.09) >
      fiatAmount
    ) {
      return PopUpAlert(
        "Alert",
        `Minimum of $${SERVER_ENVIORNMENT !== "Production" ? "5" : "1.09"
        } required to proceed with card payment`,
        "error"
      );
    }


    let web3 = await getWeb3Provider();
    let fiatPriceWEI = web3.utils.toWei(fiatAmount.toString(), "ether");
    let cryptoPriceWEI = web3.utils.toWei(cryptoAmount.toString(), "ether");
    console.log("fiatPrice", fiatPriceWEI, cryptoPriceWEI);
    if (
      !accountDetails?.firstName ||
      !accountDetails?.lastName ||
      !accountDetails?.email
    ) {
      PopUpAlert(
        "Profile Incomplete.",
        "Please complete your profile.",
        "info"
      ).then(() => {
        enableScroll();
        history.push("/my-account");
      });
    } else {
      setOpenWertModal({
        active: true,
        mode: "cardPayment",
        bidAmountFiat: fiatAmount,
        bidAmountCrypto: cryptoPriceWEI,
        bidAmountFiatWEI: fiatPriceWEI,
      });
      setActive(true);
    }
  };

  async function processWertResult(result) {
    let { click_id, tx_id } = result;
    if (!tx_id) tx_id = "";
    // if (click_id) {
      let url;

      // if (result.status == true) {
      console.log("=== Wert Auction Bid ", tx_id);

      url = `asset/auction/bid/${productDetails?.id}`;
      // } else if (result.status == false) {
      //   url = `asset/auction/bid/${productDetails?.id}?isFailed=true`;
      //   console.log("error in after", result);
      //   PopUpAlert("Alert!", "Transaction has failed. Please try again!!", "error").then(() => enableScroll());
      //   return;
      // }

      const postData = {
        bidAmountEth: bidAmountEth.toString(),
        bidAmountEuro: bidAmountEuro,
        type: "card",
        txHash: tx_id,
        click_id: click_id,

      };
      let returnValue = false;
      await axiosInstance
        .post(url, postData, config)
        .then((res) => {
          returnValue = true;
          setSuccessShow(false);
          setSuccessPopupAmount(bidAmountEuro);
          enableScroll();
          setActive(false);
        })
        .catch((err) => {
          setActive(false)
          PopUpAlert("Alert!", err.response?.data?.message, "error").then(
            () => {
              enableScroll();
              window.location.reload();
            }
          );
        });
    // } else {
    //   setActive(false)
    // }
    setActive(false);
    return { returnValue } // this return is to satisfy with the processwert of fixed assset

  }

  const setWertLoaded = (data) => {
    console.log("Wert loaded sucessfully", data);
    setActive(false);
  };


  const handleWalletAuctionCheckout = async (paymentType) => {
    disableScroll();
    setAssetType("fixed");

    setSuccessPopupAmount(bidAmountEuro);
    let magiclink = magicLinkActive ? true : false;
    if (magiclink && paymentType != "wallet") paymentType = "magiclink";
    if (
      !accountDetails?.firstName ||
      !accountDetails?.lastName ||
      !accountDetails?.email
    ) {
      PopUpAlert(
        "Profile Incomplete.",
        "Please complete your profile.",
        "info"
      ).then(() => {
        enableScroll();
        history.push("/my-account");
      });
    } else {
      setOpen(false);

      let web3 = await getWeb3Provider();

      const chainId = await web3.eth.getChainId();
      const networkVerify = await checkNetwork(chainId);
      if (!networkVerify) {
        return false;
      }

      setActive(true);
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };
      let body = {
        bidAmountEth: bidAmountEth,
        bidAmountEuro: bidAmountEuro,
        txHash: null,
        type: "wallet",
      };

      try { 
        await axiosInstance
          .post(`/asset/auction/bid/${productDetails.id}`, body, config)
          .then((response) => {
            console.log("response", response);
            setActive(false);
            setSuccessShow(true);
          })
          .catch((err) => {
            PopUpAlert("Alert", err.response?.data?.message, "error").then(
              (res) => {
                if (
                  err.response?.data?.message ===
                  "Your wallet does not have sufficient funds"
                ) {
                  history.push("/dashboard");
                }
                enableScroll();
              }
            );
            setActive(false);
            enableScroll();
            getProductDetails();
            return;
          });
      } catch (error) {
        enableScroll();
      }
    }
  };

  const setPaymentTypeForCrypto = () => {
    let loginWith = localStorage.getItem("network");
    dispatch(updateCurrentPaymentMethord(loginWith));
  };


  return (
    <div>
      {" "}
      <div
        className={styles.checkout + " " + styles.completeCheckout}
        style={{
          display: open ? "flex" : "none",
          position: "fixed",
          overflow: "hidden",
        }}
        onClick={() => setOpen(false)}
      >
        <div
          className={styles.checkoutInner}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2>Complete checkout</h2>
          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => setOpen(false)}
          >
            <img src={"/images/closeIcon.png"} alt={"closeicon"} />
          </button>
          <div className={styles.checkoutDetail}>
            {/* <div>
          <p>Item</p>
        </div> */}
            <div>
              {productDetails?.mediaType === "audio" && (
                <div
                  className={styles.playerwrapper}
                  style={{
                    position: "relative",
                    paddingTop: 0,
                    width: screenWidth > 550 ? "200px" : "150px",
                    height: screenWidth > 550 ? "165px" : "150px",
                  }}
                >
                  {/* <div> */}
                  <img
                    src={productDetails?.audioThumbnail}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="thumbnail.png"
                  />
                  <audio
                    controls
                    controlsList="nodownload"
                    style={{
                      position: "absolute",
                      bottom: "1.563rem",
                      left: 0,
                      height: 25,
                      width: "100%",
                    }}
                  >
                    <source
                      src={productDetails?.mediaPreviewUrl}
                      type="audio/mpeg"
                    />
                  </audio>
                  {/* </div> */}
                </div>
              )}
              {productDetails?.mediaType === "video" && (
                <div
                  className={styles.playerwrapper}
                  // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                  style={{
                    position: "relative",
                    paddingTop: 0,
                    width: "200px",
                    height: "165px",
                  }}
                >
                  {/* <ReactPlayer
                url={productDetails?.mediaPreviewUrl}
                width={screenWidth > 550 ? "200px" : "150px"}
                height={screenWidth > 550 ? "165px" : "150px"}
                light={false}
                controls={false}
                // style={{ position: "absolute", top: "0", left: "0" }}
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: "nodownload",
                    },
                  },
                }}
              /> */}
                  <video
                    id="vid"
                    width={screenWidth > 550 ? "200px" : "150px"}
                    height={screenWidth > 550 ? "165px" : "150px"}
                    style={{ objectFit: "cover", borderRadius: "0.313rem" }}
                    disablePictureInPicture
                    controlsList="nodownload"
                    loop="true"
                    autoplay="true"
                    muted
                    playsInline
                  >
                    <source src={productDetails?.mediaPreviewUrl} />
                    Your browser does not support the video.
                  </video>
                </div>
              )}
              {(productDetails?.mediaType === "image" ||
                productDetails?.mediaType === "3D Model") && (
                  <div
                    style={{
                      width: screenWidth > 550 ? "200px" : "150px",
                      height: screenWidth > 550 ? "165px" : "150px",
                    }}
                    className={styles.playerwrapper}
                  >
                    <img
                      src={
                        productDetails?.thumbnailUrl ||
                        productDetails?.mediaPreviewUrl
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="thumbnail.png"
                    />
                  </div>
                )}
              {/* <span style={{ marginLeft: 0 }}>
            {"Product Name: " + productDetails?.name}
          </span> */}
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <div className={styles.space}>
              {/* <h4>{productDetails?.name + ' #'+productDetails?.currentEdition+'/'+productDetails?.totalEdition}</h4> */}
              <h4>{productDetails?.name }</h4>
              <div className={styles.priceWalletWrap}>
                <div className={styles.checkoutPriceInfo}>
                  <span>Price</span>
                  <p>${bidAmountEuro}</p>
                </div>
                <div className={styles.checkoutPriceInfo}>
                  <span>Your USDC Wallet Balance</span>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    {cryptoBalance}
                    <img
                      src={`/images/usdc-logo.png`}
                      alt="USDC"
                      className={styles.maticSymbol}
                      style={{ width: 23, paddingLeft: 0, marginLeft: 5 }}
                    />
                  </p>
                </div>
                {/* <div className={styles.checkoutPriceInfo}>
                  <span>Your Cash Wallet Balance</span>
                  <p>${cashWalletBalance ? cashWalletBalance : "0.00"}</p>
                </div> */}
              </div>

              <div className={styles.checkoutTag}>
              <button
                  className={ styles.buttonFullWidthAuc }
                  style={{ borderRadius: 5, fontSize: "0.688rem" }}
                  onClick={() => {
                    disableScroll();
                    if (!walletStatus) {
                      PopUpAlert(
                        "Alert..",
                        "No wallet connection found.",
                        "error"
                      ).then((res) => {
                        enableScroll();
                        document.getElementById("connectWallet").click();
                      });
                      return false;
                    } else if (loginWith!=='metamask' && isUserVerified != "verified") {
                      PopUpAlert(
                        "Alert..",
                        "User is not verified. Please verify your account",
                        "error"
                      ).then(() => {
                        enableScroll();
                        history.push("/my-account");
                      });
                    } else {
                      handleBidWertCard("card", bidAmountEuro, bidAmountEth);
                    }
                  }}
                >
                  <span>Bid with card</span>
                </button>
               
                <button
                  className={styles.buttonFullWidthAuc}
                  style={{ borderRadius: 5, fontSize: "0.688rem" }}
                  onClick={(e) => {
                    getBalance().then(()=>{
                      const matic_balance = localStorage.getItem("tokenbalance");
                      if (Number(matic_balance) < 0.2 ) {
                        disableScroll();
                        PopUpAlert(
                          "Alert!",
                          "Insufficient funds in your crypto wallet. To proceed with the bid, please ensure that you have a minimum balance of 0.2 Matic in your wallet. Kindly add funds to your wallet before proceeding.",
                          "error",
                          true
                        ).then((res) => {
                          enableScroll(); 
                          if(!res.isDismissed) 
                            history.push("/wallet");            
                          }).catch((err)=>{
                           console.error("some error at getBalance")
                          });
                        return;
                      } else{
                         disableScroll();
                        handleBid(e);
                        setPaymentTypeForCrypto();
                      }
                    })

                    // disableScroll();
                    // handleBid(e);
                    // setPaymentTypeForCrypto();
                  }}
                >
                  <span>Bid with crypto</span>
                </button>  
                
                {/* <button
                  className={styles.buttonFullWidthAuc}
                  onClick={() => {
                    disableScroll();
                    if (!walletStatus) {
                      PopUpAlert(
                        "Alert..",
                        "No wallet connection found.",
                        "error"
                      ).then((res) => {
                        enableScroll();
                        document.getElementById("connectWallet").click();
                      });
                      return false;
                    } else if (isUserVerified != "verified") {
                      PopUpAlert(
                        "Alert..",
                        "User is not verified. Please verify your account",
                        "error"
                      ).then(() => {
                        enableScroll();
                        history.push("/my-account");
                      });
                    } else if (Number(circleBalance) < Number(bidAmountEuro)) {
                      console.log('bid amount euro : ', bidAmountEuro, circleBalance)
                      PopUpAlert(
                        "Alert",
                        "You do not have sufficient balance to bid on this product. Please topup your wallet.",
                        "error"
                      ).then(() => {
                        history.push("/circlePayment");
                      });
                    } else {
                      handleWalletAuctionCheckout("wallet");
                    }
                  }}
                  style={{ borderRadius: 5, fontSize: "0.688rem" }}
                >
                  <span>Bid with cash wallet</span>
                </button> */}
              </div>
              <Alert severity="info" style={{ marginTop: "1em" }}>
                Crypto payments supports only polygon network (MATIC)
              </Alert>
            </div>
          </div>
        </div>
      </div>
      <Loader
        active={active}
        customize={productDetails?.saleType == "fixed" ? false : true}
        title={"Bidding in progress"}
        content={"Please wait"}
        backGround="/images/liiLogoArabic.png"
      />
      {successShow && (
        <SuccessPopup
          productDetails={productDetails}
          amount={
            productDetails?.saleType == "fixed"
              ? productDetails?.isOnSell 
                ? productDetails?.resellPrice  
                : productDetails?.regularPrice  
              : successPopupAmount
          }
          close={() => closeSuccessModal()}
          type={"auction"}
          sourcePage={"crypto"}
        />
      )}
      {openWertModal?.active ? (
        <WertWidgetDiv
          arg={{
            productDetails: productDetails,
            mode: openWertModal?.mode,
            setOpenWertModal: setOpenWertModal,
            saleType: "auction",
            bidAmountFiat: openWertModal?.bidAmountFiat,
            bidAmountCrypto: openWertModal?.bidAmountCrypto,
            bidAmountFiatWEI: openWertModal?.bidAmountFiatWEI,
            processWertResult: processWertResult,
            setWertLoaded: setWertLoaded,
            callSetSuccessShow,
            wertHookSubstitueAuction
          }}
        />
      ) : null}
    </div>
  );
};

export default PaymentOptionModal;
