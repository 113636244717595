import "./privacyPolicy.css"
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div style={{ marginTop: '3.125rem', paddingBottom: '3.125rem', fontSize:18 }} className="container">
        <div className="content" style={{ margin: "auto" }}>
          Privacy Policy of Artentik | NFTs for good causes</div>
        <div className="policy">
          <div className="odd">
            <p className="policyheading">SANTA CASA COMMITMENT</p>
            <p className="points">Through this policy, Santa Casa da Misericórdia de Lisboa (SCML) undertakes, namely, to recognize the security of the personal data processed regarding the services provided through the technological Platform - Artentik, and to ensure the protection of the data subjects privacy, as fundamental dimensions of SCML’s activity.</p>
            <p className="points">SCML also provides information on the rules, principles and good practices observed in the processing of personal data entrusted to it, in accordance with the General Regulation on Data Protection (GDPR) and other legislation applicable, and on the means that data subjects have at their disposal to exercise their rights.</p>
            <p className="points">In the context of the provision of the service SCML is committed to only use processors that provide sufficient guarantees to implement appropriate technical and organizational measures in such a manner that processing will meet the GDPR’s requirements, and ensure the protection of the data subject’s rights.</p>
            <p className="points">In this Policy, aligned with the Terms and Conditions, and unless the context clearly indicates otherwise, the terms indicated below shall have the meanings that are attributed to them hereunder. Expressions used in the singular include the plural, and vice-versa.
              <br /><br />
              <b>User:</b> the person interacting with the Platform as provided for in the Terms and Conditions;<br /><br />
              <b>NFT:</b> the Non-Fungible Token, meaning a crypto-asset minted on Polygon Layer 2 Ethereum blockchain, that is unique and not interchangeable and is linked to the Artwork, certifying the authenticity of the Artwork and finally attesting its ownership;<br /><br />
              <b>Platform:</b> Artentik, i.e., a technological platform developed, maintained and provided by the Data Processor, with specific configurations for the clients of SCML, which may be accessed for the purpose of acquisition and trading of NFTs, at the following domain:
              <a href="https://asly-dev.devtomaster.com/" target="_blank" style={{ color: "#FF75DC", textDecoration: "none" }}>https://www.lii.com</a>.<br /><br />
              <b>Data Processor:</b> Boloro Global Limited, a Delaware corporation, with registered office at 100 Park Avenue, 16th floor, NY, 10017 USA.<br /><br />
              <b>SCML:</b> Santa Casa da Misericórdia de Lisboa, a private entity of public administrative utility, legal person no. 50074571, located at Largo Trindade Coelho, 1200-470 Lisboa and thereby constituted as Data Controler as stated in the GDPR.</p>
            <p className="points">For further information regarding the services, please consult our Terms and Conditions of Use.</p>

            <div className="even">
              <p className="policyheading">DATA CONTROLLER</p>
              <p className="points">The SCML – a legal person governed by private law and administrative public utility, with single legal person number 500 745 471 – is the data processing controller entity responsible for processing your personal data, and can be contacted through the following channels:
                <br /><br />
                -	Address: Largo Trindade Coelho, 1200-470 Lisboa, Portugal<br /><br />-	E-mail: dadospessoais@scml.pt<br />
                <br />-	Telephone: +351 213 235 000<br /></p>
            </div>
            <div className="odd">
              <p className="policyheading">PURPOSES AND GROUNDS FOR LAWFUL PROCESSING</p>
              <p className="points">The personal data processed will be those strictly necessary for the purposes, each of them duly legitimated by a valid legal basis.</p>
              <p className="points">While navigating the Platform SCML will want to understand how Users interact using our services by processing various browsing information resulting from cookies (see article 8) related to the service’s use. In this respect, the legal basis of this processing is SCML’s legitimate interest when processing essential cookies, and Users free, specific, informed and unequivocal consent when processing non-essential cookies such as analytical cookies.</p>
              <p className="points">Throughout the whole experience while using our services SCML collects and processes User’s personal data from the following categories of personal data:<br /><br />
                -	For the auction of the NFT: e-mail address, wallet number, bid’s placement and duration;<br /><br />
                -	For the transfer of the NFT:  e-mail address and wallet number;<br /><br />
                -	For fraudulent participation prevention and money laundering prevention: any information concerning an account, including account details and transaction details.<br />
                The legal basis on which SCML relies for this purpose is the performance of a contract to which the User is a party, as well as, legal obligations concerned to anti-fraud and anti-money laundering legislation.</p>
              <p className="points">If you decide to create a User’s profile, SCML will collect and process identification personal data (i.e. solely name, surname, e-mail address and photography) in order to manage this process. The legal basis of this processing is the User’s free, specific, informed and unequivocal consent. Users are free to decide to delete the profile created at any time in the Plataform.</p>
              <p className="points">For the purpose of targeted communication and promotional newsletters about Artentik upcoming releases and drops, SCML will ask users an independent consent to process the e-mail address, as a mean of a lawfulness processing.</p>
              <p className="points">Lastly, SCML may collect and process User’s personal data for managing and following up addressed questions and requests. This processing will require to collect and process the contact details, and the message(s)’s content as part of the question/request. </p>
            </div>
            <div className="even">
              <p className="policyheading">DATA RECIPIENTS</p>
              <p className="points">In order to enable access to our services and complete the transactions, the following entities will be recipients to which User’s personal data are disclosed, considering the limited purposes as shown:</p>
              <table>
                <tr>
                  <td style={{ fontWeight: "bold" }}>User’s personal data</td>
                  <td style={{ fontWeight: "bold" }}>Recipients</td>
                  <td style={{ fontWeight: "bold" }}>Purposes of data processing</td>
                </tr>

                <tr>
                  <td >IP address</td>
                  <td>AWS</td>
                  <td>To provide cloud hosting.</td>
                </tr>

                <tr>
                  <td>IP address</td>
                  <td>Cloudflare (CDN)</td>
                  <td>To secure and ensure the reliability of the external facing resources.</td>
                </tr>

                <tr>
                  <td>IP address/ Contact details</td>
                  <td>Metamask</td>
                  <td>To provide a secure cryptocurrency wallet and interaction with the blockchain.</td>
                </tr>

                <tr>
                  <td>IP address/Wallet number</td>
                  <td>Polygon</td>
                  <td>To provide a Layer 2 Blockchain Network.</td>
                </tr>

                <tr>
                  <td>IP address</td>
                  <td>AWS Cloudfront (CDN)</td>
                  <td>To ensure the lowest latency (time delay), so that content is delivered with the best possible performance.</td>
                </tr>

                <tr>
                  <td>E-mail</td>
                  <td>Sendinblue</td>
                  <td>To provide e-mail marketing and automation platform.</td>
                </tr>

                <tr>
                  <td>IP address/ Wallet number</td>
                  <td>Coinbase</td>
                  <td>To provide a secure cryptocurrency wallet.</td>
                </tr>

                <tr>
                  <td>Wallet number/IPFS</td>
                  <td>Pinata</td>
                  <td>To provide a storage platform for digital files.</td>
                </tr>

                <tr>
                  <td>E-mail address and profile data if created (name, surname, photo)</td>
                  <td>AWS</td>
                  <td>To store User’s data at the platform Artentik.</td>
                </tr>

                <tr>
                  <td>Wallet number/ name, address, e-mail, phone number, personal description or photo</td>
                  <td>Utrust - MODERNlTYPARTY OÜ</td>
                  <td>To ensure the processing of the cryptocurrencies.</td>
                </tr>
              </table>
              <p className="points">Upon request SCML may disclose personal data with competent authorities, within the extent legally permitted.</p>
              <p className="points">SCML will always communicate any personal data to the above recipients on a strictly need-to-know basis and only as necessary for fulfilling duly identified processing purposes.</p>
            </div>
            <div className="odd">
              <p className="policyheading">STORAGE PERIOD</p>
              <p className="points">The Users’ personal data are processed by SCML in strict compliance with the applicable legislation, and stored in specific information systems. Such data is kept in a manner that allows the identification of the data subjects for only as long as necessary for the purposes for which they are processed.</p>
              <p className="points">The data storage period varies according to the purpose for which the information is used, there being, however, legal requirements that oblige the conservation of data for a certain period of time.</p>
              <p className="points">We have also taken SCML's Document Management Regulations, available through Ordinance no. 6/2017, of 4 January, and the various deliberations of the data protection control authorities, as a reference for determining the appropriate retention period.</p>
            </div>
            <div className="even">
              <p className="policyheading">DATA PROCESSOR AND DATA TRANSFER</p>
              <p className="points">The Users’ personal data are processed by SCML in strict compliance with the applicable legislation, and stored in specific information systems. Such data is kept in a manner that allows the identification of the data subjects for only as long as necessary for the purposes for which they are processed.</p>
              <p className="points">SCML provides the Users with access to the Platform managed by the Data Processor.</p>
              <p className="points">The Data Processor is accountable for providing, developing and maintaining the Platform, with specific configurations for the clients of SCML, which may be accessed for the acquisition and trading of NFTs.</p>
              <p className="points">In order to carry out the duties assigned by SCML, the Data Processor will have access to personal data considering the purposes of the processing of personal data.</p>
              <p className="points">The Data Processor will only use other processors with prior authorization of SCML, providing sufficient guarantees of implementing appropriate technical and organizational measures to assure personal data protection.</p>
              <p className="points">The personal data is processed within the European Union territory in what concerns the data stored in the Platform.</p>
            </div>
            <div className="odd">
              <p className="policyheading">USER’s RIGHTS AND CONTACT DETAILS OF THE DATA PROTECTION OFFICER</p>
              <p className="points">With regard to User’s personal data, a request may be placed, at any time, to access to personal data concerning, as well as its rectification, elimination or limitation of processing, or to oppose its processing - provided that the legal conditions are met.</p>
              <p className="points"> These rights can be exercised preferably by contacting the SCML Data Protection Officer, preferably by e-mail to dadospessoais@scml.pt, or by post to Largo Trindade Coelho, 1200-470 Lisbon, Portugal.</p>
              <p className="points">Without prejudice to any other administrative or judicial remedy, it is also possible to file a complaint with the competent supervisory authority in accordance with the law, if one is to believe that the processing of personal data by SCML does not comply with the applicable legislation.</p>
            </div>
            <div className="even">
              <p className="policyheading">COOKIES</p>
              <p className="points">While interacting with the Platform the User is well aware that cookies will be placed. When browsing for the first time, a cookie banner displays a request to accept cookies or to configure it.</p>
              <p className="points">A cookie is a small piece of data that a website stores on the visitor’s computer or mobile device. Cookies are used for the technical functioning of the website and, based on User’s consent, to collect browsing experience for anonymized statistics, whose purpose is to improve communication and the service.</p>
              <p className="points">The maximum storage period for cookies is 13 months from the time they are placed on the browser or User’s equipment. At the end of this period a new consent will be required.</p>
              <p className="points">The User may accept, refuse and delete certain or all cookies.</p>
              <p className="points">The User is informed the refusal of certain cookies may affect the provided services and navigation on the Platform.</p>
              <p className="points">In order to configure cookies User may use the help menu of the browser such as Google Chrome; Internet Explorer; Mozila Firefox; Safari; Edge and Opera.</p>
            </div>
            <div className="odd">
              <p className="policyheading">PRIVACY POLICY UPDATE</p>
              <p className="points">This policy may be revised and amended accordingly, and changes shall be deemed effective from the date of publication on this site, with express reference to the date of update.</p>
            </div>
          </div>
        </div>


      </div>

    </>
  );
};

export default PrivacyPolicy;