import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    id: "",
    firstName: "",
    email: "",
    lastName: "",
    profilePicture: "",
    userName: "",
    accountType: null,
    balance: 0,
    status: null,
    loginType: null,
    kycStatus: null,
    isKYCLimitExceeded: false,
    emailVerified: false,
    isCreatedByAdmin: false,
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setBasicAccountDetails: (state, data) => {
      console.log("payload setAccountDetails", data?.payload);
      state.email = data?.payload?.email;
      state.firstName = data?.payload?.firstName;
      state.lastName = data?.payload?.lastName;
      state.profilePicture = data?.payload?.profilePicture;
      state.userName = data?.payload?.userName;
      state.status = data?.payload?.status;
      state.loginType = data?.payload?.loginType;
      state.id = data?.payload?.id;
      state.kycStatus = data?.payload?.kycStatus;
      state.isKYCLimitExceeded = data?.payload?.isKYCLimitExceeded;
      state.emailVerified = data?.payload?.emailVerified;
      state.isCreatedByAdmin = data?.payload?.isCreatedByAdmin;
    },
    setAccountType: (state, data) => {
      state.accountType = data?.payload?.accountType;
      state.balance = data?.payload?.balance;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBasicAccountDetails, setAccountType } = accountSlice.actions;

export default accountSlice.reducer;
