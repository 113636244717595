import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";
import Player from "../../../components/Player";
import Modal from "../../../components/Modal";
import Connect from "../../../components/Connect";
import Header from "../../../components/Header";
import API from "../../../utils/API";
import { useSelector } from "react-redux";
import { formatPrice } from "./../../../controller/utils";
import { Section } from "react-fullpage";
import { useHistory } from "react-router";
import useWindowSize from "../../../common/hooks/useWindowSize";
const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
	<button {...props}>{children}</button>
);

const Hero = ({ currentScreenIndex }) => {
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		autoplay: false,
		autoplaySpeed: 4000,
		nextArrow: (
			<SlickArrow>
				<Icon name="arrow-next" size="20" />
			</SlickArrow>
		),
		prevArrow: (
			<SlickArrow>
				<Icon name="arrow-prev" size="20" />
			</SlickArrow>
		),
	};

	const [visibleModalBid, setVisibleModalBid] = useState(false);
	const [data, setData] = useState([]);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [hour, setHour] = useState(0);
	const [timeArr, setTimeArr] = useState(null);
	const [displayBox1, setDisplayBox1] = useState(false);
	const [displayBox2, setDisplayBox2] = useState(false);
	const [displayBox3, setDisplayBox3] = useState(false);
	const history = useHistory();
	const euroValue = useSelector((state) => state.counter.euroValue) || 0;
	const [screenWidth] = useWindowSize();

	useEffect(() => {
		setTimeout(() => boxAnimation(1), 6000);
		setTimeout(() => boxAnimation(2), 7000);
		setTimeout(() => boxAnimation(3), 9000);
	}, []);

	const boxAnimation = (box) => {
		switch (box) {
			case 1:
				setDisplayBox1(true);
				break;

			case 2:
				setDisplayBox2(true);
				break;

			case 3:
				setDisplayBox3(true);
				break;

			default:
				break;
		}
	};

	const getSliderAuctions = (params) => {
		// API.get("/asset/active/auctions")
		API.get("/asset/auction/latest-three").then((res) => {
			const data = res.data?.result;
			// let currentDateTime = new Date();
			// .filter((auction) => {
			//   return  currentDateTime.getTime() >= new Date(auction?.auction?.startDate).getTime()
			// })
			let arr = data?.map((item) => {
				return {
					title:
						item.name?.length > 91
							? item.name?.substring(0, 89) + "..."
							: item?.name,
					creator:
						item.creatorName?.length > 69
							? item.creatorName?.substring(0, 66) + "..."
							: item?.creatorName,
					currency:
						(
							(item.auction?.currentBid ||
								item.auction?.openingPrice ||
								item?.regularPrice) / euroValue
						)?.toFixed(4) + (item?.saleType === "auction" ? " MATIC" : " ETH"),
					price: formatPrice(
						(
							item.auction?.currentBid ||
							item.auction?.openingPrice ||
							item?.regularPrice
						)?.toFixed(0)
					),
					avatar: "/images/favicon.ico",
					image: item?.thumbnailUrl || item?.mediaPreviewUrl,
					image2x: item?.thumbnailUrl || item?.mediaPreviewUrl,
					id: item.id,
					item,
					video: item?.mediaPreviewUrl || null,
					audio: item?.mediaPreviewUrl || null,
					audioImg: item?.audioThumbnail || null,
					mediaType: item?.mediaType,
					saleType: item?.saleType,
					auction: item?.auction,
				};
			});
			setData(arr);
		});
	};

	function timeLeft(item) {
		if (item?.auction?.endingDate) {
			var now = new Date();
			var diff = new Date(item?.auction?.endingDate) - now;
			var hours = Math.floor(diff / 3.6e6);
			var minutes = Math.floor((diff % 3.6e6) / 6e4);
			var seconds = Math.floor((diff % 6e4) / 1000);
			if (hours < 0 || minutes < 0 || seconds < -1) {
				return { hours: 0, minutes: 0, seconds: 0 };
			}
			return { hours, minutes, seconds };
		}
		return { hours: 0, minutes: 0, seconds: 0 };
	}

	useEffect(() => {
		let params = {
			limit: 20,
			page: 1,
			saleType: "auction",
		};
		getSliderAuctions(params);

		const getAllData = setInterval(() => {
			setTimeArr(
				data
					?.filter((item) => item?.saleType !== "fixed")
					?.map((item) => timeLeft(item.item))
			);
		}, 1000);
		return () => clearInterval(getAllData);
	}, []);
	// console.log('data',data);
	return (
		<>
			<Section className={cn("section", styles.section)}>
				<div className={cn("container", styles.container)}>
					{/* <div className={styles.head}>
                      </div> */}
					<div className={styles.floatingNft}>
						{screenWidth > 1250 ? (
							<></>
						) : (
							//   <LeftFloater
							//     data={data}
							//     history={history}
							//     currentScreenIndex={currentScreenIndex}
							//     screenWidth={screenWidth}
							//     displayBox1={displayBox1}
							//     displayBox2={displayBox2}
							//   />
							<></>
							//   <RightFloater
							//     data={data[0]}
							//     history={history}
							//     screenWidth={screenWidth}
							//     displayBox3={displayBox3}
							//   />
						)}

						{/* {screenWidth > 766 ? (
							<div className={styles.centerFloater}>
								<h2
									className={cn("h3", styles.title)}
									style={{
										color:
											!currentScreenIndex || currentScreenIndex == 1
												? "#FFFFFF"
												: "#000000",
									}}
								>
									Discover <br /> unique  NFT
								</h2>
							</div>
						) : (
							<h2
								className={cn("h3", styles.title)}
								style={{
									color:
										!currentScreenIndex || currentScreenIndex == 1
											? "#000000"
											: "#000000",
								}}
							>
								Discover <br /> unique  NFT
							</h2>
						)} */}

						{/* <RightFloater
              data={data[2]}
              history={history}
              screenWidth={screenWidth}
              displayBox3={displayBox3}
            /> */}
					</div>
					{/* {screenWidth < 767 && (
						<div className={styles.centerFloater}>
							<h2
								className={cn("h3", styles.title)}
								style={{
									color:
										!currentScreenIndex || currentScreenIndex == 1
											? "#FFFFFF"
											: "#000000",
								}}
							>
								Discover <br /> unique  NFT
							</h2>
						</div>
					)} */}
				</div>
			</Section>
			<Link to="/search01/all" className={styles.emblem}>
				<img src="/images/content/emblem.svg" alt="pic"></img>
			</Link>
			<Modal
				visible={visibleModalBid}
				onClose={() => setVisibleModalBid(false)}
			>
				<Connect />
			</Modal>
		</>
	);
};

const LeftFloater = ({
	data,
	history,
	screenWidth,
	currentScreenIndex,
	displayBox1,
	displayBox2,
}) => {
	return (
		<div className={styles.leftFloater}>
			<div style={{ opacity: displayBox1 ? "1" : "0" }}>
				<div className={styles.cardContainer}>
					<div
						className={styles.cardOne}
						style={{ cursor: "pointer" }}
						onClick={() => history.push("/item/" + data?.[0]?.id)}
					>
						{data?.[0]?.mediaType == "video" && (
							<div
								style={{
									width: "100%",
									height: "33.745rem",
								}}
							>
								<video
									width="100%"
									height="100%"
									style={{
										objectFit: "cover",
										borderTopLeftRadius: "0.5rem",
										borderTopRightRadius: "0.5rem",
										borderRadius: "1.4em",
									}}
									disablePictureInPicture
									controls="false"
									loop="true"
									playsInline
									autoPlay="autoplay"
									muted
								>
									<source src={data?.[0]?.video} />
									Your browser does not support the video.
								</video>
							</div>
						)}
						{data?.[0]?.mediaType == "image" && (
							<img src={data?.[0].image} alt="auction1"></img>
						)}
						<p>{data?.[0]?.title}</p>
					</div>
					<div className={styles.bidPlaceOne}>
						<div className={styles.bidPlaceImage}>
							<img
								src={
									data?.[0]?.auction?.currentBidderInfo?.profilePicUrl
										? data?.[0]?.auction?.currentBidderInfo?.profilePicUrl
										: "./images/content/Avatar.png"
								}
								alt="avatar"
							></img>
						</div>

						<div className={styles.bidPlaceDetails}>
							<p>
								Bid Placed by{" "}
								{data?.[0]?.auction?.currentBidderInfo?.firstName
									? data?.[0]?.auction?.currentBidderInfo?.firstName
									: data?.[0]?.auction?.currentBidderInfo?.publicAddress}
							</p>
							<h5>
								${" "}
								{data?.[0]?.auction?.currentBid
									? (data?.[0]?.auction?.currentBid).toFixed(3)
									: "0.00"}
							</h5>
						</div>
					</div>
				</div>
			</div>

			{screenWidth > 1250 && (
				<div
					className={styles.cardContainer_small}
					style={{ opacity: displayBox2 ? "1" : "0" }}
				>
					<div
						className={styles.cardOne}
						style={{
							cursor: "pointer",
							opacity: currentScreenIndex && currentScreenIndex == 2 ? 0 : 1,
						}}
						onClick={() => history.push("/item/" + data?.[1]?.id)}
					>
						{/* <div className={styles.avatarWrapper}> */}
						{data?.[1]?.mediaType == "video" && (
							<div className={styles.videoSize}>
								<video
									width="100%"
									height="100%"
									style={{
										objectFit: "cover",
										borderTopLeftRadius: "0.5rem",
										borderTopRightRadius: "0.5rem",
									}}
									disablePictureInPicture
									controls="false"
									loop="true"
									autoPlay="autoplay"
									playsInline
									muted
								>
									<source src={data?.[1]?.video} />
									Your browser does not support the video.
								</video>
							</div>
						)}

						{data?.[1]?.mediaType == "image" && (
							<img
								src={data?.[1].image}
								alt="auction1"
								width={"12.094rem"}
								height={"15.952rem"}
							></img>
						)}
						{/* <img
          src={
            data?.[1]?.auction?.currentBidder?.profilePic
              ? data?.[1]?.auction?.currentBidder?.profilePic
              : "./images/content/bannerAsset.png"
          }
          alt="avatar"
        ></img> */}
						{/* </div> */}
						<p>{data?.[1]?.title}</p>
					</div>
					<div className={styles.bidPlaceOne}>
						<div className={styles.bidPlaceImage}>
							<img
								src={
									data?.[1]?.auction?.currentBidderInfo?.profilePicUrl
										? data?.[1]?.auction?.currentBidderInfo?.profilePicUrl
										: "./images/content/Avatar.png"
								}
								alt="avatar"
							></img>
						</div>
						<div className={styles.bidPlaceDetails}>
							<p>
								Bid Placed by{" "}
								{data?.[1]?.auction?.currentBidderInfo?.firstName
									? data?.[1]?.auction?.currentBidderInfo?.firstName
									: data?.[1]?.auction?.currentBidderInfo?.publicAddress}
							</p>
							<h5>
								${" "}
								{data?.[1]?.auction?.currentBid
									? (data?.[1]?.auction?.currentBid).toFixed(3)
									: "0.00"}
							</h5>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const RightFloater = ({ data, history, screenWidth, displayBox3 }) => {
	return (
		<div className={styles.rightFloater}>
			<div
				className={styles.cardContainer_right}
				style={{ opacity: displayBox3 ? "1" : "0" }}
			>
				<div
					className={styles.cardOne}
					style={{ cursor: "pointer" }}
					onClick={() => history.push("/item/" + data?.id)}
				>
					{data?.mediaType == "video" && (
						<div className={styles.image_video_container}>
							<video
								width="100%"
								height="100%"
								style={{
									objectFit: "cover",
									borderTopLeftRadius: "0.5rem",
									borderTopRightRadius: "0.5rem",
								}}
								disablePictureInPicture
								controls="false"
								loop="true"
								autoPlay="autoplay"
								playsInline
								muted
							>
								<source src={data?.video} type="video/mp4" />
							</video>
						</div>
					)}
					{data?.mediaType == "image" && (
						<div className={styles.image_video_container}>
							<img src={data?.image} alt="pic2"></img>
						</div>
					)}
					{/* <img src={data?.image}></img> */}
					<p>{data?.title}</p>
				</div>

				<div className={styles.bidPlaceOne}>
					<div className={styles.bidPlaceImage}>
						<img
							src={
								data?.auction?.currentBidderInfo?.profilePicUrl
									? data?.auction?.currentBidderInfo?.profilePicUrl
									: "./images/content/Avatar.png"
							}
							alt="pp"
						/>
					</div>

					<div className={styles.bidPlaceDetails}>
						{screenWidth > 766 ? (
							<p>
								Bid Placed by{" "}
								{data?.auction?.currentBidderInfo?.firstName
									? data?.auction?.currentBidderInfo?.firstName
									: data?.auction?.currentBidderInfo?.publicAddress}
							</p>
						) : (
							<p>
								@{" "}
								{data?.auction?.currentBidderInfo?.firstName
									? data?.auction?.currentBidderInfo?.firstName
									: data?.auction?.currentBidderInfo?.publicAddress}
							</p>
						)}
						{screenWidth > 766 && (
							<h5>
								${" "}
								{data?.auction?.currentBid
									? (data?.auction?.currentBid).toFixed(3)
									: "0.00"}
							</h5>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
