import React, { useEffect, useState } from "react";
import styles from "./Item.module.sass";
// import ReactImageZoom from "react-image-zoom";
import ReactPlayer from "react-player";
import ThreeDee from "../Threedee/ThreeDee";
import { Link } from "react-router-dom";
import ThreeDeeViewer from "../../components/ThreeDeeViewer/ThreeDeeViewer";

const Lightbox = (props) => {
	const { productDetails, productPreviews, audioHeight = "31.25rem" } = props;
	const [currentItem, setCurrentItem] = useState(null);
	const [currentAsset, setCurrentAsset] = useState(null);
	const [dataType, setDataType] = useState("");

	useEffect(() => {
		if (productDetails) {
			let items = productDetails.files;
			items.sort((a, b) => a.position > b.position);
			setCurrentAsset(items[0]);
			setCurrentItem(items[0]?.previewUrl);
		}
	}, [productDetails]);

	useEffect(() => {
		if (currentAsset?.type === "3D Model") {
			setDataType("3d");
		} else if (currentAsset?.type === "video") {
			setDataType("video");
		} else if (currentAsset?.type === "audio") {
			setDataType("audio");
		} else if (currentAsset?.type === "image") {
			setDataType("image");
		}
	}, [currentAsset]);

	return (
		<div className={styles.bg}>
			{/* <h3 style={{fontSize: "1.5rem",
    lineHeight: "1.33333",
    marginBottom:"1.25rem",
    fontWeight: "600",
    letterSpacing: "-.01em",
    color: "#ffffff"}}>Asset details</h3> */}
			<div className={styles.preview}>
				{/* <div className={styles.categories}>
                {categories.map((x, index) => (
                  <div
                    className={cn(
                      { "status-black": x.category === "black" },
                      { "status-purple": x.category === "purple" },
                      styles.category
                    )}
                    key={index}
                  >
                    {x.content}
                  </div>
                ))}
              </div> */}
				{dataType === "3d" && (
					// <Link to={`/threeDeeViewer/${currentAsset.previewUrl?.substr(36)}`} target="_blank">
					//   <img alt="preview.png"
					//     src={productDetails.thumbnailUrl || currentAsset.defaultThumbnailUrl ||
					//       "https://png.pngtree.com/png-vector/20190904/ourlarge/pngtree-abstract-3d-logo-elegant-modern-geometric-png-image_18512.jpg"}
					//   >
					//   </img>
					// </Link>
					<ThreeDeeViewer id={currentAsset.previewUrl?.substr(36)} />
				)}
				{dataType === "video" && (
					<div
						className={styles.playerWrapper}
						style={{
							position: "relative",
							width: "100%",
							paddingTop: "56%",
							maxWidth: "31.25rem",
							height: "31.25rem",
						}}
					>
						<ReactPlayer
							url={currentAsset?.previewUrl}
							width="100%"
							height="100%"
							controls={true}
							light={false}
							playing={true}
							loop={true}
							playsinline
							playIcon={<button>Play</button>}
							style={{ position: "absolute", top: "0", left: "0" }}
							config={{
								file: {
									attributes: {
										disablePictureInPicture: true,
										controlsList: "nodownload",
									},
								},
							}}
						/>
					</div>
				)}

				{dataType === "audio" && (
					<div
						style={{
							position: "relative",
							maxWidth: "31.25rem",
							height: "31.25rem",
							width: "100%",
						}}
					>
						<img
							src={
								productDetails?.audioThumbnail ||
								"https://m.media-amazon.com/images/S/aplus-media/vc/871c30f0-6a69-4cca-915f-04f61595e517._CR0,0,300,300_PT0_SX300__.jpg"
							}
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								// objectFit: "scale-down",
								borderRadius: "0.625rem",
							}}
							alt="thumbnail.png"
						/>
						<audio
							controls
							controlsList="nodownload"
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								height: 35,
								width: "100%",
							}}
						>
							<source src={currentAsset?.previewUrl} type="audio/mpeg" />
							<source src={currentAsset?.previewUrl} type="audio/mp3" />
							<source src={currentAsset?.previewUrl} type="audio/flac" />
						</audio>
					</div>
				)}
				{dataType === "image" && (
					<div className={styles.lightbox_image_wrap}>
						{(productDetails?.previewUrl || currentItem) && (
							<>
								<div
									className={styles.customImageDisplayer}
									style={{
										backgroundImage: `url(${
											currentItem || productDetails?.previewUrl
										})`,
									}}
								></div>
								{/* <img
                  alt="preview.png"
                  src={currentItem || productDetails?.previewUrl}
                  width="31.25rem"
                ></img> */}

								{/* Image Zoom Functionality */}
								{/* {(window.innerWidth < 500) || (currentItem || productDetails?.previewUrl).endsWith('.gif') ?
                  <img alt="preview.png"
                    src={currentItem || productDetails?.previewUrl}>
                  </img>
                  :
                  <> {
                    <ReactImageZoom
                      {...{
                        width: 500,
                        // height: "auto",
                        zoomWidth: 500,
                        img: currentItem || productDetails?.previewUrl,
                        zoomStyle: "z-index: 999",
                        scale: 1
                      }}
                    />
                  }
                  </>
                } */}
							</>
						)}
					</div>
				)}
				{productPreviews.length > 1 && (
					<div className={styles.productImages}>
						{productPreviews?.map(
							(item, index) =>
								!item.isThumbnail && (
									<div key={index} className={styles.productImageHolder}>
										{item?.type === "video" && (
											<div
												style={{
													height: "4.375rem",
													width: 80,
													overflow: "hidden",
												}}
												onClick={() => {
													const asset = productDetails?.files?.find(
														(a) => a.id === item?.id
													);
													setCurrentItem(asset?.url);
													setCurrentAsset(asset);
												}}
											>
												<ReactPlayer
													url={item?.previewUrl}
													playIcon={false}
													width="100%"
													height="100%"
													controls={false}
													playsinline
													light={false}
													// style={{ position: "absolute", top: "0", left: "0" }}
													config={{
														file: {
															attributes: {
																disablePictureInPicture: true,
																controlsList: "nodownload",
															},
														},
													}}
												/>
											</div>
										)}
										{item.type === "audio" && (
											<div
												style={{
													height: "4.375rem",
													width: 80,
													overflow: "hidden",
												}}
												onClick={() => {
													const asset = productDetails?.files?.find(
														(a) => a.id === item?.id
													);
													setCurrentItem(asset?.url);
													setCurrentAsset(asset);
												}}
											>
												<img
													alt="preview.png"
													src={
														productDetails.audioThumbnail ||
														"https://static.vecteezy.com/system/resources/previews/000/579/872/original/music-symbols-logo-and-icons-template-vector.jpg"
													}
													onClick={(e) => {
														setCurrentItem(e.target.src);
														const asset = productDetails?.files?.find(
															(a) => a.id === item?.id
														);
														setCurrentAsset(asset);
													}}
												></img>
											</div>
										)}
										{item?.type !== "video" &&
											item.type !== "audio" &&
											!item.type?.includes("3D") && (
												<img
													alt="preview.png"
													src={item?.previewUrl}
													onClick={(e) => {
														console.log(item);
														setCurrentItem(e.target.src);
														const asset = productDetails?.files?.find(
															(a) => a.id === item?.id
														);
														setCurrentAsset(asset);
													}}
												></img>
											)}
										{
											item?.type?.includes("3D") && (
												// <Link to={`/threeDeeViewer/${item.previewUrl?.substr(36)}`} target="_blank">
												<img
													alt="preview.png"
													src={
														item.defaultThumbnailUrl ||
														"https://png.pngtree.com/png-vector/20190904/ourlarge/pngtree-abstract-3d-logo-elegant-modern-geometric-png-image_18512.jpg"
													}
													onClick={() => {
														const asset = productDetails?.files?.find(
															(a) => a.id === item?.id
														);
														setCurrentAsset(asset);
													}}
												></img>
											)
											// </Link>
										}
									</div>
								)
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Lightbox;
