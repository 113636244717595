import WertWidget from "@wert-io/widget-initializer";
import { useEffect, useState } from "react";
import { wertTopUpWidgetOptions } from "../../utils/config";
import TransparentModal from "../../components/TransparentModal";
import { axiosInstance } from "../../utils/API";
import {
  PopUpAlert,
} from "../../controller/utils";
const TopUpWalletWert = ({ setOpenTopUpModal, onCloseWidget, walletId, wallet }) => {
  const [mountTransparent, setMountTransparent] = useState(false)

  const createTopupHistory = async (wertData) => {

    try {
      console.log("wert data", wertData)
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        }
      };
      let click_id = wertData?.click_id
      if (!click_id) {
        click_id = wertTopUpWidgetOptions.click_id
      }
      let data = await axiosInstance.post('/topuphistory', {
        click_id: click_id,
        transactionType: 'topup'

      }, config)

    } catch (error) {

      console.log("error", error)
    }

  }

  const wertCloseAfterPayement = () => { 
    PopUpAlert(
      "Info",
      "Payment got initiated, amount will be refleccted in your wallet upon successful transaction.",
      "success"
    ).then(() => { 
      window.location.href = '/wallet'  
    });
  };

  const wertReachedPaymentStatus = () => { 
    PopUpAlert(
      "Info",
      "Payment got success, amount will be refleccted in your wallet.",
      "success"
    ).then(() => {
      window.location.href = '/wallet'    
    });
  };

  const wertCloseBeforeTransaction = () => {
    let popupMsg = "Your transaction is incomplete." ;
     PopUpAlert(
      "Alert",
      popupMsg,
      "error"
    ).then(() => {
      window.location.reload();
    });
  };



  const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

  const wertCommodities = ["PRODUCTION"].includes(
    SERVER_ENVIORNMENT
  )
    ? {
      commodities: JSON.stringify([wallet == "USDC" ? { commodity: "USDC", network: "polygon" } : { commodity: "MATIC", network: "polygon" }]),
      commodity: "MATIC",
      network: "polygon",
    }
    :
    ["UAT"].includes(
      SERVER_ENVIORNMENT
    ) ? {
      commodities: JSON.stringify([wallet == "USDC" ? { commodity: "TT", network: "mumbai" } : { commodity: "MATIC", network: "mumbai" }]),
    }
      : {

        commodities: JSON.stringify([wallet == "USDC" ? { commodity: "TT", network: "mumbai" } : { commodity: "MATIC", network: "mumbai" }]),
      };


const commodities = []


  const modifiedWertTopUpWidgetOptions = {
    ...wertTopUpWidgetOptions,
    // Edit or add key-value pairs as needed
    commodities: wertCommodities.commodities,
    // ... add more modifications as needed
  };


  const runWertTopUpWidget = () => {
    (async () => {
      let checkPoint ='';
      const wertWidget = new WertWidget({
        ...modifiedWertTopUpWidgetOptions,
        wertCommodities,
        address: walletId,
        listeners: {
          "payment-status": (data) => {
            checkPoint = 'afterPayement'
            createTopupHistory(data)
            console.log("Payment status is::::", data)
            if (data.status === "success") {
              checkPoint = 'afterPaymentSuccess';
            }
          },

          close: (data) => {
            createTopupHistory(data)
            setOpenTopUpModal(false);

            if (checkPoint === 'afterPayement') {
              console.log('WERT afterPayement')
              wertCloseAfterPayement()}
            else if (checkPoint === 'afterPaymentSuccess'){ 
              console.log('WERT afterPaymentSuccess')
              wertReachedPaymentStatus() }
            else if (checkPoint === ''){
              console.log('WERT wertCloseBeforeTransaction')
              wertCloseBeforeTransaction()
            }else {
              console.log('WERT else')
              onCloseWidget()
            }


            // You can perform any necessary actions when the widget is closing
            // For example, hide the widget container or perform cleanup
          },
        },
      });

      // processWertResult();
      wertWidget.mount();
      setMountTransparent(false)
    })();

  };

  useEffect(() => {
    runWertTopUpWidget();

    console.log("adding the clickId",)
  }, []);

  // if (setMountTransparent) {
  //   return <TransparentModal />
  // }

  return <div id="widget"></div>;
};

export default TopUpWalletWert;
