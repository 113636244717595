import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./News.module.sass";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { axiosInstance } from "../../utils/API";
import ReactPaginate from "react-paginate";
import NewsCard from "./NewsCard";
import Banner from "./Components/Banner";
import LatestNews from "./Components/LatestNews";
import AllNews from "./Components/AllNews";
import NewsDetailsPage from "./NewsDetailPage";

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
		overflow: "hidden",
	},
	media: {
		height: 140,
		transition: "0.3s",
	},
	"&:hover": {
		transform: "scale(1.1)",
	},
});

const News = () => {
	const [count, setCount] = useState(0);
	const [data, setData] = useState([]);


	const [params, setParams] = useState({ limit: 20, page: 1, latest: true });

	let setInnerHTML = (content) => {
		return (
			<div
				className={styles.content}
				dangerouslySetInnerHTML={{ __html: content }}
			></div>
		);
	};

	useEffect(() => {
		//enable scrolling
		var body = document.querySelector("body");
		body.style.overflow = "auto";
	}, []);

	useEffect(() => {
		axiosInstance.get("/asset/news/all", { params }).then((res) => {
			setData(res.data?.result?.news?.rows);
			setCount(res.data?.result?.totalPages);
		});
	}, [params]);

	return (
		<div
			className={cn("container", styles.container)}
			style={{ margin: "3.125rem auto", flexWrap: "wrap" }}
		>
		
				<>
					<div className={styles.news_row1}>
						<Banner
							newsInfo={data?.[0]}
							setInnerHTML={setInnerHTML}
						/>
					</div>
					<div className={styles.news_row2}>
						<LatestNews
							data={data}
						/>
					</div>
					{/* <div className={styles.news_row3}>
						<AllNews
							data={data}
							setInnerHTML={setInnerHTML}
							setOpenNewsDetailsPage={(value) => {
								setOpenNewsDetailsPage({
									...openNewsDetailsPage,
									active: true,
									title: value?.title,
									description: value?.description,
									bannerUrl: value?.bannerUrl,
									date: value?.date,
								});
							}}
						/>
					</div> */}
				</>
			
		</div>
	);
};

export default News;
