import React, { useState, useEffect } from "react";
import styles from "./Counter.module.sass";
import cn from "classnames";
import { formatPrice } from "../../controller/utils";
import { axiosInstance } from "../../utils/API";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PopUpAlert } from "../../controller/utils";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updatePreviousPaymentMethord } from "../../redux/counterSlice";
import { Checkbox } from "@material-ui/core";
import useWindowSize from "../../common/hooks/useWindowSize";

const Counter = (props) => {
  const {
    productDetails,
    onBid,
    claimNft,
    currentBidAmount,
    isUpcomingBid,
    setBidInfo,
    setBidConfirmation,
    setCurrentBidAmount,
    prePurchaseDetails,
  } = props;
  const walletStatus = useSelector((state) => state.counter.value);
  const bidButtonStatus = useSelector((state) => state.counter.bidButtonStatus);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const history = useHistory();

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hour, setHour] = useState(0);
  const [days, setDays] = useState(0);
  const [bidDetails, setBidDetails] = useState({});
  const [show, setShow] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);
  const [email, setEmail] = useState(null);
  const [expired, setExpired] = useState(false);
  const userAccount = useSelector((state) => state?.accountReducer);
  const isUserVerified = useSelector((state) => state?.accountReducer?.status);
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(true);
  const [screenWidth] = useWindowSize();
  let loginWith = localStorage.getItem("network");
  

  const handleCheckboxCheck = (event) => {
    setChecked(event.target.checked);
  };
  const userId =
    JSON.parse(localStorage.getItem("cs_details"))?.account[0] || "";

  function disableScroll() {
    document.body.style.overflow = "hidden";
  }

  // call this to Enable
  function enableScroll() {
    document.body.style.overflow = "scroll";
  }

  function timeLeft() {
    if (bidDetails.endingDate) {
      var now = new Date();
      var diff = new Date(bidDetails.endingDate) - now;
      var days = Math.floor(diff / 8.64e7);
      var hours = Math.floor((diff % 8.64e7) / 3.6e6);
      var minutes = Math.floor((diff % 3.6e6) / 6e4);
      var seconds = Math.floor((diff % 6e4) / 1000);

      if (days > 0) {
        return { days, hours, minutes, seconds };
      }

      if (hours < 0 || minutes < 0 || seconds < -1) {
        setExpired(true);
        return {days:0, hours: 0, minutes: 0, seconds: 0 };
      }
      return { days:0, hours, minutes, seconds };
    }
    return null;
  }

  const getDetails = () => {
    let config = {
      headers: { Authorization: localStorage.getItem("userToken") },
    };
    axiosInstance
      .get(`/asset/view/auction/${productDetails?.id}`, config)
      .then((res) => {
        setBidDetails(res.data.result);
        setBidInfo(res?.data?.result);
        dispatch(updatePreviousPaymentMethord(res?.data?.result?.type));
      });
  };

  useEffect(() => {
    getDetails();
    if (walletStatus) {
      getUserDetails();
    }
    let myInterval = setInterval(() => {
      getDetails();
    }, 15000);
    return () => clearInterval(myInterval);
  }, [walletStatus]);

  useEffect(() => {
    if (!show) {
      setCurrentBidAmount(0);
    }
  }, [show]);

  useEffect(() => {
    const timegetter = setInterval(() => {
      if (timeLeft()) {
        const {days, hours, minutes, seconds } = timeLeft();
        setDays(days);
        setHour(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    }, 1000);
    return () => clearInterval(timegetter);
  }, [bidDetails]);

  const handleBid = (e) => {
    e.preventDefault();
    if (isNaN(bidAmount)) {
      PopUpAlert("Alert", `Please enter valid bid amount.`, "error");
      return;
    }
    if (bidAmount == 0) {
      PopUpAlert("Alert", `Please enter valid bid amount.`, "error");
      return;
    }

    if(bidDetails?.currentBid||bidDetails.openingPrice){
      const currentBidAmount = bidDetails?.currentBid||bidDetails?.openingPrice
      const tenPercent = currentBidAmount * 0.10;
      const minimumBidAmount = bidDetails?.currentBid ? currentBidAmount + tenPercent : currentBidAmount;
      if(bidAmount<minimumBidAmount){
        PopUpAlert(
          "Alert",
          `Bid amount must be ${(minimumBidAmount).toFixed(2)
          } or greater.`,
          "error"
        );
        return;
      }

    }
    if (bidDetails?.bidIncrement) {
      if (
        bidAmount <
        (bidDetails?.currentBid || bidDetails.openingPrice) +
          bidDetails.bidIncrement
      ) {
        PopUpAlert(
          "Alert",
          `Bid amount must be ${
            ((bidDetails?.currentBid || bidDetails?.openingPrice) +
            bidDetails?.bidIncrement).toFixed(2)
          } or greater.`,
          "error"
        );
        return;
      }
    } else {
      
    if(bidDetails?.currentBid||bidDetails.openingPrice){
      const currentBidAmount = bidDetails?.currentBid||bidDetails?.openingPrice
      const tenPercent = currentBidAmount * 0.10;
      const minimumBidAmount = bidDetails?.currentBid ? currentBidAmount + tenPercent : currentBidAmount;
      if(bidAmount<minimumBidAmount){
        PopUpAlert(
          "Alert",
          `Bid amount must be ${(minimumBidAmount).toFixed(2)
          } or greater.`,
          "error"
        );
        return;
      }

    }
    }

    let r = bidAmount * euroValue;

    onBid((bidAmount * euroValue).toFixed(4), productDetails?.id, bidAmount);
  };
  // email check
  const handleBidCheck = () => {
    console.log("Clicked");
    if (email) {
      setShow(true);
    } else {
      PopUpAlert(
        "Alert",
        "Please enter your Email address to place a Bid",
        "error"
      ).then(() => {
        enableScroll();
        history.push("/my-account");
      });
    }
  };

  const getUserDetails = async () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    await axiosInstance
      .get(`user/get-profile`, config)
      .then((res) => {
        const { email } = res.data?.result;
        if (email) setEmail({ email });
      })
      .catch((err) => {
        PopUpAlert("Alert", err.response.data.message, "warning");
        history.push("/search01/all");
      });
  };

  const handleBidOuterButton = (e) => {
    e.stopPropagation();
    const netTransactionAmount =
      Number(prePurchaseDetails.totalAmount) + Number(bidAmount);
    console.log(
      "prePurchase.handleBid OutBid",
      prePurchaseDetails,
      netTransactionAmount
    );
    if (
      (prePurchaseDetails?.kycStatus != 1 &&
        prePurchaseDetails.totalAmount > 200) ||
      (prePurchaseDetails?.kycStatus != 1 && netTransactionAmount > 200)
    ) {
      PopUpAlert(
        "Alert",
        "Exceeded $200 transaction limit. Verify KYC to proceed.",
        "error"
      ).then(() => {
        history.push("/dashboard");
      });
      return false;
    } else {
      // if (
      //   currentBidAmount <= (bidDetails?.currentBid || bidDetails?.openingPrice)
      // ) {
      //   PopUpAlert(
      //     "Alert",
      //     `Bid amount must be greater than 
      //   ${bidDetails?.currentBid || bidDetails?.openingPrice}`,
      //     "error"
      //   );
      // } 
      if (bidDetails?.currentBid && currentBidAmount < (bidDetails?.currentBid + (bidDetails?.currentBid * 0.1))) {
        PopUpAlert(
              "Alert",
              `Bid amount must be greater than or equal to
            ${(bidDetails?.currentBid + (bidDetails?.currentBid * 0.1)).toFixed(2)}`,
              "error"
            );
      } else if(!bidDetails?.currentBid && currentBidAmount < bidDetails?.openingPrice) {
        PopUpAlert(
          "Alert",
          `Bid amount must be greater than or equal to
        ${(bidDetails?.openingPrice).toFixed(2)}`,
          "error"
        );
      }
      else {
        setBidConfirmation(true);
      }
    }
  };

  return (
    <>
      <div className={styles.auctionContainer}>
        {!bidDetails.isExpired && !expired ? (
          <>
            <div className={styles.auctionPriceSection}>
              <div className={styles.auctionPricePoint}>
                <p>Current bid</p>
                {productDetails?.saleType === "fixed" ? (
                  <>
                    <div data-id="price">
                      <h2>
                        {`$ ${formatPrice(
                          productDetails?.regularPrice?.toFixed(2)
                        )}`}
                      </h2>
                    </div>
                    {/* <div className={styles.price} data-id="priceEth">
                                            ({productDetails?.ethAmount?.toFixed(4)} ETH)
                                          </div> */}
                  </>
                ) : (
                  <>
                    <div className={styles.price} data-id="auc-price">
                      <h2 className={styles.priceDisplay}>
                        $
                        {formatPrice(
                          (
                            productDetails?.auction?.currentBid ||
                            productDetails?.auction?.openingPrice
                          )?.toFixed(2)
                        )}
                      </h2>
                    </div>
                    {/* <div className={styles.price} data-id="auc-priceEth">
                                            ({productDetails.ethAmount?.toFixed(4)} wETH)
                                          </div> */}
                  </>
                )}
              </div>

              {!isUpcomingBid && (
                <>
                  <div className={styles.counter}>
                    <p>Auction ends in</p>
                    <div className={styles.counterWrap}>
                      {days!==0 && <div className={styles.counterDay}>
                        <div>
                          <h4 className={styles.timerContent}>{days}</h4>
                          <h5>Days</h5>
                        </div>
                        <span> </span>
                      </div>}
                      <div className={styles.counterHour}>
                        <div>
                          <h4 className={styles.timerContent}>{hour}</h4>
                          <h5>Hours</h5>
                        </div>
                        <span> </span>
                      </div>

                      <div className={styles.counterMinute}>
                        <div>
                          <h4 className={styles.timerContent}>{minutes}</h4>
                          <h5>Minutes</h5>
                        </div>
                        <span> </span>
                      </div>

                      <div className={styles.counterSecond}>
                        <div>
                          <h4 className={styles.timerContent}>{seconds}</h4>
                          <h5>Seconds</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {bidDetails.currentBidder !== userId?.toLowerCase() ? (
              <>
                {!show && !isUpcomingBid && (
                  <>
                    <button
                      className={styles.buttonFullWidth_buynow}
                      onClick={() => {
                        // disableScroll();
                        if (!walletStatus) {
                          // PopUpAlert(
                          //   "Alert",
                          //   "No wallet connection found.",
                          //   "error"
                          // ).then((res) => {
                          // enableScroll();
                          document.getElementById("connectWallet").click();
                          // });
                          return false;
                        } else if (
                          (!userAccount?.email &&
                            localStorage.getItem("userToken")) ||
                          (!userAccount?.firstName &&
                            localStorage.getItem("userToken")) ||
                          (!userAccount?.lastName &&
                            localStorage.getItem("userToken"))
                        ) {
                          PopUpAlert(
                            "Alert",
                            "Please complete your profile.",
                            "info"
                          ).then(() => {
                            // enableScroll();
                            history.push("/my-account");
                          });
                        } else if (loginWith!=='metamask' && isUserVerified != "verified") {
                          PopUpAlert(
                            "Alert",
                            "User is not verified. Please verify your account",
                            "error"
                          ).then(() => {
                            // enableScroll();
                            history.push("/my-account");
                          });
                        } else {
                          handleBidCheck();
                        }
                      }}
                      disabled={!bidButtonStatus || !checked}
                    >
                      Bid now
                    </button>
                    {!bidButtonStatus && (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "0.875rem",
                          fontWeight: 600,
                        }}
                      >
                        Please wait, another bid is in progress
                      </p>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        marginTop: 8,
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxCheck}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                        style={{ marginRight: 10 }}
                      />
                      <p
                        style={{
                          color: "#1d1d1d",
                          // margin: "0.313rem 0px",
                          fontSize: "0.75rem",
                          // marginBottom: -15,
                          // marginTop: 15,
                          width: screenWidth < 550 && "20rem",
                        }}
                      >
                        I acknowledge that I have read and accepted the{" "}
                        <Link to={`/TermsAndConditions`}>
                          terms and conditions
                        </Link>{" "}
                      </p>
                    </div>
                    <div className={styles.warningMessage}>
                      Users cannot participate in an auction using their credit
                      cards, you can either use crypto wallet (MATIC) or cash
                      wallet (USD) for the bidding.
                    </div>
                    {/* hiding this as it is not in new figma */}
                    {/* <p
                    style={{
                      color: "#FF76DC",
                      margin: "0.313rem 0px",
                      fontSize: 12,
                    }}
                  >
                    * By clicking BID NOW I acknowledge that I have read and
                    accepted the{" "}
                    <Link to={`/TermsAndConditions`}>Terms and Condition</Link>{" "}
                    of lii's marketplace.
                  </p> */}
                  </>
                )}

                {show && (
                  <form className={cn(styles.form)} onSubmit={handleBid}>
                    <div className={styles.formContain}>
                      <label style={{ fontSize: "0.875rem" }}>
                        Current bid (&#36;) :
                      </label>
                      <div
                        style={{
                          cursor: "not-allowed",
                          fontSize: "0.938rem",
                          width: "59.5%",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span style={{ width: "50%" }}>
                          {(
                            bidDetails?.currentBid || bidDetails?.openingPrice
                          ).toFixed(2) + " " || "0 "}
                        </span>
                        <span className={styles.flexCenter}>
                          (
                          {/* {(
                            (bidDetails?.currentBid ||
                              bidDetails?.openingPrice ||
                              0) * euroValue
                          ).toFixed(2)}{" "} */}
                          {(
                            (bidDetails?.currentBid ||
                              bidDetails?.openingPrice ||
                              0) 
                          ).toFixed(2)}{" "}
                          <img
                            src={`/images/usdc-logo.png`}
                            alt="USDC"
                            className={styles.maticSymbol}
                          />
                          )
                        </span>
                      </div>
                    </div>

                    {bidDetails?.bidIncrement && (
                      <div className={styles.formContain}>
                        <label>Bid increment(&#36;) :</label>
                        <div
                          style={{
                            cursor: "not-allowed",
                          }}
                        >
                          {bidDetails?.bidIncrement + " " || "0 "}(
                          {(bidDetails?.bidIncrement * euroValue).toFixed(4) ||
                            "0"}{" "}
                          ) MATIC
                        </div>
                      </div>
                    )}

                    {/* {bidDetails?.nextBid &&
                        <div className={styles.formContain}>
                          <label>Minimum Bid :</label>
                          <input name="increment" value={bidDetails?.nextBid || ""} className={styles.input}
                            style={{ cursor: "not-allowed" }} readOnly />
                        </div>
                      } */}
                    <div className={styles.formContain}>
                      <label style={{ fontSize: "0.875rem" }}>
                        Enter bid price (&#36;):
                      </label>
                      <div style={{ width: "60%" }}>
                        <input
                          className={styles.input}
                          name="bid"
                          type="text"
                          // onMouseUpCapture={(e) => e.preventDefault()}
                          // onMouseDown={(e) => e.preventDefault()}
                          // onMouseUp={(e) => e.preventDefault()}
                          onChange={(event) => {
                            if (/[0-9]/i.test(Number(event.target.value))) {
                              // if (event.which === 38 || event.which === 4) {
                              setBidAmount(event.target.value);
                              setCurrentBidAmount(event.target.value);
                            }
                            // }
                          }}
                          value={currentBidAmount}
                          max={100000000000}
                        />
                        <p className={styles.inputETH}>
                          {/* <img src="/images/wethlogo.png" alt="ETH"
                              style={{ width: 18, height: 25, margin: "0px 0.188rem", marginTop: -3 }} /> */}
                          {/* {bidAmount ? (bidAmount * euroValue)?.toFixed(2) : 0}{" "} */}
                          {bidAmount ? (parseFloat(bidAmount) )?.toFixed(2) : 0}{" "}
                          <img
                            src={`/images/usdc-logo.png`}
                            alt="USDC"
                            className={styles.maticSymbol}
                          />
                        </p>
                      </div>
                    </div>
                    <button
                      className={"button-small " + styles.submitt}
                      style={{
                        marginRight: 15,
                        background: "#ee3951",
                      }}
                      onClick={() => setShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={"button-small " + styles.submitt}
                      type="submit"
                      onClick={(e) => handleBidOuterButton(e)}
                    >
                      Bid
                    </button>
                  </form>
                )}
                {show && productDetails?.isBuyNowForAuction && (
                  <div className={styles.formContain}>
                    <label>Buy Now price (&#36;) :</label>
                    <input
                      className={styles.input}
                      style={{ marginLeft: 10 }}
                      name="bid"
                      type="number"
                      min={"1"}
                      value={productDetails?.regularPrice}
                      readOnly
                    />
                    <button
                      className={"button-small " + styles.submitt}
                      onClick={claimNft}
                    >
                      Buy now
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div>
                <p className={styles.highBid}>
                  You are the highest bidder for this asset
                </p>
              </div>
            )}
            {isUpcomingBid && (
              <div style={{ marginTop: "1.25rem" }}>
                <p style={{ color: "#f95555" }}>Auction hasn't started yet.</p>
              </div>
            )}
          </>
        ) : bidDetails.currentBidder !== userId?.toLowerCase() ? (
          <div>
            <p style={{ color: "#f95555" }}>
              You can't purchase this asset. This auction is expired.
            </p>
            {/* {
              bidDetails.currentBidder === userId
                ? <div>
                  <button
                    className={cn("button-stroke", styles.button)}
                    style={{ width: "100%" }}
                    onClick={claimNft}
                  >
                    Claim NFT
                  </button>
                </div>
                :
               
            } */}
          </div>
        ) : (
          <div>
            <p className={styles.highBid}>You won the bid.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Counter;
