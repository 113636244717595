import React, { useState, useEffect } from "react";
import styles from "./useritem.module.sass";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { current_datetime } from "../../controller/utils";
import { useHistory } from "react-router";
import cn from "classnames";
import useWindowSize from "../../common/hooks/useWindowSize";
import moment from "moment";

const CheckoutReceipt = (props) => {
	const order = props.location?.state?.details;
	const name = props.location.state.name;
	const datetime = new Date();
	const history = useHistory();
	const polygon = process.env.REACT_APP_POLYGON_LINK;
	const [screenWidth] = useWindowSize();

	return (
		<div
			className={cn("container", styles.container)}
			style={{ marginBottom: 50 }}
		>
			<div
				className={styles.title}
				style={{
					borderBottom: "0.063rem solid #999",
					width: "100%",
					padding: "1.25rem 0px",
					marginBottom: "30",
				}}
			>
				<div
					className={styles.backArrowCircle}
					onClick={() => history.goBack()}
				>
					<img
						src={"/Icons/backArrow.svg"}
						alt="back arrow"
						style={{
							height:"1.25rem",
							width:"1.25rem"
						  }}
					/>
				</div>
				<div className={styles.pageTitle}>Transaction details</div>
			</div>
			<div
				className={
					screenWidth > 766
						? styles.detailsCheckout
						: styles.mobileDetailsCheckout
				}
				style={{ padding: 10 }}
			>
				<div className={styles.col_1}>
					{" "}
					<div className={styles.productDetails}>
						<div>
							{order?.asset?.mediaType === "audio" && (
								<div
									className={styles.imagePlayerWrapper}
									style={{
										position: "relative",
										paddingTop: 0,
									}}
								>
									{/* <div> */}
									<img
										src={order?.asset?.audioThumbnail}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											borderRadius: "0.625rem",
										}}
										alt="thumbnail.png"
									/>
									<audio
										controls
										controlsList="nodownload"
										style={{
											position: "absolute",
											bottom: 0,
											left: 0,
											height: 25,
											width: "100%",
										}}
									>
										<source src={order?.mediaPreviewUrl} type="audio/mpeg" />
									</audio>
									{/* </div> */}
								</div>
							)}
							{order?.asset?.mediaType === "video" && (
								<div
									className={styles.imagePlayerWrapper}
									// style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
									style={{
										position: "relative",
										paddingTop: 0,
									}}
								>
									<ReactPlayer
										url={
											order?.asset?.mediaPreviewUrl
												? order?.asset?.mediaPreviewUrl
												: ""
										}
										//   url={order?.mediaPreviewUrl}
										width="100%"
										height="100%"
										controls={true}
										light={false}
										// style={{ position: "absolute", top: "0", left: "0" }}
										style={{ borderRadius: "0.625rem" }}
										config={{
											file: {
												attributes: {
													disablePictureInPicture: true,
													controlsList: "nodownload",
												},
											},
										}}
									/>
								</div>
							)}
							{(order?.asset?.mediaType === "image" ||
								order?.asset?.mediaType === "3D Model") && (
								<div
									className={styles.imagePlayerWrapper}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										// objectFit: "cover",
									}}
								>
									<img
										src={
											order?.asset?.thumbnailUrl ||
											order?.asset?.mediaPreviewUrl
										}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											borderRadius: "0.625rem",
										}}
										alt="thumbnail.png"
									/>
								</div>
							)}
							{/* <span style={{ marginLeft: 0 }}>
              {"Product Name: " + order?.name}
            </span> */}
						</div>
					</div>
				</div>
				{screenWidth > 766 ? (
					<WebUi order={order} polygon={polygon} props={props} />
				) : (
					<MobileUi order={order} polygon={polygon} props={props} />
				)}
				{/* <div>
          <div className={styles.singleDetail1}>Product Name</div>
          <div className={styles.singleDetail}>{name}</div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Ordered at</div>
          <div className={styles.singleDetail}>
            {" "}
            {current_datetime(order?.createdAt) || "-"}
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Transaction Hash</div>
          <div className={styles.singleDetail}>
            <a
              href={`${process.env.REACT_APP_POLYGON_LINK}tx/${order?.orderTx}`}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#ff7cba" }}
            >
              {order?.orderTx || "-"}
            </a>
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Payment method</div>
          <div className={styles.singleDetail}>
            {" "}
            {props.location.state?.paymentType || "Ethereum Wallet"}
          </div> */}
			</div>
			{/* <div >
          <div className={styles.singleDetail}>Order Number</div>
          <div className={styles.singleDetail}> {order?.id || "-"} </div>
        </div> */}
			{/* <div>
          <div className={styles.singleDetail1}> Buyer Address</div>
          <div className={styles.singleDetail}> {order?.from || "-"} </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Quantity</div>
          <div className={styles.singleDetail}>{order?.quantity || "-"} </div>
        </div> */}
			{/* <div >
          <div className={styles.singleDetail}>Seller Address</div>
          <div className={styles.singleDetail}>{order?.to || "-"}</div>
        </div> */}
			{/* <div>
          <div className={styles.singleDetail1}>Price</div>
          <div className={styles.singleDetail}>
            &#36; {(order?.unitPrice).toFixed(2) || "-"}
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>NFT Id</div>
          <div className={styles.singleDetail}>
            {(order?.orderTx ? order?.assetId : "-") || "-"}
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Contract Number</div>
          <div className={styles.singleDetail}>
            {(order?.orderTx ? process.env.REACT_APP_CONTACT_NO : "-") || "-"}
          </div>
        </div> */}
			{/* </div> */}
		</div>
	);
};

const WebUi = ({ order, polygon, props }) => {
	return (
		<>
			<div className={styles.col_2}>
				<GetInfoRow
					label="Product name"
					data={order?.asset?.name ? order?.asset?.name : "-"}
				/>
				<GetInfoRow
					label="Transaction hash"
					data={
						order?.orderTx ? (
							<a
								href={`${polygon}tx/${order?.orderTx}`}
								target="_blank"
								rel="noreferrer"
							>
								{order?.orderTx || "-"}
							</a>
						) : (
							"-"
						)
					}
				/>
				<GetInfoRow label="Buyer address" data={order?.from || "-"} />
				<GetInfoRow
					label="Price"
					data={<>&#36; {(order?.unitPrice).toFixed(2) || "-"}</>}
				/>
				<GetInfoRow
					label="Contract address"
					data={
						(order?.orderTx
							? process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV
							: "-") || "-"
					}
				/>
			</div>
			<div className={styles.col_3}>
				<GetInfoRow
					label="Ordered at"
					data={moment.utc(order?.createdAt).local().format('DD/MM/YYYY h:mm:ss A') || "-"}
				/>
				<GetInfoRow
					label="Payment method"
					data={props.location.state?.paymentType || "Ethereum Wallet"}
				/>
				<GetInfoRow label="Quantity" data={order?.quantity || "-"} />
				<GetInfoRow
					label="NFT id"
					data={(order?.asset?.tokenId ? order?.asset?.tokenId : "-") || "-"}
				/>
			</div>
		</>
	);
};

const MobileUi = ({ order, polygon, props }) => {
	return (
		<>
			<div className={styles.col_2}>
				<GetInfoRow
					label="Product Name"
					data={order?.asset?.name ? order?.asset?.name : "-"}
				/>
				<div style={{ display: "flex" }}>
					<div style={{ marginRight: "1.25rem" }}>
						<GetInfoRow
							label="Ordered at"
							data={moment.utc(order?.createdAt).local().format('DD/MM/YYYY h:mm:ss A') || "-"}
						/>
					</div>
					<GetInfoRow
						label="Price"
						data={<>&#36; {(order?.unitPrice).toFixed(2) || "-"}</>}
					/>
				</div>
				<GetInfoRow
					label="Transaction Hash"
					data={
						order?.orderTx ? (
							<a
								href={`${polygon}tx/${order?.orderTx}`}
								target="_blank"
								rel="noreferrer"
							>
								{order?.orderTx || "-"}
							</a>
						) : (
							"-"
						)
					}
				/>
				<GetInfoRow
					label="Payment method"
					data={props.location.state?.paymentType || "Ethereum Wallet"}
				/>
				<GetInfoRow label="Buyer address" data={order?.from || "-"} />
				<GetInfoRow label="Quantity" data={order?.quantity || "-"} />
				<GetInfoRow
					label="NFT Id"
					data={(order?.asset?.id ? order?.asset?.id : "-") || "-"}
				/>
				<GetInfoRow
					label="Contract Address"
					data={
						(order?.orderTx
							? process.env.REACT_APP_CONTRACT_ADDRESS_DEV
							: "-") || "-"
					}
				/>
			</div>
		</>
	);
};

const GetInfoRow = ({ label, data }) => {
	return (
		<div className={styles.infoRow}>
			<div className={styles.infoLabel}>{label}</div>
			<div className={styles.infoContent}>{data}</div>
		</div>
	);
};

export default CheckoutReceipt;
