import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./LatestNews.module.sass";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LatestNews = ({ data }) => {
	const Card = ({ index, title, date, news }) => {
		const history = useHistory()
		return (
			<>
				<div className={styles.latestNewsCard}>
					<div
						className={styles.latestHeading}
						style={{ cursor: "pointer" }}
						onClick={()=> {
							history.push(`/news/${news?.id}`)
						}}
					>
						{title}
					</div>
					<div className={styles.timeDate}>
						{moment(date).format("MMMM Do YYYY")} |{" "}
						{moment(date).format("h:mm a")}
					</div>
				</div>
				<div className={styles.divider}>.</div>
			</>
		);
	};
	
	return (
		<div className={cn("container", styles.container)}>
			<div className={styles.latestNewsWrapper}>
				{data?.map((news, index) => {
					// if (index > 0 && index < 5) {
						return (
							<Card
								key={index}
								index={index}
								title={news?.title}
								date={news?.publishedDate}
								news={news}
							></Card>
						);
					// }
				})}
			</div>
		</div>
	);
};

export default LatestNews;
