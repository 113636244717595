import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";

const Dropdown = ({ className, value, setValue, options, defaultVal }) => {
	const [visible, setVisible] = useState(false);
	const handleClick = (value) => {
		setValue(value);
		setVisible(false);
	};

	return (
		<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
			<div
				className={cn(styles.dropdown, className, { [styles.active]: visible })}
			>
				<div className={styles.head} onClick={() => setVisible(!visible)}>
					<div
						className={styles.selection}
						style={value ? { color: "black" } : { color: "#D0D0D0" }}
					>
						{value
							? value.charAt(0).toUpperCase() + value.slice(1)
							: defaultVal}
					</div>
					<div className={styles.arrow}>
						<Icon name="newarrowBottom" size="13" />
					</div>
				</div>
				<div className={styles.body}>
					{options.map((x, index) => (
						<Link
							to={`/search01/${x}`}
							key={index}
							style={{ color: "black" }}
							onClick={() => {
								setVisible(false);
								setValue();
								if (window.innerWidth > 740)
									window.location.pathname == `/search01/${x}` &&
										window.location.reload();
							}}
						>
							<div
								className={cn(styles.option, {
									[styles.selectioned]: x === value,
								})}
								// onClick={() => handleClick(x, index)}
								key={index}
							>
								{x}
							</div>
						</Link>
					))}
				</div>
			</div>
		</OutsideClickHandler>
	);
};

export default Dropdown;
