import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function Variants() {
  return (
    <>
      {/* <Stack spacing={2}> */}
      {/* For other variants, adjust the size with `width` and `height` */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20%",
          marginLeft: "10%",
          marginTop: "7%",
          marginRight: "5%",
          width: "100%",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={500}
          height={500}
          sx={{ maxWidth: 500, minWidth: 10, m: 2 }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginRight: "10%",
            width: "40%",
          }}
        >
          <Skeleton variant="rectangular" width={620} height={50} />
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Skeleton variant="rectangular" width={200} height={50} />
            <Skeleton variant="rectangular" width={200} height={50} />
          </div>
          <Skeleton variant="rectangular" width={200} height={50} />
          <Skeleton variant="rectangular" width={620} height={100} />
          <Skeleton variant="rectangular" width={620} height={100} />
          <Skeleton variant="rectangular" width={620} height={300} />
        </div>
      </div>
      {/* </Stack> */}
    </>
  );
}