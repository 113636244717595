import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Useracc.module.sass";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utils/API";
import { useParams, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setAccountUserName, setProvider } from "../../redux/counterSlice";
// import BackArrow from "../../../public/Icons/backArrow.svg";
import { SocialLinks, TYPE_MOBILE } from "social-links";
import { PopUpAlert } from "../../controller/utils";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { CircularProgress, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import useWindowSize from "../../common/hooks/useWindowSize";

import VerifyKYCButton from "../../screens/KYCSumSub/VerifyKYCButton";

import PhoneInput from "react-phone-input-2";
import { acceptOnlyNumbers } from "../../utils/helper";

import Select from "react-select";
import { setProfileDetails } from "../../redux/profileSlice";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 0.125rem ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "0.063rem solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `0.125rem solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Useraccount = ({ className, value }) => {
  const [productDetails, setProductDetails] = React.useState();
  const [details, setDetails] = React.useState({});
  const accountDetails = useSelector((state) => state?.accountReducer);
  const [address, setAddress] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [profilePictureFlag, setProfilePictureFlag] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [blockedCountries, setBlockedCountries] = useState([]);
  const [emailId,setUserEmailId] = useState(null)
  const { id } = useParams();
  const history = useHistory();
  const status = useSelector((state) => state.counter.value);
  const editProfilePictureRef = useRef();
  const socialLinks = new SocialLinks();
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  const isUserVerified = useSelector((state) => state?.accountReducer?.status);
  const currentAccountInfo = useSelector((state) => state?.accountReducer);
  const [screenWidth] = useWindowSize();
  const [KYCModalOpen, setKYCModalOpen] = useState(false);
  const isCreator = currentAccountInfo.isCreatedByAdmin;

  const [searchTerm, setSearchTerm] = useState({})
  const [mobileCountry, setMobileCountry] = useState('us')

  // Refs to control keyboard navigation
  const inputRefs = useRef([]);

  const dispatch = useDispatch();
  const getConfig = () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    if (!localStorage.getItem("userToken")) {
      PopUpAlert("Alert", "No wallet connection found", "error");
      return;
    }
    return config;
  };

  const getBlockedCountries = () => {
    const config = getConfig();
    axiosInstance
      .get(`/kyc/allowed-countries`, config)
      .then((res) => {
        console.log("Profile.edit.allowed countries", res);
        var result = Object.keys(res?.data?.result).map((key) => {
          return {
            value: res.data.result[key],
            [key]: res.data.result[key],
            label: key,
          };
        });
        if (result?.length) setBlockedCountries(result);
      })
      .catch((err) => {
        console.log("getBlockedCountries err", err);
      });
  };

 

  const getProductDetails = async () => {
    if (!status) {
      // PopUpAlert('Disconnected', 'Connect to view your account details', 'warning');
      history.push("/search01/all");
      return;
    }
    const config = getConfig();
    await axiosInstance
      .get(`user/get-profile`, config)
      .then(async (res) => {
        const {
          firstName,
          lastName,
          email,
          mobileNumber,
          userName,
          profilePicUrl,
          instagramUrl,
          facebookUrl,
          twitterUrl,
          description,
          country,
          dob,
          emailVerified,
          isCreatedByAdmin,
        } = res.data?.result;
        if(email){
          setUserEmailId(email);
        }
        if (profilePictureFlag) {
          await setDetails({
            ...details,
            profilePicUrl: profilePicUrl,
          });
          await setProfilePictureFlag(false);
        } else {
          setDetails({
            firstName,
            lastName,
            email,
            mobileNumber,
            userName,
            profilePicUrl,
            description,
            country,
            dob,
            emailVerified,
            instagramUrl: instagramUrl ? instagramUrl : "",
            twitterUrl: twitterUrl ? twitterUrl : "",
            facebookUrl: facebookUrl ? facebookUrl : "",
            isCreatedByAdmin: isCreatedByAdmin,
          });
        }
        setProfileDetails(res)
      })
      .catch((err) => {
        PopUpAlert("Alert", err.response.data.message, "warning");
        history.push("/search01/all");
      });
  };

  React.useEffect(() => {
    getProductDetails(id);
    const acc = JSON.parse(localStorage.getItem("cs_details"))?.account;
    setAddress(acc);
  }, [status, editProfilePictureRef, refreshComponent]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    if (name === "mobileNumber") {
      let val = acceptOnlyNumbers(value);
      setDetails({ ...details, [name]: val });
      return;
    }
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  
  const handleCountrySelection =(e)=>{
    setDetails({ ...details, country:e.value});
    setSearchTerm(e)
    setMobileCountry(e.value.toLowerCase())
  }

  const handleImageUpload = (e) => {
    document.getElementById("my_file").click();
  };

  const uploadFile = (e) => {
    setLoaderShow(true);
    const config = getConfig();
    const pic = document.getElementById("profile_pic");
    let files = e.target.files[0];
    let formData = new FormData();
    formData.append("image", files);
    console.log(formData);
    axiosInstance
      .post(`/user/update-profile-pic`, formData, config)
      .then((res) => {
        setProfilePictureFlag(true);
        setRefreshComponent(!refreshComponent);
        setLoaderShow(false);
        PopUpAlert(
          "Great!",
          "Profile Pic successfully uploaded",
          "success"
        ).then((res) => {
          // console.log("Profile picture uploaded sucessfully", res);
          // window.location.reload();
        });
      })
      .catch((err) => {
        setLoaderShow(false);
        PopUpAlert("Alert", err?.response?.data?.message, "error");
        e.target.value = null;
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let specialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    // Basic Validation

    if (!details?.firstName) {
      PopUpAlert("Alert", "Please enter a valid first name.", "error");
      return false;
    } else if (specialCharacter.test(details?.firstName)) {
      PopUpAlert(
        "Alert",
        "Special Character not allowed in firstname.",
        "error"
      );
      return false;
    } else if (!details?.lastName) {
      PopUpAlert("Alert", "Please enter a valid last name.", "error");
      return false;
    } else if (specialCharacter.test(details?.lastName)) {
      PopUpAlert(
        "Alert",
        "Special Character not allowed in lastname.",
        "error"
      );
      return false;
    } else if (!details?.email) {
      PopUpAlert("Alert", "Please enter a valid Email.", "error");
      return false;
    } else if (!details?.userName) {
      PopUpAlert("Alert", "Please enter a valid username.", "error");
      return false;
    }

    //social link validaton

    const isValidSocialLink = (platform, url) => {
      let regex;

      if (platform === "facebook") {
        regex = /^(https?:\/\/)?(www\.)?facebook\.com\/.*/i;
      } else if (platform === "twitter") {
        regex = /^(https?:\/\/)?(www\.)?twitter\.com\/.*/i;
      } else if (platform === "instagram") {
        regex = /^(https?:\/\/)?(www\.)?instagram\.com\/.*/i;
      }

      return regex.test(url);
    };

    //Social Media Link validation
    if (details?.instagramUrl || details?.twitterUrl || details?.facebookUrl) {
      if (details?.facebookUr && !isValidSocialLink("facebook", details?.facebookUr)) {
        PopUpAlert("Alert", "Please enter a valid facebook link.", "error");
        return;
      }
      if (details?.twitterUrl && !isValidSocialLink("twitter", details?.twitterUrl)) {
        PopUpAlert("Alert", "Please enter a valid twitter link.", "error");
        return;
      }
      if (details?.instagramUrl && !isValidSocialLink("instagram", details?.instagramUrl)) {
        PopUpAlert("Alert", "Please enter a valid instagram link.", "error");
        return;
      }
    }

    // if(details?.description.replace(/ /g,'') === '') {
    //   PopUpAlert("Alert", "Please enter bio.", "error");
    //   return false;
    // }

    const config = getConfig();
    let popupMessage =
      isUserVerified == "verified"
        ? currentAccountInfo?.email != details?.email
          ? `Profile details successfully updated.`
          : `Profile details successfully saved.`
        : `Profile details successfully saved.`;
    axiosInstance
      .patch(`/user/update/`, details, config)
      .then((res) => {
        getProductDetails();
        PopUpAlert("Great!", popupMessage, "success").then((res) =>
          history.push("/search01/all")
        );
        dispatch(
          setAccountUserName(`${details?.firstName} ${details?.lastName}`)
        );
        setEdit(false);
        // window.location.reload(false);
      })
      .catch((err) => {
        PopUpAlert(
          "Alert",
          err.response?.data?.message || "something went wrong",
          "error"
        );
      });
      
  };

  // To control keyboard naviagation

  const handleKeyDown = (event, index) => {
    console.log("Navigation.tab.index", index);
    if (event.key === "Tab") {
      event.preventDefault();
      navigateToNextInput(index);
    }
  };

  const navigateToNextInput = (currentIndex) => {
    const nextIndex = currentIndex + 1;
    console.log(
      "Navigation.tab.Next nextIndex inputRefs.current.length",
      nextIndex,
      inputRefs.current.length
    );
    if (nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
    // If you want to loop back to the first input when reaching the last one:
    // else {
    //   inputRefs.current[0].focus();
    // }
  };

  // const navigateToNextInput = (currentIndex) => {
  //   const nextIndex = currentIndex + 1;
  //   if (nextIndex < inputRefs.current.length) {
  //     console.log(
  //       "Navigation.tab.Next nextIndex inputRefs.current.length",
  //       nextIndex,
  //       inputRefs.current.length
  //     );
  //     if (inputRefs.current[nextIndex].nodeName === "SELECT") {
  //       // If the next element is a <select>, move to the next one instead
  //       navigateToNextInput(nextIndex);
  //     } else {
  //       inputRefs.current[nextIndex].focus();
  //     }
  //   }
  //   // If you want to loop back to the first input when reaching the last one:
  //   // else {
  //   //   inputRefs.current[0].focus();
  //   // }
  // };

  const getLastNameAndEmail = () => (
    <>
      <div className={styles.inputBox}>
        <label htmlFor="lastName" className={styles.required}>
          Last name
        </label>
        <input
          type="text"
          className={styles.lastName}
          name="lastName"
          value={details.lastName}
          onChange={handleChange}
          maxLength={"30"}
          ref={(ref) => (inputRefs.current[1] = ref)}
          onKeyDown={(e) => handleKeyDown(e, 1)}
        />
      </div>
      <div className={styles.inputBox}>
        <label htmlFor="email" className={styles.required}>
          Email
        </label>
        <input
          value={details.email}
          onChange={handleChange}
          name={"email"}
          type="email"
          required
          pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+"
          title="Enter a valid email"
          // disabled={
          //   magicLinkUser && localStorage.getItem("network") === "magic"&&emailId
          // }
          disabled={emailId!==null&&true}
          ref={(ref) => (inputRefs.current[3] = ref)}
          onKeyDown={(e) => handleKeyDown(e, 3)}
        />

        {Number(accountDetails?.emailVerified) === 1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              marginTop: "0.625rem",
            }}
          >
            <button
              disabled={true}
              className={styles.verifyBtn}
              onClick={(e) => {
                e.preventDefault();
                setKYCModalOpen({ open: true, mode: "kycEmail" });
              }}
            >
              Email Verified
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              marginTop: "0.625rem",
            }}
          >
            <button
              className={styles.verifyBtn}
              onClick={(e) => {
                e.preventDefault();
                setKYCModalOpen({ open: true, mode: "kycEmail" });
              }}
            >
              Verify
            </button>
          </div>
        )}
      </div>
    </>
  );


  const handleChangePhoneCode = async(value, data, event, formattedValue)=>{

    console.log("dialcode",data.dialCode);
    console.log(formattedValue)
    console.log("ev",event.target.value)
    // let phoneNumber ="+"+data.dialCode+" "+value
let phoneNumber =value
console.log("phoneNumber",phoneNumber)

setPhone(phoneNumber)
setDetails({ ...details, mobileNumber: phoneNumber });
  }


  const getFacebookAndInstagramUrl = () => {
    return (
      <>
        {isCreator && (
          <>
            <div  className={[styles.inputBox, styles.userForm].join(' ')}>
              <label htmlFor="facebook">Facebook profile url</label>
              <input
                type="text"
                className={styles.userName}
                name="facebookUrl"
                value={details.facebookUrl}
                placeholder={"https://www.facebook.com/xxxxxx"}
                onChange={handleChange}
              />
            </div>
            <div className={[styles.inputBox, styles.userForm].join(' ')}>
              <label htmlFor="instagram">Instagram profile url</label>
              <input
                type="text"
                className={styles.userName}
                name="instagramUrl"
                value={details.instagramUrl}
                placeholder={"https://www.instagram.com/xxxxxx"}
                onChange={handleChange}
              />
            </div>
            <div className={[styles.inputBox, styles.userForm].join(' ')}>
              <label htmlFor="twitter">Twitter profile url</label>
              <input
                type="text"
                className={styles.userName}
                name="twitterUrl"
                value={details.twitterUrl}
                placeholder={"https://twitter.com/xxxxxx"}
                onChange={handleChange}
              />
            </div>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    getBlockedCountries();
    let dateEl = document.getElementById("datePickerId");
    if (dateEl) dateEl.max = new Date().toISOString().split("T")[0];
  }, []);

  useEffect(()=>{
    if(details.country && blockedCountries){
    const selectedCountry = blockedCountries.filter(e=>e.value == details.country)
    setSearchTerm(selectedCountry[0])
    }
      },[details,blockedCountries])

  return (
    <>
      {loaderShow && (
        <div className={styles.loaderContent}>
          <CircularProgress color="black" />{" "}
        </div>
      )}
      {KYCModalOpen.open ? (
        <VerifyKYCButton
          KYCModalOpen={KYCModalOpen}
          setKYCModalOpen={setKYCModalOpen}
          mode={KYCModalOpen.mode}
        />
      ) : (
        ""
      )}
      <div className={cn("container",styles.userForm , styles.container) }>
        <div
          className={styles.title}
          style={{
            // borderBottom: "0.063rem solid #999",
            width: "100%",
            padding: "0.625rem 0px",
          }}
        >
          <div className={styles.heading}>
            <div
              className={styles.backArrowContainer}
              onClick={() =>
                history?.location?.state?.magicRedirect
                  ? history.push("/dashboard")
                  : history.goBack()
              }
            >
              <img
                src={"/Icons/backArrow.svg"}
                alt="back arrow"
                style={{
                  height:"1.25rem",
                  width:"1.25rem"
                }}
              />
            </div>
            <h2>Edit profile</h2>
          </div>
        </div>
        <div className={cn(styles.detailContainer , styles.userForm)}>
          <form className={cn(styles.form)} onSubmit={handleSubmit}>
            <div className={ cn(styles.userForm ,styles.editProfileContainer)}>
              {/* {magicLinkUser?.publicAddress && (
                <div className={styles.magicLinkDetails}>
                  <p>Magic Link Wallet ID : {magicLinkUser?.publicAddress}</p>
                  <p>
                    Magic Balance :{" "}
                    {Number(magicLinkUser?.magicBalance).toFixed(3)} MATIC
                  </p>
                </div>
              )} */}
              <div className={cn(styles.userForm ,styles.editProfileContent)}>
                <div
                  className={cn(styles.userForm ,styles.col_1)}
                  style={{
                    alignItems: "center",
                    marginTop: "1.563rem",
                  }}
                >
                  <div className={cn(styles.userForm ,styles.profilePicture)}>
                    <img
                      src={
                        details?.profilePicUrl
                          ? details?.profilePicUrl
                          : "/images/user.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className={cn(styles.userForm ,styles.editProfilePicture)}>
                    <img
                      src={"/Icons/editPencil.svg"}
                      alt=""
                      className={styles.editProfilePicIcon}
                      width="2.75rem"
                      height="2.75rem"
                      onClick={handleImageUpload}
                    />
                  </div>
                  <div className={cn(styles.userForm ,styles.profilePicSubText)}>
                    <p>Allowed file extensions : .png, .jpg, .jpeg</p>
                  </div>
                </div>
                <div className={cn(styles.userForm ,styles.col_2)}>
                  <input
                    type="file"
                    id="my_file"
                    style={{ display: "none" }}
                    onChange={uploadFile}
                    accept="image/*"
                  />

                  <div className={cn(styles.userForm ,styles.inputBox)}>
                    <label htmlFor="FirstName" className={styles.required}>
                      First name
                    </label>
                    <input
                      type="text"
                      className={styles.firstName}
                      name="firstName"
                      value={details.firstName}
                      onChange={handleChange}
                      maxLength={"30"}
                      ref={(ref) => (inputRefs.current[0] = ref)}
                      onKeyDown={(e) => handleKeyDown(e, 0)}
                    />
                  </div>
                  {screenWidth < 639 && getLastNameAndEmail()}
                  <div className={cn(styles.userForm ,styles.inputBox)}>
                    <label htmlFor="userName" className={styles.required}>
                      Username
                    </label>
                    <input
                      type="text"
                      className={styles.userName}
                      name="userName"
                      value={details.userName}
                      onChange={handleChange}
                      maxLength={"30"}
                      ref={(ref) => (inputRefs.current[2] = ref)}
                      onKeyDown={(e) => handleKeyDown(e, 2)}
                    />
                  </div>
                    <div style={{width:'100%', margin:'1.5rem',height:'45px'}}>
                    <div style={{textAlign:'left'}}>
                      <label htmlFor="country" className={styles.required}>
                        Country
                      </label>
                      <Tooltip
                        title="To comply with legal regulations and platform policies, access to lii is prohibited from the following countries: Afghanistan, Belarus, Central African Republic, Congo (Democratic Republic), Cuba, Guinea-Bissau, Iran, Iraq, Korea (Democratic People's Republic), Libya, Mali, Myanmar, Russia, Somalia, South Sudan, Sudan, Syrian Arab Republic, Ukraine, Venezuela (Bolivarian Republic), and Yemen"
                        aria-label="Prohibited countries"
                      >
                        <InfoIcon style={{ fontSize: "medium" }} />
                      </Tooltip>
                    </div>
            
                     <Select
                        menuPlacement="top"
                        options={blockedCountries}
                        value={searchTerm}
                        onChange={handleCountrySelection}
                      />
                  
                  </div>
                  <div className={cn(styles.userForm ,styles.inputBox)}>
                    <label htmlFor="dob" className={styles.required}>
                      DOB
                    </label>
                    <input
                      type="date"
                      id="datePickerId"
                      className={styles.firstName}
                      name="dob"
                      value={details.dob}
                      onChange={handleChange}
                      ref={(ref) => (inputRefs.current[6] = ref)}
                      onKeyDown={(e) => handleKeyDown(e, 6)}
                    />
                  </div>
                  {screenWidth > 638 && getFacebookAndInstagramUrl()}
                </div>
                <div className={cn(styles.userForm ,styles.col_3)}>
                  {/* {screenWidth > 638 ? getLastNameAndEmail() : <span></span>} */}
                  {screenWidth > 638
                    ? getLastNameAndEmail()
                    : getFacebookAndInstagramUrl()}
                  {/*  */}
                  <div className={styles.inputBox}>
                    <label htmlFor="mobileNumber" className={styles.required}>
                      Mobile
                    </label>
                    <PhoneInput
                     enableSearch={true}
                    country={mobileCountry}
                    value={details.mobileNumber}
                    onChange={handleChangePhoneCode}
                  />
                    {/* <input
                      ref={(ref) => (inputRefs.current[4] = ref)}
                      type="text"
                      className={styles.firstName}
                      name="mobileNumber"
                      value={details.mobileNumber}
                      onChange={handleChange}
                      // onKeyDown={(e) => handleKeyDown(e, 0)}
                    /> */}
                  </div>
                  {isCreator && (
                    <div className={styles.inputBox}>
                      <label htmlFor="description">Bio</label>
                      <textarea
                        type="text"
                        className={styles.firstName}
                        name="description"
                        value={details.description}
                        onChange={handleChange}
                        maxlength="250"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={cn(styles.userForm ,styles.editProfileFooter)}>
                <button
                  className={styles.cancelBtn}
                  style={{ marginLeft: 15 }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.reload();
                  }}
                >
                  Cancel
                </button>
                <button
                  className={styles.subscribeBtn}
                  style={{ marginLeft: 15 }}
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Useraccount;
