import { Box, Modal } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "./checkout.module.sass";
import { axiosInstance } from "../../utils/API";
import { PopUpAlert } from "../../controller/utils";
import * as openpgp from "openpgp";
import axios from "axios";
import { country, canadaStateList, UsStateList } from "../../controller/utils";
import Loader from "../Item/Loader.jsx";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const AddCard = ({ visible, closeModal }) => {
  const [fields, setFields] = useState({});
  const [error, setError] = useState({});
  const [ipAddress, setIpAddress] = useState(null);
  const [districtFieldShow, setDistrictFieldShow] = useState(false);
  const [stateList, setStateList] = useState(null);
  const secretKey = useSelector((state) => state?.keyVal?.keyData);
  const [ipAddress1, setIpAddress1] = useState(null);
  const [ipAddress2, setIpAddress2] = useState(null);
  const [ipAddress3, setIpAddress3] = useState(null);
  const [enableLoader, setEnableLoader] = useState(false);
  const userEmail = useSelector((state) => state?.accountReducer?.email);
  const isUserVerified = useSelector((state) => state?.accountReducer?.status);
  let loginWith = localStorage.getItem("network");

  const history = useHistory();
  useEffect(() => {
    getIP0()
    // getIP1();
    // getIP2();
    // getIP3();
  }, []);

  const getIP0 = async () => {
    let result = await axiosInstance.get("/getIp");
    // setIpAddress1(result?.data?.ip);
  };
  const getIP1 = async () => {
    let result = await axios.get("https://api.ipify.org?format=json");
    setIpAddress1(result?.data?.ip);
  };

  const getIP2 = async () => {
    let result = await axios.get("http://ip-api.com/json/");
    setIpAddress2(result?.data?.query);
  };

  const getIP3 = async () => {
    let result = await axios.get(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=23a6bb5e8371490b98182c6ce55b67d9"
    );
    setIpAddress3(result?.data?.ip_address);
  };

  const cardNUmberFormat = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue?.length;

    // if (!previousValue || value.length > previousValue.length) {
    if (cvLength <= 4) {
      return currentValue;
    }
    if (cvLength <= 8) {
      return `${currentValue.slice(0, 4)} ${currentValue.slice(4)}`;
    }

    if (cvLength <= 12) {
      return `${currentValue.slice(0, 4)} ${currentValue.slice(
        4,
        8
      )} ${currentValue.slice(8, 12)}`;
    }

    return `${currentValue.slice(0, 4)} ${currentValue.slice(
      4,
      8
    )} ${currentValue.slice(8, 12)} ${currentValue.slice(12, 16)}`;
    // }
  };
  const expireValid = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue?.length;

    // if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 2) {
      return currentValue;
    }
    return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 6)}`;
  };

  const cvvValidation = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue?.length;

    // if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 3) {
      return currentValue;
    }
    return `${currentValue.slice(0, 3)}`;
  };
  const onHandleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    // //console.log('name>>>>',name);
    if (name === "card_number") {
      // //console.log('cardNUmberFormat(value)',cardNUmberFormat(value));
      value = cardNUmberFormat(value);
    } else if (name === "card_expiry") {
      value = expireValid(value);
    } else if (name === "country") {
      if (value === "US" || value === "CA") {
        if (value === "US") {
          setStateList(UsStateList);
        } else if (value === "CA") {
          setStateList(canadaStateList);
        }
        setDistrictFieldShow(true);
      } else {
        setDistrictFieldShow(false);
      }
    } else if (name === "card_cvv") {
      value = cvvValidation(value);
    }

    setFields({ ...fields, [name]: value });
  };
  const validationField = () => {
    let field = fields;
    let errors = {};
    let formIsValid = true;

    //Name
    // if (!field["name"]) {
    //   formIsValid = false;
    //   errors["name"] = "Please enter your name";
    // }
    if (!field["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "Please enter your first name";
    }
    if (!field["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "Please enter your last name";
    }
    // if (!field["email"]) {
    //   formIsValid = false;
    //   errors["email"] = "Please enter your email id";
    // }
    // if (typeof field["email"] !== "undefined") {
    //   if (
    //     !field["email"].match(
    //       /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
    //     )
    //   ) {
    //     formIsValid = false;
    //     errors["email"] = "Please enter valid email id";
    //   }
    // }
    if (!field["city"]) {
      formIsValid = false;
      errors["city"] = "Please enter your city";
    }
    if (!field["country"]) {
      formIsValid = false;
      errors["country"] = "Please enter your country";
    }
    if (!field["postalCode"]) {
      formIsValid = false;
      errors["postalCode"] = "Please enter your postal code";
    }
    if (!field["address_one"]) {
      formIsValid = false;
      errors["address_one"] = "Please enter your address";
    }
    // if (!field["address_two"]) {
    //   formIsValid = false;
    //   errors["address_two"] = "Please enter your address";
    // }
    if (!field["card_number"]) {
      formIsValid = false;
      errors["card_number"] = "Please enter your card number";
    }
    // if (!field["card_name"]) {
    //   formIsValid = false;
    //   errors["card_name"] = "Please enter name on your card.";
    // }
    if (typeof field["card_number"] !== "undefined") {
      const value = field["card_number"].replace(/[^\d]/g, "");
      if (value?.length < 15) {
        formIsValid = false;
        errors["card_number"] = "Please enter maximum 16 digits";
      }
    }
    // if (districtFieldShow) {
    //   if (!field["district"]) {
    //     formIsValid = false;
    //     errors["district"] = "Please select your district";
    //   }
    // }

    if (!field["card_expiry"]) {
      formIsValid = false;
      errors["card_expiry"] = "Please enter your card expiry";
    }
    //   if (typeof field["card_expiry"] !== "undefined") {
    //     const value = field['card_expiry'].replace(/[^\d]/g, '');
    //     if (value.length < 15) {
    //       formIsValid = false;
    //       errors["card_expiry"] = "Please enter maximum 16 digits";
    //     }
    //   }

    if (!field["card_cvv"]) {
      formIsValid = false;
      errors["card_cvv"] = "Please enter your card cvv";
    }
    console.log("Error card", errors);
    setError(errors);
    return formIsValid;
  };
  const getIP = async () => {
    // const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data);
    // setIpAddress(res.data.IPv4)
    let result = await axios.get("https://api.ipify.org?format=json");
    setIpAddress(result?.data?.ip);
  };
  const onHandleSubmit = async (e) => {
    const CIRCLE_PUBLIC_KEY = process.env.REACT_APP_CIRCLE_PUBLIC_KEY;
    if (validationField()) {
      setEnableLoader(true);
      //console.log('feil',fields);
      try {
        const url = process.env.REACT_APP_CIRCLE_API_URL + "encryption/public";
        // const key = await decryptData(CIRCLE_PUBLIC_KEY);
        const key = CIRCLE_PUBLIC_KEY;
        let configs = {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + key,
          },
        };
        const result = await axios.get(url, configs);
        const publicKeyArmored = result?.data?.data?.publicKey;
        const keyId = result?.data?.data?.keyId;

        const cardDetails = {
          number: fields?.card_number?.trim().replace(/\D/g, ""),
          cvv: fields?.card_cvv,
        };
        const message = await openpgp.createMessage({
          text: JSON.stringify(cardDetails),
        });
        const publicKey = await openpgp.readKey({
          armoredKey: window.atob(publicKeyArmored),
        });
        const encryptedData = await openpgp.encrypt({
          message,
          encryptionKeys: publicKey,
        });

        const expiry = fields?.card_expiry.split("/");
        const data = {
          cardName: `${fields?.firstName} ${fields?.lastName}`,
          billingDetails: {
            name: `${fields?.firstName} ${fields?.lastName}`,
            city: fields?.city,
            country: fields?.country,
            line1: fields?.address_one,
            line2: fields?.address_two ? fields?.address_two : "",
            district: fields?.district ? fields?.district : "MA",
            postalCode: fields?.postalCode,
          },
          metadata: {
            email: userEmail,
            // phoneNumber:'',
            sessionId: "DE6FA86F60BB47B379307F851E238617",
            ipAddress: ipAddress3
              ? ipAddress3
              : ipAddress2
              ? ipAddress2
              : ipAddress1,
          },
          idempotencyKey: "",
          keyId: keyId,
          encryptedData: window.btoa(encryptedData),
          expMonth: parseInt(expiry[0]),
          expYear: parseInt(expiry[1]),
        };
        let config = {
          headers: {
            Authorization: localStorage.getItem("userToken"),
          },
        };
        await axiosInstance
          .post("/cards", data, config)
          .then((res) => {
            setEnableLoader(false);
            PopUpAlert("success", "Card Added Successfully", "success");
            closeModal();
          })
          .catch((err) => {
            setEnableLoader(false);
            PopUpAlert("Alert", err?.response?.data?.message, "error");
          });
      } catch (err) {
        setEnableLoader(false);
        PopUpAlert(
          "Alert",
          err?.message ? err?.message : err?.data?.response?.error,
          "error"
        );
      }
    }
  };
  return (
    // <Modal open={visible} onClose={closeModal} style={{ zIndex: "99" }}>
    //   <Box className={styles.modalDialog}>
    //     {" "}
    <div className={styles.addCardModal}>
      {/* <button className={styles.close} onClick={closeModal}>
            &#10006;
          </button> */}
      <h2>Add card</h2>
      <form>
        {/* <div className={styles.formGroup}>
              <label>Name on card.</label>
              <input
                type="text"
                className={styles.formControl}
                name="card_name"
                value={fields?.card_name}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.card_name && (
                <label className={styles.error}>{error?.card_name}</label>
              )}
            </div> */}
        <div className={styles.formGroup}>
          <label>Card number</label>
          <p style={{ color: "#AFAFAF" }}>
            Enter the 16 digit card number on the card
          </p>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              placeholder="0000   0000   0000  0000"
              className={styles.formControl}
              name="card_number"
              value={fields?.card_number}
              onChange={(e) => onHandleChange(e)}
            />
            {/* <img
              src="/images/master.svg"
              alt="card"
              style={{ marginLeft: "-2.5rem" }}
            /> */}
          </div>
          {error?.card_number && (
            <label className={styles.error}>{error?.card_number}</label>
          )}
        </div>
        <div className={styles.row} style={{ flexWrap: "unset" }}>
          <div className={styles.col} style={{ width: "calc(100% /2 - 0.625rem)" }}>
            <div className={styles.formGroup}>
              <label>Card CVV</label>
              <input
                type="password"
                className={styles.formControl}
                name="card_cvv"
                value={fields?.card_cvv}
                placeholder="XXX"
                onChange={(e) => onHandleChange(e)}
              />
              {error?.card_cvv && (
                <label className={styles.error}>{error?.card_cvv}</label>
              )}
            </div>
          </div>
          <div className={styles.col} style={{ width: "calc(100% /2 - 0.625rem)" }}>
            <div className={styles.formGroup}>
              <label>Card expiry</label>
              <input
                type="text"
                className={styles.formControl}
                name="card_expiry"
                value={fields?.card_expiry}
                placeholder="MM/YYYY"
                onChange={(e) => onHandleChange(e)}
              />
              {error?.card_expiry && (
                <label className={styles.error}>{error?.card_expiry}</label>
              )}
            </div>
          </div>
        </div>
        {/* 
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.formGroup}>
                  <label>First name</label>
                  <input
                    type="text"
                    className={styles.formControl}
                    name="firstName"
                    value={fields?.firstName}
                    onChange={(e) => onHandleChange(e)}
                  />
                  {error?.firstName && (
                    <label className={styles.error}>{error?.firstName}</label>
                  )}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.formGroup}>
                  <label>Last name</label>
                  <input
                    type="text"
                    className={styles.formControl}
                    name="lastName"
                    value={fields?.lastName}
                    onChange={(e) => onHandleChange(e)}
                  />
                  {error?.lastName && (
                    <label className={styles.error}>{error?.lastName}</label>
                  )}
                </div>
              </div>
            </div> */}
        <div className={styles.addCardName}>
          {/* <div className={styles.formGroup}>
                <label>Last name</label>
                <input
                  type="text"
                  className={styles.formControl}
                  name="name"
                  value={fields?.name}
                  onChange={(e) => onHandleChange(e)}
                />
                {error?.name && (
                  <label className={styles.error}>{error?.name}</label>
                )}
              </div> */}
          {/* <label>Name</label>
              <input
                type="text"
                className={styles.formControl}
                name="name"
                value={fields?.name}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.name && (
                <label className={styles.error}>{error?.name}</label>
              )} */}
        </div>
        {/* <div className={styles.formGroup}>
              <label>Email</label>
              <input
                type="text"
                className={styles.formControl}
                name="email"
                value={fields?.email}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.email && (
                <label className={styles.error}>{error?.email}</label>
              )}
            </div> */}
        {/* <div className={styles.formGroup}>
                <label>Phone</label>
                <input type="text" className={styles.formControl} name="phone" value={fields?.phone} onChange={(e)=>onHandleChange(e)}/>
                {error?.phone && <label className={styles.error}>{error?.phone}</label>}
            </div> */}
        <div className={styles.row}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.formGroup}>
                <label>First name</label>
                <input
                  type="text"
                  className={styles.formControl}
                  name="firstName"
                  value={fields?.firstName}
                  onChange={(e) => onHandleChange(e)}
                />
                {error?.firstName && (
                  <label className={styles.error}>{error?.firstName}</label>
                )}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.formGroup}>
                <label>Last name</label>
                <input
                  type="text"
                  className={styles.formControl}
                  name="lastName"
                  value={fields?.lastName}
                  onChange={(e) => onHandleChange(e)}
                />
                {error?.lastName && (
                  <label className={styles.error}>{error?.lastName}</label>
                )}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.formGroup}>
              <label>Address one</label>
              <input
                type="text"
                className={styles.formControl}
                name="address_one"
                value={fields?.address_one}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.address_one && (
                <label className={styles.error}>{error?.address_one}</label>
              )}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.formGroup}>
              <label>City</label>

              <input
                type="text"
                className={styles.formControl}
                name="city"
                value={fields?.city}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.city && (
                <label className={styles.error}>{error?.city}</label>
              )}
            </div>
          </div>
          {/* <div className={styles.col}>
                <div className={styles.formGroup}>
                  <label>Address Two</label>
                  <input
                    type="text"
                    className={styles.formControl}
                    name="address_two"
                    value={fields?.address_two}
                    onChange={(e) => onHandleChange(e)}
                  />
                  {error?.address_two && (
                    <label className={styles.error}>{error?.address_two}</label>
                  )}
                </div>
              </div> */}
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.formGroup}>
              <label>Country</label>
              <select
                className={styles.formControl}
                name="country"
                value={fields?.country}
                onChange={(e) => onHandleChange(e)}
              >
                <option value="">Select the Country</option>
                {country?.map((each, i) => (
                  <option key={i} value={each?.code}>
                    {each?.name}
                  </option>
                ))}
              </select>
              {error?.country && (
                <label className={styles.error}>{error?.country}</label>
              )}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.formGroup}>
              <label>Postal code</label>
              <input
                type="text"
                className={styles.formControl}
                name="postalCode"
                value={fields?.postalCode}
                onChange={(e) => onHandleChange(e)}
              />
              {error?.postalCode && (
                <label className={styles.error}>{error?.postalCode}</label>
              )}
            </div>
          </div>
        </div>
        {/* {districtFieldShow && (
              <div className={styles.formGroup}>
                <label>District</label>
                <select
                  className={styles.formControl}
                  name="district"
                  value={fields?.district}
                  onChange={(e) => onHandleChange(e)}
                >
                  <option value="">Select the Country</option>
                  {stateList?.map((each, i) => (
                    <option key={i} value={each?.code}>
                      {each?.name}
                    </option>
                  ))}
                </select>
                {error?.district && (
                  <label className={styles.error}>{error?.district}</label>
                )}
              </div>
            )} */}

        <div className={styles.formGroup}>
          <div className={styles.payArea}>
            <button
              type="button"
              onClick={(e) => {
                if (!userEmail) {
                  // PopUpAlert(
                  //   "Alert..",
                  //   "No wallet connection found",
                  //   "error"
                  // ).then((res) => {
                  document.getElementById("connectWallet").click();
                  // });
                  return false;
                } else if (loginWith!=='metamask' && isUserVerified != "verified") {
                  PopUpAlert(
                    "Alert..",
                    "User is not verified. Please verify your account",
                    "error"
                  ).then(() => {
                    history.push("/my-account");
                  });
                } else {
                  onHandleSubmit(e);
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <Loader
        active={enableLoader}
        customize={true}
        title={"Please Wait"}
        content={""}
      />
    </div>
    //   </Box>
    // </Modal>
  );
};

export default AddCard;
