import React from "react";
import { useSelector } from "react-redux";
import styles from "./Item.module.sass";

const SuccessPopup = ({
	productDetails,
	amount,
	close,
	type,
	sourcePage = null,
}) => {
	const euroValue = useSelector((state) => state.counter.euroValue) || 0;

	return (
		<div className={styles.successPopup}>
			<div className={styles.successPopupContent}>
				<img src="/images/content/success.png" alt="success_img" />
				{type !== "fixed" ? (
					<p>
						Your bid for <b>{productDetails?.name}</b> with{" "}
						<b>{Number(amount)?.toFixed(2)} $</b> has been successfully placed{" "}
					</p>
				) : (
					<p>
						Your order for <b>{productDetails?.name}</b> with{" "}
						<b>
							{
								(sourcePage = "payWithCard"
									? Number(amount)?.toFixed(2)
									: (amount * euroValue)?.toFixed(4))
							}{" "}
							{(sourcePage = "payWithCard" ? "$" : "MATIC")}
						</b>{" "}
						has been successfully placed.{" "}
					</p>
				)}
				<button type="button" onClick={() => close()}>
					{" "}
					Done
				</button>
			</div>
		</div>
	);
};

export default SuccessPopup;
