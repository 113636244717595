import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
import Theme from "../Theme";
import axios from "axios";
import Swal from "sweetalert2";
import Checkbox from "./../Checkbox/index";
import { PopUpAlert } from "../../controller/utils";
import Icon from "../Icon";
import { useHistory } from "react-router";
import useDarkMode from "use-dark-mode";
import useWindowSize from "../../common/hooks/useWindowSize";
import validator from "validator";

const items = [
  {
    title: "",
    menu: [
      {
        title: "About lii",
        url: "/About",
      },
      // {
      //   title: "About Santa Casa da Misericórdia de Lisboa",
      //   url: "/AboutSantaCasa",
      // },
      {
        title: "FAQs",
        url: "/faqs",
      },
      {
        title: "Terms & Conditions",
        url: "/TermsAndConditions",
      },
      // {
      //   title: "Privacy Policy",
      //   url: "/PrivacyPolicy",
      // },
      {
        title: "Support",
        url: `${process.env.REACT_APP_SOCIAL_DISCORD_SUPPORT}`,
      },
    ],
  },
];

const validateEmail = (email) => {
  let isValid;
  isValid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  isValid = email?.split(".")[email.split(".")?.length - 1]?.length > 1;
  return isValid;
};

const Footers = () => {
  const history = useHistory();
  const darkMode = useDarkMode(true);
  const [screenWidth] = useWindowSize();

  const [email, setEmail] = useState("");
  const [terms, setTerms] = useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState(false);


  useEffect(() => {
    if (localStorage.getItem('subscriptionStatus')) {

      setSubscribeStatus(true)
    }

  }, [])
  const handleSub = (e) => {


    e.preventDefault();
    // if (!terms) {
    //   PopUpAlert('', 'Please check the privacy policy to subscribe', 'info')
    //   return
    // }
    if (!validateEmail(email) || !validator.isEmail(email)) {
      PopUpAlert("Alert", "Please enter a valid email.", "error");
      return false;
    }
    if (email?.length > 1) {
      axios
        .post(process.env.REACT_APP_EMAIL_NEWSLETTER, { email })
        .then((res) => {
          setSubscribeStatus(true)
          localStorage.setItem('subscriptionStatus',true)
          PopUpAlert("Great", "Thank you for your subscription", "success");
          setEmail('')
        })
        .catch((err) => {
          PopUpAlert("Alert", err.response.data.message, "error");
        });
    } else {
      PopUpAlert("Alert", "Please enter your email", "error");
    }
  };

  const homePage = (id) => {
    const discord = process.env.REACT_APP_SOCIAL_DISCORD;
    const instagram = process.env.REACT_APP_SOCIAL_INSTAGRAM;
    const tiktok = process.env.REACT_APP_SOCIAL_TIKTOK;
    if (id === 1) window.open(`${instagram}`, "_blank");
    if (id === 2) window.open(`${discord}`, "_blank");
    if (id === 3) window.open(`${tiktok}`, "_blank");
  };



  const getLinks = () => {
    return (
      <>
        <ul>
          <a className={styles.footerLink} href="/about">
            <li className={styles.hightLightColorNone}>About us</li>
          </a>
          <a className={styles.footerLink} href="/faq">
            <li className={styles.hightLightColorNone}>FAQ</li>
          </a>
          <a
            className={styles.footerLink}
            href={process.env.REACT_APP_SOCIAL_DISCORD_SUPPORT}
            target="blank"
          >
            <li className={styles.hightLightColorNone}>Support</li>
          </a>
        </ul>
        <ul>
          <a className={styles.footerLink} href="/TermsAndConditions">
            <li className={styles.hightLightColorNone}>Terms and conditions</li>
          </a>
          <a
            className={styles.footerLink}
            href="/TermsAndConditions?section=privacyPolicy"
          >
            <li className={styles.hightLightColorNone}>Privacy policy</li>
          </a>
          <a
            className={styles.footerLink}
            href="/TermsAndConditions?section=aml"
          >
            <li className={styles.hightLightColorNone}>AML KYC policy</li>
          </a>
        </ul>
      </>
    );
  };

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <p>
              {" "}
              Keep up with <br /> the latest news
            </p>

            {/* <a href="https://en.scml.pt/">
              <Image
                className={styles.santa_casa_logo}
                src="/images/logo_scmlWhite.png"
                srcDark="/images/curated_santa_casta_logo.png"
                alt="scml-white-logo.png"
              />
            </a> */}
            {/* <div className={styles.info}>NFT for Good Causes</div> */}
            <div
              className={styles.version}
            // style={{display:"none"}}
            >
              {/* <div className={styles.details}>{darkMode.value ? "Light theme" : "Dark theme"}</div>
              <Theme className="theme-big" /> */}
            </div>
          </div>
          {/* <div className={styles.col}>
            {items.map((x, index) => (
              <Group className={styles.group + " " + styles.footerLinks} item={x} key={index} />
            ))}
          </div> */}
          <div className={`${styles.col} ${styles.newsLetterWrap}`} style={{ height: "7.5rem", display: "flex", flexDirection: "column" }}>
            {subscribeStatus ? <>
              <div className={styles.category} style={{ marginBottom: 10 }}>
                You have successfully subscribed to the newsletter
              </div>
            </> :
              <>
                <div className={styles.category} style={{ marginBottom: 10 }}>
                  Subscribe to the newsletter
                </div>
                <div className={styles.text}>
                  <form onSubmit={handleSub} className={styles.form}>
                    <div className={styles.pseudoText}>
                      <img src="/images/content/email.svg"></img>
                      <input
                        type="text"
                        name="newsletter"
                        className={styles.input}
                        placeholder="Enter email address"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      // pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+"
                      />
                    </div>
                    {/* <input type="email" name="newsletter" className={styles.input} placeholder="Enter Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+"
                /> */}
                    <button className={styles.subscribeBtn}>Subscribe</button>
                    {/* <div style={{ margin: "0.625rem 0.625rem 0.625rem 0px", position: "relative" }}>
                  <div className={styles.terms}> */}
                    {/* <input type="checkbox" className={styles.termsBox}/> */}
                    {/* <Checkbox value={terms} onChange={() => setTerms(!terms)} /> */}
                    {/* <label style={{ verticalAlign: "top" }}>I have read and accepted the privacy policy.</label>
                  </div>
                </div> */}
                  </form>
                </div>
              </>
            }
            {/* <div className={styles.social_media_icon_wrap}>
              <button onClick={() => homePage(1)}><Icon className={styles.social_media_icons} name="instagram" /></button>
              <button onClick={() => homePage(2)}><Icon className={styles.social_media_icons} name="facebook" /></button>
              <button onClick={() => homePage(3)}><Icon className={styles.social_media_icons} name="twitter" /></button>
            </div> */}
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.copyright}>
            <a href="/" className={styles.hightLightColorNone} style={{ opacity: '0' }}>
              <Image
                className={styles.santa_casa_logo}
                src="/images/ASLY_LOGO.png"
                srcDark="/images/ASLY_LOGO.png"
                alt="scml-white-logo.png"
              />
            </a>
          </div>
          <div className={styles.aboutpanel}>
            {screenWidth > 474 ? (
              getLinks()
            ) : (
              <div className={styles.about_ul}>{getLinks()}</div>
            )}

            <div className={styles.communityTab} style={{ opacity: 0 }}>
              <p>Join the community</p>
              <div className={styles.socialMediaHandles}>
                <div className={styles.social_media_icon_wrap}>
                  {/* <button onClick={() => homePage(1)}>
                    <img src="/Icons/insta.svg" alt="social" height={"1.25rem"} />
                  </button> */}
                  {/* <button onClick={() => homePage(2)}>
                    <img
                      src="/Icons/discord.svg"
                      alt="social"
                      height={"1.25rem"}
                    />
                  </button> */}
                  {/* <button onClick={() => homePage(3)}>
                    <img src="/Icons/tiktok.svg" alt="social" height={"1.25rem"} />
                  </button> */}
                  {/* <button onClick={() => homePage(3)}>
                    <Icon
                      className={styles.social_media_icons}
                      name="twitter"
                    />
                  </button> */}

                  {/* <button onClick={() => homePage(2)}>
                    <Icon
                      className={styles.social_media_icons}
                      name="linkedin"
                    />
                  </button>
                  <button onClick={() => homePage(2)}>
                    <Icon className={styles.social_media_icons} name="reddit" />
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
