import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./useritem.module.sass";
import { useParams } from "react-router-dom";
import API, { axiosInstance } from "../../utils/API";
import Card from "./../../components/Card";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import axios from "../../utils/API";
import { CircularProgress } from "@material-ui/core";


const ResellItem = () => {
  const [params, setParams] = useState({ limit: 8, page: 1 });
  const [count, setCount] = useState(5);
  const [assets, setAssets] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);


  const loginStatus = useSelector((state) => state.counter.value);
  const euroValue = useSelector((state) => state.counter.euroValue);

  const getWindowSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getAllAssets();
    setIsLoading(false)
    window.addEventListener("resize", getWindowSize);

    return () => window.removeEventListener("resize", getWindowSize);
  }, [params]);

  const getAllAssets = async () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      params,
    };

    try {
      let userData = await API.get("/user/orders/reselled/", config);
      setAssets(userData?.data?.result?.rows || []);
      setCount(userData?.data?.result?.totalPages || 0);
    } catch (err) {}
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setParams({ ...params, page: selectedPage + 1 });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      {/* <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div
            className={styles.title}
            style={{
              borderBottom: "0.063rem solid #999",
              width: "100%",
              padding: "1.25rem 0px",
            }}
          >
            Active Listing
          </div> */}
            {isLoading && (
              <div className={styles.loaderContent}>
                <CircularProgress color="black" />
              </div>
            )}
      {loginStatus ? (
        <div className={styles.viewContainer}>
          {assets?.length !== 0 ? (
            <>
              {/* <div className={styles.list}> */}
              {assets?.map((x, index) => (
                <Card
                  className={styles.card}
                  item={x}
                  key={index}
                  type={"view"}
                  orderId="157"
                  showLabel={true}
                  price={euroValue}
                  sourcePage={"activeListing"}
                  hidePurchaseToolBar={true}
                />
              ))}
              {/* </div> */}

              {assets?.length > 0 && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={count}
                  onPageChange={handlePageClick}
                  forcePage={params?.page - 1}
                  containerClassName={styles.pagination}
                  previousLinkClassName={cn(
                    styles.pagination__link,
                    "page_switch"
                  )}
                  // previousClassName={cn(styles.pagination__button, "page_switch")}
                  nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                  pageClassName={"page_no"}
                  disabledClassName={styles.pagination__link__disabled}
                  activeClassName={styles.pagination__link__active}
                  pageRangeDisplayed={width < 640 ? 1 : 3}
                  marginPagesDisplayed={width < 640 ? 1 : 4}
                />
              )}
            </>
          ) : (
           
           !isLoading && <div className={`noRecordsFound ${styles.noRecordsFound}`}>
              {" "}
              No assets listed yet, click on My NFT to explore products.
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div style={{ textAlign: "center", fontSize: '0.938rem' }}>
            Connect Wallet to view products.
          </div>
        </div>
      )}
      {/* </div>
      </div> */}
    </>
  );
};

export default ResellItem;
