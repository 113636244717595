import React, { useEffect, useState } from "react";
import styles from "./CirclePayment.module.sass";
import copy from "copy-to-clipboard";
import { axiosInstance } from "../../utils/API";
import { PopUpAlert } from "../../controller/utils";
import Alert from "@material-ui/lab/Alert";

function CryptoPayment() {
	const [cryptoDetails, setCryptoDetails] = useState([]);
	const [copied, setCopied] = useState(false);
	let config = {
		headers: {
			Authorization: localStorage.getItem("userToken"),
		},
	};

	useEffect(() => {
		getCryptoPaymentDetails();
	}, []);

	const copyToClipboard = () => {
		copy(cryptoDetails?.address);
		setCopied(true);
	};

	const getCryptoPaymentDetails = async () => {
		await axiosInstance
			.get(`/user/blockchain/address`, config)
			.then((res) => {
				setCryptoDetails(res?.data?.result?.[0]);
			})
			.catch((err) => {
				PopUpAlert("Alert", err?.response?.data?.message, "error");
			});
	};

	return (
		<div>
			<div className={styles.cryptoAddressLabel}>Deposit address</div>
			<div className={styles.cryptoQrCode}>
				{cryptoDetails?.qrcodeBase64 ? (
					<img
						src={`${cryptoDetails?.qrcodeBase64}`}
						alt="qrcode"
						height="202px"
					/>
				) : null}
			</div>
			<div className={styles.cryptoAddress}>{cryptoDetails?.address}</div>
			<div className={styles.warningMessage}>
				{" "}
				<Alert severity="info" style={{ marginTop: "0.6em" }}>
					Only USDC is accepted.
				</Alert>
				{/* <Alert severity="warning">Please switch to polygon network.</Alert> */}
			</div>

			<div className={styles.cryptoAddressButtonWrapper}>
				<div className={styles.paymentButton} onClick={copyToClipboard}>
					{copied ? "Copied" : "Copy address"}
				</div>
			</div>
		</div>
	);
}

export default CryptoPayment;
