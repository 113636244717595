import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import cn from "classnames";
import styles from "./ViewAssets.module.sass";
import Icon from "../../components/Icon";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import DropdownLink from "../../components/DropDownLink";
import RangeSlider from "../../components/RangeSlider";
import TuneIcon from "@material-ui/icons/Tune";
import CloseIcon from "@material-ui/icons/Close";
import ReactPaginate from "react-paginate";
import API from "../../utils/API";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { setRefresh, setResetSearchBar } from "../../redux/utilitySlice";
import Catagories from "../../components/Header/Catagories";
import Slider from "@material-ui/core/Slider";
import useWindowSize from "../../common/hooks/useWindowSize";
import { useDebounce } from "../../common/hooks/useDebounce";
import { CircularProgress } from "@material-ui/core";
import CollectionCard from "../Home/Collections/CollectionCard";
import { useThrottle } from "../../common/hooks/useThrottle";
const navLinks = ["All items", "NFT", "Game", "Photography", "Music", "Video"];
const dateOptions = ["Newest", "Oldest"];
const sortOptions = [
  "Price - lowest",
  "Price - highest",
  "Latest",
  "Most viewed",
  "Most popular",
];
const colorOptions = ["Active", "Inactive"];
const types = ["All", "Fixed price", "Auction", "Free"];
const PopularityTypes = ["High to low", "Low to high"];
const categories = ["NFT", "Music"];

const useStyles = makeStyles({
  paper: {
    width: "100%",
  },
});

const ViewAsset = () => {
  const classes = useStyles();
  const { catagory } = useParams();
  const euroValue = useSelector((state) => state.counter.euroValue);
  const resetSearchBar = useSelector(
    (state) => state?.utilityReducer?.resetSearchBar
  );
  const loginStatus = useSelector((state) => state.counter.value);
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [price, setPrice] = useState("");
  const [color, setColor] = useState(colorOptions[0]);
  const [saleType, setsaleType] = useState("All");
  const [tab, setTab] = useState("");
  const [refreshFilter, setRefreshFilter] = useState(true);
  const [resetRangeSlider, setResetRangeSlider] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [categoryFilterObj, setCategoryFilterObj] = useState(null);
  const [loaderShow, setLoaderShow] = useState(false);
  // const [search, setSearch] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [values, setValues] = useState([5]);
  const [assets, setAssets] = useState([]);
  const [collections, setCollections] = useState([]);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(1);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [count, setCount] = useState(0);
  const [screenWidth] = useWindowSize();
  const [visibleNav, setVisibleNav] = useState(false);
  const [previousSearchText, setPreviousSearchText] = useState("");
  const [value, setValue] = useState([22, 50]);
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(true);
  const urlParam = queryString.parse(search);
  const { pathname } = history.location;
  // usePRevious Hook
  const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  };

  const previousSearchData = usePrevious(history.location?.state?.searchData);

  const [filterDrawerState, setFilterDrawerState] = React.useState({
    left: false,
    right: false,
  });

  const toggleFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFilterDrawerState({ ...filterDrawerState, [anchor]: open });
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    //enable scrolling
    var body = document.querySelector("body");
    body.style.overflow = "auto";
  }, []);

  useDebounce(
    () => {
      if (
        history.location?.state?.searchData != undefined &&
        previousSearchData !== history.location?.state?.searchData
      ) {
        getAllAssets(price);
        setPage(1);
      }
    },
    [history.location.state?.searchData, min, max],
    1000
  );

  useEffect(() => {

    getAllAssets(price);
    // To reset to 1st page when searching
    // if (
    //   history.location?.state?.searchData &&
    //   previousSearchData !== history.location?.state?.searchData
    // ) {
    //   setPage(1);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // saleType,
    date,
    // search,
    refreshFilter,
    categoryFilter,
    page,
    // catagory,
    categoryFilterObj,
    // min,
    // max,
    // history.location.state?.searchData,
    refresh,
    // collections
  ]);

  useEffect(()=> {
    if(saleType==='collections') {
      getAllAssets(price)
    }
  }, [saleType])


  const handleSubmit = ()=> {
    screenWidth < 475 && setVisibleNav(!visibleNav);
    getAllAssets(price);
    setPage(1);

  }
  useThrottle(()=> getAllAssets(price), [search, history.location.state?.searchData], 500)

  const getCategoryList = async () => {
    const result = await API.get("/asset/categories?page=1&limit=100")
      .then((result) => {
        setCategoryFilterObj(result?.data?.result?.categories?.rows);
        let categories = result?.data?.result?.categories?.rows?.map(
          (category) => category.name
        );
        setCategoryList(["All", ...categories]);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (tab === "explore") {
      setsaleType("All");
    }
  }, [tab]);

  // Page back handle using url parameter
  useEffect(() => {
    if (urlParam?.tab)
      setTab(urlParam?.tab)

    if (urlParam?.pn) {
      setPage(urlParam?.pn);
      // To know search term, if we are coming back from product detail page
    }
    if (urlParam?.search) {
      setPreviousSearchText(urlParam?.search);
    }
    if (urlParam?.tab === "collections") {
      setsaleType("collections");
      setPage(1);
    }
    // To know search term, if we are coming back from product detail page

  }, [urlParam]);

  const getAllAssets = async (e) => {
    setLoaderShow(true);
    let userData;

    console.log(
      "let me",
      history.location.state?.searchData?.length,
      previousSearchText
    );

    try {
      let params = {
        limit: 6,
        saleType: saleType === "collections" ? "collection" : saleType,
        page: page == 1 ? urlParam?.pn ? urlParam?.pn : 1 : page,
        min,
        max,
        search: history.location?.state?.searchData
          ? history.location.state?.searchData
          : previousSearchText
            ? previousSearchText
            : "",
        category: categoryFilterObj?.filter(
          (item) => item.name == categoryFilter
        )[0]?.id,
      };

      if (saleType == "Fixed price") {
        params = { ...params, saleType: "fixed" };
      }
      if (saleType == "Auction") {
        params = { ...params, saleType: "auction" };
      }
      if (saleType == "All") {
        params = { ...params, saleType: "" };
      }
      if (saleType == "Free") {
        params = { ...params, saleType: "free" };
      }


      if (catagory) {
        let mainCategory = categoryFilterObj?.find(
          (item) => item.name === catagory
        )?.id;
        if (!mainCategory && catagory.toLowerCase() != "all") {
          let subCategory = catagory;
          params = { ...params, subCategory };
        } else {
          params = { ...params, category: mainCategory };
        }
      }

      if (e === sortOptions[0]) {
        params = { ...params, price: "low_to_high" };
      } else if (e === sortOptions[1]) {
        params = { ...params, price: "high_to_low" };
      } else if (e === sortOptions[2]) {
        params = { ...params, latest: true };
      } else if (e === sortOptions[3]) {
        params = { ...params, mostView: true };
      } else if (e === sortOptions[4]) {
        params = { ...params, popularity: true };
      } else if (e === sortOptions[5]) {
        params = { ...params, popularity: false };
      } else {
      }

      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
        params,
      };

      setPrice(e);
      userData = await API.get(
        "/asset/all",
        loginStatus
          ? config
          : {
            params,
          }
      );
      // console.log(userData.data?.result?.collections?.rows, 'userdata')
      // console.log(saleType, 'saleType')
      setCount(userData.data?.result?.totalPages);
      saleType === "collections"
        ? setCollections(userData.data?.result?.collections?.rows)
        : setAssets(userData.data?.result?.assets?.rows);
      setLoaderShow(false);
    } catch (err) {
      console.log(err);
      setLoaderShow(false);
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log("qassss");
    setPage(selectedPage + 1);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    history.push(
      `/search01/${history.location?.pathname?.split("/")?.[
      history.location?.pathname?.split("/")?.length - 1
      ]
      }?pn=${selectedPage + 1}&search=${history.location?.state?.searchData
        ? history.location?.state?.searchData
        : previousSearchText
          ? previousSearchText
          : ""
      }`
    );
  };

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };
  

  const handleReset = () => {
    screenWidth < 475 && setVisibleNav(!visibleNav);
    setsaleType("All");
    setDate("");
    setMin("");
    setPrice("");
    setMax("");
    setRefreshFilter(!refreshFilter);
    setCategoryFilter("");
    setResetRangeSlider(!resetRangeSlider);
    history.replace("/search01/all");
    dispatch(setResetSearchBar(!resetSearchBar));
    setPage(1);
    setPreviousSearchText("");
  };

  const filterSection = () => (

    <>
      {tab !== "collections" && <div className={styles.filters + " okok"}>

        <div className={styles.range}>
          <div className={styles.labelHeading}>
            {screenWidth > 474 ? (
              <>
                <img src="/images/content/filter.png" alt="filter"></img>Filters
              </>
            ) : (
              <>
                Filters
                <div className={styles.filter_icon}>
                  <CloseIcon onClick={() => setVisibleNav(!visibleNav)} />
                </div>
              </>
            )}
          </div>

          <label htmlFor="min" className={styles.subLabel}>
            Price range
          </label>
          <br />
          <div className={styles.filterRangeContainer}>
            <div className={styles.item}>
              {/* <div className={styles.subLabel}>Price Range</div> */}
              <RangeSlider
                // setMax={debounce((value) => {
                //   console.log({ value });
                //   setMax(value);
                //   let categorySelected = pathname
                //     ?.split("/")
                //   [pathname?.split("/")?.length - 1]?.split("?")?.[0];
                //   if (urlParam?.pn) {
                //     history.replace(
                //       `/search01/${categorySelected ? categorySelected : "all"}`
                //     );
                //     setPage(1);
                //     // To make page no 1. Back button implemented so we need to remove pn from url param also
                //   }
                //   setPage(1);
                // },500)}
                setMax = {(value) => setMax(value)}
                // setMin={debounce((value) => {
                //   setMin(value);
                //   setPage(1);
                // }, 500)}
                setMin = {(value)=>setMin(value) }
                resetRangeSlider={resetRangeSlider}
              />
            </div>
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.item}>
            <div className={styles.subLabel}>Category</div>
            <DropdownLink
              className={styles.dropdown}
              value={catagory}
              setValue={(value) => {
                setPage(1);
              }}
              options={categoryList}
              defaultVal="Select a category"
            />
          </div>
          <div className={styles.item}>
            <div className={styles.subLabel}>Sale type</div>
            <Dropdown
              className={styles.dropdown}
              value={saleType}
              setValue={(value) => {
                setsaleType(value);
                // let categorySelected = pathname
                //   ?.split("/")
                // [pathname?.split("/")?.length - 1]?.split("?")?.[0];
                // if (urlParam?.pn) {
                //   history.replace(`/search01/${categorySelected}`);
                //   setPage(1);
                //   // To make page no 1. Back button implemented so we need to remove pn from url param also
                // }
              }
            }
              options={types}
              defaultVal="Select a sale type"
            />
          </div>
          {/* <div className={styles.item}>
<div className={styles.label}>Category</div>
<Dropdown
  className={styles.dropdown}
  value={categoryFilter}
  setValue={setCategoryFilter}
  options={categoryList}
/>
<Catagories />
</div>*/}
        </div>
        <div className={styles.reset_done_btn_wrap}>
          <div className={styles.reset} onClick={handleReset}>
            {/* Below condition check to check any filter applied */}

            <button className={styles.customButton} type="button">
              Reset
            </button>
          </div>
          <div className={styles.reset}>
            <button
              className={styles.button_black}
              onClick={
                handleSubmit
              }
            >
              Apply
            </button>
          </div>

          {/* {screenWidth < 475 && (
  <span
    className={styles.done}
    onClick={() => setVisibleNav(!visibleNav)}
  >
    Done
  </span>
)} */}
        </div>

      </div>}
    </>
  );

  const userDbId = JSON.parse(localStorage.getItem("cs_details"))?.userId;

  //to check if loggedin user is the creator/artist/cocreator
  const checkIfCreatorOrArtist = () => {

    const productDetails = assets[0];

    if (userDbId) {
      const creatorId = productDetails?.creatorId
      console.log("creatorid", creatorId);
      let artistId;
      const coCreators = [];
      productDetails?.userAssetCommission?.forEach((item, index) => {
        if (item?.creatorType === "coCreator") {
          coCreators.push(item);
        } else if (item?.creatorType == "artist") {
          artistId = item;
        }
      });
      console.log("artossssssst", artistId);
      if (
        userDbId == creatorId ||
        artistId?.["userId"] == userDbId ||
        coCreators.some(
          (item) => item.hasOwnProperty("userId") && item["userId"] == userDbId
        )
      ) {
        return true;
      }

      return false;
    } else {
      return false;
    }
  };

  return (
    <div
      className={cn("section-pt80", styles.section)}
      style={{ position: "relative" }}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.title}></div>
          <div className={styles.search}>
            {tab !== "collections" &&
              <div className={styles.sort_container}>
                {screenWidth > 474 && (
                  <div className={styles.subLabel}>Sort by :</div>
                )}
                <Dropdown
                  className={styles.dropdown}
                  value={price}
                  setValue={(value) => {
                    getAllAssets(value);
                    let categorySelected = pathname
                      ?.split("/")
                    [pathname?.split("/")?.length - 1]?.split("?")?.[0];
                    if (urlParam?.pn) {
                      history.replace(
                        `/search01/${categorySelected ? categorySelected : "all"}`
                      );
                    }
                    setPage(1);
                  }}
                  options={sortOptions}
                  defaultVal="Select a filter"
                />
                {screenWidth < 475 && (
              <div className={styles.filter_icon}>
                <TuneIcon onClick={() => setVisibleNav(!visibleNav)} />
              </div>
            )}
              </div>
            }

            

            {/* <button className={styles.result}>
              <Icon name="search" size="16" />
            </button> */}
          </div>
        </div>

        <div className={styles.row}>
          {screenWidth > 474 ? filterSection(saleType) : ""}
          <div className={styles.wrapper + " productslist"}>
            {loaderShow && (
              <div className={styles.loaderContent}>
                <CircularProgress color="black" />
              </div>
            )}

            {tab!=="collections" && !loaderShow && assets?.length === 0 ? (
              <div
                className={styles.list}
                style={{
                  textAlign: "center",
                  // margin: 0,
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h4>No assets for current filter</h4>
              </div>
            ) : tab !== "collections" ? (
              <div className={styles.list}>
                {assets.map((x, index) => (
                  <Card
                    userDbId={userDbId}
                    className={styles.card}
                    item={x}
                    key={index}
                    price={euroValue}
                    marketplace={true}
                    setRefresh={() => setRefresh(!refresh)}
                  />
                ))}
              </div>
            ) : (!loaderShow && collections?.length === 0 ? <div
                className={styles.list}
                style={{
                  textAlign: "center",
                  // margin: 0,
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h4>No assets for current filter</h4>
              </div> : <div className={styles.list}>
              {collections.map((x, index) => (
                <div className={styles.collectionCardContainer}>
                  <CollectionCard
                    details={x}
                    currentTab={0}
                    key={index}
                    profileDetails={{ name: 1 }}
                  />
                </div>
              
              
                ))}
              </div>
            )}
            {(assets?.length > 0 || collections?.length > 0) && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={count}
                onPageChange={handlePageClick}
                forcePage={page - 1}
                containerClassName={styles.pagination}
                previousLinkClassName={cn(
                  styles.pagination__link,
                  "page_switch"
                )}
                // previousClassName={cn(styles.pagination__button, "page_switch")}
                nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                pageClassName={"page_no"}
                disabledClassName={styles.pagination__link__disabled}
                activeClassName={styles.pagination__link__active}
                pageRangeDisplayed={screenWidth < 640 ? 1 : 3}
                marginPagesDisplayed={screenWidth < 640 ? 1 : 4}
              />
            )}
          </div>
        </div>

        {/* {screenWidth < 474 && (
					<button
						className={styles.filter_btn}
						onClick={() => setVisibleNav(!visibleNav)}
					>
						Filter
					</button>
				)} */}
        {(screenWidth < 475 && tab !== 'collections') && (
          <div
            className={cn(styles.filter_drawer_wrapper, {
              [styles.active]: visibleNav,
            })}
          >
            <nav className={styles.nav}>
              <div
                className={styles.link}
                style={{ position: "relative", cursor: "pointer" }}
              >
                {filterSection()}
              </div>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewAsset;
