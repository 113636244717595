import React from "react";
import Hero from "./Hero";
import HotBid from "../../components/HotBid";

const Faq = () => {
  return (
    <>
      <Hero />
      {/* <HotBid classSection="section-pb" /> */}
    </>
  );
};

export default Faq;
