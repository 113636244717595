import React, { useState, useEffect } from "react";
import styles from "./wishlist.module.sass";
import { axiosInstance } from "../../utils/API";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import ReactPlayer from "react-player";
import { current_datetime } from "../../controller/utils";
import cn from "classnames";

import { PopUpAlert } from "../../controller/utils";
import useWindowSize from "../../common/hooks/useWindowSize";
import { CircularProgress } from "@material-ui/core";

const WishListDetails = () => {
  const history = useHistory();

  const status = useSelector((state) => state.counter.value);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;

  const [wishlist, setWishlist] = useState([]);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [params, setParams] = useState({ limit: 10, page: 1 });
  const [screenWidth] = useWindowSize();

  // let config = {
  //     headers: {
  //         Authorization: localStorage.getItem("userToken"),
  //     },
  // };

  useEffect(() => {
    getAllWishList();
  }, [refresh, params]);

  // Fetch wishlist list
  const getAllWishList = async () => {
    setLoader(true);
    if (localStorage.getItem("userToken")) {
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
        params,
      };
      try {
        let userData = await axiosInstance.get("user/wishlist", config);
        setWishlist(userData?.data?.result?.userWishList?.rows);
        setCount(userData?.data?.result?.totalPages);
        setLoader(false);
      } catch (err) {
        setLoader(false);
        // console.log({ err }, err?.response?.status);
        if (err?.response?.status == 404) {
          setWishlist([]);
          setCount(0);
        }
      }
    }
  };

  // Handle Pagination Page Click
  const handlePageClick = ({ selected: selectedPage }) => {
    setParams({ ...params, page: selectedPage + 1 });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    history.push(`/wishlist?pn=${selectedPage + 1}`);
  };

  // Remove Asset from wishlist

  const removeFromWishlist = (id) => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      data: {
        assetId: id,
      },
    };
    axiosInstance
      .delete(`/user/wishlist/remove`, config)
      .then((res) => {
        if (res) {
          // setEnableLoader(false);
          wishlist?.length == 1 &&
            setParams({ ...params, page: 1 }) &&
            history.push(`/wishlist`);
          setRefresh(!refresh);
          PopUpAlert("Success", "NFT removed from wishlist.", "success");
        }
      })
      .catch((err) => {
        setRefresh(!refresh);
        PopUpAlert(
          "Oops",
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message,
          "error"
        );
      });
  };

  // Asset Media Container
  const AssetMediaContainer = ({ item }) => {
    return (
      <div
        className={styles.wishListItem}
        style={{ padding: "0.313rem" }}
        key={item?.asset?.id}
      >
        <div>
          {item.mediaType === "audio" && (
            <div
              className={styles.playerwrapper}
              style={{
                position: "relative",
                paddingTop: 0,
              }}
            >
              {/* <div> */}
              <img
                src={item?.audioThumbnail}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="thumbnail.png"
              />
              <audio
                controls
                controlsList="nodownload"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 25,
                  width: "100%",
                }}
              >
                <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
              </audio>
              {/* </div> */}
            </div>
          )}
          {item.mediaType === "video" && (
            <div
              className={styles.playerwrapper}
              // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
              style={{
                position: "relative",
                paddingTop: 0,
              }}
            >
              <ReactPlayer
                url={item?.mediaPreviewUrl}
                width="100%"
                height="100%"
                controls={true}
                light={false}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div>
          )}
          {(item?.mediaType === "image" || item?.mediaType === "3D Model") && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={styles.playerwrapper}
            >
              <img
                src={item.thumbnailUrl || item?.mediaPreviewUrl}
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
                alt="thumbnail.png"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  // Wislist Each card
  const WishListCard = (props) => {
    const { item } = props;
    return (
      <>
        <div
          className={styles.wishlistCard}
          style={{
            boxShadow: "0px 0.563rem 0.938rem rgba(0, 0, 0, 0.08)",
          }}
        >
          <div style={{ marginRight: 10 }}>
            <AssetMediaContainer item={item?.asset} />
          </div>
          <div
            className={styles.col_2}
            style={{ padding: "0 0.5em 0.8em 0.5em", flex: "auto" }}
          >
            <div className={styles.row_1}>
              <p className={styles.title}>{item?.asset?.name}</p>
              {screenWidth > 766 && (
                <div className={styles.actionButtons}>
                  <button
                    onClick={() => history.push(`/item/${item?.asset?.id}`)}
                  >
                    View
                  </button>

                  <div
                    className={styles.closeButton}
                    onClick={() => removeFromWishlist(item?.asset?.id)}
                  >
                    <img src="/Icons/closeButton.svg" alt="close" />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.row_2}>
              <div className={styles.col_1}>
                {screenWidth > 766 && (
                  <div className={styles.dateLabel}>Asset type:</div>
                )}
                <div className={styles.dateWrapper}>
                  <span
                    className={styles.date}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item?.asset?.saleType}
                    {/* {current_datetime(item?.createdAt).split(" ")[0]}{" "} */}
                  </span>
                  {/* <span className={styles.time}>
                    {current_datetime(item?.createdAt).split(" ")[1]} UTC
                  </span> */}
                </div>
              </div>
              <div
                className={styles.col_2}
                style={
                  screenWidth < 767
                    ? {
                        flexDirection: "row",
                        padding: "0.313rem 0 0 0",
                      }
                    : { padding: "0px" }
                }
              >
                <div className={styles.priceLabel}>
                  {item?.asset?.saleType == "auction"
                    ? item?.asset?.auction?.isExpired
                      ? "Winning bid"
                      : "Current bid:"
                    : "Price"}
                </div>
                <div className={styles.priceWrapper}>
                  <span className={styles.price}>
                    ${" "}
                    {item?.asset?.saleType != "auction"
                      ? item?.asset?.isOnSell
                        ? item?.asset?.resellPrice?.toFixed(2)
                        : item?.asset?.regularPrice?.toFixed(2)
                      : item?.asset?.auction?.currentBid?.toFixed(2)}{" "}
                    ({" "}
                    {/* {item?.asset?.saleType != "auction"
                      ? item?.asset?.isOnSell
                        ? (item?.asset?.resellPrice * euroValue)?.toFixed(2)
                        : (item?.asset?.regularPrice * euroValue)?.toFixed(2)
                      : (item?.asset?.auction?.currentBid * euroValue)?.toFixed(
                          2
                        )}{" "} */}
                         {item?.asset?.saleType != "auction"
                      ? item?.asset?.isOnSell
                        ? (item?.asset?.resellPrice )
                        : (item?.asset?.regularPrice )
                      : (item?.asset?.auction?.currentBid )}{" "}
                    <img
                      src={`/images/usdc-logo.png`}
                      alt="USDC"
                      className={styles.maticSymbol}
                    />
                    )
                  </span>
                </div>
              </div>
              {screenWidth < 767 && (
                <span
                  className={
                    item?.asset?.availableStock > 0 &&
                    item?.asset?.status != "inactive"
                      ? styles.inStock_mobile
                      : styles.outOfstock_mobile
                  }
                >
                  {item?.asset?.saleType == "fixed" &&
                  item?.asset?.availableStock > 0 &&
                  item?.asset?.status != "inactive"
                    ? "In stock"
                    : item?.asset?.saleType == "fixed"
                    ? "Out of stock"
                    : null}
                  {item?.asset?.saleType == "auction" &&
                  item?.asset?.auction?.isExpired
                    ? "Auction expired"
                    : item?.asset?.saleType == "auction"
                    ? "Auction in progress"
                    : null}
                </span>
              )}
              {screenWidth > 766 && (
                <div className={styles.col_3}>
                  <div
                    className={
                      (item?.asset?.saleType == "fixed" &&
                        item?.asset?.availableStock > 0 &&
                        item?.asset?.status != "inactive") ||
                      (item?.asset?.saleType == "auction" &&
                        !item?.asset?.auction?.isExpired)
                        ? styles.inStock
                        : styles.outOfstock
                    }
                  >
                    <p>
                      {item?.asset?.saleType == "fixed" &&
                      item?.asset?.availableStock > 0 &&
                      item?.asset?.status != "inactive"
                        ? "In stock"
                        : item?.asset?.saleType == "fixed"
                        ? "Out of stock"
                        : null}
                      {item?.asset?.saleType == "auction" &&
                      item?.asset?.auction?.isExpired
                        ? "Auction expired"
                        : item?.asset?.saleType == "auction"
                        ? "Auction in progress"
                        : null}
                    </p>
                  </div>
                </div>
              )}
              {screenWidth < 767 && (
                <div className={styles.actionButton_mobile}>
                  <button
                    onClick={() => history.push(`/item/${item?.asset?.id}`)}
                  >
                    View
                  </button>

                  <div
                    className={styles.closeButton}
                    onClick={() => removeFromWishlist(item?.asset?.id)}
                  >
                    Remove
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        {status ? (
          <>
            <div
              className={styles.title}
              style={{
                width: "100%",
                padding: "1.25rem 0px",
                marginBottom: 45,
                display: "flex",
                flexDirection: "row",
                fontWeight: 600,
                fontSize: "1.875rem",
              }}
            >
              <div
                className={styles.backButtonWrapper}
                onClick={() => history.goBack()}
              >
                <img
                  src="/images/content/backArrow.svg"
                  height={40}
                  width={40}
                  style={{ marginRight: "0.625rem", opacity: 1 }}
                  alt="back"
                />
              </div>
              Wishlist
            </div>
            {/* loader */}
            {loader ? (
              <div className={styles.loaderContent}>
                <CircularProgress color="black" />{" "}
              </div>
            ) : wishlist?.length > 0 ? (
              wishlist?.map((asset, index) => {
                const item = asset;
                return <WishListCard key={index} item={item} />;
              })
            ) : (
              <span style={{ display: "flex", justifyContent: "center" }}>
                Wishlist is empty.
              </span>
            )}
            {wishlist?.length > 0 && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={count}
                forcePage={params?.page - 1}
                onPageChange={handlePageClick}
                containerClassName={styles.pagination}
                previousLinkClassName={cn(
                  styles.pagination__link,
                  "page_switch"
                )}
                nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                pageClassName={"page_no"}
                disabledClassName={styles.pagination__link__disabled}
                activeClassName={styles.pagination__link__active}
                pageRangeDisplayed={width < 510 ? 1 : 3}
                marginPagesDisplayed={width < 510 ? 1 : 3}
              />
            )}
            {/* )} */}
          </>
        ) : (
          <div
            style={{
              width: "90%",
              padding: "1.875rem 5%",
              margin: "auto",
              fontSize: '0.938rem',
            }}
          >
            Login to view details.
          </div>
        )}
      </div>
    </div>
  );
};

export default WishListDetails;
