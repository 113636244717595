import { useState, useEffect } from "react";
// import { Input, CallToAction } from '@magiclabs/ui';

import {
  magic,
  connector,
  getBalanceToken,
  getWeb3Provider,
  maticWeb3,
  PopUpAlert,
} from "../controller/utils";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  details,
  increment,
  setMagicUser,
  setBalance,
} from "./../redux/counterSlice";
import { axiosInstance } from "./../utils/API";
import { setBasicAccountDetails } from "./../redux/accountSlice";
import { useHistory } from "react-router-dom";
import axios from "./../utils/API";
import styles from "./counter/Counter.module.sass";
import Loader from "../screens/Item/Loader";
import { CircularProgress } from "@material-ui/core";
import { setAmount } from "../redux/usdcSlice";
import { getUsdcBalanceFromAccount } from "../utils/balance";

const EmailForm = (props) => {
  const [email, setEmail] = useState("");
  const [loaderShow, setLoaderShow] = useState(false);
  const [magicLinkLoader, setMagicLinkLoader] = useState(false);

  const magicUser = useSelector((state) => state.counter.magicUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("network") == "magic") {
      PopUpAlert(
        "Alert!",
        "You are currently connected with lii using the same mail id",
        "error"
      ).then(() => {
        history.replace("/");
      });
    } else {
      if (magicUser) {
        magic.user.logout().then(() => {
          dispatch(setMagicUser(null));
        });
      }
      try {
        setLoaderShow(true);
        // Trigger Magic link to be sent to user
        try {
          await magic.auth
            .loginWithEmailOTP({
              email,
              // redirectURI: new URL("/", window.location.origin).href, // optional redirect back to your app after magic link is clicked
            })
            .then((res) => {
              // magic.user.showSettings();
              history.push(`/?magic_credential=${res}`);
              //   finishEmailRedirectLogin();
              setLoaderShow(false);
            })
            .catch((err) => {
              setLoaderShow(false);
            });
          // .then(async() => history.push("/"));
          // history.push("/");
        } catch (error) {
          console.log("Magic link error", error);
          setLoaderShow(false);
        }

        // Validate didToken with server
        //   const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/login`, {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: 'Bearer ' + didToken,
        //     },
        //   });

        //   if (res.status === 200) {
        // Set the UserContext to the now logged in user
        //   let userMetadata = await magic.user.getMetadata();
        //   dispatch(setMagicUser(userMetadata));
        //   setDisabled(false);

        //   return userMetadata;
        //   }
      } catch (error) {
        // re-enable login button - user may have requested to edit their email
        console.log(error);
        setLoaderShow(false);
      }
    }
  };

  const fetchBalance = (address) => {
    let web3 = new Web3(maticWeb3);
    return web3.eth.getBalance(address).then((bal) => web3.utils.fromWei(bal));
  };

  const getConfig = () => {
    if (!localStorage.getItem("userToken")) {
      PopUpAlert("Alert", "No wallet connection found", "error");
      return;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    return config;
  };

  const finishEmailRedirectLogin = async () => {
    setMagicLinkLoader(true);
    const userMetadata = await magic.user.getMetadata();
    let magicBalance = await fetchBalance(userMetadata?.publicAddress);
    userMetadata.magicBalance = magicBalance;
    dispatch(setMagicUser(userMetadata));
    handleConnectWallet(null, true, userMetadata);
  };

  const handleConnectWallet = async (
    e,
    magiclink = false,
    magicData = null
  ) => {
    setMagicLinkLoader(true);
    let web3;
    try {
      if (magiclink) {
        web3 = new Web3(maticWeb3);
      }

      const account = await web3.eth.getAccounts();
      let balance = await web3.eth.getBalance(account[0]);
      balance = web3.utils.fromWei(balance, "ether");

      let usdcBalance = await getUsdcBalanceFromAccount(account[0]);

      if (account) {
        try {
          let signature = await web3.eth.personal.sign(
            "Welcome to lii",
            account[0],
            ""
          );

          let payload = {
            signature,
            publicAddress: account[0],
          };

          if (magicData) payload.email = magicData?.email;

          axios
            .post(`/user/authenticate`, payload)
            .then(async (res) => {
              let userdata = res.data?.result;
              localStorage.setItem(
                "cs_details",
                JSON.stringify({
                  account,
                  balance,
                  userId: userdata?.userId,
                })
              );
              localStorage.setItem("userToken", userdata?.accessToken);
              localStorage.setItem(
                "network",
                `${magiclink ? "magic" : "metamask"}`
              );
              dispatch(increment());
              if (!magiclink) dispatch(setMagicUser(null));
              dispatch(details({ account, balance }));

              dispatch(setAmount(usdcBalance))

              getBalanceToken();
              const getProfile = await getProfileDetails();
              if (
                !getProfile?.firstName ||
                !getProfile?.lastName ||
                !getProfile?.email ||
                !getProfile?.userName
              ) {
                PopUpAlert(
                  "Connection Successful",
                  "Please complete your profile.",
                  "success"
                ).then(() =>
                  history.push({
                    pathname: "/my-account",
                    state: { magicRedirect: true },
                  })
                );
              } else {
                PopUpAlert(
                  "Connection Successful",
                  "You are now connected to lii.",
                  "success"
                );
                // .then((res) => history.push("/"));
              }
              setMagicLinkLoader(false);
            })
            .catch((err) =>
              PopUpAlert("Alert", err.response?.data?.message, "error").then((action)=>{
window.location.reload()
              })
            );
        } catch (err) {
          if (err.code === 4001) {
            PopUpAlert("Alert", "User denied the signature request", "error");
          } else {
            alert("error", err);
          }
          if (history.location.pathname == "/") {
            document.body.style.overflow = "hidden";
          }
          setMagicLinkLoader(false);
        }
      }
    } finally {
      // setLoading(false);
      setMagicLinkLoader(false);
    }
  };

  const getProfileDetails = async () => {
    const config = getConfig();
    const profileDetails = await axiosInstance
      .get(`user/get-profile`, config)
      .then((res) => {
        const {
          id,
          profilePicUrl,
          email,
          firstName,
          lastName,
          userName,
          status,
          loginWith,
          kycStatus,
          isKYCLimitExceeded,
          emailVerified,
          isCreatedByAdmin,
        } = res.data?.result;

        let payload = {
          id: id,
          email: email,
          firstName: firstName,
          lastName: lastName,
          profilePicture: profilePicUrl,
          userName: userName,
          status: status,
          loginType: loginWith,
          kycStatus: kycStatus,
          isKYCLimitExceeded: isKYCLimitExceeded,
          emailVerified: emailVerified,
          isCreatedByAdmin: isCreatedByAdmin,
        };
        dispatch(setBasicAccountDetails(payload));
        return payload;
      })
      .catch(() => {
        return false;
      });

    return profileDetails;
  };

  return (
    <>
      {loaderShow && (
        <div className={styles.loaderContent}>
          <CircularProgress color="black" />{" "}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <h3 className="form-header">Login</h3>
        <div className="input-wrapper">
          <input
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            style={{ maxWidth: 250, padding: "1.25rem 0.625rem" }}
            // prefix={<Icon type={MonochromeIcons.Envelope} size={23} color={'#000'} />}
          />
        </div>
        <div>
          <button
            className={styles.buttonFullWidth}
            style={{ width: 250, padding: "0.625rem" }}
            // leadingIcon={MonochromeIcons.PaperPlane}
            // color='primary'
            // size='sm'
            // disabled={disabled}
            onClick={handleSubmit}>
            Login With Email
          </button>
        </div>
      </form>
      {magicLinkLoader && (
        <Loader
          active={magicLinkLoader}
          customize={true}
          title={"Logging in to lii"}
          content={"This will take a few seconds"}
          backGround={"/images/loginBgLoader.gif"}
        />
      )}
      <style>{`
        form,
        label {
          display: flex;
          flex-flow: column;
          text-align: center;
        }
        .form-header {
          font-size: 1.375rem;
          margin: 1.563rem 0;
        }
        .input-wrapper {
          width: 80%;
          margin: 0 auto 1.25rem;
        }
      `}</style>
    </>
  );
};

export default EmailForm;
