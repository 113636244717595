import React from 'react';
import ReactThreeFbxViewer from 'react-three-fbx-for-pyt';

// let fbxUrl = require('./asd.fbx');

export default function ThreeDee(props) {

    const { url } = props;

    let cameraPosition = {
        x: 150,
        y: 300,
        z: 350
    }

    return (
        < div>
            <h4>Hold and Drag to rotate and scroll to zoom</h4>
            <div style={{ border: "0.063rem solid #FF00FF",  padding:"2.5rem" }}>
                <ReactThreeFbxViewer cameraPosition={cameraPosition} width={500} height={400}
                    url={url}
                    onLoading={()=>console.log('loading')}
                />
            </div>
        </div >
    )
}
