import React, { useState, useEffect } from "react";
import styles from "./checkout.module.sass";
import AddCard from "./AddCard";
import { axiosInstance } from "../../utils/API";
import { PopUpAlert } from "../../controller/utils";
import { useHistory } from "react-router";
import * as openpgp from "openpgp";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Loader from "../Item/Loader.jsx";
import Alert from "@material-ui/lab/Alert";
import { getWeb3Provider, connector } from "../../controller/utils";
import { useSelector } from "react-redux";
import SuccessPopup from "../Item/SuccessPopup";
import useWindowSize from "../../common/hooks/useWindowSize";

// import { decryptData } from "../../utils/decrypt";
// import detectEthereumProvider from "@metamask/detect-provider";

const Checkout = () => {
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState({});
  const [error, setError] = useState({});
  const [cardList, setCardList] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);
  const [enableLoader, setEnableLoader] = useState(false);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const secretKey = useSelector((state) => state?.keyVal?.keyData);
  const userEmail = useSelector((state) => state?.accountReducer?.email);
  const history = useHistory();
  const [screenWidth] = useWindowSize();
  const loginStatus = useSelector((state) => state.counter.value);
  const [ipAddress1, setIpAddress1] = useState(null);
  const [ipAddress2, setIpAddress2] = useState(null);
  const [ipAddress3, setIpAddress3] = useState(null);
  const isUserVerified = useSelector((state) => state?.accountReducer?.status);
  const [successShow, setSuccessShow] = useState(false);
  const magicLinkUser = useSelector((state) => state.counter?.magicUser);
  let loginWith = localStorage.getItem("network");


  if (!connector.connected) {
    // create new session
    connector.createSession();
  }

  const getIP1 = async () => {
    let result = await axios.get("https://api.ipify.org?format=json");
    setIpAddress1(result?.data?.ip);
  };

  const getIP2 = async () => {
    let result = await axios.get("http://ip-api.com/json/");
    setIpAddress2(result?.data?.query);
  };

  const getIP3 = async () => {
    let result = await axios.get(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=23a6bb5e8371490b98182c6ce55b67d9"
    );
    setIpAddress3(result?.data?.ip_address);
  };

  useEffect(() => {
    getIP1();
    getIP2();
    getIP3();
    document.body.style.overflow = "visible";
  }, []);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "card_number") {
      const card = cardList?.find((each, i) => each.circleCardId == value);
      console.log(cardList, card);
      // //console.log("card",card,card?.userEmail);
      setFields({ ...fields, email: card?.userEmail, [name]: value });
      setCardInfo(card);
    } else if (name === "cvv") {
      value = cvvValidation(value);
      setFields({ ...fields, [name]: value });
    } else {
      setFields({ ...fields, [name]: value });
    }
  };
  const cvvValidation = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue?.length;

    // if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 3) {
      return currentValue;
    }
    return `${currentValue.slice(0, 3)}`;
  };

  useEffect(() => {
    getListOfCard();
    const object = history?.location?.state?.productDetails;
    setProductDetails(object);
    setFields({
      ...fields,
      price: object?.isOnSell
        ? object?.resellPrice?.toFixed(2)
        : object?.regularPrice?.toFixed(2),
    });

    //To get euro to USD Value.(Circle Payment only accept USD Currency)
    // const getUSD = async (euroToUSDurl) => {
    //   try {
    //     const authorizationKey = `Bearer ${process.env.REACT_APP_CIRCLE_PUBLIC_KEY}`;
    //     let configs = {
    //       headers: {
    //         Accept: "application/json",
    //         Authorization: authorizationKey,
    //       },
    //     };
    //     let regularPrice = await axios.get(euroToUSDurl, configs);
    //     regularPrice = object?.regularPrice / regularPrice?.data?.result?.EUR;
    //     setFields({ ...fields, price: "$" + regularPrice?.toFixed(2) });
    //   } catch (error) {
    //     console.log("Error while retriving USD Exchange rate.");
    //     return false;
    //   }
    // };

    // const euroToUSDurl = `${process.env.REACT_APP_BASE_URL}convert/usd`;
    // getUSD(euroToUSDurl);
  }, []);
  const getListOfCard = () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    axiosInstance
      .get("/user/cards", config)
      .then((res) => {
        setCardList(res?.data?.result?.cards);
      })
      .catch((err) => {
        // PopUpAlert(
        //   "Alert",
        //   err?.message ? err?.message : err?.data?.response?.message,
        //   "error"
        // );
      });
  };
  const validationField = () => {
    let field = fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if (!field["card_number"]) {
      formIsValid = false;
      errors["card_number"] = "Please select card number";
    }
    // if (!field["email"]) {
    //   formIsValid = false;
    //   errors["email"] = "Please enter your email id";
    // }

    if (!field["cvv"]) {
      formIsValid = false;
      errors["cvv"] = "Please enter your cvv";
    }

    setError(errors);
    return formIsValid;
  };

  const submitHandle = async () => {
    if (!fields?.card_number) {
      PopUpAlert("Alert", "Select a card.", "error");
      return;
    } else if (!fields?.cvv || fields?.cvv?.length < 3) {
      PopUpAlert("Alert", "Enter a valid CVV.", "error");
      return;
    }
    setEnableLoader(true);
    let account, balance, check, provider;
    if (validationField()) {
      // await provider?.enable();
      const wc_status = connector.connected;
      let web3 = await getWeb3Provider();
      // let web3 = wc_status
      // 	? new Web3(provider)
      // 	: magicLinkUser?.publicAddress
      // 	? new Web3(maticWeb3)
      // 	: new Web3(Web3.givenProvider);
      // const wc_status = connector.connected;
      // let web3 = connector.connected
      //   ? new Web3(provider)
      //   : new Web3(Web3.givenProvider);

      try {
        account = wc_status ? connector.accounts : await web3.eth.getAccounts();
        balance = await web3?.eth.getBalance(account[0]);
        balance = web3.utils.fromWei(balance, "ether");
        // check = checkbalance(balance);
      } catch(error) {
        console.log("wallet connect error1 ",error)
        alert("Network Session Expired, please reconnect your wallet");
        // dispatch(decrement());
        setEnableLoader(false);
        window.location.reload();
        return;
      }

      // let currentAccount = null;
      // await window.ethereum
      //   .request({ method: "eth_accounts" })
      //   .then((account) => {
      //     currentAccount = account;
      //   })
      //   .catch((err) => {
      //     // Some unexpected error.
      //     // For backwards compatibility reasons, if no accounts are available,
      //     // eth_accounts will return an empty array.
      //     setEnableLoader(false);
      //     console.error(err);
      //   });

      const url = process.env.REACT_APP_CIRCLE_API_URL + "encryption/public";
      // const key = decryptData(process.env?.REACT_APP_CIRCLE_PUBLIC_KEY);
      const authorizationKey = `Bearer ${process.env.REACT_APP_CIRCLE_PUBLIC_KEY}`;
      let configs = {
        headers: {
          Accept: "application/json",
          Authorization: authorizationKey,
        },
      };
      const result = await axios.get(url, configs);
      const publicKeyArmored = result?.data?.data?.publicKey;
      const keyId = result?.data?.data?.keyId;

      const cardDetails = {
        cvv: fields?.cvv,
      };
      const message = await openpgp.createMessage({
        text: JSON.stringify(cardDetails),
      });
      const publicKey = await openpgp.readKey({
        armoredKey: window.atob(publicKeyArmored),
      });
      const encryptedData = await openpgp.encrypt({
        message,
        encryptionKeys: publicKey,
      });
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };
      let transactionObject = {
        from: account[0],
        to: productDetails.isOnSell
          ? productDetails.purchasedBy
          : process.env.REACT_APP_ADDRESS_DEV,
        value: web3.utils.toWei(`${productDetails?.regularPrice?.toFixed(4)}`),
      };
      const uuid = uuidv4();
      // const toAddress = process.env.REACT_APP_ADDRESS_DEV;
      let body = {
        ethPrice: productDetails.isOnSell
          ? productDetails?.resellPrice * euroValue
          : productDetails?.regularPrice * euroValue,
        quantity: 1,
        from: transactionObject.from,
        salePrice: productDetails.isOnSell
          ? productDetails?.resellPrice
          : productDetails?.regularPrice,
        saleType: productDetails?.saleType,
        status: "pending",
        to: transactionObject.to,
        paymentType: "circle",
        paymentDetail: {
          metadata: {
            email: userEmail,
            phoneNumber: "+14155555555",
            sessionId: "DE6FA86F60BB47B379307F851E238617",
            ipAddress: ipAddress3
              ? ipAddress3
              : ipAddress2
              ? ipAddress2
              : ipAddress1,
          },
          amount: {
            amount: `${Number(fields?.price)}`,
            currency: "USD",
          },
          autoCapture: true,
          source: {
            id: cardInfo?.circleCardId,
            type: "card",
          },
          idempotencyKey: uuid,
          keyId: "key1",
          verification: "cvv",
          description: "Payment",
          encryptedData: window.btoa(encryptedData),
          // channel: "ba943ff1-ca16-49b2-ba55-1057e70ca5c7",
        },
      };
      await axiosInstance
        .post(`/asset/checkout/${productDetails.id}`, body, config)
        .then((res) => {
          if (res) {
            setEnableLoader(false);
            setSuccessShow(true);
            // PopUpAlert("Success", "Order Created Successfully", "success").then(
            //   (res) => {
            //     history.push("/orderDetails");
            //   }
            // );
          }
        })
        .catch((err) => {
          setEnableLoader(false);
          PopUpAlert(
            "Alert",
            err?.response?.data?.message
              ? err.response.data.message
              : err?.message,
            "error"
          );

          if (err.response?.data?.message ===
						"Your previous order is under process") {
					} {
						history.push('/dashboard?tab=3')
					}
        });
    }
    // //console.log('data>>>>',fields);
  };

  const modalClose = () => {
    getListOfCard();
    setVisible(false);
  };

  const handleback = () => {
    if (visible) {
      setVisible(false);
    }
  };
  const closeSuccessModal = () => {
    setSuccessShow(false);
    history.push("/orderDetails");
  };

  const getCVVAndPaymentButton = () => {
    return (
      <>
        <div className={styles.inputArea} style={{ marginTop: 20 }}>
          <p>CVV</p>
          <input
            type="password"
            className={styles.formControl}
            name="cvv"
            value={fields?.cvv}
            onChange={(e) => handleChange(e)}
          />
          {error?.cvv && <label className={styles.error}>{error?.cvv}</label>}
        </div>

        <Alert severity="info" style={{ marginTop: "1em" }}>
          Card processing fees are 2.9% + $0.3 per transaction.
        </Alert>

        <div className={styles.payArea}>
          <button onClick={(e) => submitHandle(e)}>Make a payment</button>
        </div>
      </>
    );
  };

  return (
    <>
      {/* {visible && <AddCard visible={visible} closeModal={() => modalClose()} />} */}
      <section className="section" style={{ background: "#ffffff" }}>
        {successShow && (
          <SuccessPopup
            productDetails={productDetails}
            amount={
              productDetails?.isOnSell
                ? productDetails?.resellPrice?.toFixed(2)
                : productDetails?.regularPrice?.toFixed(2)
            }
            sourcePage={"payWithCard"}
            close={() => closeSuccessModal()}
            type={"fixed"}
          />
        )}
        {loginStatus ? (
          <div className={`${styles.checkout_container} "container"`}>
            <div className={styles.checkoutContainer}>
              <div
                className={styles.colOne}
                onClick={() => (visible ? setVisible(false) : history.goBack())}
              >
                <img src="/images/content/backArrow.svg" />
              </div>

              <div className={styles.colTwo}>
                {visible && (
                  <AddCard visible={visible} closeModal={() => modalClose()} />
                )}
                {!visible && (
                  <>
                    <h2>Pay with card</h2>

                    <div className={styles.amountField}>
                      <p>Amount</p>
                      <h3>${fields?.price}</h3>
                    </div>
                    <div className={styles.supportedCardsWrap}>
                      <h3>Supported Cards</h3>
                      <div>
                        <img src="/images/visa.png" alt="card" />
                        <img src="/images/masterCard.png" alt="card" />
                        <img src="/images/maestro.png" alt="card" style={{width:43}}/>
                        <img
                          src="/images/americanExpress.png"
                          alt="card"
                        />
                      </div>
                    </div>
                    <div className={styles.cardDetails}>
                      <div style={{ flex: "0 0 70%" }}>
                        <p>Card number</p>
                        <p style={{ color: "#AFAFAF", fontWeight: 500 }}>
                          Select the 16 digit card number on the card
                        </p>
                      </div>
                      <button
                        onClick={(e) => {
                          if (!userEmail) {
                            // PopUpAlert(
                            //   "Alert..",
                            //   "No wallet connection found",
                            //   "error"
                            // ).then((res) => {
                            document.getElementById("connectWallet").click();
                            // });
                            return false;
                          } else if (loginWith!=='metamask' && isUserVerified != "verified") {
                            PopUpAlert(
                              "Alert..",
                              "User is not verified. Please verify your account.",
                              "error"
                            ).then(() => {
                              history.push("/my-account");
                            });
                          } else {
                            setVisible(true);
                          }
                        }}
                      >
                        Add card
                      </button>
                    </div>
                    <div></div>
                    <div className={styles.inputArea}>
                      <select
                        className={styles.formControl}
                        name="card_number"
                        value={fields?.card_number}
                        onChange={(e) => handleChange(e)}
                      >
                        <option>Select the card</option>
                        {cardList?.map((each, i) => (
                          <option value={each.circleCardId}>
                            {each.binNo?.slice(0, 4)} XXXX XXXX
                            {/* {each?.cardName} */}
                          </option>
                        ))}
                      </select>
                      {error?.card_number && (
                        <label className={styles.error}>
                          {error?.card_number}
                        </label>
                      )}
                    </div>
                    {getCVVAndPaymentButton()}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <p style={{ textAlign: "center", fontSize: "1rem" }}>
              Login to proceed.
            </p>
          </div>
        )}

        <Loader active={enableLoader} />
      </section>
    </>
  );
};

export default Checkout;
