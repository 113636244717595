import { createSlice } from "@reduxjs/toolkit";

export const circlePaymentSlice = createSlice({
  name: "circle",
  initialState: {
    walletId: "",
    userId: "",
    balance: "",
    currency: 0,
    circleAddress: "",
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = true;
    },
    decrement: (state) => {
      state.value = false;
      state.user = {};
    },
    details: (state, data) => {
      state.user.account = data.payload.account;
      state.user.balance = data.payload.balance;
    },
    setEuroValue: (state, data) => {
      console.log("store", data);
      state.euroValue = data.payload;
    },
    setCirlceId: (state, payload) => {
      state.circleAddress = payload.payload.address;
    },
    
    setCirlceDetails: (state, payload) => {
      state.walletId = payload.payload.walletId;
      state.userId = payload.payload.userId;
      state.balance = payload.payload.balance;
      state.currency = payload.payload.currency;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCirlceId, setCirlceDetails } = circlePaymentSlice.actions;

export default circlePaymentSlice.reducer;
