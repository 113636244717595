import WertWidget from "@wert-io/widget-initializer";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { v4 as uuidv4 } from "uuid";
import { BigNumber } from "bignumber.js";
import {
  PopUpAlert,
  bidWithUsdcPayment,
  buyWithUsdcPayment,
  decryptData,
  getWeb3Provider,
  wertBlockChainBidMethod,
  wertBlockChainBuyMethod,
} from "../../controller/utils";
import { useEffect, useState } from "react";
import { contractDetails } from "../../utils/contract";
import { axiosInstance, axiosInstanceBlockChain } from "../../utils/API";
import { useSelector } from "react-redux";
import { wertOtherWidgetOptions } from "../../utils/config";
import axios from "axios";

const config = {
  headers: {
    Authorization: localStorage.getItem("userToken"),
  },
};

const WertWidgetDiv = ({ arg }) => {
  const {
    productDetails,
    mode,
    saleType,
    bidAmountFiat,
    bidAmountCrypto,
    bidAmountFiatWEI,
    processWertResult,
    handleWertResult,
    onCloseWidget,
    closeWert,
    setCloseWert,
    callSetSuccessShow,
    wertHookSubstitueAuction,  // wertHookSubstitueAuction for updating the bid success from FE
    updateFixedOrderStatus, // for creating order updation after the payment get initiated from wert
  } = arg;

  console.log(
    "wert 1 bidAmountFiat, bidAmountCrypto, bidAmountFiatWEI",
    bidAmountFiat,
    bidAmountCrypto,
    bidAmountFiatWEI
  );
  console.log("Wert Widget Is Loaded!!!!!!!. ")
  const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

  const userAccountDetails = useSelector((state) => state.counter.user);
  const [wertProcessed, setWertProcessed] = useState(false)
  let codeExecutedTillPayment_status = '';
 
  // This will fetch signature from blockchain

  async function handleGetSignature(transactionDetails) {
    console.log("wert signature transaction details", transactionDetails);
    // console.log("wert parameters  : ", bidAmountFiatWEI, bidAmountCrypto);
    console.log(
      "wert sale Type  : ",
      saleType,
      "MATIC PRICE",
      saleType === "auction" ? bidAmountCrypto : transactionDetails?.maticPrice,
      bidAmountCrypto,
      "FIAT AMOUUNT",
      saleType === "auction"
        ? bidAmountFiatWEI
        : transactionDetails?.regularPrice
    );

    const payload = {
      collectableId: transactionDetails?.tokenId,
      payer: userAccountDetails?.account?.[0],
      maticPrice:
        saleType === "auction"
          ? bidAmountCrypto
          : transactionDetails?.maticPrice,
      fiatPrice:
        saleType === "auction"
          ? bidAmountFiatWEI
          : transactionDetails?.regularPrice,
      isCard: true,
    };


    // posting to asly backend
    return new Promise((resolve, reject) => {

      let url = '/blockchain/signature/payWithCrypto'
      if (productDetails?.saleType == "auction") {
        url = '/blockchain/signature/bidWithUsdc'
        payload.fiatPrice = bidAmountFiatWEI

      }

      axiosInstance.post(url, payload, config).then((res) => {
        if (res) {
          resolve(res)
        } else {
          reject(new Error("Empty response"));
        }

      }).catch((error) => {
        reject(error)
      })
    });
  }

  async function getTransactionDetails() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/asset/buy/${productDetails?.id}`, config)
        .then((res) => {
          if (res) {
            resolve(res);
          } else {
            reject(new Error("Empty response"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  const onCloseWidgetwert = () => {
    PopUpAlert(
      "Info",
      "Transaction in progress. This process may take some time to complete  .",
      "success"
    ).then(() => {
      if(saleType!=='auction')
      window.location.href = '/dashboard?tab=3'
      else 
      window.location.href = '/dashboard?tab=2&atab=0'    
    });    
  };

  const wertCloseAfterPayement = () => {
    let popupMsg = (saleType == 'fixed') ? 
        "Payment initiated; the token will appear in your account upon successful processing." :
        "Payment initiated; the bid will get reflected in active bids tab on successful processing."
    PopUpAlert(
      "Info",
      popupMsg,
      "success"
    ).then(() => {
      if(saleType!=='auction')
      window.location.href = '/dashboard?tab=3'
      else 
      window.location.href = '/dashboard?tab=2&atab=0'    
    });
  };

  const wertReachedPaymentStatus = () => {
    let popupMsg = (saleType == 'fixed') ? 
    "Payment got success, token will be reflected in your account in sometime." :
    "Payment got success, bid will get reflected in active bids tab in sometime."
    PopUpAlert(
      "Info",
      popupMsg,
      "success"
    ).then(() => {
      if(saleType!=='auction')
      window.location.href = '/dashboard?tab=3'
      else 
      window.location.href = '/dashboard?tab=2&atab=0'    
    });
  };

  const wertCloseBeforeTransaction = () => {
    let popupMsg = "Your transaction is incomplete. Please try again.." ;
     PopUpAlert(
      "Alert",
      popupMsg,
      "error"
    ).then(() => {
      window.location.reload();
    });
  };

  const closeSuccessModal = () => {
    if (saleType === "fixed") {
      window.location.href = '/dashboard?tab=3'
    } else {
      window.location.href = '/dashboard?tab=2&atab=0'    
    }
  };

  const runWertWidget = () => {

    // if (window.ethereum) {


    try {

      // console.log(" Inside window etherium condition")

      (async () => {
        let openWidget = true;
        const web3 = await getWeb3Provider();
        let transactionDetails = await getTransactionDetails();

        console.log("wert transactionDetails", transactionDetails);

        transactionDetails = transactionDetails?.data?.result;
        let userSignatureResult = await handleGetSignature(transactionDetails);
        let userSignature = userSignatureResult?.data?.result?.result;
        let signatureDetails = userSignatureResult?.data?.result?.payload
        console.log("this is user signature", userSignature)

        console.log("this is user signature", signatureDetails)

        // const maticPriceBN = new BigNumber(Number(
        //   saleType === "auction"
        //     ? bidAmountCrypto
        //     : transactionDetails?.maticPrice
        // ));
        // const fiatPriceBN = new BigNumber(Number(
        //   saleType === "auction"
        //     ? bidAmountFiatWEI
        //     : transactionDetails?.regularPrice
        // ));

        // const maticPriceHex = "0x" + maticPriceBN.toString(16);
        // const fiatPriceHex = "0x" + fiatPriceBN.toString(16);

        console.log(
          "wert.input_data_sc - before sc_inp amount maticPrice, regularPrice, maticPriceHex, fiatPriceHex",
          transactionDetails?.maticPrice,
          transactionDetails?.regularPrice,
          // maticPriceHex,
          // fiatPriceHex
        );

        console.log("----------> wert DAta----------->", signatureDetails.creators)


        console.log('account info', userAccountDetails?.account?.[0], 'tokenId', transactionDetails.tokenId, 'price', signatureDetails.price, 'array', [signatureDetails.royaltyReceiver, signatureDetails.royaltyPercentage, signatureDetails.ipfsHash], 'creators', userSignatureResult?.data?.result?.creators, 'signature', userSignature)
        let sc_input_data_fixed = null
        let sc_input_data_auction = null
        if (saleType != 'auction') {
          sc_input_data_fixed = web3.eth.abi.encodeFunctionCall(
            buyWithUsdcPayment,
            [
              userAccountDetails?.account?.[0], // Change needed - Take receiver address of user // userprofile
              transactionDetails?.tokenId, // Token ID from API
              signatureDetails?.price,
              [signatureDetails?.royaltyReceiver, signatureDetails?.royaltyPercentage, signatureDetails?.ipfsHash],
              userSignatureResult?.data?.result?.creators,
              userSignature, // signature.-- Get signature from Blockchain API.. Need to done from FE
            ],
          );
        } else {
          console.log("signatureDetails", signatureDetails, "user signnn", userSignature)
          sc_input_data_auction = web3.eth.abi.encodeFunctionCall(
            bidWithUsdcPayment,
            [
              userAccountDetails?.account?.[0], // Change needed - Take receiver address of user // userprofile
              transactionDetails?.tokenId, // Token ID from API
              signatureDetails.amount,
              userSignature,
            ]
          );
        }



        const privateKey = process.env.REACT_APP_WERT_KEY; // To use wert - private key
        console.log("this is the saletype", saleType)
        const sc_input_data =
          saleType === "auction" ? sc_input_data_auction : sc_input_data_fixed;
        console.log("sc_input_data : ",sc_input_data)
        const signedData = signSmartContractData(
          {
            address: userAccountDetails?.account?.[0], // / Change needed - Take receiver address of user // userprofile
            commodity: ["PRODUCTION"].includes(SERVER_ENVIORNMENT)
              ? "USDC"
              : "TT", // Currently Test token , In prod it will change to USDC
            commodity_amount:
              saleType === "auction"
                ? bidAmountFiat
                : productDetails?.isOnSell
                  ? productDetails?.resellPrice
                  : productDetails?.regularPrice, // the USDC amount that should be send to the contract method in decimal format .
            network: ["PRODUCTION"].includes(SERVER_ENVIORNMENT)
              ? "polygon"
              : "mumbai",
            sc_address: contractDetails?.ASLY_MARKETPLACE_ADDRESS, // your SC address - take from contract abi - smart contract address
            sc_input_data,
          },
          privateKey
        );

        //Backend API call to create order
        // if (saleType !== "auction")
        const wertResult = await processWertResult({ click_id: wertOtherWidgetOptions?.click_id }) 
        if(!wertResult.returnValue) openWidget = false
        // .then((data)=>{
        //   console.log("data  processWertResult ",data)
        //   if (!data) openWidget= false
        // });

        // const otherWidgetOptions = {
        //   partner_id: "01GSX0Q2W016CVJFDHQX2MBENS", // your partner id
        //   container_id: "widget",
        //   click_id: uuidv4(), // unique id of purhase in your system
        //   origin: "https://sandbox.wert.io", // this option needed only in sandbox
        //   width: 1400,
        //   height: 600,
        // };
        const nftOptions = {
          extra: {                   
            item_info: {
              author: `${transactionDetails?.creator?.firstName} ${transactionDetails?.creator?.lastName}`,
              image_url: transactionDetails?.mediaPreviewUrl,
              name: transactionDetails?.name,
              // name: transactionDetails?.name+ ' #'+transactionDetails?.currentEdition+'/'+transactionDetails?.totalEdition,
              seller: "lii",
            },
          },
        };

        console.log("==== Wert Widget Options signedData ====", signedData);
        console.log(
          "==== Wert Widget Options wertOtherWidgetOptions ====",
          wertOtherWidgetOptions
        );
        console.log("==== Wert Widget Options nftOptions ====", nftOptions);

        console.log("Wert Widget Options signedData", signedData);
        console.log(
          "Wert Widget Options wertOtherWidgetOptions",
          wertOtherWidgetOptions
        );
        console.log("Wert Widget Options nftOptions", nftOptions);
        console.log("wert sc data inputs", sc_input_data)
        const wertWidget = new WertWidget({
          ...signedData,
          ...wertOtherWidgetOptions,
          ...nftOptions,
          listeners: {
            "payment-status": (data) => {
              codeExecutedTillPayment_status = 'afterPayement';
              if ( saleType == "fixed" && data.status === "pending" ) updateFixedOrderStatus( transactionDetails.id, wertResult.returnObj.id, wertResult.returnObj.transactionId)// assetId, orderId, transactionId data passed in this order 
              console.log("event types wert", WertWidget.eventTypes);
              console.log("wert colse state", closeWert)
              console.log("====== Wert data Payment status:", data);
              if (data.status === "success") {
                codeExecutedTillPayment_status = 'afterPaymentSuccess';

                // *** for wert payment we don't depend on data.status === "success", we get webhook call from wert ***
                // if (saleType === "auction") { processWertResult(data) }
                // else handleWertResult(data.tx_id)

                if (saleType !== "auction") handleWertResult(data.tx_id)
                else wertHookSubstitueAuction( wertOtherWidgetOptions?.click_id, data.transaction_id)
                // added temporyly for demo purpose.. Remove this later to make it work with webhook
                // handleWertResult(data);

                console.log("wert widget iframe removaaaaaaaalllll")

                const removeIframeByMatchingUrl = (url) => {
                  const iframes = document.querySelectorAll('iframe');

                  for (const iframe of iframes) {
                    if (iframe.src.includes(url)) {
                      iframe.remove();
                    }
                  }
                };
                // Usage
                removeIframeByMatchingUrl('sandbox.wert.io');
                wertWidget.destroy();
                if (codeExecutedTillPayment_status == 'afterPaymentSuccess')  
                  { console.log("callSetSuccessShow")
                  callSetSuccessShow(saleType);
                   }
                 else 
                PopUpAlert("Processing..", "Oops! Something went wrong you will be redirected to home page.", "info").then(() => {
                  window.location.href = '/'
                });
              }
              else {
                console.log("else case", data)
              }
              console.log("wert close state updated!")
            },
            "close": (data) => {
              console.log("got the data",data ,"closing",wertProcessed, "codeExecutedTillPayment_status",codeExecutedTillPayment_status)
              if (!wertProcessed && saleType == 'fixed') {
                processWertResult(data)
              }

              if (codeExecutedTillPayment_status === 'afterPayement') {
                console.log('WERT afterPayement')
                wertCloseAfterPayement()}
              else if (codeExecutedTillPayment_status === 'afterPaymentSuccess'){ 
                console.log('WERT afterPaymentSuccess')
                wertReachedPaymentStatus() }
              else if (codeExecutedTillPayment_status === ''){
                console.log('WERT wertCloseBeforeTransaction')
                wertCloseBeforeTransaction()
              }else {
                console.log('WERT else')
                onCloseWidgetwert()
              }
            },
          },
        });

        // processWertResult();
       if(openWidget) {
        wertWidget.mount()
      };
      })();

    } catch (error) {
      console.log("error in try ,", error)
    }
    // } else  {

    //   console.log(" window etherium condition failure .")
    // }
  };

  useEffect(() => {

    console.log('rendering useeffect ')

    runWertWidget();

  }, []);

  return <div id="widget"></div>;
};

export default WertWidgetDiv;
