import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./terms.module.sass";
import queryString from "query-string";

const TermsAndConditions = () => {
	const { pathname } = useLocation();
	const [typeOfTab, setTypeOfTab] = useState("terms");
	const { search } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
		const urlParam = queryString.parse(search);
		if (urlParam?.section == "privacyPolicy") {
			setTypeOfTab("policy");
		} else if (urlParam?.section == "aml") {
			setTypeOfTab("aml");
		}
	}, [pathname]);

	return (
		<div className={cn("section", styles.section)}>
			<div
				className={cn("container", styles.container)}
				style={{ fontSize: 18 }}
			>
				{/* <div className="content-TermsCondition">
          {" "}
          Terms and Conditions of Use of lii
        </div> */}

				<div className="content-bottom-content">
					<div className={styles.menuWrapper_container}>
						<div className={styles.menuWrapper}>
							<div
								className={
									typeOfTab == "terms" ? styles.selected : styles.nonSelected
								}
								onClick={() => setTypeOfTab("terms")}
							>
								{"Terms and condition"}
							</div>
							<div
								className={
									typeOfTab == "policy" ? styles.selected : styles.nonSelected
								}
								onClick={() => setTypeOfTab("policy")}
							>
								{"Privacy policy"}
							</div>
							<div
								className={
									typeOfTab == "aml" ? styles.selected : styles.nonSelected
								}
								onClick={() => setTypeOfTab("aml")}
							>
								{"AML KYC policy"}
							</div>
						</div>
					</div>

					{typeOfTab === "terms" ? (
						<div className="policy">
							<div className="odd">
								<p className="points">
									Last updated: 22/08/2022
								</p>
								<h3>
									WAGMI NFT marketplace Terms and Conditions
								</h3>

								<p className="policyheading">
									{" "}
									1. Acceptance of terms:{" "}
								</p>

								<p className="points">
									lii NFT marketplace is owned and operated by WAGMI Enterprises LLC, whose address is at
									1910 Thomas Ave. Cheyenne, Wyoming 82001-3527 US (the “Company”, “We”, “Us”,
									“Our”). These Terms and Conditions (“NFT Terms”, “Terms of Service”) constitute a legally
									binding agreement between the Company and each registered or unregisters end user (“User”,
									“You” or “Your”) of lii NFT Marketplace located at www.lii.market (“the Site”) or such other
									URL as may be designated by the Company from time to time, as well as any mobile apps or
									other related services or application thereto.
								</p>

								<p className="points">
									PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP. THESE
									TERMS GOVERN YOUR USE OF THE APP, UNLESS WE HAVE EXECUTED A
									SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE
									ONLY WILLING TO MAKE THE APP AVAILABLE TO YOU IF YOU ACCEPT ALL OF
									THESE TERMS. BY USING THE APP OR ANY PART OF IT, OR BY CLICKING “I
									ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX,
									YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY
									ALL OF THESE TERMS.
								</p>

								<p className="points">
									Any changes to these NFT terms will be in effect as of the “Last Revised” date referred to at
									the top of this page. You acknowledge and agree that the form and nature of the NFT
									Marketplace, and any part of it, may change from time to time without prior notice to you, and
									that we may add new or remove existing features and change any part of the NFT Marketplace
									and inform You
								</p>

								<p className="points">
									IF ANY PROVISION OF THESE NFT TERMS OR ANY FUTURE CHANGES are
									UNACCEPTABLE TO YOU, DO NOT USE OR CONTINUE TO USE THE NFT
									MARKETPLACE. YOUR CONTINUED USE OF THE NFT MARKETPLACE
									FOLLOWING THE POSTING OF ANY NOTICE OF ANY CHANGE TO THIS TERMS OF
									SERVICE SHALL CONSTITUTE YOUR IRREVOCABLE ACCEPTANCE IN
									AGREEMENT TO SUCH CHANGE.
								</p>

								<p className="policyheading">2. Overview of the NFT Marketplace:</p>

								<p class="points">
									The NFT marketplace provides you with the opportunity to create (or as we call it, mint), sell,
									purchase, bid on, collect, trade, showcase and otherwise transact digital blockchain collectables,
									which may be represented as non-fungible token or “NFT” linked with certain digital media
									and arts (“NFT Media”). We facilitate transactions on a safe harbor basis between buyer and
									seller of an NFT, but we are not a party to any agreement between buyer and seller of an NFT
									on the NFT marketplace. We collect revenue on the NFT marketplace via transaction fees and
									other applicable fees which we display when you interact with the NFT marketplace. For the
									avoidance of doubt, any NFTs transacted on the NFT marketplace are considered digital assets.
									While the NFT marketplace acts in total neutrality towards the transaction happening on the
									marketplace it reserves the right (but is not required nor obligated to) to take any discretionary
									action in relation to any dispute arising from the trade of NFTs happening on the NFTs marketplace,
									including in connection with any auctions or other purchase methods. You irrevocably undertake not
									to entangle the NFTs marketplace in such disputes.
								</p>
								<p class="points">
									For NFT Sellers: By minting, providing, or selling an NFT through the NFT Marketplace, you hereby represent and warrant that you own all legal right, title, and interest in the intellectual property rights embedded with each NFT Media linked or associated with such NFT, or you are legally authorized or licensed by the intellectual property owners/holders to mint, provide or sell the NFT on the NFTs Marketplace. For clarity, the Company has no obligation or liability to you for keeping, storing, or helping you recover any NFT Media associated with your NFTs.
								</p>
								<p class="points">
									For NFT Buyers: When you purchase an NFT, you own the NFT that is associated with certain NFT Media, but you do not own any intellectual property rights encapsulated in such NFT Media except for the license grants expressly set forth herein. In certain cases, we may help to evaluate or provide you with information about a seller of an NFT. However, such information is provided for informational purposes only. You bear full responsibility for verifying the authenticity, legitimacy, and identity of any NFT you purchase on the NFT Marketplace. We make no representations, guarantees, or promises about the identity, legitimacy, legality, decency, quality, or authenticity of any NFT on the NFT Marketplace. Notwithstanding any sale clearing period that may be implemented for the sale of any NFTs, you acknowledge that the Company is not required nor obligated to adjudicate nor decide on any dispute in connection with any NFTs sold on the NFT Marketplace.
								</p>
								<p className="policyheading">3. License to Your Content</p>

								<p className="points">
									In connection with your use of the NFT Marketplace, you may be able to post, upload, or submit
									content to be made available through the NFT Marketplace, including NFT Media that is tied to
									NFTs you wish to sell on the NFT Marketplace as a seller, and any other content associated
									with your NFTs (“Your Content”). You retain all rights to Your Content you post, upload,
									submit, or otherwise make available through the NFT Marketplace, except for rights expressly
									granted herein. By creating, uploading, posting, sending, receiving, storing, or otherwise
									making available any of your Content on, in or through the Site, you grant to WAGMI a nonexclusive,
									worldwide, royalty-free, perpetual license to such Content to make available,
									digitally distribute, access, use, store, copy, adapt, modify, prepare derivative works of,
									distribute, publish, transmit, stream, download, broadcast, and otherwise distribute such
									Content solely for the purpose of providing and/or promoting the Site and, featuring your
									Content within our Site and promoting it through our marketing ecosystem. To the extent
									applicable and permissible by law, you hereby waive any and all claims that you may now or
									hereafter have in any jurisdiction to so-called “moral rights” or right of “droit moral” with
									respect to any of your Content. You may request to remove your User Materials in accordance
									with our Privacy Policy, which is hereby incorporated by reference.
								</p>

								<p className="points">
									As part of the foregoing license grant, you agree that the other Users of the NFT Marketplace
									shall have the right to comment on and/or tag Your Content and/or use, publish, display, modify
									or include a copy of Your Content as part of their own use of the NFT Marketplace; except that
									the foregoing shall not apply to any of Your Content that you post privately for non-public
									display on the NFT Marketplace. By posting or submitting Your Content to the NFT Marketplace,
									you irrevocably represent and irrevocably warrant that you have, or have obtained, all rights,
									licenses, consents, permissions, power and/or authority necessary to grant the rights granted
									herein for Your Content. You agree that Your Content will not contain material subject to
									copyright or other proprietary rights unless you have the necessary permissions or are otherwise
									legally entitled to post the material and grant us the license described above.
								</p>

								<p className="points">
									If you sell an NFT through the NFT Marketplace, you grant to the buyer of the NFT a
									worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the
									NFT Media for such purchased NFT, solely for the following purposes: (a) for the buyer’s own
									personal use; (b) as part of a marketplace that permits the purchase and sale of such NFTs,
									provided that the marketplace cryptographically verifies each NFT’s owner’s rights to display the
									NFT Media for their NFTs to ensure that only the actual owner can display the NFT Media; or (c)
									as part of a third-party website or application that permits the inclusion, involvement, or
									participation of your NFT, provided that the website/application cryptographically verifies each
									NFT’s owner’s rights to display the NFT Media for their NFTs to ensure that only the actual
									owner can display the NFT Media, and provided that the NFT Media is no longer visible once
									the owner of the NFTs leaves the website/application (the “NFT Purchase License”).
								</p>

								<p className="points">
									If you are a buyer of NFTs, then you acknowledge and agree that the NFT Purchase License
									set forth above only lasts as long as you are the valid owner and holder of the NFT associated
									with the licensed NFT Media. If you sell or transfer the NFT to another person, this NFT
									Purchase License will transfer to such other owner or holder of the NFT, and you will no longer
									have the benefits of such NFT Purchase License. Unless otherwise specified by the seller of an
									NFT in writing, your purchase of an NFT does not give you the right to publicly display, perform,
									distribute, sell or otherwise reproduce the NFT or its related NFT Media for any commercial
									purpose.
								</p>

								<p className="points">
									If you sell an NFT, you agree that you will not have any claims against the Company for any
									breach of these NFT Terms by a purchaser, including if they make commercial use of the related
									NFT Media in breach of these NFT Terms.
								</p>

								<p className="points">
									We have the right to remove or refuse to post any of Your Content, including NFTs, (a) for any
									or no reason in our sole discretion; and (b) take any action with respect to Your Content that we
									deem necessary or appropriate in our sole discretion, including if we believe that Your Content
									violates these NFT Terms, infringes any intellectual property right of any person or entity,
									threatens the personal safety of Users of the NFT Marketplace or the public, is defamatory or
									incite to violence or suggests political views or religious prohibited information or could create
									liability for the Company or other Users.
								</p>

								<p className="policyheading">4. Account Registration and Communication Preferences</p>

								<p className="points">
									If you wish to use the NFT marketplace in any way, you will need to register for an account on the Site
									(“Account”). By creating an Account, you agree to (a) provide accurate, current and
									complete Account information about yourself, (b) maintain and promptly update from time to time as necessary
									your Account information, (c) maintain the security of your password and accept all risks of unauthorized
									access to your Account and the information you provide to us, and (d) immediately notify us if you discover
									or otherwise suspect any security breaches related to the Site, or your Account. We will block all multiple
									accounts of the same user. Furthermore, you agree that you will not (1) create another account if we’ve
									disabled one you had unless you have our written permission first; (2) buy, sell, rent or lease access to
									your Account or username unless you have our written permission first; (3) share your Account password with
									anyone; (4) log in or try to log in to access the Site through unauthorized third-party applications or
									clients.
								</p>

								<p className="points">
									You may use the platform by linking your digital wallets on Meta Mask (<a href="https://metamask.io/"
										target="_blank" rel="noopener noreferrer">https://metamask.io/</a>) or Wallet Connect (<a
											href="https://walletconnect.com/" target="_blank"
											rel="noopener noreferrer">https://walletconnect.com/</a>). They represent an electronic wallet, which
									allows you to purchase, store, and engage in transactions using cryptocurrency. Our platform is built on
									polygon and uses MATIC cryptocurrency. You may also use the platform without having an existing digital
									wallet. We will use the e-mail address you provided us with in the registration process to create and link
									to that e-mail a digital wallet.
								</p>

								<p className="points">
									By creating an Account, you also consent to receive electronic communications from WAGMI (e.g., via email or
									by posting notices to the Site). These communications may include notices about your Account (e.g., password
									changes and other transactional information) and are part of your relationship with us. You agree that any
									notices, agreements, disclosures or other communications that we send to you electronically will satisfy any
									legal communication requirements, including, but not limited to, that such communications be in writing. You
									should maintain copies of electronic communications from us by printing a paper copy or saving an electronic
									copy. We may also send you promotional communications via email, including, but not limited to, newsletters,
									special offers, surveys and other news and information we think will be of interest to you. You may opt out
									of receiving these promotional emails at any time by following the unsubscribe instructions provided in our
									privacy notice.
								</p>

								<p className="points">
									You must provide all equipment and software necessary to connect to the Site and services, including but not
									limited to, a mobile device that is suitable to connect with and use Site and services, in cases where the
									Site offers a mobile component. You are solely responsible for any fees, including Internet connection or
									mobile fees, that you incur when accessing the Site or services.
								</p>

								<p className="points">
									Notwithstanding anything to the contrary herein, you acknowledge and agree that you shall have no ownership
									or other property interest in your Account, and you further acknowledge and agree that all rights in and to
									your Account are and shall forever be owned by and inure to the benefit of WAGMI.
								</p>

								<p className="policyheading">5. Specific Terms for the Platform</p>

								<p className="points">
									WAGMI has developed an exclusive platform that enables users to enter the NFT space seamlessly with a few
									clicks. It offers fully curated services on Polygon Blockchain for faster, cheaper, and more sustainable
									transactions. WAGMI only allows certain creators that are invited or otherwise approved by the Company
									(“Onboarded Creators”) to mint and sell NFTs (the “Creator’s NFTs”) associated with their original NFT Media
									(the “Creator’s NFT Media”).
								</p>

								<p className="points">
									The Company may enter into an addendum with any Invited Creator (a “Creator Addendum”) setting forth terms
									for using the Platform as an Onboarded Creator. If there is a conflict between a Creator Addendum and these
									NFT Terms, the provisions of the Creator Addendum shall take precedence for such Onboarded Creators.
									Onboarded Creators can also set their own royalty fee for their NFT collections, which would give the
									creator a % of any future resale of the NFT on our marketplace.
								</p>

								<p className="points">
									The Onboarded Creator hereby grants to the Company a perpetual, irrevocable, and exclusive right and license
									to use, reproduce, make available, communicate to the public, disseminate, digitally distribute and display
									the Creator’s NFT Media in connection with the promotion of the Creator’s NFT Media, the Creator’s NFTs and
									the NFT Marketplace. For clarity, unless otherwise specified in a Creator Addendum, the exclusive license
									granted above means that Onboarded Creator cannot itself, or grant to any other party any right to, use,
									reproduce, display the Creator’s NFT Media in connection with non-fungible tokens or any other blockchain
									collectibles, platforms or services.
								</p>

								<p className="points">
									If requested by the Company, Onboarded Creators will reasonably cooperate with the Company to validate the
									authenticity of the Creator’s NFTs and the Creator’s NFT Media.
								</p>

								<p className="points">
									Any registered User is allowed to use the platform to purchase or sell NFTs (“Standard User”). For an
									initial sale of an NFT (i.e., the first instance a newly minted NFT is sold by the creator to a buyer), the
									transaction proceeds from the sale of the NFT (the “Transaction Proceeds”) shall be frozen in the Company’s
									master account for a pre-specified period of time (the “Initial Sale Clearing Period”) after which the
									Transaction Proceeds will be released to the seller in a subaccount created specifically for the user.
								</p>

								<p className="points">
									The Company may extend the Initial Sale Clearing Period for a further period of time (the “Extended Clearing
									Period”) provided that:
									<ul>
										<li>Within the Initial Sale Clearing Period, a bona fide complaint is raised by the buyer or a third party
											for claims that relate to intellectual property infringement, the identity, legitimacy, authenticity,
											and/or validity of legal title of the NFT, these NFT Terms and/or violation of the terms of the NFT sale
											as agreed between the seller and the buyer. For any claim raised by the buyer, the buyer shall furnish
											proof that the NFT at issue remains in their account and agrees to have the NFT frozen pending
											resolution of the dispute. The buyer or the third party submitting a complaint shall agree that WAGMI
											may supply a copy of this complaint to the seller, including the buyer/third party’s identity and
											contact details.</li>
										<li>The Company suspects the NFT, the NFT transaction, the seller’s activity in relation to the NFT and/or
											the Transaction Proceeds to be in violation of the applicable terms of sale (including these NFT Terms)
											or any applicable laws and regulations.</li>
									</ul>
								</p>

								<p className="points">
									After the Extended Clearing Period, the Company may only continue to freeze if legal proceedings or
									investigations by the authorities have been commenced against the seller (and where such legal proceedings
									or investigations require the Transaction Proceeds to be frozen), or in such other circumstances that the
									Company deems appropriate in its sole discretion. In such circumstances, the Company has the discretion to
									extend the freeze for any further duration pending the resolution of the dispute. If the Company does not
									receive proof that such a formal legal action or complaint has been filed within the Extend Clearing Period,
									the Company may be entitled to release the Transaction Proceeds to the seller. If the complainant and the
									seller are able to resolve the dispute at any stage, the Company will require written confirmation of the
									resolution of the dispute from all parties before it may proceed to release the Transaction Proceeds to the
									seller.
								</p>

								<p className="points">
									IN NO CIRCUMSTANCES SHALL THE COMPANY BEAR ANY LIABILITY OR RESPONSIBILITY FOR ANY ACT OR OMISSION DONE IN
									RELATION TO THE FREEZING (OR OMISSION TO FREEZE) OF THE TRANSACTION PROCEEDS, AND THERE SHALL BE NO FREEZING
									AS PART OF ANY SECONDARY SALE OF NFTS.
								</p>

								<p className="policyheading">6. Verification and Payment</p>

								<p className="points">
									When you make purchases through the NFT Marketplace, including, without limitation, any purchase for NFTs,
									you must provide and maintain valid payment information in connection with your WAGMI Account. You represent
									and warrant that you are authorized to use the payment method you use via the NFT Marketplace to make any
									purchase. You authorize us to charge your payment method for the total amount of your purchase price. Your
									order may be suspended or canceled for any reason, including if the payment method cannot be verified, is
									invalid, or is otherwise not acceptable. It could also be canceled because of a blockchain transaction
									failure.
								</p>

								<p className="points">
									WAGMI may require you to provide additional information and documents at the request of any competent
									authority or in case of application of any applicable law or regulation, including laws related to
									anti-laundering (legalization) of incomes obtained by criminal means, or for counteracting financing of
									terrorism. The company may also require you to provide additional information and documents in cases where
									it has reasons to believe that (1) your Account is being used for money laundering or for any other illegal
									activity; (2) you have concealed or reported false identification information and other details; or (3)
									transactions effected via your Account were affected in breach of this Agreement.
								</p>

								<p className="points">
									In such cases, WAGMI in its sole discretion, may pause or cancel your transactions until such additional
									information and documents are reviewed by the company and accepted as satisfying the requirements of
									applicable law. If you do not provide complete and accurate information and documents in response to such a
									request, WAGMI may refuse to provide you access to your account and to your Content.
								</p>

								<p className="points">
									We have engaged the services of third-party payment processors to facilitate transactions on our website. By
									using WAGMI Marketplace, you agree to abide by the terms and conditions of our third-party payment
									processors. Payments can be made in either cryptocurrency or fiat currency, subject to the availability of
									the respective payment options. Please note that any fees associated with the use of cryptocurrency will be
									borne by you, the user.
								</p>

								<p className="points">
									We have no liability to you or any third party for any claims or damages that may arise as a result of any
									payments or transactions that you engage in via the NFT Marketplace, or any other payment or transactions
									that you conduct via the NFT Marketplace. We do not provide refunds for any purchases that you might make on
									or through the NFT Marketplace — whether for NFTs or anything else.
								</p>

								<p className="points">
									You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and
									assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental
									authority associated with your use of the NFT Marketplace or NFTs, except for income taxes levied on us as a
									result of such purchases of NFTs.
								</p>

								<p className="policyheading">7. Copyright Policy</p>

								<p className="points">
									The Company may, but is not obligated to, monitor the NFTs, NFT Media, and Your Content uploaded to the NFT
									Marketplace for any infringement of a third party’s intellectual property rights. However, the Company
									cannot undertake to review all such content before it is posted on the Service and cannot ensure prompt
									removal of objectionable content after it has been posted. Accordingly, the Company assumes no liability for
									any action regarding transmissions, communications, or content provided by any user or third party. The
									Company will take down listed NFTs in response to takedown notices and/or other intellectual property
									infringement claims and will terminate a seller’s access if the seller is deemed to be a repeat infringer.
									If you believe that any text, graphics, photos, audio, videos or other materials or works uploaded,
									downloaded or appearing on the Services have been copied in a way that constitutes copyright infringement,
									you may submit a notification to our legal department, by providing the following information in writing:
								</p>
								<ul className="policylist">
									<li>
										identification of the copyrighted work that is claimed to be infringed; • identification of the allegedly
										infringing material that is requested to be removed, including a description of where it is located on the
										NFT Marketplace;
									</li>

									<li>
										information for our copyright agent to contact you, such as an address, telephone number, and e-mail
										address;
									</li>

									<li>
										a statement that you have a good faith belief that the identified, allegedly infringing use is not
										authorized by the copyright owners, its agent, or the law;
									</li>

									<li>
										a statement that the information above is accurate, and under penalty of perjury, that you are the
										copyright owner or the authorized person to act on behalf of the copyright owner; and
									</li>
									<li>the physical or
										electronic signature of a person authorized to act on behalf of the owner of the copyright or of an
										exclusive right that is allegedly infringed.
									</li>
								</ul>

								<p className="points">
									It is our policy, in appropriate circumstances and at our discretion, to disable or terminate the accounts
									of Users who repeatedly infringe copyrights or intellectual property rights of others.
								</p>

								<p className="points">
									Any User of the NFT Marketplace who has uploaded or posted materials identified as infringing as described
									above may supply a counter-notification. When we receive a counter-notification, we may reinstate the posts
									or material in question, in our sole discretion. To file a counter notification with us, you must provide a
									written communication (by regular mail or by email) that sets forth:
								</p>
								<ul className="policylist">
									<li>
										your name, address, telephone number, e-mail address, and physical or electronic signature;
									</li>

									<li>
										identification of the material that has been removed or to which access has been disabled and the location
										at which the material appeared before it was removed or access to it was disabled; and
									</li>

									<li>
										a statement under penalty of perjury that you have a good faith belief that the material was removed or
										disabled as a result of mistake or misidentification of the material to be removed or disabled.
									</li>
								</ul>
								<p className="points">
									Please note that you will be liable for damages if you materially misrepresent that content or an activity
									is not infringing the copyrights of others.
								</p>

								<p className="policyheading">8. Restrictions on Marketing and Promotional Activities and Logo Use</p>

								<p className="points">
									Any marketing and promotional activities, whether in digital, print, or any other form, that you may conduct
									in connection with your use of the NFT Marketplace are subject to our review and prior written approval. You
									should submit any such plan relating to marketing and promotional activities and materials for our review
									and provide any information that we reasonably request. The plan should contain all key information,
									including but not limited to details of the relevant NFT projects, all planned media channels or
									distributions, relationships between different counterparties, etc.
								</p>

								<p className="points">
									No mention of token-related topics or any implication relating to token issue or listing may be included.
									You must not create and/or publish any marketing or promotional materials that may: (a) be deemed false and
									misleading; (b) breach or infringes the rights of any third parties, including intellectual property rights;
									(c) breach any applicable laws and regulations; (d) breach any of our guidelines or instructions that may be
									made available to you; and (e) bring into disrepute or damage the goodwill of WAGMI. We reserve the right to
									request changes or suspension to such plan and activity as we deem necessary and reject any plan and
									activity in its entirety at our absolute discretion.
								</p>

								<p className="points">
									Subject to further guidelines that may be made available to you, we may permit you to use the WAGMI Marks,
									at our sole discretion, for the sole purposes of marketing or promoting your NFTs on the NFT Marketplace.
									Terms of such guidelines made available to you shall be incorporated into these terms by express reference
									herein. You must not use WAGMI’s name, logo, trade names, and any other marks (“WAGMI Marks”) without our
									prior written approval.
								</p>

								<p className="points">
									You agree to indemnify us against all liabilities, costs, expenses, damages and losses (including but not
									limited to any direct, indirect or consequential losses, loss of profit, loss of reputation, loss of
									goodwill and all interest, penalties and legal costs (calculated on a full indemnity basis), and all other
									professional costs and expenses) suffered or incurred by us arising out of or in connection with any such
									marketing and promotional activities that you conduct in connection with your use of the NFT Marketplace.
								</p>

								<p className="policyheading">9. Assumption of Risks</p>

								<p className="points">
									DO YOUR OWN RESEARCH. You accept and acknowledge that (i) the value of an NFT is subjective; prices of an
									NFT are subject to volatility and fluctuations in the price of cryptocurrency can also materially and
									adversely affect NFT prices; (ii) a lack of use or public interest in NFTs could negatively impact the
									potential utility of NFTs; (iii) the regulatory regime governing NFTs is uncertain, and new regulations or
									policies may materially adversely affect the utility of NFTs; and (iv) there are risks associated with
									purchasing items associated with content created by third parties through peer-to-peer transactions,
									including but not limited to, the risk of purchasing counterfeit items, mislabeled items, items that are
									vulnerable to metadata decay, items on smart contracts with bugs, and items that may become untransferable.
									You represent and warrant that you have done sufficient research before making any decisions to sell, buy,
									transfer, or otherwise interact with any NFTs. You further acknowledge and agree it is your sole
									responsibility to carry out all necessary due diligence for all your activities relating to NFTs, and you
									represent and warrant that you have not and are not relying on, and shall have no remedies, in respect of
									any statement or representation made by the Company in relation to any sale, buy, transfer or interaction
									otherwise with any NFTs.
								</p>

								<p className="points">
									Any purchase or sale you make, accept or facilitate outside of the NFT Marketplace of an NFT will be
									entirely at your risk. You acknowledge that you have obtained sufficient information to make an informed
									decision to purchase an NFT, including carefully reviewing the code of the smart contract and the NFT and
									fully understand and accept the functions of the same. We do not control or endorse purchases or sales of
									NFTs outside of the NFT Marketplace. We expressly deny and disclaim any liability to you and deny any
									obligation to indemnify you or hold you harmless for any losses you may incur by transacting, or
									facilitating transactions, in NFTs outside of the NFT Marketplace.
								</p>

								<p className="points">
									Certain parts of the NFT Marketplace may display, include or make available content, data, information,
									applications, or materials from third parties (“Third Party Materials”). By using the NFT Marketplace, you
									acknowledge and agree that the Company is not responsible for examining or evaluating the content, accuracy,
									completeness, availability, timeliness, validity, copyright compliance, legality, decency, quality, or any
									other aspect of such Third Party.
								</p>

								<p className="points">
									We do not warrant or endorse and do not assume and will not have any liability or responsibility to you or
									any other person for any third-party services, Third Party Materials, or any other materials, products, or
									services of third parties.
								</p>

								<p className="points">
									If you have a dispute with one or more users, YOU RELEASE US FROM CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND
									AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IN ENTERING INTO
									THIS RELEASE, YOU EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE
									LIMIT THE COVERAGE OF THIS RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST IN YOUR
									FAVOR AT THE TIME OF AGREEING TO THIS RELEASE.
								</p>
								<p className="policyheading">10. Limitation of Liability:</p>

								<p className="points">
									TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO
									EVENT WILL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR
									ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
									INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES RELATED TO THE LOSS OF
									REVENUE, LOSS OF PROFIT, LOSS OF BUSINESS OR ANTICIPATED SAVING, LOSS
									OF USE, LOSS OF GOODWILL OR LOSS OF DATA,<strong> WHETHER CAUSED BY TORT
										(INCLUDING NEGLIGENCE), BREACH OF CONTRACT,</strong> OR OTHERWISE, EVEN
									IF PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES;
									AND FOR ANY OTHER CLAIM, DEMAND, OR DAMAGES WHATSOEVER RESULTING
									FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF THE
									DELIVERY, USE, OR PERFORMANCE OF THE SERVICE. ACCESS TO, AND USE OF,
									THE SERVICE, PRODUCTS OR THIRD—PARTY SITES, AND PRODUCTS ARE AT
									YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE
									FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS
									OF DATA RESULTING THEREFROM.
									NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
									EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY
									ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS TO
									AND USE OF THE SERVICE, CONTENT, OR NFTS EXCEED $1000.
								</p>
								<p className="policyheading">11. Termination</p>

								<p className="points">
									If you breach any of the provisions of these NFT Terms, we reserve the right, with or without
									notice and in our sole discretion, to suspend, disable, terminate, or delete your account and/or
									your ability to access or use the NFT Marketplace (or any part of the foregoing) at any time and
									for any or no reason, and you acknowledge and agree that we shall have no liability or obligation
									to you in such event and that you will not be entitled to a refund of any amounts that you have
									already paid to us.
								</p>
								<p className="policyheading">12. Governing Law:</p>

								<p className="points">
									All matters arising out of or relating to these Terms will be governed by and construed in
									accordance with the laws of the state of Wyoming and the applicable Federal Laws of the United
									States of America (USA). The Courts of Wyoming shall have jurisdiction over all disputes
									arising under this contract.
								</p>
								<p className="policyheading">13. Entire Agreement clause:</p>

								<p className="points">
									These Terms and our Privacy Policy constitute the entire legal agreement between you and us
									and will be deemed to be the final and integrated agreement between you and us and govern
									your access to and use of the Site and any further mobile application the Company develop, and
									completely replace any prior or contemporaneous agreements between you and us related to
									your access to or use of the Site, whether oral or written.
								</p>
								<p className="policyheading">14. Contact</p>

								<p className="points">
									General questions or comments about the NFT Marketplace or these NFT Terms should be sent either by mail to 
									<a href="mailto:support@lii.market"> support@lii.market</a>.
								</p>
							</div>
						</div>
					) : typeOfTab === "policy" ? (
						//privacy
						<div className="policy">
							<div className="odd">
								<p className="points">
									Last updated: 08/23/2023
								</p>
								<h3>
									WAGMI - Privacy Notice
								</h3>

								<p className="points">WAGMI Enterprises LLC (“WAGMI”, “we”, or “us”) is committed to protecting the privacy
									of its customers and takes its data protection responsibilities with the utmost seriousness. We
									have prepared this Privacy Notice (“Notice”) to explain to our users (“You”) how we collect
									and process personal information about them when they visit our website at 
									<a href="https://www.lii.market"> https://www.lii.market</a>, or when they otherwise do business or make
									contact with us.
								</p>

								<p className="points">
									We reserve the right to change this Privacy Notice at any point in time and for any reason. You will be
									updated by changes through the “Last updated” date on top of this Privacy Notice.
								</p>
								<p className="policyheading">
									1. What Personal Information does WAGMI collect and process? Why does WAGMI process my personal
									information? What are the legal bases of personal information?
								</p>

								<p className="points">
									In order for the company to deliver its services properly and fulfil all of its desired purposes we may
									collect and process the following information about you:
								</p>

								<div class="columns">
									<div class="column">
										<p className="points">
											What personal information does WAGMI collect and process?
										</p>
										<p className="points">
											<strong>
												Information You Provide Us:
											</strong>
										</p>
										<ul className="policylist">
											<li>Email address</li>
											<li>Name</li>
											<li>Date of birth</li>
											<li>Home address</li>
											<li>Phone number</li>
											<li>Nationality</li>
											<li>Device ID</li>
											<li>A video recording of you and a photographic image</li>
											<li>Transactional information</li>
											<li>Blockchain Address</li>
										</ul>
										<br />
									</div>

									<div class="column">
										<p className="points">
											Why does WAGMI process my personal information?
										</p>
										<br />
										<ul className="policylist">
											<li> <strong>Transaction services.</strong> We use your personal information to process your
												orders
												and
												communicate with
												you about orders and services.
											</li>
											<br />
											<li><strong>Communicate with you.</strong> We use your personal information to communicate with
												you
												in
												relation to WAGMI
												Services.
											</li>
											<br />
											<li><strong>Comply with Anti-Money Laundering Laws.</strong> We collect and process identity
												information
												and Sensitive
												Personal Data to comply with our Know Your Customer (KYC) obligations under applicable laws
												and
												regulations, and Anti-Money Laundering laws and regulations.
											</li>
											<br />
										</ul>
									</div>
									<div class="column">
										<p className="points">
											Legal Basis for our use of personal information
										</p>
										<br />
										<ul className="policylist">
											<li><strong>Performance of a contract:</strong> when we provide you with products or services or
												communicate with you
												about
												them. This includes when we use your personal information to take and handle orders and
												process
												payments.
											</li>
											<br />
											<li><strong>Legal obligation:</strong> to comply with our legal obligations under applicable
												laws
												and
												regulations, and
												Anti-Money Laundering laws and regulations.
											</li>
											<br />
											<li><strong>Your consent:</strong> when we ask for your consent to process your personal
												information
												for
												a specific
												purpose
												that we communicate to you. When you consent to processing your personal information for a
												specified
												purpose, you may withdraw your consent at any time, and we will stop processing your
												personal
												information for that purpose. The withdrawal of consent does not affect the lawfulness of
												processing
												based on consent before its withdrawal.
											</li>
											<br />
										</ul>
									</div>
								</div>
								<div class="columns">
									<div class="column">
										<p className="points">
											<strong>
												Information Collected by our Servers (or a third -party service provider):
											</strong>
										</p>
										<ul className="policylist">
											<li>Web browser</li>
											<li>IP address</li>
											<li>Time zone</li>
											<li>Cookies that are installed on your device.</li>
											<li>Blockchain address, wallet type, and date/time stamps</li>
											<li>Transaction history;</li>
											<li>Information from other sources such as credit history information from credit bureaus;</li>
										</ul>
									</div>
									<div class="column">
										<ul className="policylist">
											<li>Provide, troubleshoot, and improve WAGMI Services. We use your personal information to
												provide
												functionality, analyze performance, fix errors, and improve the usability and effectiveness
												of
												WAGMI
												Services.
											</li>
											<br />
											<li><strong>Fraud prevention and credit risks.</strong> We (and our service providers) process
												personal
												information to
												prevent and detect fraud and abuse to protect the security of our users, WAGMI Services, and
												others.
												We may also use scoring methods to assess and manage credit risks.
											</li>
											<br />
										</ul>
									</div>
									<div class="column">
										<ul className="policylist">
											<li><strong>Performance of a contract:</strong> when we provide you with products or services or
												communicate with you
												about
												them. This includes when we use your personal information to take and handle orders and
												process
												payments.
											</li>
											<br />
											<li><strong>Legal obligation:</strong> to comply with our legal obligations under applicable
												laws
												and
												regulations, and
												Anti-Money Laundering laws and regulations.
											</li>
											<br />
										</ul>
									</div>
								</div>
								<div class="columns">
									<div class="column">
										<p className="points">
											<strong>
												Information Collected by Technologies:
											</strong>
										</p>
										<ul className="policylist">
											<li>
												<strong>Log files</strong> track actions occurring on the Site, and collect data including
												your
												IP address,
												browser type, Internet service provider, referring/exit pages, and date/time stamps.
											</li>
											<li>
												<strong>Cookies.</strong> Like many online services, we use cookies to collect information.
												A
												copy of our cookie
												Notice is available.
											</li>
											<li>
												<strong>Pixel Tag</strong> (also referred to as clear Gifs, Web beacons, or Web bugs).
											</li>
										</ul><br />
									</div>

									<div class="column">

										<ul className="policylist">
											<li><strong>Improve our services.</strong> We process personal information to improve our
												services
												and
												provide you with a
												better user experience.
											</li>
											<br />
											<li>
												<strong>Recommendations and personalization.</strong> We use your personal information to
												recommend
												features and
												services, identify your preferences, and personalize your experience with WAGMI Services.
											</li>
											<br />
											<li>Analyze how users interact with our Service, make improvements to our service quality, and
												provide
												users with a more personalized experience.
											</li>
											<br />
										</ul>
									</div>
									<div class="column">
										<ul className="policylist">
											<li><strong>Your consent:</strong> when we ask for your consent to process your personal
												information
												for
												a specific
												purpose
												that we communicate to you. When you consent to processing your personal information for a
												specified
												purpose, you may withdraw your consent at any time, and we will stop processing your
												personal
												information for that purpose. The withdrawal of consent does not affect the lawfulness of
												processing
												based on consent before its withdrawal.
											</li>
										</ul>
									</div>
								</div>
								<div class="columns">
									<div class="column">
										<p className="points">
											<strong>
												Analytics Services:
											</strong>
										</p>
										<p className="points">
											Third-party analytics services: cookies, pixel tags and similar tools collect:
										</p>
										<ul className="policylist">
											<li>Number of users.</li>
											<li>Session statistics.</li>
											<li>Approximate geolocation.</li>
											<li>Browser and device information.</li>
										</ul>
									</div>

									<div class="column">
										<ul className="policylist">
											<li>We use information from Analytics Services <strong>to improve our Service.</strong> The
												information
												generated by Cookies or other technologies about your use of our Service
												(the Analytics Information) is transmitted to the Analytics Services. The Analytics Services
												use
												Analytics Information to compile reports on user activity, which we may receive on an
												individual
												or
												aggregate basis.
											</li>
										</ul>
									</div>
									<div class="column">
										<ul className="policylist">
											<li><strong>Legal obligation:</strong> to comply with our legal obligations under applicable
												laws
												and
												regulations, and
												Anti-Money Laundering laws and regulations.
											</li>
										</ul>
									</div>
								</div>
								<p className="policyheading" >
									2. Disclosure of Your Personal Data:
								</p>
								<p className="points">
									We attach great importance to the security of your personal data. We will not sell, share, or
									lease your personal information to unrelated third parties unless we are required by law to do
									so or have your permission. We share your Personal Information with third parties to help us
									use your Personal Information, as described above.
								</p>
								<p className="points">
									We may also share personal information with the following persons:
								</p>
								<ul className="policylist">
									<p className="points">
										<strong>Third party service providers:</strong> We employ other companies and individuals to perform
										functions on our behalf. Examples include analyzing data, providing marketing assistance, processing
										payments, transmitting content, and assessing and managing credit risk. These third-party service
										providers only have access to personal information needed to perform their functions but may not use
										it for other purposes. Further, they must process the personal information in accordance with our
										contractual agreements and only as permitted by applicable data protection laws.
									</p>
									<p className="points">
										<strong>Legal Authorities:</strong> We may be required by law or by Court to disclose certain
										information about you or any engagement we may have with you to relevant regulatory, law
										enforcement, and/or other competent authorities. We will disclose information about you to legal
										authorities to the extent we are obliged to do so according to the law. We may also need to share
										your information in order to enforce or apply our legal rights or to prevent fraud.
									</p>
									<p className="points">
										<strong>Business transfers:</strong> As we continue to develop our business, we might sell or buy
										other businesses or services. In such transactions, user information generally is one of the
										transferred business assets but remains subject to the promises made in any pre-existing Privacy
										Notice (unless, of course, the user consents otherwise). Also, in the event that WAGMI or
										substantially all of its assets are acquired by a third party, user information will be one of the
										transferred assets.
									</p>
									<p className="points">
										<strong>Protection of WAGMI and others:</strong> We release accounts and other personal information
										when we believe release is appropriate to comply with the law or with our regulatory obligations;
										enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety
										of WAGMI, our users, or others. This includes exchanging information with other companies and
										organizations for fraud protection and credit risk reduction.
									</p>
								</ul>
								<p className="policyheading" >
									3. Minors:
								</p>
								<p className="points">
									WAGMI does not allow anyone under the age of 18 to use WAGMI Services and does not
									knowingly collect personal information from children under 18.
								</p>
								<p className="policyheading" >
									4. Third-Party Websites
								</p>
								<p className="points">
									Our Service may contain links to third-party websites. When you click on a link to any other
									website or location, you will leave our Service and go to another site, and another entity may
									collect Personal Data from you. We have no control over, do not review, and cannot be
									responsible for these third-party websites or their content. Please be aware that the terms of
									this Privacy Notice do not apply to these third-party websites or their content, or to any
									collection of your Personal Data after you click on links to such third-party websites. We
									encourage you to read the privacy policies of every website you visit. Any links to third-party
									websites or locations are for your convenience and do not signify our endorsement of such third
									parties or their products, content, or websites.
								</p>
								<p className="policyheading" >
									5. Third-Party Wallets
								</p>
								<p className="points">
									To use our Service, you must use a third-party wallet which allows you to engage in
									transactions on public blockchains. Your interactions with any third-party wallet provider are
									governed by the applicable terms of service and privacy Notice of that third party.
								</p>
								<p className="policyheading" >
									6. Your Rights
								</p>
								<ul className="policylist">
									<li>
										<strong>Data Access:</strong> You can access your personal data on your account’s user dashboard.
										You also
										have the right to access a copy of your personal information and to request information about how we
										process
										your personal information. This includes information about the purposes of the processing, the
										categories of
										personal information we process, and the recipients or categories of recipient to whom the personal
										information has been or will be disclosed.
									</li>
									<br />
									<li>
										<strong>Data Portability:</strong> You can request a copy of your personal data by submitting an
										email to us at <a href="mailto:dpo@wagmi.ae">dpo@wagmi.ae</a> and including “Please send me a copy of my personal
										data” in the
										“Subject” line. Our DPO will verify your ability to access that email, then send you a digital
										export of the
										data we hold that is associated with your email address. We will use reasonable efforts to respond
										to your
										request within 30 days of our receipt of verification of the request. Our DPO may be limited in its
										ability to
										send certain personal data to you (e.g., the identification of your electronic wallet).
									</li>
									<br />
									<li>
										<strong>Data Erasure:</strong> You may submit a request to our DPO to delete your personal data by
										submitting an
										email to us at <a href="mailto:dpo@wagmi.ae">dpo@wagmi.ae</a> and including “Please delete my
										personal data” in the
										“Subject” line. Our DPO will verify your ability to access that email, then delete the personal data
										associated with your email address. We will use reasonable efforts to respond to your request within
										30 days of
										our receipt of verification of the request.
									</li>
									<br />
									<li>
										<strong>Right to Object/Opt-Out:</strong> You have the right to request that we restrict how we
										process your
										personal information if the processing is performed for direct marketing purposes and the processing
										is
										performed for statistical survey purposes. You may also have the right to object/restrict to the
										processing if
										it was carried out in violation of the Federal Data Protection Law. If you make a valid request to
										restrict the
										processing of your personal information, we will continue to store the information. We will not
										process the
										information in any other ways, unless we have your consent; we need the personal information to
										establish,
										exercise, or defend legal claims; we need to protect the rights of another natural or legal person;
										or we need
										to process the personal information for reasons of important public interest.
									</li>
									<br />
									<li>
										<strong>Data Correction:</strong> You can modify your personal data via your account’s user
										dashboard. Note that
										since some of the data we collect is specific to you – for example, your electronic wallet address –
										you may not
										be able to modify this data without needing to create a new user profile. If you are unable to
										rectify
										inaccurate data, you can contact us at <a href="mailto:dpo@wagmi.ae">dpo@wagmi.ae</a>.
									</li>
									<br />
									<li>
										<strong>Right to Object to Automated Decision Making:</strong> As a data subject, you have the right
										to object
										to decisions made solely through automated processing, including profiling, if these decisions
										significantly
										affect you. If you wish to exercise this right, please contact us using the provided contact
										details. However,
										please note that there are specific cases where the right to object may not apply.
									</li>
									<br />
									<ul class="policysublist">
										<li>
											<strong>Processing Necessary for Contractual Obligations:</strong> You may not object to the
											automated processing of your personal data if it is necessary for the performance of a contract
											between you and us.
										</li>
										<br />
										<li>
											<strong>Processing Authorized by Law:</strong> You may not object to the automated processing of
											your personal data if it is required or authorized by applicable laws, regulations, or legal
											obligations.
										</li>
										<br />
										<li>
											<strong>Processing Based on Expressed Consent:</strong> You may not object to the automated
											processing of your personal data if you have provided explicit consent for such processing.
										</li>
									</ul>
									<li>
										<p className="points"><strong>Your Communications Preferences:</strong>
											You can choose whether you wish to receive marketing communications from us. If you receive
											marketing communications from us and would like to opt out, you can do so by following the
											directions in
											that communication.You can also make choices about your receipt of marketing communications by
											signing into your account and can update contact information, manage your contact preferences,
											opt out of email subscriptions, and
											choose whether to share your contact information with WAGMI and our partners.Alternatively, you
											can request that we withdraw your consent to use your personal data by
											submitting an email to us at <a href="mailto:dpo@wagmi.ae">dpo@wagmi.ae</a> and including
											“Please withdraw my
											consent for marketing communications” in the “Subject” line. Our DPO will verify your ability to
											access that email, then update our systems to remove your email address from the system we use
											to send
											marketing communications. We will use reasonable efforts to respond to your request within 30
											days of our receipt of verification of the request. Please note that these choices do not apply
											to
											mandatory communications that are part of the services, or to surveys or other informational
											communications that have their own unsubscribe method.
										</p>
									</li>
								</ul>
								<p className="points">
									<strong>IF YOU ARE A USER IN THE EUROPEAN ECONOMIC AREA OR UNITED KINGDOM, YOU HAVE CERTAIN RIGHTS
										UNDER THE RESPECTIVE EUROPEAN AND UK GENERAL DATA PROTECTION REGULATIONS (“GDPR”).</strong>
									These include the right to (i) request access and obtain a copy of your personal data; (ii) request
									rectification or erasure; (iii) object to or restrict the processing of your personal data; and (iv)
									request portability of your personal data. Additionally, if we have collected and processed your
									personal data with your consent, you have the right to withdraw your consent at any time.
								</p>
								<p className="points">
									<strong>IF YOU ARE A CALIFORNIA RESIDENT, YOU HAVE CERTAIN RIGHTS UNDER
										THE CALIFORNIA CONSUMER PRIVACY ACT (“CCPA”).</strong> These include the right to
									(i) request access to, details regarding, and a copy of the personal information we have collected
									about you and/or shared with third parties; (ii) request deletion of the personal information that
									we have collected about you; and (iii) the right to opt-out of sale of your personal information.
									As the terms are defined under the CCPA, we do not “sell” your “personal information.”
								</p>
								<ul className="policylist">
									<li>
										If you wish to exercise your rights under the GDPR, CCPA, or other applicable data protection or
										privacy
										laws, please contact us at <a href="mailto:dpo@wagmi.ae">dpo@wagmi.ae</a>. We will use reasonable
										efforts to respond to your request within 30 days of our receipt of the request.
									</li>

									<li>
										We may ask you to verify your identity or ask for more information about your request. We will
										consider
										and act upon any above request in accordance with applicable law. We will not discriminate against
										you
										for exercising any of these rights.
									</li>

									<li>
										Notwithstanding the above, PLEASE NOTE that we cannot edit or delete any information that is stored
										on a blockchain, for example the blockchain, as we do not have custody or control over any
										blockchains.
										The information stored on the blockchain may include purchases, sales, and transfers related to your
										blockchain address and NFTs held at that address.
									</li>
								</ul>
								<p className="policyheading" >
									7. Where we Store and Process Personal Data; International Transfers
								</p>
								<p className="points">
									Personal data collected by WAGMI may be stored and processed in the United States or in any other
									country where WAGMI or its affiliates, subsidiaries, or service providers maintain facilities. The
									storage location(s) are chosen to operate efficiently, improve performance, and create redundancies to
									protect data in the event of an outage or other problem. We take steps to ensure that the data we
									collect is processed according to the provisions of this Notice and the requirements of applicable law
									wherever the data is located.

									We transfer personal data from the UAE to other countries, some of which may not have an adequate
									level of data protection. When we engage in such transfers, we use a variety of legal mechanisms,
									including contracts, to help ensure your rights and protections travel with your data.
								</p>

								<p className="policyheading" >
									8. Data Retention:
								</p>
								<p className="points">
									We keep your personal information to enable your continued use of WAGMI Services, for as long as it is
									required to fulfill the relevant purposes described in this Privacy Notice, and as may be required by
									law such as for tax and accounting purposes, compliance with Anti-Money Laundering laws, or as
									otherwise communicated to you.
								</p>
								<p className="policyheading" >
									9. Advertising:
								</p>
								<p className="points">
									In order for us to provide you with the best user experience, we may share your personal information
									with our marketing partners for the purposes of targeting, modeling, and/or analytics as well as
									marketing and advertising. You have the right to object at any time to processing of your personal
									information for direct marketing purposes.
								</p>


								<p className="policyheading" >
									10. Contact Information:
								</p>
								<p className="points">
									Our data protection officer can be contacted at <a href="mailto:dpo@wagmi.ae">dpo@wagmi.ae</a>, and
									will work to address any questions or issues that you have with respect to the collection and processing
									of your personal information.
								</p>

							</div>
						</div>

					) : (
						//aml polcy
						<div className="policy">
							<div className="odd">
								<p className="points">
									Last updated: 07/06/2023
								</p>
								<h3>
									AML Policy
								</h3>

								<p className="points">
									WAGMI Enterprises LLC, (the “Company”, “We”, “Us”, “Our”) is determined to prohibit and actively prevent
									money laundering and any activity that facilitates money laundering or the funding of terrorist or criminal
									activities by complying with all applicable requirements under the Bank Secrecy Act of 1970, The Anti-Money
									Laundering Act of 2020 (the Act), and securities laws and rules.
								</p>
								<p className="points">
									Our AML policies, procedures, and internal controls are designed to ensure compliance with all applicable
									regulations, or requirements from service providers and will be reviewed and updated on a regular basis to
									ensure that the appropriate policies, procedures, and internal controls are in place to account for changes
									in regulations, changes in our business, and changes in our service provider’s requirements.
								</p>

								<p className="policyheading">1. Money Laundering Reporting Officer (MLRO):</p>
								<p className="points">
									If an individual becomes aware that their involvement in a matter may amount to money laundering, then they
									must report it to the Money Laundering Reporting Officer (MLRO) and not take any further action until they
									have received consent from the MLRO.
								</p>
								<p className="points">
									WAGMI has designated the Co-founder, Nagham Hassan, as the Money Laundering Reporting Officer (MLRO). In the
									absence of the MLRO or in instances where it is suspected that the MLRO is involved in suspicious
									transactions, concerns should be raised with the Executive Director, Central Services.
								</p>
								<p className="points">
									The duties of the MLRO are, but are not limited to, ensure the development of a risk assessment framework as
									well as the development of the appropriate policies and procedures; oversee the onboarding of customers,
									including high-risk customers; ensure effective monitoring compliance; report to the management body any
									suspicious transactions; undertake the necessary training and awareness.
								</p>

								<ul className="policylist">
									<li>Address: Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab Emirates. </li>
									<li>Telephone Number: +971 55 262 8794 </li>
									<li>Email: <a href="mailto:nagham@wagmi.ae">nagham@wagmi.ae</a></li>
								</ul>
								<p className="policyheading">2. Record Keeping Procedures:</p>

								<p className="points">
									WAGMI maintains electronic records in their internal databases of every customer due diligence record (CDD
									OR EDD), KYC, and details of all relevant transactions carried out for a minimum of seven years starting the
									end of any client relationship. These records may be used as evidence in any subsequent
									investigation/inspection by the relevant supervising body. Copies of KYC information related to specific
									customers might be transmitted to trusted third-party service
									providers when requested to fulfill regulatory and transaction monitoring obligations, respond to regulatory
									and examination requests, or to perform testing on the Third Party’s performance of KYC. WAGMI also ensures
									that this record-keeping procedure complies with every data protection and privacy regulation. See the
									privacy policy for more information.
								</p>
								<p className="points">
									WAGMI will retain the following information about customers:
								</p>
								<ul className="policylist">
									<li>All identifying information received </li>
									<li>Descriptions of any steps taken to verify customers </li>
									<li>Any additional due diligence information collected </li>
								</ul>
								<p className="points">
									Additionally, any KYC/AML service provider in contract with WAGMI will have to retain:
								</p>
								<ul className="policylist">
									<li>Copies of all ID documentation obtained from the customer for a minimum of seven years following the
										date the verification is performed </li>
									<li>Records of all sanctions, PEP, and negative news screening performed, including any dispositions of
										alerts, for a minimum of seven years from the date the screening was performed.</li>
								</ul>
								<p className="policyheading">3. KYC and Customer Due Diligence</p>
								<p className="points">
									WAGMI relies on a third-party AML service provider to perform the Know Your Customer (“KYC”) procedure. This
									AML policy ensures that both WAGMI and its contracted third parties adhere to legislations, guidelines, and
									directives put in place by regulatory authorities and international watchdogs for KYC and AML compliance.
								</p>
								<p className="policysubheading">i. Documents Required for Verification:</p>
								<p className="points">
									Our KYC procedure requires Customers to provide both proof of identity and address: The following documents
									are considered as Proof of Identity:
								</p>
								<p className="points">
									− Identity Verification:
								</p>
								<ul className="policylist">
									<li>A valid passport;</li>
									<li>A Valid ID-Card;</li>
									<li>Driver's license;</li>
									<li>Social security card for specific citizens pursuant to their national laws.</li>
								</ul>
								<p className="points">
									The following documents are considered as proof of address:
								</p>
								<p className="points">
									− Address Verification:
								</p>
								<ul className="policylist">
									<li>A current utility bill (such as gas, electricity, telephone, or mobile phone bill);</li>
									<li>A document issued by a government department that shows the End-user’s address;</li>
									<li>A bank statement (no older than 3 months that shows the End-user’s address).</li>
								</ul>

								<p className="policysubheading">
									ii. Customer Requiring KYC
								</p>
								<p className="points">
									Customers must be subjected to KYC in all of the following situations:
								</p>
								<p className="points">
									- All end-users using our third-party payment providers.
								</p>
								<ul className="policylist">
									<li>Customer is withdrawing funds (both fiat and crypto) from its account.</li>
									<li>Customer is an individual whose account is “open loop”, i.e., the wallet can hold USD Coin (“USDC”) and
										have on-chain access. This includes all “closed loop customers wishing to withdraw funds.</li>
									<li>Customer is an individual whose account is “closed loop”, and has reached either of the following
										thresholds:</li>

									<ul className="policysublist">
										<li>Customer has deposited the equivalent of $10,000 over the life of the account.</li>
										<li>Customer has a cumulative balance of the equivalent of $2,000 available during the course of a
											single calendar day. For example: if a wallet has a value of $1,500, and the customer spends $1,000
											and subsequently reloads $600 before the end of the day, this wallet will have a cumulative daily
											balance of $2,100 and therefore be subject to KYC.</li>
									</ul>
								</ul>
								<p className="points">
									WAGMI may, on a risk-basis, subject additional customers to KYC, or require Third Parties to conduct
									additional KYC on its behalf.
								</p>
								<p className="policysubheading">
									iii. How is it Done?
								</p>
								<p className="points">
									Users can register on the platform by either linking an existing crypto wallet or by providing us with an
									e-mail address.
									Users will also have to complete their profile to navigate and use the platform. The information we require
									our users to provide us
									include, but not limited to, name; surname; phone number; Date of Birth, and location address. After the
									completion of a profile,
									our Customer Risk Rating / Enhanced Due Diligence process will be triggered. Our user screening AML software
									will classify each user
									into a risk category with the information provided as well as information we collected from the servers
									(please see our privacy policy).
									Each risk level triggers a different AML internal process (view section. iv). Furthermore, users who decide
									to withdraw money from their
									account will be required to undergo a Know Your Customer process to verify and confirm their identity. WAGMI
									or the third-party conducting
									KYC on its behalf will use facial recognition technology to identify, authenticate the user’s identity. In
									the case of identification,
									the software will match the unique modalities of a person against many other faces to determine the
									individual's identity. Our KYC procedure
									is further explained in Appendix 1.
								</p>

								<p className="points">
									WAGMI will also be monitoring blockchain activity to ensure sanctioned addresses cannot register and use the
									platform. It is important to note
									that NFTs purchased on the WAGMI platform cannot be transferred into another platform through an existing
									crypto wallet. They are specific and
									exclusive to our platform.
								</p>
								<p className="points">
									WAGMI or the third-party conducting KYC on its behalf provide an AML software that performs user screening
									against AML databases within seconds
									to identify high-risk entities in real-time. This procedure leverages advanced technology and synergizes
									artificial intelligence and human intelligence
									to deliver highly accurate results. Our policy revolves around an ML/TF customer risk assessment based on
									quantitative and qualitative data and information,
									as well as the use of internal meetings or interviews; internal questionnaires concerning risk
									identification and controls; and a review of internal audit reports.
								</p>
								<p className="points">
									The ML/TF customer risk assessment guides WAGMI in the performance and execution of its customer due
									diligence measures. Each customer is given an ML/FT risk
									profile that determines WAGMI’s level of involvement. Indeed, identified high risks profiles will trigger
									the application of enhanced due diligence (EDD)
									measures, and in the absence of any suspicion of ML/FT, identified low risks profiles will be managed by
									simplified due diligence measures (SDD).
								</p>
								<p className="points">
									However, a customer’s ML/FT risk profile is dynamic and subject to change depending on numerous factors,
									including (but not limited to) the discovery of
									new information or a change in behavior. Therefore, the appropriate level of due diligence will also vary
									depending on the specific situation and risk indicators identified.
									In that regard, WAGMI will increase the type and level of due diligence exercised on a customer of any ML/FT
									risk category whenever the circumstances require,
									including situations in which there are any doubts as to the accuracy or appropriateness of the customer’s
									originally designated ML/FT risk category.
								</p>
								<p className="points">
									They are four risk levels that lead to different legal and practical consequences:
								</p>
								<ul className="policylist">
									<li>
										<strong>Low:</strong> Customers whose identity is easily identified. They only have to go through the
										KYC procedure when
										withdrawing money, which involves a minimal and simplified customer due diligence measure.
									</li>
									<br />
									<li>
										<strong>Medium:</strong> Customers who pose a higher risk than an average customer. They also only have
										to go through the
										KYC procedure when withdrawing money or if any of the conditions set in paragraph 3(ii) are met.
										However, additional information might be required if needed.
									</li>
									<br />
									<li>
										<strong>High:</strong> Customers whose financial activities are monitored with Customer Due Diligence.
										These customers
										can be, for example, a politically exposed person, or someone that holds a public office… The service
										screens an individual’s selected ID attributes of Name and DOB against watchlists of global regulatory
										authorities, foreign and domestic databases, compromised PEPs, and sanctioned individuals. The service
										highlights the category of the PEP based on the degree of risks they pose and any immediate family
										member, or a close associate of the PEP. In these instances, WAGMI may require additional information
										from these high-risk users to confirm their identity and legitimacy.
									</li>
									<br />
									<ul className="policysublist">
										<li>
											The required Additional information and evidence from high-risk customers include but are not
											limited
											to: Financial Information, Authentication Factors, and Background Checks. For legal entities the
											following information can be requested, but is not limited to: Source of funds (revenue) and source
											of
											wealth; Identifying information on individuals with control over the customer (legal person or
											arrangement), such as signatories or guarantors; Occupation or type of business; Financial
											statements;
											Banking references; Domicile; Description of the customer’s primary trade area and whether
											international
											transactions are expected to be routine; Description of the business operations, the anticipated
											volume
											of currency and total sales, and a list of major customers and suppliers; Explanations for changes
											in
											business activity.
										</li>
										<br />
										<li>
											Factors to consider when determining whether EDD is required include, but are not limited to: The
											person’s location; Their occupation; Transaction types; Expected activity patterns in terms of
											transaction types, dollar values, and frequencies; Expected payment method.
										</li>
										<br />
									</ul>
									<li>
										Prohibited: Customers who are banned from financial activities due to their involvement in financial
										crimes.
										The list also includes users who are ineligible (or may become ineligible) to use our Services:
									</li>
									<ul className="policysublist">
										<li>
											Users who cause us to violate any relevant laws, sponsor bank requirements, and card or payment
											association (e.g, VISA, MasterCard, NACHA) rules and policies.
										</li>
										<br />
										<li>
											Residents, nationals, or agents of Cuba, North Korea, Sudan, Syria, the Crimea region of Ukraine, or
											any
											other country to which the United States, United Kingdom, or Bermuda embargo goods (“Restricted
											Territories”).
										</li>
										<br />
										<li>
											Users who are on the Table of Denial Orders, the Entity List, or the List of Specially Designated
											Nationals, Her Majesty’s Treasury Financial Sanctions Target, or Bermuda Sanctions Regime List
											(“Restricted Persons”); or.
										</li>
										<br />
										<li>
											Users that intend to transact with any Restricted Territories or Restricted Persons.
										</li>

									</ul>
									<p className="points">
										The list of prohibited customers also includes users whose activities are prohibited because of its
										illegality or immorality or other. Such list includes but is not limited to:
									</p>
									<ul className="policysublist">
										<li>
											Illegal products and services: Illegal drugs, substances designed to mimic illegal drugs, and
											equipment
											designed for making or using drugs; Fake references or ID providing services; Telecommunications
											manipulation equipment including jamming devices; Any other products or services that are in
											violation
											of law in the jurisdictions where your business is located or targeted to…
										</li>
										<br />
										<li>
											Products and services that infringe intellectual property rights: Sales or distribution of music,
											movies, software, or any other licensed materials without appropriate authorization; Counterfeit
											goods;
											illegally imported or exported products; Unauthorized sale of brand name or designer products or
											services; Any other products or services that directly infringe or facilitate infringement upon the
											trademark, patent, copyright, trade secrets, proprietary or privacy rights of any third party…
										</li>
										<br />
										<li>
											Products and services that are unfair, predatory, or deceptive: Pyramid schemes; ‘Get rich quick’
											schemes including: investment opportunities or other services that promise high rewards to mislead
											consumers; No value-added services including sale or resale of a service without added benefit to
											the
											buyer and resale of government offerings without authorization or added value; Negative response
											marketing and telemarketing; Predatory mortgage consulting, lending, credit repair and counseling
											services; Predatory investment opportunities with no or low money down; Remote technical support,
											mugshot publication or pay-to-remove sites, essay mills, chain letters, door to-door sales; Any
											other
											businesses that WAGMI considers unfair, deceptive, or predatory towards consumers.
										</li>
										<br />
										<li>
											Prohibited Business Type: Adult content and services; Firearms, explosives and dangerous materials;
											Auction services; ATM Operatory; Bankruptcy services; Controlled substances and drug paraphernalia;
											Court ordered payments; Credit repair services; Cyberlocking services; Debt collection; Gambling;
											Illegal activities; Precious metals and jewelry; Marijuana/CBD; Multi-level marketing; Pay-day
											lender;
											Ransomware; Shell banks and financial institutions; Unlicensed money service businesses; Weapons
											Manufacturing; Other businesses, Sale of email or direct marketing lists enabling unsolicited
											contacts,
											such as from telemarketing merchants (inbound or outbound); Sale of manuals, how-to guides, or
											equipment
											to disable, “hack”, or modify access controls on software, servers, phones, or websites, including
											but
											not limited to mod-chips, drive chips, or access cards; Items or sites that encourage or promote
											hate,
											violence, or racial intolerance; Psychic services.
										</li>
										<br />
										<li>
											Certain legal services: Law firms collecting funds for purposes other than legal service fee
											payment;
											Bankruptcy attorneys; Bail bonds.
										</li>
										<br />
										<li>
											Misuse of WAGMI’s services: Use of WAGMI’s services with false, manipulated, inaccurate, or
											misleading
											information regarding your identity, business entity, the nature of business, and any other
											information
											requested by WAGMI (you must inform us immediately of any changes to your personal and business
											information); Use of WAGMI’s services to facilitate transactions on behalf of another undisclosed
											merchant or for products/services that were not disclosed in the merchant's WAGMI account
											application;
											Processing where there is no bona fide good or service sold, or donation accepted.
										</li>
										<br />
										<li>
											Prohibited Transactions: Using the Services for transfers related to the following is prohibited,
											and
											WAGMI and some of its third party providers reserve the right to monitor for transactions that
											relate
											to: Wash trading, front-running, insider trading, market manipulation or other forms of market-based
											fraud or deceit; Purchasing goods of any type from “Darknet” markets, or any other service or
											website
											that acts as a marketplace for illegal goods (even though such a marketplace might also sell legal
											goods); Money laundering; Terrorist financing; Processing transactions for another business other
											than
											the one listed on the account.
										</li>
									</ul>
								</ul>
								<p className="points">
									WAGMI and some of its third parties will monitor its users’ transactions and activities for potential
									violations of this policy. Monitoring includes, but is not limited to, reviewing accounts for retrievals,
									returns, or Chargebacks, as well as using third-party information or sources. We encourage you to contact us
									if you believe you may be in violation of this Policy. We reserve the right, per the terms of the User
									Agreement to take any corrective action (with or without notice to you) as we deem necessary to mitigate our
									risk or ensure compliance with this Policy, including but not limited to: (i) blocking or withholding a
									transaction; (ii) holding funds associated with a prohibited transaction; (iii) suspending, restricting, or
									terminating your use of the Services, or (iv) subjecting you to applicable fines, assessment, expenses and
									fees.
								</p>
								<p className="policysubheading">iv. Prohibited Jurisdictions:</p>

								<p className="points">
									WAGMI prohibits customers from the following countries:
								</p>
								<ul className="policylist">
									<li>Afghanistan</li>
									<li>Belarus</li>
									<li>Central African Republic (the)</li>
									<li>Congo (the Democratic Republic of the)</li>
									<li>Cuba</li>
									<li>Guinea-Bissau</li>
									<li>Iran (Islamic Republic of)</li>
									<li>Iraq</li>
									<li>Korea (the Democratic People's Republic of)</li>
									<li>Libya</li>
									<li>Mali</li>
									<li>Myanmar</li>
									<li>Russia</li>
									<li>Somalia</li>
									<li>South Sudan</li>
									<li>Sudan (the)</li>
									<li>Syrian Arab Republic</li>
									<li>Ukraine</li>
									<li>United States (Only for the following States: Alaska, Hawaii, Minnesota, New York)</li>
									<li>Venezuela (Bolivarian Republic of)</li>
									<li>Yemen</li>
								</ul>
								<p className="points">
									WAGMI will implement geographic restrictions to its website, in order to block access to users coming from these prohibited jurisdictions.
									WAGMI has implemented security controls to identify IP addresses behind VPNs and will block users from using
									the platform if their location falls within the list of prohibited countries.
									Our screening process is also capable of flagging any individual residing in a prohibited jurisdiction.
									These users will not be able to complete their profile without a KYC procedure.
									However, WAGMI cannot guarantee that all users coming from these prohibited jurisdictions will be blocked,
									especially when these users try to hide or mask their location.
									Nevertheless, WAGMI will block funds of all customers that are from prohibited jurisdictions and will notify
									within 24 hours the necessary service providers of such action.
								</p>

								<p className="points">
									Individuals residing in a non-prohibited country but providing a passport or document from a prohibited
									country during the KYC procedure will either:
								</p>
								<ul className="policylist">
									<li>
										Be blocked automatically if the country presented is from an OFAC sanctioned country or Russia.
									</li>
									<li>
										If the country is outside of those 5 (Cuba, Iran, North Korea, Syria, or Russia) but in our other
										Prohibited bucket, the user will be onboarded.
										However, a deep dive/EDD will be performed into the individual to prove residency (i.e. proof of
										address, IP checks, and a review of any other data signals they may have collected on the customer that
										indicate jurisdiction like email, phone number, etc).
										If there's any doubt on the residency, then they need to be offboarded.
									</li>
								</ul>
								<p className="points">
									Independent testing:
								</p>

								<p className="points">
									The security of our customers and their assets is of utmost importance to us. We ensure an independent
									testing of our compliance program and internal controls.
									This testing will be conducted by our compliance team or a third-party organization every year.
									The independent testing will cover all aspects of this AML policy.
								</p>
								<p className="policyheading">
									4. Training:
								</p>

								<p className="points">
									WAGMI provides general AML training for all its appropriate personnel. This training covers the aspects of
									the Federal Decree No. (20) of 2018 that are relevant to an NFT platform and its risk profile. Appropriate
									personnel include those whose duties require knowledge or involve some aspect of AML compliance. The
									training covers the regulatory requirements, supervisory guidance, and the company’s internal AML policies,
									procedures, and processes. The AML compliance officer and its compliance staff receive periodic training in
									order to adapt and remain informed of changes to regulatory requirements and changes to the company’s risk
									profile.
								</p>
								<p className="policyheading">
									5 Reporting Procedure for Suspicions of Money Laundering:
								</p>

								<p className="points">
									We will conduct ongoing monitoring to identify and report suspicious transactions and, on a risk basis,
									maintain and update customer information, including information regarding the identity of users, using the
									customer risk profile as a baseline against which customer activity is assessed for suspicious transaction
									reporting. WAGMI and its AML service provider ensure that all individuals and aggregate transactions,
									including blockchain transactions, are monitored and typologies for money laundering, terrorist financing,
									or any other potentially illicit activity are identified, reviewed and escalated, if needed. Any unusual
									transaction activity identified as part of the transaction monitoring review process will be referred to
									WAGMI ’s third-party payment providers . The notification will include a summary of the activity, any
									relevant end user identifiers (i.e., username, email, etc.), date range of the activity, any relevant
									documentation such as KYC documents, and relevant end-user communication records. Moreover, all positive
									sanctions, PEP and adverse media matches will also be reported to WAGMI ’s third-party payment providers
								</p>
								<p className="points">
									Finally, WAGMI will immediately freeze funds in any Accounts API associated with sanctioned individuals,
									awaiting the proper course of action from regulatory authority and its third-party payment providers.
								</p>
								<p className="points">
									Under the BSA/AML regulatory framework, WAGMI will report using the BSA E-Filing System to the Financial
									Crimes Enforcement Network any suspicious transactions and any additional information required in relation
									to them, when there are suspicions, or reasonable grounds to suspect, that the proceeds are related to a
									crime, or to the attempt or intention to use funds or proceeds for the purpose of committing, concealing or
									benefitting from a crime.
								</p>
								<p className="points">
									Within the meaning of the Bank Secrecy Act a suspicious transaction refers to any transaction, attempted
									transaction, or funds which a company has reasonable grounds to suspect as constituting—in whole or in part,
									and regardless of the amount or the timing—any of the following:
								</p>
								<ul className="points">
									<li>The proceeds of crime (whether designated as a misdemeanor or felony, and whether committed within the
										State or in another country in which it is also a crime);</li>
									<li>Being related to the crimes of money laundering, the financing of terrorism, or the financing of illegal
										organizations;</li>
									<li>Being intended to be used in an activity related to such crimes.</li>
								</ul>
								<h3>
									Appendix 1
								</h3>
								<h4>KYC Procedure</h4>
								<p className="points">
									WAGMI will integrate KYC/AML service provider (Sumsub) APIs which use Basic Auth &
									Access Token-based Authentication, HTTP Status Codes, JSON-Request & Response bodies.
									These APIs help automate the KYC/AML processes by providing the following:
								</p>
								<ul className="policylist">
									<li>Verification Procedure</li>
								</ul>
								<p className="points">
									The identity verification procedure consists of KYC screening, AML screening, and ongoing
									AML monitoring. All these verifications are performed using Artificial Intelligence to fetch
									and analyse the data from valid identity documents and by utilizing the information provided
									by the end-user.
								</p>
								<ul className="policylist">
									<li>Information required:</li>
									<ul className="policysublist">
										<li>Physical person</li>

										<p className="points">
											Identification number (for U.S. customers, this is a Social Security Number
											(“SSN”) (or evidence of an application for one). For non-U.S. customer, this is one or more of the
											following: a taxpayer identification number; a passport number and country of issuance; an
											alien identification card number; or a number and country of issuance of any other unexpired
											government-issued document evidencing nationality or residence and bearing a photograph or
											similar safeguard)</p>

										<li>Entity or moral person:</li>

										<p className="points">
											Legal Entity and DBA name; Country of Formation; Date of Formation; Physical
											address; Tax ID; Nature of Business; Description of business model, product / service and customer
											types; Verification of the entity customer (i.e., formation document); Authorized
											representative’s name, title, email address, and phone number; Name, date of birth, title of all
											directors and verification of these individuals (i.e., collecting IDs); The following information
                    about direct / indirect beneficial owners > 20%: name, DOB, residential address, ownership
											percentage;</p>

										<p className="points">
											Additional information about financial institutions: countries licensed in, description of
											customer types and their location, identity of designated compliance officer, AML policy;
											Additional information about money services business: information for financial institutions, MSB
											registration and Money Transmitter License or Legal Opinion why it is not required for
											entities located or have customers in the US;
										</p>

										<p className="points">
											Additional information about hedge funds: information for financial institutions, prospectus
											or offering memorandum; Additional information about asset managers: information for
											financial institutions, investment policy statement;
										</p>

										<p className="points">
											Additional information for Trusts: Identity of settlor of the trust, identity of protector, identity
											of trustee (including beneficial owners);
										</p>

										<p className="points">
											Additional information for Individual Retirement Accounts: countries of operation, identity
											of all major beneficiaries;
										</p>

										<p className="points">
											Additional information for charities: description of how donors are vetted, countries of
											operation, identity of all major donors.
										</p>

										<ol class="policynumberedlist">
											<li><strong>Know Your Customer (KYC)</strong> </li>
											<p className="points">The verification process looks like a web and is woven from nods. If one of
												the nodes is not passed, this part of the verification will not be passed like a hole in the web. In order to patch this
												hole, WAGMI will ask the user to provide the necessary data to fix this error. If elimination is
												not possible for a number of reasons, we will decide to either suspend or terminate the user’s
												account.
											</p>

											<p className="points">
												The first node is the Facial Image Check which consist of a Liveness check. Liveness detection
												technologies are able to determine whether a scanned face is real and alive and not a mask,
												video element, photo etc. This is to detect spoofing fraud - the impersonation of another applicant,
												computer system, or network for spreading malicious programs, exploiting network
												13 and system vulnerabilities, stealing data or bypassing certain access control elements to
												corporate
												networks.
											</p>

											<p className="points">
												<div className="green-text">&#9989; If the system is sure that the applicant is alive - the Face Image
													Check node turns green, and the verification moves on to the next nodes. </div>
											</p>

											<p className="points">
												<div className="red-text"> &#10060; If in doubt, it turns red, and the node is sent for a manual check.
													At this stage, we have to ensure that the applicant isn’t holding a mobile phone, showing any signs
													of constraint, or attempting to defraud the system using emulators, static images, or ‘deep
													fakes’.</div>
											</p>

											<p className="points">
												The second node is the Image Metadata Check, wherethe photo metadata is automatically assessed.
												This check is intended to ensure that the uploaded image hasn’t been edited electronically. This
												process evaluates an automated signature. A software signature includes file metadata, compression
												parameters, vendor or software-specific tags, sections, etc. This process involves automated
												signature and screenshot detection.
											</p>

											<p className="points">
												The third node is a Document check. This node contains several tools for verification and the
												relevant requirements for each.
											</p>

											<p className="points">
												Requirements for Identity documents
											</p>

											<ol className="policynlowarromanlist">
												<li>The Identity document must contain the following information:
													<ul className="policylist">
														<li>Owner’s full name</li>
														<li>Owner’s full date of birth (if present)</li>
														<li>Document number</li>
														<li>Validity data (issue date or validity period)</li>
														<li>Owner’s photo</li>
														<li>Owner’s signature (if applicable)</li>
													</ul>
												</li>

												<li>The Identity document must comply with the following requirements:
													<ul className="policylist">
														<li>The uploaded file is an original photo (static image) or scan (not a screenshot or a
															photo uploaded from social networks) in JPG, JPEG, PNG, or PDF</li>
														<li>If the document has data on the front and back, both sides should be uploaded</li>
														<li>The size of the uploaded file is no less than 100 KB or 300 DPI</li>
														<li>The photo is in color</li>
														<li>The information in the document is readable</li>
														<li>All corners of the document are visible, and no foreign objects or graphic elements
															are
															present</li>
														<li>The uploaded photo has not been edited with any software or converted to PDF</li>
														<li>The document is not digital (in most cases)</li>
													</ul>
												</li>
											</ol>
											<p className="points">
												Our KYC/AML service provider uses the Public Register of Authentic Identity and Travel Documents
												Online (PRADO) as one of its sources for document checks. It also has an integration with
												government databases (for example, in Brazil and Nigeria) and with local data providers such as
												GBG in the UK.
											</p>
											<p className="points">
												Where the documents are issued by a country not included in the PRADO system, it relies on a
												documents samples database and checklist that includes both national samples of documents and
												the patterns of these documents.
											</p>
											<p className="points">
												The final node is a Face Match. Sumsub’s biometric technology enables it to verify that the ID
												document truly belongs to the person. By default, the user’s face is matched against the
												document photograph automatically.
											</p>
											<li><strong>AML / Background Checks </strong></li>
											<p className="points">
												WAGMI AML verification process will be done using the full name and date of birth of the
												end-user, this information will be extracted from the document verification done in the KYC
												section. Additionally, the AML verification process is completed at registration. WAGMI AML
												verification process will be checking whether a client customer (natural person or legal entity)
												is on any global sanctions lists, PEP lists, watchlists, blacklists or adverse media (OFAC, UN,
												HMT, EU, DFT etc.).
											</p>

											<p className="points">
												The AML verification is a comprehensively robotic procedure that is trained by modern AI
												(artificial intelligence) techniques and machine learning algorithms. The end-user will simply
												upload the picture of their document, the software will run the originality check and extract
												the name and DOB from the ID. Then the software will screen the individuals against a complete
												database containing approximately 10,000 independent and reliable data sources from 200+
												countries and territories that include watchlists, sanctions, adverse media, PEPs, OFAC and
												more. Finally, the verification will be completed within 60 seconds. All sources are updated
												periodically: from 15 minutes to 48 hours after publication on the referral website.
											</p>
										</ol>

									</ul>
								</ul>

							</div>
						</div>
					)}
				</div>
			</div>
		</div >
	);
};

export default TermsAndConditions;
