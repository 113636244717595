 import React from 'react'
import CollectionBannerInfo from './collectionBannerInfo'
 
 function CollectionDetails() {
   return (
     <div style={{marginBottom:'50px'}}>
        <CollectionBannerInfo />
     </div>
   )
 }
 
 export default CollectionDetails