import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { InputAdornment } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import styles from "./BankTransfer.module.sass";

function cryptoPayment() {
  return (
    <div>
      <div className={styles.withDrawModalDescription}>
        Select the amount you want to deposit
      </div>
      {/* <div className={styles.cryptoQrCode}>
        <img src="/images/QRCode.svg" alt="qrcode" height="202px" />
      </div> */}
      <div className={styles.cryptoToUSDWrapper}>
        <div className={styles.cryptoToCurrency}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <img src={"/images/etherium-circle.jpg"} width="1.25rem" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: false }}
          />
          <div className={styles.USDValue}>
            <span className={styles.USDValueLabel}>Equivalent</span>
            <span className={styles.USDValueAmount}>1000 USD</span>
          </div>
          <div className={styles.amountCalculator}>
            <div className={styles.amountCalculatorSection}>
              <span className={styles.USDValueLabel}>Transaction Fees</span>
              <span className={styles.USDValueAmount}>10 USD</span>
            </div>
            <div className={styles.amountCalculatorSection}>
              <span className={styles.USDValueLabel}>Total amount</span>
              <span className={styles.USDValueAmount}>1010 USD</span>
            </div>
          </div>
          <div
            className={styles.formGroup}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2.25rem",
            }}
          >
            <button
              type="button"
              className={styles.formBtn}
            //   onClick={(e) => submitHandle(e)}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default cryptoPayment;
