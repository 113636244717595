import React, { useEffect, useState } from "react";
import styles from "./AnalyticsWidget.module.sass"; // Import your CSS module
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import ViewArrayIcon from "@mui/icons-material/ViewArray";
import { axiosInstance } from "../../../utils/API";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  select: {
    border: "0.063rem solid black",
    borderRadius: "0.313rem",
    padding: "0.375rem 0.5rem",
    minWidth: "7.5rem",
    height: "0.313rem",
    "&:focus": {
      backgroundColor: "transparent",
      border: "0.063rem solid black",
      boxShadow: "none", // Remove box shadow on focus
    },
  },
  icon: {
    fill: "black",
  },
  label: {
    transform: "translate(0px, -0.375rem)",
    color: "black",
    fontSize: "0.875rem",
    fontWeight: "600",
    opacity: "0.8",
    "&.Mui-focused": {
      color: "black", // Set label color when focused
    },
  },
  inputUnderline: {
    "&:before": {
      borderBottom: "none", // Remove default underline
    },
    "&:after": {
      borderBottom: "none", // Remove underline after focus
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none", // Remove underline on hover
    },
  },
}));

const AnalyticsWidget = () => {
  const screenWidth = window.innerWidth; // You might need to handle window resize events to update this value
  const [analyticsInfo, setAnalyticsInfo] = useState([]);
  const [filterByDate, setfilterByDate] = useState("year");
  const boxData = [
    {
      id: 1,
      label: "Total NFTs created",
      value: analyticsInfo?.totalNFTsCreated,
      icon: ViewColumnIcon,
    },
    {
      id: 2,
      label: "Total NFTs sold",
      value: analyticsInfo?.totalSold,
      icon: ViewCarouselIcon,
    },
    {
      id: 4,
      label: "Total orders",
      value: analyticsInfo?.totalOrder,
      icon: DashboardIcon,
    },
    {
      id: 3,
      label: "NFTs available for Sale",
      value: analyticsInfo?.remainingAsset,
      icon: ViewArrayIcon,
    },
    {
      id: 5,
      label: "Total Amount received",
      value: analyticsInfo?.receivedAmt
        ? analyticsInfo?.receivedAmt.toFixed(2)
        : "0",
      icon: PaymentsRoundedIcon,
    },
  ];

  const config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };

  useEffect(() => {
    fetchAnalyticsData(filterByDate);
  }, [filterByDate]);

  const fetchAnalyticsData = async (filterBy) => {
    try {
      const response = await axiosInstance.get(
        `/user/creator/analytics?filterBy=${filterBy}`,
        config
      );
      console.log("analytics.fetch", response);
      setAnalyticsInfo(response.data);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
      throw error;
    }
  };

  return (
    <div className={styles.analyticsWrapper}>
      <div className={styles.dateFilterContainer}>
        <DateFilter
          filterByDate={filterByDate}
          setfilterByDate={setfilterByDate}
        />
      </div>

      <div className={styles.section1}>
        {boxData?.map((box) => {
          return <BoxWidget data={box} />;
        })}
      </div>
      {/* <div className={styles.section2}>
        {boxData?.slice(2, 4)?.map((box) => {
          return <BoxWidget data={box} />;
        })}
      </div>
      <div className={styles.section3}>
        {boxData?.slice(4)?.map((box) => {
          return <BoxWidget data={box} />;
        })}
      </div> */}
    </div>
  );
};

const BoxWidget = ({ data }) => {
  const iconStyle = {
    fontSize: "2.125rem",
    width: "2.5rem",
    height: "2.5rem",
  };
  return (
    <div className={styles.boxContainer}>
      <div className={styles.iconWrapper}>
        <data.icon style={iconStyle} />
      </div>
      <div className={styles.boxInfo}>
        <div className={styles.label}>{data?.label}</div>
        <h3 className={styles.count}>{data?.value}</h3>
      </div>
    </div>
  );
};

const DateFilter = ({ filterByDate, setfilterByDate }) => {
  const classes = useStyles();

  const selectOptions = [
    { id: "year", label: "This year" },
    { id: "month", label: "This month" },
    { id: "week", label: "This week" },
  ];

  const handleChange = (event) => {
    setfilterByDate(event.target.value);
  };
  return (
    <div className={styles.dateFilterWrapper}>
      <InputLabel
        classes={{ root: classes.label }} // Apply the custom label styles
        id="selectboxInputLabel"
      >
        {/* Filter by date */}
      </InputLabel>
      <FormControl>
        {/* <InputLabel
          classes={{ root: classes.label }} // Apply the custom label styles
          id="selectboxInputLabel"
        >
          Filter by date
        </InputLabel> */}
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          classes={{
            select: classes.select,
            icon: classes.icon,
            underline: classes.inputUnderline,
          }}
          value={filterByDate}
          onChange={handleChange}
        >
          {/* <MenuItem value="">
            <em>Filter by date</em>
          </MenuItem> */}
          {selectOptions?.map((option) => (
            <MenuItem value={option?.id}>{option?.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AnalyticsWidget;
